import { uiStore } from "stores/UiStore";
import Details from "./Details/Details";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { DeleteRounded, EditRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import CampaignForm from "./CampaignForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import useApi from "hooks/useApi";
import { optionsClickOutside } from "utils/controls";
import { useSnackbar } from "notistack";
import WarningDialog from "components/common/DeleteDialog";
import { Typography } from "@mui/material";

function CampaignDetails() {
    const { t } = useTranslation();
    const { campaignName } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const api = useApi();
    const { setOptions } = uiStore;
    const { enqueueSnackbar } = useSnackbar();
    const [editCampaign, setEditCampaign] = useState(false);
    const [deleteCampaignDialog, setDeleteCampaignDialog] = useState(false);

    const campaignQuery = useQuery(['campaign', campaignName],
        () => api.get(`/campaigns/${campaignName}/`).then(res => res.data),
        {
            enabled: !!campaignName,
            refetchOnWindowFocus: true,
        }
    );

    const { data: campaign, isLoading: campaignLoading } = campaignQuery;

    const { mutate: updateCampaign, isLoading: campaignUpdating } = useMutation(
        (data) => api.put(`/campaigns/${campaign?.id}`, data),
        {
            onSuccess: (res) => {
                enqueueSnackbar(t('Campaign updated successfully.'), { variant: 'success' });
                navigate(location.pathname.split('/').slice(0, -1).join('/') + `/${res.data.name}`);
                campaignQuery.refetch();
                setEditCampaign(false);
                optionsClickOutside();
            },
            onError: () => enqueueSnackbar(t('Error updating campaign.'), { variant: 'error' }),
        }
    );

    const { mutate: deleteCampaign, isLoading: campaignDeleting } = useMutation(
        () => api.delete(`/campaigns/${campaign?.id}`),
        {
            onSuccess: () => {
                enqueueSnackbar(t('Campaign deleted successfully.'), { variant: 'success' });
                setDeleteCampaignDialog(false);
                navigate(location.pathname.split('/').slice(0, -1).join('/'));
                optionsClickOutside();
            },
            onError: () => enqueueSnackbar(t('Error deleting campaign.'), { variant: 'error' }),
        }
    );

    useEffect(() => {
        setOptions([
            {
                key: 'edit-campaign',
                text: t('Edit Campaign'),
                icon: <EditRounded />,
                onClick: () => setEditCampaign(true),
            },
            {
                key: 'delete-campaign',
                text: t('Delete Campaign'),
                icon: <DeleteRounded />,
                onClick: () => setDeleteCampaignDialog(true),
            }
        ]);

        return () => setOptions([]);
    }, []);

    return (
        <>
            <Details key='campaign' type='campaign' data={campaign} loading={campaignLoading} />
            {editCampaign &&
                <CampaignForm
                    title={t('Edit Campaign')}
                    data={campaign}
                    onClose={() => setEditCampaign(false)}
                    onSave={(data) => updateCampaign(data)}
                    saving={campaignUpdating}
                    type='campaigns'
                />
            }
            <WarningDialog
                open={deleteCampaignDialog}
                requireInput
                title={t('Delete Campaign')}
                text={
                    <div>
                        <Typography variant='subtitle2' fontWeight={600} gutterBottom>
                            {t('All the Sub Campaigns, Teams and Fundraisers under')} <strong>{campaign?.name}</strong> {t('will be deleted.')}
                        </Typography>
                        <Typography variant='subtitle2' fontWeight={600} gutterBottom>
                            {t('All your associations with donations, pledges and donors will be lost. (They will not be deleted)')}
                        </Typography>
                    </div>
                }
                onClose={() => setDeleteCampaignDialog(false)}
                onDelete={() => deleteCampaign()}
                deleteInputText={t('delete campaign')}
                approveText={t('Delete')}
            />
        </>
    );
}

export default observer(CampaignDetails);