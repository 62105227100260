

function Total ({ prefix, amount, suffix, description}) {
    return (
        <div className="">
            <div className="flex justify-center items-end text-lg leading-snug">
                <p className="">{prefix}
                <span className="ms-1 text-4xl">{amount}</span>
                {suffix}</p>
            </div>
            <p>{description}</p>
        </div>
    )
}

export default Total;