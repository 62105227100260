import { useEffect, useState } from "react";
import WaitingListModal from "../home/HomePage/waitnigListForm"
import DRMAlert from "../home/HomePage/alerts";
import { HiCheckCircle } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

function RedirectToWaitingList() {
    const [waitngListFormOpen, setWaitingListFormOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/waiting-list');
    }, []);

    return (
        <div className='w-full h-screen bg-gray-800 text-white text-center flex justify-center items-center align-middle'>
            <div className="max-w-lg">
                <h1 className="text-4xl font-bold mb-4">
                    Request Early Access
                </h1>
                <p className="text-lg mb-10">
                    Donbar is now inviting select organizations to join our beta release. Enhance your contribution management with our cutting-edge platform.
                </p>
                <button
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                    onClick={() => setWaitingListFormOpen(true)}
                >
                    Request Invitation
                </button>
                <WaitingListModal
                    open={waitngListFormOpen}
                    setOpen={setWaitingListFormOpen}
                    setAlert={setShowAlert}
                />
                <DRMAlert
                    open={showAlert}
                    title={"Thank You!"}
                    text={"You have succesfully joined the waiting list. We will get back to you soon!"}
                    type={"success"}
                    icon={<HiCheckCircle />}
                />
            </div>
        </div>
    )
}

export default RedirectToWaitingList