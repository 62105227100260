import { Paper } from '@mui/material';

function Section({ children, sx, ...props }) {
    return (
        <Paper
            sx={{
                borderRadius: '1rem',
                p: '1.75rem',
                my: '1.5rem',
                ...sx
            }}
            elevation={0}
            {...props}
        >
            {children}
        </Paper>
    )
}

export default Section;