import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import H1 from "components/common/Typography/H1"
import LoadingComponent from "components/Loading"
import currencies from 'data/currencies';
import SearchContact from "../../../../components/general/SearchContact"
import { Autocomplete, Fab, FormControl, IconButton, InputLabel, MenuItem, Popover, Select, TextField } from "@mui/material"
import { expenseMethods } from "data/general"
import { LoadingButton } from "@mui/lab"
import DeleteDialog from "components/common/DeleteDialog"
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Options from "components/Options"
import useApi from "hooks/useApi"

function ExpenseView() {
    const { id } = useParams()
    const { t } = useTranslation()
    const api = useApi();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [categories, setCategories] = useState([])
    const [expenseId, setExpenseId] = useState(null)
    const [date, setDate] = useState(new Date().toISOString().slice(0, 16))
    const [amount, setAmount] = useState('')
    const [currency, setCurrency] = useState(localStorage.getItem('currency') || 'USD')
    const [paymentMethod, setPaymentMethod] = useState('')
    const [category, setCategory] = useState('')
    const [contact, setContact] = useState('')
    const [contactId, setContactId] = useState('')
    const [description, setDescription] = useState('')
    const [notes, setNotes] = useState('')

    const [optionsOpen, setOptionsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState('');
    const [deleteText, setDeleteText] = useState('');

    const [submitData, setSubmitData] = useState({
        date: date,
        amount: amount,
        currency: currency,
    });
    const [saving, setSaving] = useState(false);

    const currencyValues = Object.keys(currencies)

    const options = [
        {
            text: t('Delete expense'),
            icon: <DeleteOutlineRoundedIcon />,
            onClick: () => {
                setDeleteDialogOpen(true);
                setDeleteTitle(t('Delete Expenses'));
                setDeleteText(`${t('Are you sure you want to delete expense')} ${id}?`);
            }
        },
    ]

    useEffect(() => {
        api.get('/expenses/categories/').then((response) => {
            setCategories(response.data)
        }).catch((error) => {
            console.log(error)
        })

        if (id === 'new') return setLoading(false)

        api.get(`/expenses/`, {
            params: {
                expense_id: id,
            },
        }).then((res) => {
            const response = res.data.results[0]
            setExpenseId(response.expense_id)
            setDate(new Date(response?.date).toISOString().slice(0, 16))
            setAmount(response.amount)
            setCurrency(response.currency)
            setCategory(response?.category?.id)
            setPaymentMethod(response.payment_method)
            setContactId(response?.contact?.id)
            setContact((response?.contact?.first_name || '') + (response?.contact?.first_name ? ' ' : '') + (response?.contact?.last_name || ''));
            setDescription(response.description)
            setNotes(response.notes)
            setSubmitData({
                date: new Date(response?.date).toISOString().slice(0, 16),
                amount: response.amount,
                currency: response.currency,
            })
            setLoading(false)
        }).catch((error) => {
            console.log(error)
        })

    }, [id])

    useEffect(() => {
        console.log(submitData);
    }, [submitData]);

    const handleInputChange = (name, value) => {
        console.log(name, value);
        setSubmitData(prevState => {
            let newState = { ...prevState };

            if (!value && value !== '') {
                delete newState[name];
            } else {
                newState[name] = value;
            }

            return newState;
        });
    };

    const handleNameChange = (e) => {
        setContactId('');
        if (typeof e === 'string' || !e) {
            handleInputChange('contact_name', e);
            handleInputChange('contact', '');
        } else {
            handleInputChange('contact_name');
            handleInputChange('contact', e.id);
        }
    };

    const handleDelete = () => {
        api.delete(`/expenses/update/`, {
            params: {
                expense_ids: JSON.stringify([id]),
            },
        }).then((res) => {
            navigate(`/expenses`)
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleSave = () => {
        setSaving(true);
        if (id === 'new') {
            api.post(`/expenses/update/`, submitData).then((res) => {
                setSaving(false);
                navigate(`/expenses/${res.data}`)
            }).catch((error) => {
                console.log(error)
            })
        } else {
            api.put(`/expenses/update/`, submitData, {
                params: {
                    expense_id: expenseId,
                },
            }).then((res) => {
                setSaving(false);
            }).catch((error) => {
                console.log(error)
            })
        }
    }


    const renderExpense = () => (
        <div className="mt-12 bg-white rounded-2xl shadow-sm p-10">
            <div className="flex flex-col gap-y-8">
                <fieldset className="relative flex gap-x-8 mb-2">
                    <div className="w-full">
                        <SearchContact
                            onChange={(e) => handleNameChange(e)}
                            freeSolo={true}
                            value={contact}
                            placeholder={t('Name')}
                            label={t('Name')}
                        />
                    </div>
                    <TextField
                        className="w-2/3"
                        variant="standard"
                        label={t('Date')}
                        placeholder={t('Date')}
                        type='datetime-local'
                        value={date}
                        onChange={(e) => {
                            setDate(e.target.value);
                            handleInputChange('date', e.target.value);
                        }}
                    />
                </fieldset>
                <fieldset className="flex gap-x-8 mb-2">
                    <TextField
                        className="w-full"
                        variant="standard"
                        label={t('Amount')}
                        placeholder={t('Amount')}
                        value={amount}
                        onChange={(e) => {
                            setAmount(e.target.value);
                            handleInputChange('amount', parseFloat(e.target.value));
                        }}
                    />
                    <Autocomplete
                        clearOnEscape
                        className="w-full"
                        disableClearable
                        options={currencyValues}
                        getOptionLabel={(option) => t(currencies[option].name)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label={t("Currency")}
                                variant="standard"
                            />}
                        filterSelectedOptions={false}
                        value={currency}
                        onChange={(event, newValue) => {
                            setCurrency(newValue);
                            localStorage.setItem('currency', newValue);
                            handleInputChange('currency', newValue);
                        }}
                    />
                    <FormControl className="w-full text-start">
                        <InputLabel id="payment-method-label" variant="standard">{t('Payment Method')}</InputLabel>
                        <Select
                            labelId="payment-method-label"
                            variant="standard"
                            value={paymentMethod}
                            onChange={(e) => {
                                setPaymentMethod(e.target.value);
                                handleInputChange('payment_method', e.target.value);
                            }}
                        >
                            {expenseMethods.map((method) => (
                                <MenuItem key={method.value} value={method.value}>{t(method.label)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className="w-full text-start">
                        <InputLabel id="category-method-label" variant="standard">{t('Expense Type')}</InputLabel>
                        <Select
                            labelId="category-method-label"
                            variant="standard"
                            value={category}
                            onChange={(e) => {
                                setCategory(e.target.value);
                                handleInputChange('category', e.target.value);
                            }}
                        >
                            <MenuItem value={''}>{t('None')}</MenuItem>
                            {categories.map((cat) => (
                                <MenuItem key={cat.id} value={cat.id}>{t(cat.name)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </fieldset>
                <fieldset className="flex flex-col gap-y-8 mb-2">
                    <TextField
                        className="w-1/2"
                        variant="standard"
                        label={t('Description')}
                        placeholder={t('Description')}
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value);
                            handleInputChange('description', e.target.value);
                        }}
                    />
                    <TextField
                        className="w-full"
                        variant="standard"
                        label={t('Notes')}
                        placeholder={t('Notes')}
                        value={notes}
                        onChange={(e) => {
                            setNotes(e.target.value);
                            handleInputChange('notes', e.target.value);
                        }}
                    />
                </fieldset>
            </div>
            <LoadingButton
                sx={{ borderRadius: 99, mt: '4rem' }}
                variant="contained"
                color="primary"
                size='large'
                pending={saving}
                onClick={handleSave}
            >
                {t('Save')}
            </LoadingButton>
        </div>
    )

    return (
        <>
            <div className="flex float-end items-center">
                <Options options={options} />
            </div>
            <H1>{id === 'new' ? t('New Expense') : `${t('Expense')} ${expenseId}`}</H1>
            {loading ? <LoadingComponent /> : renderExpense()}
            <DeleteDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onDelete={handleDelete}
                title={deleteTitle}
                text={deleteText}
            />
        </>
    )
}

export default ExpenseView;