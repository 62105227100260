import { makeAutoObservable } from 'mobx';
import i18n from '../i18n';

class MainStore {
    organizations = null;
    orgId = null;
    subscription = null;
    settings = null;
    appLoaded = false;
    emailPlans = [];

    constructor() {
        makeAutoObservable(this);
    }

    setOrganizations(organizations) {
        this.organizations = organizations;
        this.setOrgIdBasedOnLocalStorage();
    }

    setOrgId(orgId) {
        this.orgId = orgId;
        localStorage.setItem('lastSelectedOrgId', orgId);
    }

    setSubscription(subscription) {
        this.subscription = subscription;
    }

    setSettings(settings) {
        this.settings = settings;
        i18n.changeLanguage(settings.language);
        document.documentElement.dir = i18n.dir(settings.language);
    }

    setEmailPlans(emailPlans) {
        const updatedPlans = emailPlans.map(plan => {
            const price = plan['price_' + this.billingCurrency];
            delete plan['price_ILS'];
            delete plan['price_USD'];
            return { ...plan, price };
        });
        this.emailPlans = updatedPlans;
    }

    setOrgIdBasedOnLocalStorage() {
        const lastSelectedOrgId = localStorage.getItem('lastSelectedOrgId');
        const orgExists = this.organizations.some(org => org.org_id === lastSelectedOrgId);
        if (lastSelectedOrgId && orgExists) {
            this.setOrgId(lastSelectedOrgId);
        } else if (this.organizations.length > 0) {
            this.setOrgId(this.organizations[0].org_id);
        }
    }

    setAppLoaded(appLoaded) {
        this.appLoaded = appLoaded;
    }

    get billingCurrency() {
        if (this.subscription?.currency) return this.subscription?.currency;
        if (['USD', 'ILS'].includes(this.settings?.currency)) return this.settings?.currency;
        return this.settings?.language === 'he' ? 'ILS' : 'USD';
    }

    get emailPlan() {
        const getPlan = this.emailPlans.find(plan =>
            plan.amount === this.subscription?.email_subscription?.limit &&
            plan?.price === this.subscription?.email_subscription?.price
        );
        if (getPlan) return getPlan;
        return {
            name: 'Custom',
            amount: this.subscription?.email_subscription?.limit,
            price: this.subscription?.email_subscription?.price,
            description: 'Custom plan',
        };
    }

    get nextEmailPlan() {
        if (!this.subscription?.email_subscription?.next_email_subscription) return null;
        const getPlan = this.emailPlans.find(plan =>
            plan.amount === this.subscription?.email_subscription?.next_email_subscription?.limit &&
            plan?.price === this.subscription?.email_subscription?.next_email_subscription?.price);
        if (getPlan) return getPlan;
        return {
            name: 'Custom',
            amount: this.subscription?.email_subscription?.next_email_subscription?.limit,
            price: this.subscription?.email_subscription?.next_email_subscription?.price,
            description: 'Custom plan',
        };
    }

    get nextTotalBilling() {
        if (this.subscription?.next_billing_amount) {
            return Object.values(this.subscription.next_billing_amount).reduce((a, b) => a + b, 0);
        }
        return null;
    }

    get roamingDays() {
        const date = new Date(this.subscription?.next_billing_date).getDate();
        const today = new Date().getDate();
        return date - today;
    }
}

export const mainStore = new MainStore();