import Section from "components/common/Section";
import ContactForm from "./ContactForm";
import { uiStore } from "stores/UiStore";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import useApi from "hooks/useApi";
import { useSnackbar } from "notistack";
import { contactsStore } from "stores/ContactsStore";
import { useNavigate } from "react-router-dom";

function NewContact() {
    const { t } = useTranslation();
    const { setTitle } = uiStore;
    const { enqueueSnackbar } = useSnackbar();
    const { location: prevLocation } = contactsStore;
    const api = useApi();
    const navigate = useNavigate();

    useEffect(() => {
        setTitle(t("New Contact"));
        return () => setTitle("");
    }, []);

    const createContact = useMutation(
        (data) => api.post("/contacts/create", data),
        {
            onSuccess: (res) => {
                navigate(`/contacts/${res.data.contact_id}`);
                enqueueSnackbar(t("Contact created successfully"), { variant: "success" });
            }
        }
    );

    return (
        <Section>
            <ContactForm contactMutation={createContact} backLocation={prevLocation} />
        </Section>
    );
}

export default NewContact;