import Lists from "../../../../components/Lists";

function ContactsLists() {
  return (
    <div>
      <Lists
        listSet="contacts"
      />
    </div>
  )
}

export default ContactsLists;