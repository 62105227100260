import React from "react";
import Container from "./container";
import { Disclosure } from "@headlessui/react";
import { HiChevronUp } from "react-icons/hi";

const Faq = () => {
  return (
    <Container className="!p-0">
      <div className="w-full max-w-2xl p-2 mx-auto rounded-2xl">
        {faqdata.map((item, index) => (
          <div key={item.question} className="mb-5">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex items-center justify-between w-full px-4 py-4 text-lg text-start text-gray-800 rounded-lg bg-gray-50 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-indigo-100 focus-visible:ring-opacity-75 dark:bg-gray-800 dark:text-gray-200">
                    <span>{item.question}</span>
                    <HiChevronUp
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-indigo-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-gray-500 dark:text-gray-300">
                    {item.answer}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        ))}
      </div>
    </Container>
  );
}

const faqdata = [
  {
    question: "Does Donbar take a percentage of the donations?",
    answer: "No, we believe in your cause. 100% of your donations go towards your organization's good work.",
  },
  {
    question: "How much does Donbar cost?",
    answer: "Our basic subscription, which covers up to 120 donations per year, is absolutely free. For more that that, we offer a subscriptions from $39 per month.",
  },
  {
    question: "Do you offer a free trial?",
    answer:
      "Absolutely! We offer a 30-day free trial, so you can experience the benefits of Donbar firsthand.",
  },
  {
    question: "What is your refund policy?",
    answer:
      "We stand behind our promise. We guarantee that Donbar will save you both time and money. If you feel otherwise, we offer a full money-back guarantee. Your satisfaction is our priority.",
  },
];

export default Faq;