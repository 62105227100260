import { Campaign, NavigateNextRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, ListSubheader, MenuItem, Select, TextField, Typography } from "@mui/material";
import useApi from "hooks/useApi";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import CampaignsDropdown from "./CampaignsDropdown";

function AddDonorProspect({ open, onClose, onConfirm, processing }) {
    const { t } = useTranslation();
    const api = useApi();
    const [selectedCampaign, setSelectedCampaign] = useState([]);
    const [fundraiser, setFundraiser] = useState(null);

    const fundraiserParams = {
        campaign: selectedCampaign.find(item => item.type === 'campaign')?.id,
        sub_campaign: selectedCampaign.find(item => item.type === 'sub_campaign')?.id || null,
        group: selectedCampaign.find(item => item.type === 'group')?.id || null,
    }

    const { data: fundraisersData, isLoading: fundraisersLoading } = useQuery(['fundraisersDropdown', fundraiserParams],
        () => api.get('/campaigns/fundraisers/dropdown', { params: fundraiserParams }).then(res => res.data),
        {
            refetchOnWindowFocus: true,
            enabled: open && selectedCampaign.length > 0
        }
    );

    const fundraisers = fundraisersData || [];

    const parseSelectedValue = () => {
        const data = selectedCampaign.reduce((acc, item) => {
            if (item.type === 'campaign') {
                acc.campaign_id = item.id
            } else if (item.type === 'sub_campaign') {
                acc.sub_campaign_id = item.id
            } else if (item.type === 'group') {
                acc.group_id = item.id
            }
            return acc;
        }, {});

        if (fundraiser) {
            data.fundraiser_id = fundraiser.id;
        }

        return data;
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {t('Add Donor Prospect')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('Add a new donor prospect to a Campaign, Sub Campaign, Group or Fundraiser.')}
                </DialogContentText>
                <CampaignsDropdown onChange={setSelectedCampaign} />
                <Autocomplete
                    fullWidth
                    disabled={selectedCampaign.length === 0}
                    options={fundraisers}
                    getOptionLabel={(option) => `${option.name} (${option.contact_id})`}
                    sx={{ mt: 2 }}
                    loading={fundraisersLoading}
                    renderInput={(params) => <TextField {...params} label={t('Fundraiser')} />}
                    onChange={(e, value) => setFundraiser(value)}
                />
            </DialogContent>
            <DialogActions sx={{ mx: 1, mb: 1 }}>
                <Button onClick={onClose}>{t('Cancel')}</Button>
                <LoadingButton
                    loading={processing}
                    variant="contained"
                    onClick={() => onConfirm(parseSelectedValue())}
                    disabled={selectedCampaign.length === 0}
                >
                    {t('Add')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default AddDonorProspect;