
import { HiOutlineMail } from 'react-icons/hi';
import { HiFaceSmile, HiChartBarSquare, HiCursorArrowRays, HiDevicePhoneMobile, HiAdjustmentsHorizontal, HiSun } from 'react-icons/hi2';
import { MdNotificationsActive } from 'react-icons/md';
import { GoChecklist } from "react-icons/go";
import saveTime from '../../img/save-time.svg';
import noMissedDonations from '../../img/no-missed-donations.svg';
import stressLess from '../../img/stress-less.svg';


const benefitOne = {
    title: "Ready to ditch the spreadsheets?",
    desc: "Experience the ease and convenience of managing donations and donors with Donbar. Say hello to organization and goodbye to spreadsheets!",
    image: "/img/benefit-one.png",
    bullets: [
        {
            title: "Donor Management",
            desc: "Keep your donor details organized and at your fingertips. With Donbar, no potential donor detail slips through the cracks",
            icon: <HiFaceSmile />,
        },
        {
            title: "Donation Tracking",
            desc: "Stay on top of your donations with Donbar's intuitive tracking system. Rest assured, every donation is accounted for",
            icon: <HiChartBarSquare />,
        },
        {
            title: "Donor Retention",
            desc: "Keep your donors engaged and updated. With Donbar's communication features, show them how their contributions are making a difference.",
            icon: <HiCursorArrowRays />,
        },
    ],
};

const benefitThree = {
    title: "Stay Organized, Maintain Your Head Space",
    desc: "Experience a new level of organization with Donbar. Say goodbye to the chaotic mix of documents, reports, and manual tracking that leaves you feeling overwhelmed. With our intuitive platform, you can easily manage all of your donation tracking needs in one place. You'll never again worry about forgetting a donation or a crucial thank you letter. Donbar keeps track of everything for you, sending reminders and updates so nothing slips through the cracks. With Donbar, you're not just organized, you're in control.",
    image: stressLess,
    bullets: [],
};

const benefitFour = {
    title: "Reclaim Over 90% of Your Time",
    desc: "Harness the power of Donbar and see significant time savings. Our users report that they save more than 90% of their time previously spent managing donations and related tasks. Let Donbar do the work so you can focus on your mission.",
    image: saveTime,
    bullets: [],
};

const benefitTwo = {
    title: "Worried about missing out on donations?",
    desc: "Donbar has got your back! Our system alerts you if a recurring donation fails to come in or is about to expire. Say goodbye to mental checklists and constant worries.",
    image: noMissedDonations,
    bullets: [
        {
            title: "Get a reminder about missed donations",
            desc: "Donbar would ensure every donation counts and no opportunity slips away",
            icon: <MdNotificationsActive />,
        },
        {
            title: "Track Pledges",
            desc: "with Donbar you can track pledges and ensure they are fulfilled",
            icon: <GoChecklist />,
        },
        {
            title: "Email your donors",
            desc: "Send thank-you letters to donors in a click.",
            icon: <HiOutlineMail />,
        },
    ],
};


export { benefitOne, benefitTwo, benefitThree, benefitFour };
