import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

const CurrencyMask = forwardRef(function CurrencyMask(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            // allow only integers
            decimalScale={0}
        // prefix="$"
        />
    );
});

export { CurrencyMask }