import { Pagination as MuiPagination, PaginationItem } from "@mui/material"
import { NavigateBefore, NavigateNext } from "@mui/icons-material"


function Pagination({ totalPages = 1, currentPage = 1, onChange, sx = {} }) {

    return (
        <MuiPagination
            count={totalPages}
            page={currentPage}
            onChange={(e, page) => onChange(page)}
            color="primary"
            shape="rounded"
            sx={{ ...sx, display: 'flex', justifyContent: 'center' }}
            renderItem={item => (
                <PaginationItem
                    components={{ previous: NavigateNext, next: NavigateBefore }}
                    {...item}
                />
            )}
        />
    )
}

export default Pagination