import { useTranslation } from "react-i18next";
import H1 from "components/common/Typography/H1";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingComponent from "components/Loading";
import { Avatar, Fab, IconButton, Popover, Tooltip } from "@mui/material";
import DeleteDialog from "components/common/DeleteDialog";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import AddIcon from '@mui/icons-material/Add';
import TaskDetails from "./TaskDetails";
import Options from "components/Options";
import { localDateTime } from "utils/dates";
import useApi from "hooks/useApi";

const groupedNames = {
    overdue: 'Overdue',
    nextHour: 'Next Hour',
    today: 'Today',
    tomorrow: 'Tomorrow',
    thisWeek: 'This Week',
    nextWeek: 'Next Week',
    thisMonth: 'This Month',
    later: 'Later',
    noDate: 'No Date',
    completed: 'Completed',
}

function Tasks() {
    const { t } = useTranslation();
    const api = useApi();
    // const { formatDate, formatDatetime, direction } = useLanguage();
    const [loading, setLoading] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [groupedTasks, setGroupedTasks] = useState({});
    const [updated, setUpdated] = useState(false);

    const [checkedItems, setCheckedItems] = useState([]);
    const [checkAll, setCheckAll] = useState(false);

    const pageSizes = [50, 100, 250, 500];
    const [pageSize, setPageSize] = useState(localStorage.getItem('tasksPageSize') || pageSizes[1]);
    const pagePadding = 2;
    const visiblePages = 3;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState('');
    const [deleteText, setDeleteText] = useState('');

    const [taskDetailsOpen, setTaskDetailsOpen] = useState(false);
    const [newTask, setNewTask] = useState(false);
    const [editId, setEditId] = useState();

    const pagination = () => {
        const pages = [];

        for (let i = 1; i <= Math.min(2, totalPages); i++) {
            pages.push(i);
        }

        if (currentPage - visiblePages - pagePadding > 1) {
            pages.push('...');
        }

        for (let i = currentPage - visiblePages; i <= currentPage + visiblePages; i++) {
            if (i > pagePadding && i <= totalPages - pagePadding) {
                pages.push(i);
            }
        }

        if (currentPage + visiblePages + pagePadding < totalPages) {
            pages.push('...');
        }

        for (let i = totalPages - 1; i <= totalPages; i++) {
            if (i > 2) {
                pages.push(i);
            }
        }

        return pages;
    };

    const options = [
        {
            text: `${t('Delete')} ${checkedItems.length > 0 ? checkedItems.length : ''} ${t('Tasks')}`,
            icon: <DeleteOutlineRoundedIcon />,
            onClick: () => {
                setDeleteDialogOpen(true);
                setDeleteTitle(t('Delete Tasks'));
                setDeleteText(`${t('Are you sure you want to delete')} ${checkedItems.length} ${t('tasks')}?`);
            },
            disabled: checkedItems.length === 0,
        }
    ]

    const handleCheckboxClick = (event, taskId) => {
        // Prevent this click from being propagated to parent elements (like the row)
        event.stopPropagation();

        handleCheck(taskId);
    };

    useEffect(() => {
        setLoading(true);
        api.get('/tasks/', {
            params: {
                page_size: pageSize,
                page: currentPage,
            },
        }).then(res => {
            setTasks(res.data.results);
            setTotalPages(Math.ceil(res.data.count / pageSize));
            const grouped = res.data.results.reduce((acc, task) => {
                const dueDate = new Date(task.due_date);
                const now = new Date();
                const hour = new Date();
                hour.setHours(now.getHours() + 1)
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                today.setDate(now.getDate() + 1);
                const tomorrow = new Date();
                tomorrow.setHours(0, 0, 0, 0);
                tomorrow.setDate(now.getDate() + 2);
                const thisWeek = new Date();
                thisWeek.setHours(0, 0, 0, 0);
                thisWeek.setDate(now.getDate() + (7 - thisWeek.getDay()));
                const nextWeek = new Date();
                nextWeek.setHours(0, 0, 0, 0);
                nextWeek.setDate(now.getDate() + (14 - nextWeek.getDay()));
                const thisMonth = new Date();
                thisMonth.setHours(0, 0, 0, 0);
                thisMonth.setMonth(now.getMonth() + 1);
                thisMonth.setDate(1);
                const completed = task.completed_date ? true : false;

                if (completed) {
                    acc.completed.push(task);
                } else if (!task.due_date) {
                    acc.noDate.push(task);
                } else if (dueDate < now) {
                    acc.overdue.push(task);
                } else if (dueDate < hour) {
                    acc.nextHour.push(task);
                } else if (dueDate < today) {
                    acc.today.push(task);
                } else if (dueDate < tomorrow) {
                    acc.tomorrow.push(task);
                } else if (dueDate < thisWeek) {
                    acc.thisWeek.push(task);
                } else if (dueDate < nextWeek) {
                    acc.nextWeek.push(task);
                } else if (dueDate < thisMonth) {
                    acc.thisMonth.push(task);
                } else {
                    acc.later.push(task);
                }
                return acc;
            }, {
                overdue: [],
                nextHour: [],
                today: [],
                tomorrow: [],
                thisWeek: [],
                nextWeek: [],
                thisMonth: [],
                later: [],
                noDate: [],
                completed: [],
            });
            setGroupedTasks(grouped);
            setLoading(false);
        }).catch(err => {
            console.error(err);
        });
    }, [updated, currentPage, pageSize]);

    const handleCheck = (task) => {
        setCheckedItems((prev) => {
            if (prev.includes(task)) {
                setCheckAll(false);
                return prev.filter((item) => item !== task);
            } else {
                const newItems = [...prev, task];
                if (newItems.length === tasks.length) {
                    setCheckAll(true);
                }
                return newItems;
            }
        });
    };

    const handleCheckAll = () => {
        setCheckAll(prev => !prev);
        if (!checkAll) {
            const allTasks = tasks.map((task) => task.id);
            setCheckedItems(allTasks);
        } else {
            setCheckedItems([]);
        }
    };

    const handleDelete = () => {
        api.delete('/tasks/delete/', {
            params: {
                task_ids: JSON.stringify(checkedItems),
            },
        }).then(() => {
            setUpdated(prev => !prev);
            setCheckedItems([]);
            setDeleteDialogOpen(false);
            setOptionsOpen(false);
        }).catch(err => {
            console.error(err);
        });
    };

    const renderTasks = () => (
        <div className="container mx-auto text-start">
            <table className="table-auto border-collapse w-full mb-10">
                <thead>
                    <tr className="rounded-lg text-sm font-medium text-gray-700">
                        <th className="px-4 py-2">
                            <div className="flex justify-center items-center h-full">
                                <input
                                    type="checkbox"
                                    onChange={handleCheckAll}
                                    checked={checkAll}
                                    className="h-4 w-4"
                                />
                            </div>
                        </th>
                        <th className="px-4 py-2">{t('Status')}</th>
                        <th className="px-4 py-2">{t('Task')}</th>
                        <th className="px-4 py-2">{t('Due Date')}</th>
                        <th className="px-4 py-2">{t('User')}</th>
                        <th className="px-4 py-2">{t('Task Type')}</th>
                        <th className="px-4 py-2">{t('Description')}</th>
                    </tr>
                </thead>
                {Object.keys(groupedTasks).map((key) => {
                    if (groupedTasks[key].length) return (
                        <tbody key={key} className="text-sm font-normal after:block after:h-12 text-gray-700 rounded-2xl ">
                            <tr className="border-b border-gray-200 bg-white">
                                <td className="px-4 py-4 rounded-t-2xl font-bold text-xl" colSpan='10'>
                                    {t(groupedNames[key])} ({groupedTasks[key].length})
                                </td>
                            </tr>
                            {groupedTasks[key].map((task, index) => (
                                <tr
                                    key={task.id}
                                    className={`cursor-pointer hover:bg-gray-100 bg-white ${index === groupedTasks[key].length - 1 ? '' : 'border-b '} border-gray-200 py-10`}
                                    onClick={() => {
                                        setNewTask(false)
                                        setEditId(task.id)
                                        setTaskDetailsOpen(true)
                                    }}
                                >
                                    {/* <td className={`px-4 py-4 ${index === 0 && 'rounded-ts-2xl'} */}
                                    <td className={`px-4 py-4
                            ${index === groupedTasks[key].length - 1 && 'rounded-bs-2xl'}`}>
                                        <div className="flex justify-center items-center h-full">
                                            <input
                                                type="checkbox"
                                                // onChange={() => handleCheck(task.id)}
                                                onClick={(e) => handleCheckboxClick(e, task.id)}
                                                checked={checkedItems.includes(task.id)} className="h-4 w-4" />
                                        </div>
                                    </td>
                                    <td className="px-4 py-4">
                                        {task.completed_date &&
                                            <div className="bg-green-100 text-xs font-semibold px-2 py-1 w-fit text-green-700 rounded-md">
                                                {t('Done')}
                                            </div>}
                                        {!task.completed_date && task.due_date && new Date(task.due_date) < new Date() &&
                                            <div className="bg-red-100 text-xs font-semibold px-2 py-1 w-fit text-red-700 rounded-md">
                                                {t('Overdue')}
                                            </div>}
                                        {/* {!task.completed_date && !task.due_date &&
                                    <div className="bg-yellow-100 text-xs font-semibold px-2 py-1 w-fit text-yellow-700 rounded-md">
                                        {t('No due date')}
                                    </div>} */}
                                    </td>
                                    <td className="px-4 py-4">
                                        {task?.contact?.contact_id &&
                                            <Link to={`/contacts/${task?.contact?.contact_id}`} className="font-semibold hover:text-blue-900">
                                                {task?.contact?.first_name} {task?.contact?.last_name}&nbsp;
                                            </Link>}
                                        {task.title}
                                    </td>
                                    <td className="px-4 py-4">
                                        {task.due_date && localDateTime(task.due_date)}
                                    </td>
                                    <td className="px-4 py-2">
                                        <div className="font-semibold flex gap-2 h-full my-auto align-middle items-center">
                                            <Tooltip title={task.user_name} placement="top">
                                                <div className="font-semibold flex gap-2 h-full my-auto align-middle items-center">
                                                    <Avatar sx={{ height: '2rem', width: '2rem', border: '1px solid #1e3a8a' }} src={task.user_picture_url} alt={task.user_name} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td className="px-4 py-4">
                                        {task?.task_type?.name}
                                    </td>
                                    {/* <td className={`px-4 py-4 ${index === 0 && 'rounded-te-2xl'} */}
                                    <td className={`px-4 py-4 
                            ${index === groupedTasks[key].length - 1 && 'rounded-be-2xl'}`}>
                                        <div className='flex-shrink min-w-0 max-w-xs truncate-2-lines'>
                                            {task.description}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>)
                })}
            </table>
            <div className="flex justify-end">
                <div className='fixed bottom-10 text-end mx-10'>
                    <Fab
                        color='primary'
                        onClick={() => {
                            setTaskDetailsOpen(true)
                            setNewTask(true)
                        }}
                    >
                        <AddIcon />
                    </Fab>
                </div>
            </div>
            <div className="ltr flex items-center justify-center">
                {pagination().map((page, index) => (
                    <button
                        key={index}
                        className={`mx-1 px-3 py-1 rounded-md text-sm font-medium ${page === currentPage
                            ? 'bg-blue-900 text-white'
                            : 'text-gray-700 hover:bg-gray-200'
                            }`}
                        onClick={() => { typeof page === 'number' && setCurrentPage(page) }}
                        disabled={typeof page !== 'number'}
                    >
                        {page}
                    </button>
                ))}
            </div>
        </div>
    )

    return (
        <>
            <DeleteDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onDelete={handleDelete}
                title={deleteTitle}
                text={deleteText}
            />
            <div className="flex float-end items-center">
                <Options options={options} />
            </div>
            <H1>{t('Tasks')}</H1>
            {loading ? <LoadingComponent /> : renderTasks()}
            {taskDetailsOpen &&
                <TaskDetails
                    open={taskDetailsOpen}
                    onClose={() => {
                        setTaskDetailsOpen(false)
                        setEditId(null)
                    }}
                    newTask={newTask}
                    id={editId}
                    setUpdated={() => setUpdated(!updated)}
                />}
        </>
    )
}

export default Tasks;