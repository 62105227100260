import { CircularProgress, Modal } from "@mui/material";

function LoadingModal({ open, title, message }) {
    return (
        <Modal open={open} className="flex justify-center items-center">
            <div className="bg-white flex justify-center rounded-2xl p-4 w-1/3">
                <div className="flex flex-col gap-y-4 items-center">
                    <p className="text-gray-700 text-base font-bold">{title}</p>
                    <CircularProgress size={100} thickness={2} />
                    <p className="text-center">{message}</p>
                </div>
            </div>
        </Modal>
    );
}

export default LoadingModal;