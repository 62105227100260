import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { debounce } from "lodash";
import H1 from "components/common/Typography/H1";
import CustomInput from "../../../../components/CustomInput";
import TagInput from "../../../../components/TagInput";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
import { HiChevronDown } from "react-icons/hi";
import currencies from "data/currencies";
import { Button } from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField } from "@mui/material";
import SearchContact from "../../../../components/general/SearchContact";
import LoadingComponent from "components/Loading";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Options from "components/Options";
import { useSnackbar } from "notistack";
import { mainStore } from "stores/MainStore";
import useApi from "hooks/useApi";

function DonationDetails() {
    // const { paramid } = useParams();
    const { orgId } = mainStore;
    const api = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const [manualDonation, setManualDonation] = useState(false);
    const [donation, setDonation] = useState();
    const [Loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [emails, setEmails] = useState([]);
    const [phones, setPhones] = useState([]);
    const [date, setDate] = useState(new Date().toLocaleInput());
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState(localStorage.getItem('currency') || 'USD');
    const [payments, setPayments] = useState('1');
    const [paymentsOptionsOpen, setPaymentsOptionsOpen] = useState(false);
    const [fundraiser, setFundraiser] = useState('');
    const [fundraiserId, setFundraiserId] = useState('');
    const [donorNote, setDonorNote] = useState('');
    const [donationNote, setDonationNote] = useState('');
    const [donationType, setDonationType] = useState(1);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [contactSearchResults, setContactSearchResults] = useState([]);
    const [fundraiserSearchResults, setFundraiserSearchResults] = useState([]);
    const [contactId, setContactId] = useState('');
    const [campaignId, setCampaignId] = useState('');
    const [subCampaignId, setSubCampaignId] = useState('');
    const [campaigns, setCampaigns] = useState([]);
    // const [subCampaigns, setSubCampaigns] = useState([]);
    const [submitData, setSubmitData] = useState({
        currency: localStorage.getItem('currency') || 'USD',
        payments: 1
    });
    const [paymentMethodSubmitData, setPaymentMethodSubmitData] = useState({});
    const [cardNumber, setCardNumber] = useState();
    const [cardType, setCardType] = useState('');
    const [transactionType, setTransactionType] = useState(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const closeOptions = useRef();
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [campaignsList, setCampaignsList] = useState([]);
    const [subCampaignsList, setSubCampaignsList] = useState([]);

    const [searchLoading, setSearchLoading] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const currencyValues = Object.keys(currencies)

    const paymentMethods = [
        { value: 'Cash', label: t('Cash') },
        { value: 'Check', label: t('Check') },
        { value: 'CreditCard', label: t('Credit Card') },
        { value: 'BankTransfer', label: t('Bank Transfer') },
        { value: 'StandingBankOrder', label: t('Standing Bank Order') },
        { value: 'PayPal', label: t('PayPal') },
        { value: 'GooglePay', label: t('Google Pay') },
        { value: 'ApplePay', label: t('Apple Pay') },
        { value: 'NedarimPlus', label: t('Nedarim Plus') },
        { value: 'Other', label: t('Other') },
    ];

    const cardTypes = [
        { value: 'Visa', label: t('Visa') },
        { value: 'MasterCard', label: t('MasterCard') },
        { value: 'AmericanExpress', label: t('American Express') },
        { value: 'Discover', label: t('Discover') },
        { value: 'Other', label: t('Other') },
    ];

    const transactionTypes = [
        { value: 'Regular', label: t('Regular') },
        { value: 'Recurring', label: t('Recurring') },
    ];

    const paymentsOptions = ['0', '1', '6', '12', '24', '36'];

    const options = [
        {
            text: t('Delete Donation'),
            icon: <DeleteOutlineRoundedIcon />,
            onClick: () => handleDelete(),
        }
    ];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (closeOptions.current && !closeOptions.current.contains(event.target)) {
                setOptionsOpen(false);
                setPaymentsOptionsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [closeOptions]);

    const handleDelete = async () => {
        try {
            await api.post('/donations/delete/', { donation_ids: [id] });
            enqueueSnackbar(t('Donation deleted successfully'), { variant: 'success' });
            navigate('/donations');
        } catch (error) {
            enqueueSnackbar(t('Error deleting donation'), { variant: 'error' });
            console.error(`Error deleting donation: ${error.message}`);
        }
    };

    const handlePaymentMethodDataChange = (name, value) => {
        setPaymentMethodSubmitData(prevState => {
            let newState = { ...prevState };
            if (name === 'payment_method_type') {
                newState['payment_method_type'] = value;
            } else if (name === 'card_number') {
                newState['payment_method_data'] = { ...prevState.payment_method_data, card_number: value };
            } else if (name === 'card_type') {
                newState['payment_method_data'] = { ...prevState.payment_method_data, card_type: value };
            } else if (name === 'transaction_type') {
                newState['payment_method_data'] = { ...prevState.payment_method_data, transaction_type: value };
            }
            handleInputChange('payment_method', newState);
            return newState;
        });
    };

    const handleInputChange = (name, value) => {
        console.log(name, value);
        setSubmitData(prevState => {
            let newState = { ...prevState };

            if (!value && value !== '') {
                delete newState[name];
            } else {
                newState[name] = value;
            }

            return newState;
        });
    };

    const handleSubmit = (e) => {
        document.addEventListener('keydown', function (event) {
            if (event.key === 'Enter') {
                return;
            }
        });
        e.preventDefault();
        setSaving(true);
        if (id == 'new') {
            api.post('/donations/create/', submitData).then((response) => {
                navigate(`/donations/${response.data.donation_id}`);
                setSnackbar(true)
                setSnackbarMessage(t('Donation Saved Successfully'))
            }).catch((error) => {
                console.error(`Error fetching donation: ${error.message}`);
            });
        } else {
            try {
                const response = api.patch(`/donations/${id}/update/`, submitData);
                console.log(response.data);
                setSnackbar(true)
                setSnackbarMessage(t('Donation Saved Successfully'))
            } catch (error) {
                console.error(`Error fetching donation: ${error.message}`);
            }
        }
        setSaving(false);
    };

    useEffect(() => {
        api.get('/campaigns/dropdown').then((response) => {
            setCampaigns(response.data);
        }).catch((error) => {
            console.error(`Error fetching campaigns: ${error.message}`);
        });
        if (id == 'new') {
            handleInputChange('donation_date', new Date().toISOString().slice(0, 16));
            console.log('submitData');
            setLoading(false);
            return;
        }
        const fetchDonation = async () => {
            try {
                let response = await api.get('/donation-list/donations/', {
                    params: {
                        donation_id: id,
                    },
                });
                response = response.data.results[0];
                console.log(response);
                setManualDonation(response?.entry_type === 1 ? false : true);
                setDonation(response);
                setName((response?.contact?.first_name || '') + (response?.contact?.first_name ? ' ' : '') + (response?.contact?.last_name || ''));
                setContactId(response?.contact?.id || '');
                setEmails(response?.contact?.email_addresses || []);
                setPhones(response?.contact?.phone_numbers || []);
                setDate(new Date(response?.donation_date).toLocaleInput());
                setAmount(response?.amount || '');
                setCurrency(response?.currency || '');
                setPayments(response.payments.toString());
                setFundraiser((response?.fundraiser || ''));
                setFundraiserId(response?.fundraiser?.id || '');
                setCampaignId(response?.campaign || '');
                setSubCampaignId(response?.sub_campaign || '');
                setDonorNote(response?.donor_note || '');
                setDonationNote(response?.donation_notes || '');
                setDonationType(response?.donation_type)
                setPaymentMethod(response?.payment_method?.payment_method_type || '');
                setCardNumber(response?.payment_method?.payment_method_data?.card_number || '');
                setCardType(response?.payment_method?.payment_method_data?.card_type || '');
                setTransactionType(response?.payment_method?.payment_method_data?.transaction_type || '');
                if (response?.payment_method?.payment_method_type === 'CreditCard') {
                    handlePaymentMethodDataChange('card_number', response?.payment_method?.payment_method_data?.card_number);
                    handlePaymentMethodDataChange('card_type', response?.payment_method?.payment_method_data?.card_type || '');
                    handlePaymentMethodDataChange('transaction_type', response?.payment_method?.payment_method_data?.transaction_type || '');
                }

                handleInputChange('amount', response?.amount || '');
                handleInputChange('currency', response?.currency || '');
                handleInputChange('payments', response?.payments.toString());

                setLoading(false);
            } catch (error) {
                console.error(`Error fetching donation: ${error.message}`);
            }
        };

        fetchDonation();
    }, [orgId, id]);

    // const handleContactSearch = async (search, field) => {
    //     try {
    //         const response = await axios.get('/contacts/', {
    //             params: {
    //                 org_id: orgId,
    //                 search: search,
    //                 status: 1,
    //             },
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //             },
    //         });
    //         if (field === 'name') {
    //             setContactSearchResults(response.data.results);
    //         }
    //         else if (field === 'fundraiser') {
    //             setFundraiserSearchResults(response.data.results);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching search results:', error);
    //     }
    // };

    useEffect(() => {
        if (searchOpen) {
            const fetchSearchResults = debounce(() => {
                setSearchLoading(true);
                api.get('/contacts/', {
                    params: {
                        page_size: 20,
                        search: searchValue,
                        status: 1,
                    },
                }).then((response) => {
                    setSearchResults(response.data.results);
                    setSearchLoading(false);
                }).catch((error) => {
                    console.error('Error fetching search results:', error);
                });
            }, 100);

            fetchSearchResults();
            return () => fetchSearchResults.cancel();
        }
    }, [searchValue, searchOpen]);


    const handleNameSelect = (result) => {
        setName(result.first_name + ' ' + result.last_name);
        setContactId(result.id);
        setContactSearchResults([]);
        handleInputChange('contact', result.id);
        handleInputChange('contact_name');
    };

    // useEffect(() => {
    //     console.log({contactId, fundraiserId})
    // }, [contactId, fundraiserId])

    const handleNameChange = (e) => {
        setContactId('');
        console.log({ e });
        if (typeof e === 'string' || !e) {
            handleInputChange('contact_name', e);
            handleInputChange('contact', '');
        } else {
            handleInputChange('contact_name');
            handleInputChange('contact', e.id);
        }
    }

    const handleNameBlur = () => {
        setTimeout(() => {
            setContactSearchResults([]);
        }
            , 200);
    }

    const handleFundraiserSelect = (result) => {
        setFundraiser(result.first_name + ' ' + result.last_name);
        setFundraiserId(result.id);
        setFundraiserSearchResults([]);
        handleInputChange('fundraiser', result.id);
    };

    const handleFundraiserBlur = () => {
        if (fundraiserId == '') {
            setFundraiser('');
        }

        setTimeout(() => {
            setFundraiserSearchResults([]);
        }
            , 200);
    }

    const handleDateChange = (e) => {
        setDate(e.target.value);
        handleInputChange('donation_date', e.target.value);
    }
    const handleAmountChange = (e) => {
        setAmount(e.target.value);
        handleInputChange('amount', e.target.value);
    }
    const handleCurrencyChange = (e) => {
        setCurrency(e);
        localStorage.setItem('currency', e);
        handleInputChange('currency', e);
    }
    const handlePaymentsChange = (e) => {
        setPayments(e);
        handleInputChange('payments', e);
    }
    const handlePaymentTypeChange = (e) => {
        setDonationType(e.target.value);
        handleInputChange('donation_type', e.target.value);
    }
    const handleDonorNoteChange = (e) => {
        setDonorNote(e.target.value);
        handleInputChange('donor_note', e.target.value);
    }
    const handleDonationNoteChange = (e) => {
        setDonationNote(e.target.value);
        handleInputChange('donation_notes', e.target.value);
    }

    const handleCampaignChange = (e) => {
        setCampaignId(e.target.value);
        setSubCampaignId('');
        handleInputChange('campaign', e.target.value);
        handleInputChange('sub_campaign', '');
    }

    const handleSubCampaignChange = (e) => {
        setSubCampaignId(e.target.value);
        handleInputChange('sub_campaign', e.target.value);
    }

    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
        handlePaymentMethodDataChange('payment_method_type', e.target.value);
        handlePaymentMethodDataChange('card_number', null);
    }

    const handleCardNumberChange = (e) => {
        setCardNumber(e.target.value);
        handlePaymentMethodDataChange('card_number', e.target.value);
    }

    const handleCardTypeChange = (e) => {
        setCardType(e.target.value);
        handlePaymentMethodDataChange('card_type', e.target.value);
    }

    const handleTransactionTypeChange = (e) => {
        setTransactionType(e.target.value);
        handlePaymentMethodDataChange('transaction_type', e.target.value);
    }

    const renderDonation = () => (
        <div className="container mx-auto text-start">
            <div className="flex gap-4">
                <div>
                    <label className="block font-bold text-xs text-custom-blue">
                        {t('Campaign')}
                    </label>
                    <select
                        value={campaignId}
                        onChange={(e) => handleCampaignChange(e)}
                        className={`bg-transparent font-semibold text-2xl pe-2 ${!campaignId && 'text-gray-500'}`}
                    >
                        <option className="text-gray-500 italic" value=''>{`-${t('Select')}-`}</option>
                        {campaigns.map((campaign) => (
                            <option key={campaign.id} value={campaign.id}>
                                {campaign.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label className="block font-bold text-xs text-custom-blue">
                        {t('Sub Campaign')}
                    </label>
                    <select
                        value={subCampaignId}
                        onChange={(e) => handleSubCampaignChange(e)}
                        className={`bg-transparent font-semibold text-2xl pe-2 ${!subCampaignId && 'text-gray-500'}`}
                    >
                        <option className="text-gray-500 italic" value=''>{`-${t('Select')}-`}</option>
                        {campaignId && campaigns.find(campaign => campaign.id === campaignId)?.sub_campaigns?.map((subCampaign) => (
                            <option key={subCampaign.id} value={subCampaign.id}>
                                {subCampaign.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <form className='container mx-auto text-start' onSubmit={handleSubmit}>
                <div className="mt-8 p-10 bg-white rounded-2xl shadow-sm">
                    <div className="w-full mb-8">
                        <h2 className="text-custom-blue font-bold mb-8 text-xl">{t('Name')}</h2>
                        <fieldset className="relative flex gap-x-8 mb-2">
                            <div className="relative w-full">
                                <SearchContact
                                    onChange={(e) => handleNameChange(e)}
                                    freeSolo={true}
                                    value={name}
                                    placeholder={t('Name')}
                                    label={t('Name')}
                                />
                            </div>
                            <CustomInput
                                className="w-2/3"
                                disabled={manualDonation}
                                label={t('Date')}
                                placeholder={t('Date')}
                                type={'datetime-local'}
                                value={date}
                                onChange={handleDateChange}
                            />
                        </fieldset>
                    </div>

                    <div className="w-full mb-16">
                        <h2 className="text-custom-blue font-bold mb-8 text-xl">{t('Donation Details')}</h2>
                        <fieldset className="relative flex gap-x-8 mb-8">
                            <CustomInput
                                className="w-full"
                                label={t('Amount')}
                                placeholder={t('Amount')}
                                disabled={manualDonation}
                                value={amount}
                                onChange={handleAmountChange}
                            />
                            <div className="w-full">
                                <Autocomplete
                                    clearOnEscape
                                    disableClearable
                                    options={currencyValues}
                                    getOptionLabel={(option) => t(currencies[option].name)}
                                    disabled={manualDonation}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label={t("Currency")}
                                            variant="standard"
                                        />}
                                    filterSelectedOptions={false}
                                    value={currency}
                                    onChange={(event, newValue) => {
                                        handleCurrencyChange(newValue)
                                    }}
                                />
                            </div>
                            <div className="flex w-full relative">
                                <Autocomplete
                                    clearOnEscape
                                    disableClearable
                                    freeSolo
                                    fullWidth
                                    disabled={manualDonation}
                                    options={paymentsOptions}
                                    getOptionLabel={(option) => (option === '0' ? t('No Time Limit') : option) || (payments === '0' ? t('No Time Limit') : payments)}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label={t('Payments')}
                                            variant="standard"
                                        />}
                                    filterOptions={(options) => options}
                                    filterSelectedOptions={false}
                                    value={payments.toString()}
                                    onChange={(event, newValue) => {
                                        handlePaymentsChange(newValue)
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        handlePaymentsChange(newInputValue)
                                    }}
                                />
                            </div>
                            <div className="w-full">
                                <FormControl fullWidth variant="standard">
                                    <InputLabel id='payment-type-label' variant="standard">{t('Payment Type')}</InputLabel>
                                    <Select
                                        variant="standard"
                                        labelId='payment-type-label'
                                        disabled={manualDonation}
                                        value={donationType}
                                        onChange={handlePaymentTypeChange}
                                        label={t('Donation Type')}
                                    >
                                        <MenuItem value={1}>{t('Regular')}</MenuItem>
                                        <MenuItem value={2}>{t('Recurring')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="w-full">
                                <FormControl fullWidth variant="standard">
                                    <InputLabel id='payment-method-label' variant="standard">{t('Payment Method')}</InputLabel>
                                    <Select
                                        variant="standard"
                                        labelId='payment-method-label'
                                        disabled={manualDonation}
                                        value={paymentMethod ? paymentMethod : 'Other'}
                                        onChange={handlePaymentMethodChange}
                                        label={t('Payment Type')}
                                    >
                                        {paymentMethods.map((method) => (
                                            <MenuItem key={method.value} value={method.value}>
                                                {method.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </fieldset>
                        {paymentMethod === 'CreditCard' && (
                            <fieldset className="relative flex gap-x-8 mb-8">
                                <CustomInput
                                    className="w-full"
                                    disabled={manualDonation}
                                    label={t('Card Number')}
                                    placeholder={t('Card Number')}
                                    value={cardNumber}
                                    onChange={handleCardNumberChange}
                                />
                                <div className="w-full">
                                    <label className="block font-bold text-xs text-custom-blue">
                                        {t('Card Type')}
                                    </label>
                                    <div className="relative">
                                        <select
                                            className={`w-full py-1.5 border-b border-gray-300 mt-1 focus:border-blue-900 focus:border-b-2 outline-none
                                            ${cardType === '' ? 'text-gray-400' : ''}`}
                                            disabled={manualDonation}
                                            value={cardType}
                                            onChange={handleCardTypeChange}
                                        >
                                            <option value='' className="text-gray-400">{t('Card Type')}</option>
                                            {cardTypes.map((type) => (
                                                <option key={type.value} value={type.value}
                                                    className="text-custom-blue"
                                                >
                                                    {type.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <label className="block font-bold text-xs text-custom-blue">
                                        {t('Transaction Type')}
                                    </label>
                                    <div className="relative">
                                        <select
                                            className={`w-full py-1.5 border-b border-gray-300 mt-1 focus:border-blue-900 focus:border-b-2 outline-none ${transactionType === '' ? 'text-gray-400' : ''}`}
                                            disabled={manualDonation}
                                            value={transactionType}
                                            onChange={handleTransactionTypeChange}
                                        >
                                            <option value='' className="text-gray-400">{t('Transaction Type')}</option>
                                            {transactionTypes.map((type) => (
                                                <option key={type.value} value={type.value} className="text-custom-blue"
                                                >
                                                    {type.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </fieldset>)}
                        <fieldset className="relative flex gap-x-8 mb-8">
                            <CustomInput
                                className="w-full"
                                label={t('Donor Notes')}
                                placeholder={t('Donor Notes')}
                                value={donorNote}
                                onChange={handleDonorNoteChange}
                            />
                            <div className="relative w-full">
                                <SearchContact
                                    onChange={(e) => handleInputChange('fundraiser', e?.id || '')}
                                    value={fundraiser}
                                    placeholder={t('Fundraiser')}
                                    label={t('Fundraiser')}
                                />
                            </div>
                        </fieldset>
                        <fieldset className="relative flex gap-x-8 mb-8">
                            <CustomInput
                                className="w-full"
                                label={t('Donation Notes')}
                                placeholder={t('Donation Notes')}
                                value={donationNote}
                                onChange={handleDonationNoteChange}
                            />
                        </fieldset>
                        {/* <div className="w-full mb-8">
                            <h2 className="text-custom-blue font-bold mb-8 text-xl">{t('Comunication')}</h2>
                            <fieldset className="relative flex gap-x-8 mb-2">
                                <TagInput
                                    tags={phones}
                                    onTagChange={handlePhoneNumbersChange}
                                    name="phone_number"
                                    label={t('phone_number')}
                                    validationText={t('Up to 3 phone numbers')}
                                />
                                <TagInput
                                    tags={emails}
                                    onTagChange={setEmails}
                                    name="email"
                                    label={t('email')}
                                    validationText={t('Up to 3 emails')}
                                />
                            </fieldset>
                        </div> */}
                    </div>
                </div>
                <div className='flex justify-center mt-12'>
                    <Button
                        sx={{ bgcolor: '#1e3b8a', fontSize: '1.5rem', fontWeight: 400, borderRadius: 99 }}
                        size='lg'
                        className='rounded-full'
                        variant='solid'
                        type='submit'
                        loading={saving}
                    >
                        {t('Save')}
                    </Button>
                </div>
            </form>
            <Snackbar
                open={snackbar}
                onClose={() => setSnackbar(false)}
                autoHideDuration={5000}
                message={t(snackbarMessage)}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.5 }}
                        onClick={() => setSnackbar(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            ></Snackbar>
        </div>
    )

    return (
        <>
            {Loading ? <LoadingComponent className='h-[32rem]' /> :
                <div>
                    <div className="flex justify-between">
                        <H1>
                            {donation ? <span>
                                {`${t('Donation')} ${donation.donation_id}`}
                                {name && <sub className="text-sm ms-2">/ {name}</sub>}
                            </span> : t('New Donation')}
                        </H1>
                        {id !== 'new' && <Options options={options} />}
                    </div>
                    {renderDonation()}
                </div>
            }
        </>
    );
}
export default DonationDetails;