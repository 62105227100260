
import React, { createContext, useContext, useState, useEffect } from 'react';
import { log } from '../../../utils/devUtils';
import { forwardRef } from 'react';

const TableRowContext = createContext();

const Tbody = ({ children, ...otherProps }) => {
    const childArray = React.Children.toArray(children);
    const [rowCount, setRowCount] = useState(0);

    useEffect(() => {
        setRowCount(React.Children.count(children));
    }, [children]);

    return (
        <tbody {...otherProps} className="text-sm font-normal text-gray-700 bg-white rounded-2xl shadow-sm">
            <TableRowContext.Provider value={{ rowCount }}>
                {childArray.map((child, index) =>
                    React.cloneElement(child, { index, isFirst: index === 0, isLast: index === rowCount - 1 })
                )}
            </TableRowContext.Provider>
        </tbody>
    );
};

const Tr = forwardRef(({ children, className, isFirst, isLast, ...otherProps }, ref) => {
    const childArray = React.Children.toArray(children);
    const [colCount, setColCount] = useState(0);

    useEffect(() => {
        setColCount(React.Children.count(children));
    }, [children]);

    const trClassName = `hover:bg-gray-100 ${isLast ? '' : 'border-b '} border-gray-200 py-10 ${isFirst ? 'rounded-ts-2xl' : ''
        } ${isLast ? 'rounded-bs-2xl' : ''}`;

    const enhancedChildren = React.Children.map(children, child => {
        // Only enhance Td elements
        if (child.type.name === 'Td') {
            return React.cloneElement(child, { isFirst, isLast });
        }
        return child;
    });

    return <tr ref={ref} className={trClassName + ' ' + className} {...otherProps}>
        {childArray.map((child, index) =>
            React.cloneElement(child, {
                index,
                isTopFirst: index === 0 && isFirst,
                isTopLast: index === colCount - 1 && isFirst,
                isBottomFirst: index === 0 && isLast,
                isBottomLast: index === colCount - 1 && isLast
            })
        )}
    </tr>;
});

Tr.displayName = 'Tr';

const Td = ({ children, className, padding = true, isTopFirst, isTopLast, isBottomFirst, isBottomLast, ...otherProps }) => {
    const tdClassName = `
        ${padding && 'p-4'}
        ${isTopFirst && 'rounded-ts-2xl'}
        ${isTopLast && 'rounded-te-2xl'}
        ${isBottomFirst && 'rounded-bs-2xl'}
        ${isBottomLast && 'rounded-be-2xl'}
    `;

    return <td {...otherProps} className={tdClassName + ' ' + className}>{children}</td>;
};

export { Tbody, Tr, Td };
