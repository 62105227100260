import axios from 'axios';
import qs from 'qs';
import { errorsStore } from '../stores/ErrorsStore';


const api = axios.create({
    baseURL: '/api',
    headers: {
        'X-User-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response?.status === 400) {
            errorsStore.setError400(error.response.data);
        }
        return Promise.reject(error);
    }
)

export default api;