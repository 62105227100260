import { CloseRounded } from "@mui/icons-material";
import { Autocomplete, Button, IconButton, Stack, TextField } from "@mui/material";
import SearchContact from "components/general/SearchContact";
import useApi from "hooks/useApi";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce";

function ConnectionInput({ connections, setConnections }) {
    const { t } = useTranslation();
    const api = useApi();
    const [currentRelationship, setCurrentRelationship] = useState('');

    const autocompleteParams = useMemo(() => {
        const params = {
            connections__relationship__icontains: currentRelationship || undefined,
        }
        return { params };
    }, [currentRelationship]);

    const debouncedAutocompletParams = useDebounce(autocompleteParams, 250);

    const AutocompleteQuery = useQuery(["autocomplete", debouncedAutocompletParams],
        () => api.get(`/contacts/field-search/connections__relationship`, autocompleteParams).then((res) => res.data),
        {
            enabled: !!debouncedAutocompletParams,
            keepPreviousData: true,
        }
    );

    const { data: autocompleteData } = AutocompleteQuery;

    useEffect(() => console.log('connections', connections), [connections]);

    return (
        <div className="text-start">
            {connections.map((connection, index) => {
                if (connection?.id && (!connection.relationship && !connection.related_to)) return null;
                return (
                    <Stack key={index} alignItems='flex-end' direction="row" spacing={1}>
                        <Stack alignItems='center' direction="row" spacing={4}>
                            <Autocomplete
                                fullWidth
                                freeSolo
                                options={autocompleteData || []}
                                getOptionLabel={(option) => option}
                                value={connection.relationship || ""}
                                onInputChange={(_, value) => {
                                    setCurrentRelationship(value);
                                    const updatedConnections = [...connections];
                                    updatedConnections[index].relationship = value;
                                    setConnections(updatedConnections);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label={t("Relationship")}
                                    />
                                )}
                            />
                            <div className="w-72">
                                <SearchContact
                                    label={t("Related to")}
                                    onChange={(e) => {
                                        const updatedConnections = [...connections];
                                        updatedConnections[index].related_to = { id: e?.id, display_name: e?.display_name }
                                        setConnections(updatedConnections);
                                    }}
                                    placeholder={t("Search for a contact")}
                                    value={connection.related_to?.display_name || ''}
                                />
                            </div>
                        </Stack>
                        <IconButton
                            sx={{ mt: '2rem' }}
                            onClick={() => {
                                const updatedConnections = [...connections];
                                if (updatedConnections[index]?.id) {
                                    updatedConnections[index].relationship = null;
                                    updatedConnections[index].related_to = null;
                                } else {
                                    updatedConnections.splice(index, 1);
                                }
                                setConnections(updatedConnections);
                            }}
                        >
                            <CloseRounded />
                        </IconButton>
                    </Stack>
                )
            }
            )}
            <Button
                onClick={() => {
                    setConnections([...connections, { relationship: '', related_to: null }]);
                }}
                sx={{ mt: '1rem' }}
            >
                {t("Add Connection")}
            </Button>
        </div>
    )
}

export default ConnectionInput