import Pagination from "components/common/Pagination";
import DynamicTable from "components/general/DynamicTable";
import { useTranslation } from "react-i18next";


function ExpenseList({ expenses, totalPages, currentPage, setCurrentPage, getSort, setSort, checkedExpenses, setCheckedExpenses }) {
    const { t } = useTranslation();

    const handleCheck = (expense) => {
        const expenseChecked = checkedExpenses.includes(expense);
        if (expenseChecked) setCheckedExpenses(prev => prev.filter(c => c !== expense));
        else setCheckedExpenses(prev => [...prev, expense]);
    };

    const handleCheckAll = () => {
        if (checkedExpenses.length === expenses.length) setCheckedExpenses([]);
        else setCheckedExpenses(expenses.map(expense => expense.expense_id));
    };

    return (
        <>
            <DynamicTable
                columns={[
                    {
                        value: t("number"),
                        field: "expense_id",
                    },
                    {
                        value: t("Name"),
                        field: "name",
                        componentType: 'item_contact'
                    },
                    {
                        value: t("Date"),
                        field: "date",
                        componentType: 'date'
                    },
                    {
                        value: t("Expense Type"),
                        field: "category.name",
                    },
                    {
                        value: t("Amount"),
                        field: "amount",
                        componentType: 'currency_amount'
                    },
                    {
                        value: t("Description"),
                        field: "description",
                    }
                ]}
                rows={expenses || []}
                checkField={'expense_id'}
                checkedRows={checkedExpenses}
                handleCheck={(expense) => handleCheck(expense.expense_id)}
                handleCheckAll={handleCheckAll}
            />
            <Pagination sx={{ mt: '2rem' }} totalPages={totalPages} currentPage={currentPage} onChange={setCurrentPage} />
        </>
    );
}


export default ExpenseList;
