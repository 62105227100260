import { AddRounded } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import Options from "components/Options";
import SelectOption from "components/SelectOption";
import useApi from "hooks/useApi";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { optionsClickOutside } from "utils/controls";

function SelectCampaign({ anchorEl, setAnchorEl, cardType }) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const api = useApi();
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const { data: campaigns } = useQuery(['campaignsDropdown'],
        () => api.get('/campaigns/dropdown').then(res => res.data),
        {
            refetchOnWindowFocus: true,
            enabled: true,
        }
    );

    const { mutate: addDonorCard } = useMutation(
        (campaign) => api.post('/settings/contact-cards/add', { card_type: cardType, data: { campaign: campaign } }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('contactViewCards');
                setAnchorEl(null);
                optionsClickOutside();
            },
            onError: (error) => {
                if (error.response?.status === 400) {
                    enqueueSnackbar(error.response.data?.campaign, { variant: 'error' });
                } else {
                    enqueueSnackbar(t('Error adding card'), { variant: 'error' });
                }
            }
        }
    )

    return (
        <SelectOption
            title={t('Select Campaign')}
            text={t('Select a campaign for the card')}
            optionAnchorEl={anchorEl}
            setOptionAnchorEl={setAnchorEl}
            options={campaigns || []}
            selectedOption={selectedCampaign}
            setSelectedOption={setSelectedCampaign}
            handleAddToOption={addDonorCard}
            placeholder={t('Select Campaign')}
        />
    )
}

function AddCard() {
    const { t } = useTranslation();
    const dir = document.dir;
    const [selectedCampaignAnchorEl, setSelectedCampaignAnchorEl] = useState(null);
    const [cardType, setCardType] = useState(null);

    return (
        <>
            <Options
                dense
                optionsButton={<>
                    <Button
                        startIcon={<AddRounded />}
                        variant="outlined"
                        sx={{
                            width: '100%',
                            textTransform: 'none',
                            borderStyle: 'dashed',
                        }}
                    >
                        {t('Add Card')}
                    </Button>
                </>}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: dir === 'rtl' ? 'right' : 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: dir === 'rtl' ? 'right' : 'left',
                }}
                options={[
                    {
                        text: t('Donor Card'),
                        onClick: (e) => {
                            setCardType('Donor');
                            setSelectedCampaignAnchorEl(e.currentTarget);
                        },
                    },
                    {
                        text: t('Set Campaign Card'),
                        onClick: (e) => {
                            setCardType('Campaign');
                            setSelectedCampaignAnchorEl(e.currentTarget);
                        },
                    }
                ]}
            />
            <SelectCampaign
                anchorEl={selectedCampaignAnchorEl}
                setAnchorEl={setSelectedCampaignAnchorEl}
                cardType={cardType}
            />
        </>
    )
}

export default AddCard;