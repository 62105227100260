import { VolunteerActivismRounded } from "@mui/icons-material";
import TotalCard from "./TotalCard";
import { useTranslation } from "react-i18next";

function DonationsSumCard({ value, currency }) {
    const { t, i18n } = useTranslation();

    return (
        <TotalCard
            title={t('Total Donations')}
            value={value?.toLocaleString(i18n.language, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            icon={<VolunteerActivismRounded />}
        />
    );
}

export default DonationsSumCard;