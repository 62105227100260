import { useQuery } from "react-query";
import api from "api/axiosInstance"
import { contactsStore } from "stores/ContactsStore";
import { useState } from "react";

const fetchContacts = (params) => api.get("/contacts/", { params }).then((res) => res.data);

export const useContacts = (options = {}) => {
    const { getParams, setContactsList, setCount, setNextPageContactId, setPrePageContactId } = contactsStore;
    const [fetchAllEnabled, setFetchAllEnabled] = useState(false);

    const { onFetchAllSuccess } = options;

    const contactsQuery = useQuery(["contacts", getParams], () => fetchContacts(getParams), {
        enabled: options?.enabled,
        onSuccess: (data) => {
            setContactsList(data?.results || []);
            setCount(data?.count || 0);
            setNextPageContactId(data?.next_contact_id || null);
            setPrePageContactId(data?.prev_contact_id || null);
        },
    });

    const allContactsQuery = useQuery(["allContacts", fetchAllEnabled], () => fetchContacts({ ...getParams, all: true }), {
        enabled: fetchAllEnabled,
        onSuccess: onFetchAllSuccess,
    });

    const fetchAllContacts = () => {
        if (!fetchAllEnabled) allContactsQuery.refetch();
        else setFetchAllEnabled(true);
    }

    return {
        contactsQuery,
        fetchAllContacts,
        allContactsQuery
    }
}
