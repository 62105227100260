import { Badge, Box, Paper, Tab, Tabs, Tooltip } from "@mui/material";
import Section from "components/common/Section";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Donors from "./Donors";
import Donations from "./Donations";
import Overview from "./Overview";
import Pledges from "./Pledges";
import { InfoRounded } from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import NavigationCards from "../NavigationCards";
import { uiStore } from "stores/UiStore";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import PledgeDetails from "views/Donations/Pledges/PledgeDetails";
import { useNavigate } from "react-router-dom";

function TabPanel({ children, value, index, dir }) {
    return (
        <div
            dir={dir}
            hidden={value !== index}
        >
            {children}
        </div>
    );
}

function Details({ data, loading, type }) {
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const [tabValue, setTabValue] = useState(0);
    const navigate = useNavigate();

    const [tabsIsSticky, setTabsIsSticky] = useState(false);
    const [transitioning, setTransitioning] = useState(false);

    const [checkedDonors, setCheckedDonors] = useState([]);

    const { setFabAction } = uiStore;

    const [newPledge, setNewPledge] = useState(false);

    useEffect(() => {
        if (tabValue === 3) setFabAction(() => setNewPledge(true));
        else if (tabValue === 2) setFabAction(() => navigate('/donations/new'));
        else setFabAction(null);
        return () => setFabAction(null);
    }, [tabValue]);

    useEffect(() => {
        if (transitioning) {
            setTransitioning(false);
        }
    }, [transitioning])

    const handleScroll = (e) => {
        setTabsIsSticky(document.getElementById('campaign-tabs')?.getBoundingClientRect()?.top <= 64 + 24 + 12 + 4);
    };

    useEffect(() => {
        const box = document.getElementById('layout-content');
        box.addEventListener('scroll', handleScroll);

        return () => box.removeEventListener('scroll', handleScroll);
    }, []);

    const viewLabels = {
        campaign: t('Campaign'),
        subCampaign: t('Sub Campaign'),
        group: t('Team'),
        fundraiser: t('Fundraiser'),
    }

    return (
        <Box >
            <NavigationCards data={data} />
            <Section
                id='campaign-tabs'
                sx={{
                    py: '0.8rem',
                    position: 'sticky',
                    top: '12px',
                    zIndex: 1,
                    ...tabsIsSticky && {
                        width: 'calc(100% + 4rem)',
                        left: '50%',
                        pt: '0rem',
                        pb: '0.5rem',
                        transform: 'translateX(-2rem)',
                        backdropFilter: 'blur(10px)',
                        backgroundColor: 'rgba(255, 255, 255, 0.6)',
                        boxShadow: 'rgba(255, 255, 255, 0.9) 0rem 0rem 0.0625rem 0.0625rem inset, rgba(0, 0, 0, 0.15) 0rem 0.25rem 0.6875rem 0rem'
                    }
                }}
            >
                <Box>
                    <Tabs
                        value={tabValue}
                        onChange={(e, value) => {
                            setTabValue(value);
                            setTransitioning(true);
                        }}
                    >
                        <Tab key={0} label={i18n.language === 'he' ? viewLabels[type] : 'Overview'} value={0} />
                        <Tab key={1} label={t('Donor Prospects')} value={1} />
                        {type !== 'groups' && [
                            <Tab key={2} label={t('Donations')} value={2} />,
                            <Tab key={3} label={t('Pledges')} value={3} />
                        ]}
                        <Tooltip key={4} placement="top" title={<div>{t('Coming Soon!')}</div>}>
                            <Badge
                                badgeContent={<InfoRounded color="disabled" fontSize="small" />}
                                sx={{
                                    '& .MuiBadge-badge': {
                                        right: 8,
                                        top: 24,
                                    }
                                }}
                            >
                                <Tab label={t('Tasks')} value={4} disabled />
                            </Badge>
                        </Tooltip>
                    </Tabs>
                </Box>
            </Section>
            <SwipeableViews
                index={tabValue}
                onChangeIndex={(index) => {
                    setTabValue(index)
                    setTransitioning(true);
                }}
                axis={i18n.language === 'he' ? 'x-reverse' : 'x'}
            >
                <TabPanel dir={i18n.dir(i18n.language)} key={0} value={tabValue} index={0}>
                    <Overview data={data} loading={loading} />
                </TabPanel>
                <TabPanel dir={i18n.dir(i18n.language)} key={1} value={tabValue} index={1}>
                    <Donors checkedDonors={checkedDonors} setCheckedDonors={setCheckedDonors} view={type} />
                </TabPanel>
                {type !== 'groups' && [
                    <TabPanel dir={i18n.dir(i18n.language)} key={2} value={tabValue} index={2}>
                        <Donations view={type} />
                    </TabPanel>,
                    <TabPanel dir={i18n.dir(i18n.language)} key={3} value={tabValue} index={3}>
                        <Pledges view={type} />
                    </TabPanel>
                ]}
            </SwipeableViews>
            {newPledge &&
                <PledgeDetails
                    open={newPledge}
                    onClose={() => {
                        setNewPledge(false);
                    }}
                    newPledge={newPledge}
                    setUpdated={() => {
                        setNewPledge(false);
                        enqueueSnackbar(t('Pledge created.'), { variant: 'success' });
                        queryClient.invalidateQueries('pledges');
                    }}
                    initialData={{
                        campaign: data?.campaign || null,
                        sub_campaign: data?.sub_campaign || null,
                        fundraiser: data?.contact || null
                    }}
                    onUpdate={() => {
                        enqueueSnackbar(t('Pledge updated.'), { variant: 'success' });
                        queryClient.invalidateQueries('pledges');
                    }}
                    hideCampaign
                    hideFundraiser={type === 'fundraiser'}
                />}
        </Box>
    );
}

export default Details;