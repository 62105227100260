import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Button, Tab, Tabs } from '@mui/material';
import useApi from 'hooks/useApi';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import ContactForm from './ContactForm';
import { useMutation, useQuery } from 'react-query';
import Section from 'components/common/Section';
import { uiStore } from 'stores/UiStore';
import { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import Donations from './Donations';
import Overview from './Overview';
import { ArrowBackIosNewRounded, ArrowForwardIosRounded, DeleteRounded, NavigateBeforeRounded, NavigateNextRounded } from '@mui/icons-material';
import WarningDialog from 'components/common/DeleteDialog';
import { contactsStore } from 'stores/ContactsStore';
import { useContacts } from 'hooks/useContacts';
import { observer } from 'mobx-react-lite';
import LoadingComponent from 'components/Loading';

function ContactDetails() {
    const { contactId, tab } = useParams();
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const api = useApi();
    const { setTitle, setOptions, setViewNavigation } = uiStore;
    const navigate = useNavigate();
    const { contactsList, location: prevLocation, currentPage, setCurrentPage, setReferrer, listType, nextPageContactId, prevPageContactId, getParams, count } = contactsStore;
    const [contact, setContact] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const nextContactId = contactsList[contactsList.indexOf(parseInt(contactId)) + 1] || nextPageContactId || null;
    const prevContactId = contactsList[contactsList.indexOf(parseInt(contactId)) - 1] || prevPageContactId || null;

    const { contactsQuery } = useContacts({ enabled: contactsList.includes(parseInt(contactId)) ? false : true });

    const { isLoading: contactsLoading, isRefetching: contactsRefetching } = useQuery(["contact", contactId],
        () => api.get(`/contacts/${contactId}`).then((res) => res.data),
        {
            enabled: !!contactId,
            keepPreviousData: false,
            cacheTime: 0,
            onSuccess: (data) => setContact(data),
        }
    );

    useEffect(() => {
        console.log('loading', contactsLoading, contactsRefetching);
    }, [contactsLoading, contactsRefetching]);

    const [formTempKey, setFormTempKey] = useState(contactId);

    // useEffect(() => {
    //     setContact({})
    //     contactQuery.refetch();
    //     setFormTempKey(contactId + Math.random());
    // }, [contactId]);

    // const { data: contactData } = contactQuery;
    const donationParams = {
        contact_id: contactId,
        sort: JSON.stringify([]),
    }

    const donationsQuery = useQuery(["contactDonations", contactId],
        () => api.get('/donation-list/donations/', { params: donationParams }).then((res) => res.data),
        {
            enabled: !!contactId,
        }
    );

    const { data: donationsData } = donationsQuery;

    const activityQuery = useQuery(["contactActivity", parseInt(contactId)],
        () => api.get(`contact-activities/`, { params: { contact_id: contactId } }).then((res) => res.data?.results),
        {
            enabled: !!contactId,
        }
    );

    const { data: activityData } = activityQuery;


    const updateContact = useMutation(
        (data) => api.put(`/contacts/${contactId}`, data).then((res) => res.data),
        {
            onSuccess: (data) => {
                setFormTempKey(contactId + Math.random());
                enqueueSnackbar(t("Contact updated"), { variant: "success" });
                setContact(data);
                setTitle(data.display_name);
            },
            // onError: (error) => {
            //     if (error.response?.status !== 400) {
            //         enqueueSnackbar(t("Error updating contact"), { variant: "error" });
            //     }
            // }
        }
    );

    const { mutate: deleteContact } = useMutation(
        () => api.post('/contacts/delete/', { contact_ids: [contactId] }),
        {
            onSuccess: () => {
                enqueueSnackbar(t("Contact deleted"), { variant: "success" });
                setDeleteDialogOpen(false);
                navigate(prevLocation);
            },
            onError: () => {
                enqueueSnackbar(t("Error deleting contact"), { variant: "error" });
            }
        }
    );

    function ViewNavigation() {
        return (
            <div className="flex justify-between items-center mb-2 -mt-10">
                <Button
                    onClick={() => {
                        setReferrer(listType)
                    }}
                    LinkComponent={RouterLink}
                    to={`${prevLocation}#${contactId}`}
                    startIcon={i18n.language === 'he' ? <ArrowForwardIosRounded /> : <ArrowBackIosNewRounded />}
                    disabled={!prevLocation}
                >
                    {t('Back')}
                </Button>
                <div>
                    <Button
                        LinkComponent={RouterLink}
                        to={`/contacts/${prevContactId}${tab ? `/${tab}` : ''}`}
                        startIcon={i18n.language === 'he' ? <NavigateNextRounded /> : <NavigateBeforeRounded />}
                        disabled={!prevContactId}
                    >
                        {t('Previous')}
                    </Button>
                    <Button
                        LinkComponent={RouterLink}
                        to={`/contacts/${nextContactId}${tab ? `/${tab}` : ''}`}
                        endIcon={i18n.language === 'he' ? <NavigateBeforeRounded /> : <NavigateNextRounded />}
                        disabled={!nextContactId}
                    >
                        {t('Next')}
                    </Button>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (!contactsList.includes(parseInt(contactId))) {
            if (nextPageContactId === parseInt(contactId)) setCurrentPage(currentPage + 1)
            else if (prevPageContactId === parseInt(contactId)) setCurrentPage(currentPage - 1);
        }
        setViewNavigation(<ViewNavigation />);
        return () => setViewNavigation(null);
    }, [contactId]);

    useEffect(() => {
        setViewNavigation(<ViewNavigation />);
    }, [prevLocation, contactId, tab, prevContactId, nextContactId]);

    useEffect(() => {
        setTitle(contact?.display_name);
        setOptions([
            {
                key: 'delete-contact',
                text: t('Delete Contact'),
                icon: <DeleteRounded />,
                onClick: () => setDeleteDialogOpen(true),
            }
        ])

        return () => {
            setTitle(null);
            setOptions([]);
        }
    }, [contactId, contact]);

    const contactFormKey = useMemo(() => contactId + JSON.stringify(contact), [contactId, contact]);

    if (contactsLoading || contactsRefetching) return <LoadingComponent className='h-96' />

    return (
        <div key={contactId}>
            <TabContext value={tab || 'contact'}>
                <Section
                    sx={{ pt: '0.8rem' }}
                >
                    <TabList
                        onChange={(e, value) => navigate(`/contacts/${contactId}/${value}`)}
                        sx={{ borderBottom: 1, borderColor: 'divider', mx: '-1.75rem' }}
                    >
                        <Tab label={t("Contact")} value="contact" />
                        <Tab label={t("Donations")} value="donations" />
                        <Tab label={t("Contact Details")} value="details" />
                    </TabList>
                    <TabPanel value="contact">
                        <Overview contact={contact} activities={activityData} />
                    </TabPanel>
                    <TabPanel value="donations">
                        <Donations
                            donations={donationsData}
                            donationSort={[]}
                        />
                    </TabPanel>
                    <TabPanel value="details">
                        <ContactForm key={contactFormKey} contact={contact} contactMutation={updateContact} backLocation={prevLocation} />
                    </TabPanel>
                </Section>
            </TabContext>
            <WarningDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onDelete={deleteContact}
                title={t("Delete Contact")}
                text={t("Are you sure you want to delete this contact?")}
                approveText={t("Delete")}
            />
        </div>
    );
}

export default observer(ContactDetails);