import { Link } from "react-router-dom";
import ThemeChanger from "./DarkSwitch";
import { Disclosure } from "@headlessui/react";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../themeContext";
import { use } from "i18next";
import { useEffect, useState } from "react";
import logo from "../../img/logo.svg";
import WaitingListModal from "./waitnigListForm";
import DRMAlert from "./alerts";
import { HiCheckCircle } from "react-icons/hi";

const Navbar = () => {
  const { t } = useTranslation();
  const [waitngListFormOpen, setWaitingListFormOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const navigation = [
    [t('product'), "#product"],
    [t('Features'), "#features"],
    [t('FAQs'), "#faqs"],
    [t('Contact Us'), "#contact"],
    // "Pricing",
    // "Company",
    // "Blog",
  ];

  return (
    <div className="w-full sticky top-0 px-8 z-10 bg-[#ffffffcc] backdrop-blur-md shadow-lg dark:shadow-[#111827cc] dark:bg-[#111827cc]">
      <DRMAlert
        open={showAlert}
        title={"Thank You!"}
        text={"You have succesfully joined the waiting list. We will get back to you soon!"}
        type={"success"}
        icon={<HiCheckCircle />}
      />
      <WaitingListModal
        open={waitngListFormOpen}
        setOpen={setWaitingListFormOpen}
        setAlert={setShowAlert}
      />
      <nav className="container relative flex flex-wrap items-center justify-between py-4 xl:py-8 mx-auto lg:justify-between xl:px-0">
        {/* Logo  */}
        <Disclosure>
          {({ open }) => (
            <>
              <div className="flex flex-wrap items-center justify-between w-full lg:w-auto">
                <Link href="/">
                  <span className="flex items-center space-x-2 text-2xl font-bold text-blue-900 dark:text-gray-100">
                    <span className=" text-white fill-white border-white">
                      <img
                        src={logo}
                        alt="N"
                        width="32"
                        height="32"
                        className="w-10 p-1.5 bg-white rounded-md"
                      />
                    </span>
                    <span className="ps-2">Donbar</span>
                  </span>
                </Link>

                <Disclosure.Button
                  aria-label="Toggle Menu"
                  className="px-2 py-1 ms-auto text-gray-500 rounded-md lg:hidden hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none dark:text-gray-300 dark:focus:bg-gray-700">
                  <svg
                    className="w-6 h-6 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24">
                    {open && (
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                      />
                    )}
                    {!open && (
                      <path
                        fillRule="evenodd"
                        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                      />
                    )}
                  </svg>
                </Disclosure.Button>

                <Disclosure.Panel className="flex flex-wrap w-full my-5 lg:hidden">
                  <>
                    {navigation.map((item, index) => (
                      <a key={index} href={item[1]} className="w-full px-4 py-2 -ms-4 text-gray-500 rounded-md dark:text-gray-300 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 dark:focus:bg-gray-800 focus:outline-none">
                        {item[0]}
                      </a>
                    ))}
                    <a
                      href={`${process.env.REACT_APP_AUTH_URL}/login`}
                      className="px-6 py-2 mt-3 text-center text-white bg-indigo-700 rounded-full lg:ms-5">
                      Login
                    </a>
                  </>
                </Disclosure.Panel>
              </div>
            </>
          )}
        </Disclosure>

        {/* menu  */}
        <div className="hidden text-center lg:flex lg:items-center">
          <ul className="items-center justify-end flex-1 pt-6 list-none lg:pt-0 lg:flex">
            {navigation.map((menu, index) => (
              <li className="me-3 nav__item" key={index}>
                <a href={menu[1]} className="inline-block px-4 py-2 text-lg font-normal text-gray-800 no-underline rounded-md dark:text-gray-200 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none dark:focus:bg-gray-800">
                  {menu[0]}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="hidden me-3 space-x-4 lg:flex nav__item">
          <a
            href={`${process.env.REACT_APP_AUTH_URL}/login`}
            className="px-6 py-2 text-white bg-indigo-700 rounded-full md:me-5">
            Login
          </a>
          {/* <LanguageSwitcher /> */}
          <ThemeChanger />
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
