import NoResultsFound from "components/general/NoResultsFound";
import TabTableLoading from "components/general/TabTableLoading";
import DonationList from "components/specific/DonationList";
import { useDonations } from "hooks/useDonations";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { donationsStore } from "stores/DonationsStore";
import { useTranslation } from "react-i18next";
import { VolunteerActivismRounded } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { Box } from "@mui/material";
import DirectItemsFilter from "../DirectItemsFilter";

function onExport(data) {
    console.log(data);
}

function Donations({ view }) {
    const { campaignName, subCampaignName, groupName, fundraiserId } = useParams();
    const { t } = useTranslation();
    const [donationParams, setDonorParams] = useSearchParams('')
    const { currentPage, setCurrentPage, pageSize, getSort, setSort, setParams, reset } = donationsStore;
    const [isInitialised, setIsInitialised] = useState(false);
    const [checkedDonations, setCheckedDonations] = useState([]);
    const [checkAll, setCheckAll] = useState(false);

    const [directDataOnly, setDirectDataOnly] = useState(true);

    const { donationsQuery, allDonationsQuery, fetchAllDonations } = useDonations({ onFetchAllSuccess: onExport, enabled: isInitialised });
    const { data: donationsData, isLoading: donationsLoading, error: donationsError, isFetching: donationsFetching } = donationsQuery;
    const donations = donationsData?.results || [];

    const pageCount = Math.ceil(donationsData?.count / pageSize);

    useEffect(() => {
        donationParams.set('campaign__name', campaignName);
        if (subCampaignName) donationParams.set('sub_campaign__name', subCampaignName);
        else if (directDataOnly) donationParams.set('sub_campaign__name__isnull', true);
        else donationParams.delete('sub_campaign__name__isnull');
        if (fundraiserId) donationParams.set('fundraiser__contact_id', fundraiserId);
        else if (directDataOnly) donationParams.set('fundraiser__isnull', true);
        else donationParams.delete('fundraiser__isnull');
        reset();
        setParams(donationParams);
        setIsInitialised(true);
    }, [campaignName, subCampaignName, groupName, fundraiserId, directDataOnly]);

    if (donationsLoading || !isInitialised) return <TabTableLoading />;

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                gap: '1rem',
            }}>
                {view !== 'fundraisers' &&
                    <DirectItemsFilter
                        directDataOnly={directDataOnly}
                        setDirectDataOnly={setDirectDataOnly}
                        label={t('Direct Donations Only')}
                    />}
            </Box>
            {donations.length === 0 ?
                (
                    <NoResultsFound
                        title={t('no-campaign-donations')}
                        subtitle={t('no-campaign-donations-subtitle')}
                        Icon={VolunteerActivismRounded}
                    />
                ) : (
                    <DonationList
                        donations={donations}
                        checkedItems={checkedDonations}
                        setCheckedItems={setCheckedDonations}
                        checkAll={checkAll}
                        setCheckAll={setCheckAll}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        getSort={getSort}
                        setSort={setSort}
                        totalPages={pageCount}
                    />
                )}
        </Box>
    );
}

export default observer(Donations);
