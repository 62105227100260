// Refactored: 01/28
import React from 'react';
import ReactDOM from 'react-dom/client';
import './tailwind.css'
import './index.css';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RequiredAuthProvider } from '@propelauth/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoadingComponent from './components/Loading';
import { QueryClient, QueryClientProvider } from 'react-query';
import HomePage from './home/HomePage';
import { enableLogging } from 'mobx-logger';
//...
import * as Sentry from "@sentry/react";


Sentry.init({
	dsn: "https://cd0416923079ebfe8dcf5828ada19663@o4506807305043968.ingest.sentry.io/4506807307010048",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	enabled: process.env.NODE_ENV === 'production',
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [/^https:\/\/donbar\.org\//],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


console.dev = (...args) => {
	if (process.env.NODE_ENV === 'development') {
		console.log(...args);
	}
};

Date.prototype.toLocaleInput = function () {
	if (!this) return null;
	const offset = this.getTimezoneOffset() * 60000;
	const adjustedDate = new Date(this.getTime() - offset);
	return adjustedDate.toISOString().slice(0, 16);
};

// enableLogging({
//   action: true,
//   reaction: true,
//   transaction: true,
//   compute: true
// });

const queryClient = new QueryClient(
	{
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				retry: false,
				// staleTime: 1000 * 60 * 5,
			},
		},
	}
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<HomePage />} />
				<Route path='/*' element={
					<RequiredAuthProvider
						authUrl={process.env.REACT_APP_AUTH_URL}
						displayWhileLoading={<LoadingComponent className='h-screen' size='48' />}
					>
						<QueryClientProvider client={queryClient}>
							<App />
						</QueryClientProvider>
					</RequiredAuthProvider>
				} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.REACT_APP_DEBUG !== 'true') {
	reportWebVitals();
}
