import { Campaign, NavigateNextRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, ListSubheader, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { CurrencyMask } from "components/utils/masks";
import currencies from "data/currencies";
import useApi from "hooks/useApi";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { mainStore } from "stores/MainStore";
import CampaignsDropdown from "./CampaignsDropdown";

const currencyValues = Object.keys(currencies);

function AddFundraiser({ open, onClose, onConfirm, processing }) {
    const { settings } = mainStore;
    const { t } = useTranslation();
    const [selectedCampaign, setSelectedCampaign] = useState([]);
    const [goal, setGoal] = useState('');
    const [currency, setCurrency] = useState(settings.currency);
    const [description, setDescription] = useState('');

    const parseSelectedValue = () => {
        const data = selectedCampaign.reduce((acc, item) => {
            if (item.type === 'campaign') {
                acc.campaign_id = item.id
            } else if (item.type === 'sub_campaign') {
                acc.sub_campaign_id = item.id
            } else if (item.type === 'group') {
                acc.group_id = item.id
            }
            return acc;
        }, {});

        return {
            ...data,
            description,
            goal,
            goal_currency: currency,
        };
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {t('Add Fundraiser')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('Add a new fundraiser to a Campaign, Sub Campaign or Group.')}
                </DialogContentText>
                <Stack spacing={2} sx={{ mt: 2 }}>
                    <CampaignsDropdown onChange={setSelectedCampaign} />
                    <Stack direction="row" spacing={2}>
                        <TextField
                            fullWidth
                            label={t("Goal")}
                            InputProps={{
                                inputComponent: CurrencyMask,
                            }}
                            value={goal}
                            onChange={(e) => setGoal(e.target.value)}
                            disabled={selectedCampaign.length === 0}
                        />
                        <Autocomplete
                            clearOnEscape
                            disableClearable
                            fullWidth
                            options={currencyValues}
                            getOptionLabel={(option) => t(currencies[option].name)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label={t("Goal Currency")}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            py: '9px',
                                        }
                                    }}
                                />}
                            filterSelectedOptions={false}
                            value={currency}
                            onChange={(event, newValue) => setCurrency(newValue)}
                            disabled={selectedCampaign.length === 0}
                            sx={{
                                '& .MuiAutocomplete-inputRoot': {
                                    py: '9px',
                                }
                            }}
                        />
                    </Stack>
                    <TextField
                        fullWidth
                        label={t("Description")}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        disabled={selectedCampaign.length === 0}
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{ mx: 1, mb: 1 }}>
                <Button onClick={onClose}>{t('Cancel')}</Button>
                <LoadingButton
                    loading={processing}
                    variant="contained"
                    onClick={() => onConfirm(parseSelectedValue())}
                    disabled={selectedCampaign.length === 0}
                >
                    {t('Add')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default AddFundraiser;