import { HelpRounded, InfoRounded } from "@mui/icons-material";
import { Badge, Tooltip } from "@mui/material";

const badgeComponents = {
    info: InfoRounded,
    help: HelpRounded,
};

function HelperTooltip({ content, variant="info", children }) {
    const Icon = badgeComponents[variant];

    return (
        <Tooltip key={4} placement="top" title={content}>
            <Icon color="disabled" sx={{
                fontSize: 18,
                verticalAlign: "top",
            }} />
        </Tooltip>
    );
}

export default HelperTooltip;