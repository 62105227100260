import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Autocomplete, Box, Fab, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Tab, TextField, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import H1 from "components/common/Typography/H1";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "components/Loading";
import { formatDate } from "utils";
import AddIcon from '@mui/icons-material/Add';
import DeleteDialog from "components/common/DeleteDialog";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import PlaylistRemoveRoundedIcon from '@mui/icons-material/PlaylistRemoveRounded';
import currencies from "data/currencies";
import DonationListLeg from "components/DonationListLeg";
import { tab } from "@material-tailwind/react";
import ContactList from "views/Contacts/Contacts/ContactListLeg";
import Options from "components/Options";
import useApi from "hooks/useApi";
import { useQuery } from "react-query";
import { PieChart, useDrawingArea } from "@mui/x-charts";
import { localDate } from "utils/dates";


function CampaignView() {
    const { t, i18n } = useTranslation();
    const api = useApi()
    const { name } = useParams();
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState('1');
    const [id, setId] = useState();
    const [campaignName, setCampaignName] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [goal, setGoal] = useState('');
    const [goalCurrency, setGoalCurrency] = useState('');

    const [savingCampaign, setSavingCampaign] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updated, setUpdated] = useState(false);

    const [subCampaigns, setSubCampaigns] = useState([]);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState('');
    const [deleteText, setDeleteText] = useState('');
    const [deleteFunction, setDeleteFunction] = useState(() => { });

    const [donations, setDonations] = useState([]);
    const pageSizes = [50, 100, 250, 500];
    // const [pageSize, setPageSize] = useState(localStorage.getItem('donationsPageSize') || pageSizes[0]);
    const [pageSize, setPageSize] = useState(pageSizes[0]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [donationsCurrentPage, setDonationsCurrentPage] = useState(1);
    const [donationsTotalPages, setDonationsTotalPages] = useState(1);
    const [donationSort, setDonationSort] = useState([]);

    const [donors, setDonors] = useState([]);
    const [donorSort, setDonorSort] = useState([]);
    const [donorsCurrentPage, setDonorsCurrentPage] = useState(1);
    const [donorsTotalPages, setDonorsTotalPages] = useState(1);

    const currencyValues = Object.keys(currencies)

    const options = [
        {
            text: t('Delete Campaign'),
            icon: <DeleteOutlineRoundedIcon />,
            onClick: () => {
                setDeleteModalOpen(true);
                setDeleteTitle(t('Delete Campaign'));
                setDeleteText(`${t('Are you sure you want to delete this campaign')}?`);
                setDeleteFunction(() => handleDeleteCampaign);
            },
        },
        ...(tabValue === '5' ? [{
            text: `${t('Remove')} ${checkedItems.length} ${t('Donors')}`,
            icon: <PlaylistRemoveRoundedIcon />,
            onClick: () => {
                setDeleteModalOpen(true);
                setDeleteTitle(`${t('Remove')} ${checkedItems.length} ${t('Donors')}?`);
                setDeleteText(`${t('Are you sure you want to remove')} ${checkedItems.length} ${t('Donors')}?`);
                setDeleteFunction(() => handleRemoveDonors);
            },
            disabled: checkedItems.length === 0,
        }] : []),
    ];

    const campaignQuery = useQuery(['campaign', name],
        () => api.get(`/campaigns/${name}/`).then(res => res.data),
        {
            enabled: name !== 'new',
            refetchOnWindowFocus: true
        }
    );

    const { data: campaignData, isLoading: campaignLoading } = campaignQuery;


    // useEffect(() => {
    //     if (name === 'new') return;
    //     setLoading(true);
    //     axios.get('/campaigns/', {
    //         params: {
    //             name: name,
    //             org_id: orgId,
    //         },
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //         },
    //     }).then(res => {
    //         const campaign = res.data[0];
    //         setId(campaign.id);
    //         setCampaignName(campaign.name);
    //         setDescription(campaign.description);
    //         setStartDate(new Date(campaign.start_date).toISOString().slice(0, 16) || '');
    //         setEndDate(new Date(campaign.end_date).toISOString().slice(0, 16) || '');
    //         setGoal(campaign.goal);
    //         setGoalCurrency(campaign.goal_currency);
    //         setLoading(false);
    //     }).catch(err => {
    //         console.error(err);
    //     });

    //     axios.get('/campaigns/sub-campaigns/', {
    //         params: {
    //             campaign_name: name,
    //             org_id: orgId,
    //         },
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //         },
    //     }).then(res => {
    //         setSubCampaigns(res.data);
    //     }
    //     ).catch(err => {
    //         console.error(err);
    //     });

    // }, [updated]);

    const fetchDonations = () => {
        api.get('/donation-list/donations/', {
            params: {
                page_size: pageSize,
                page: donationsCurrentPage,
                campaign__name: JSON.stringify([name]),
                sort: JSON.stringify(donationSort),
            },
        }).then((res) => {
            setDonationsTotalPages(Math.ceil(res.data.count / pageSize));
            setDonations(res.data.results);
        }).catch((error) => {
            console.log(error);
        });
    }

    const fetchDonors = () => {
        api.get('/contacts/', {
            params: {
                page: donorsCurrentPage,
                page_size: pageSize,
                sort: JSON.stringify(donorSort),
                campaign_donors__name: JSON.stringify([name]),
            },
        }).then((res) => {
            setDonorsTotalPages(Math.ceil(res.data.count / pageSize));
            setDonors(res.data.results);
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        if (tabValue === '4') {
            fetchDonations();
        } else if (tabValue === '5') {
            fetchDonors();
        }
    }, [pageSize, donationsCurrentPage, donationSort, donorsCurrentPage, donorSort, name, tabValue, updated]);

    useEffect(() => {
        setCheckedItems([]);
        setCheckAll(false);
    }, [tabValue]);


    const handleSaveCampaign = () => {
        setSavingCampaign(true);
        if (name === 'new') {
            api.post('/campaigns/update/', {
                name: campaignName,
                description: description,
                start_date: startDate === '' ? null : startDate,
                end_date: endDate === '' ? null : endDate,
                goal: goal,
                goal_currency: goalCurrency,
            }).then(res => {
                setSavingCampaign(false);
                navigate(`/donations/campaigns/${campaignName}`);
                setUpdated(!updated);
            }).catch(err => {
                console.error(err);
                setSavingCampaign(false);
            });
        } else {
            api.put('/campaigns/update/', {
                name: campaignName,
                description: description,
                start_date: startDate,
                end_date: endDate,
                goal: goal,
                goal_currency: goalCurrency,
            }, {
                params: {
                    name: name,
                },
            }).then(res => {
                setSavingCampaign(false);
                navigate(`/donations/campaigns/${campaignName}`);
                setUpdated(!updated);
            }).catch(err => {
                console.error(err);
            });
        }
    };

    const handleDeleteCampaign = () => {
        api.delete('/campaigns/update/', {
            params: {
                name: name,
            },
        }).then(res => {
            setDeleteModalOpen(false);
            setDeleteFunction(() => { });
            navigate('/donations/campaigns');
        }).catch(err => {
            console.error(err);
            setDeleteModalOpen(false);
        });
    };

    const handleRemoveDonors = () => {
        api.post('/campaign-donors-update/remove/', {
            campaign_id: id,
            donor_ids: checkedItems,
        }).then((res) => {
            setDeleteModalOpen(false);
            setDeleteFunction(() => { });
            if (donorsCurrentPage >= donorsTotalPages && donorsCurrentPage > 1) {
                setDonorsCurrentPage(donorsCurrentPage - 1);
            } else {
                setUpdated(!updated);
            }
        }).catch((error) => {
            console.log(error);
        });
    }


    const renderCampaingDetails = () => (
        <div className="text-start">
            <h2 className=" text-custom-blue font-bold text-xl">{t('Campaign Details')}</h2>
            <div className="flex flex-col gap-8 mb-10">
                <fieldset className="flex gap-x-8">
                    <TextField
                        sx={{ width: '16rem' }}
                        variant="standard"
                        label={t('Name')}
                        value={campaignName}
                        onChange={(e) => setCampaignName(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        variant="standard"
                        label={t('Description')}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </fieldset>
                <fieldset className="flex gap-x-8">
                    <TextField
                        variant="standard"
                        label={t('Start Date')}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        type='datetime-local'
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        variant="standard"
                        label={t('End Date')}
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        type='datetime-local'
                        InputLabelProps={{ shrink: true }}
                    />
                </fieldset>
                <fieldset className="flex gap-x-8">
                    <TextField
                        variant="standard"
                        label={t('Goal')}
                        value={goal}
                        onChange={(e) => setGoal(e.target.value)}
                        type="number"
                    />
                    <div className="w-44">
                        <Autocomplete
                            clearOnEscape
                            options={currencyValues}
                            getOptionLabel={(option) => t(currencies[option]?.name || '')}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label={t("Goal Currency")}
                                    variant="standard"
                                />}
                            filterSelectedOptions={false}
                            value={goalCurrency}
                            onChange={(event, newValue) => {
                                setGoalCurrency(newValue)
                            }}
                        />
                    </div>
                </fieldset>
                <div className='flex justify-center mt-12'>
                    <LoadingButton
                        sx={{ borderRadius: 99 }}
                        color='primary'
                        size='large'
                        className='rounded-full'
                        variant='contained'
                        type='submit'
                        loading={savingCampaign}
                        onClick={handleSaveCampaign}
                    >
                        {t('Save')}
                    </LoadingButton>
                </div>
            </div>
        </div >
    );

    const renderSubCampaigns = () => (
        <div>
            {subCampaigns.length === 0 ?
                <div className="text-center text-gray-500 text-2xl mt-10">
                    {t('No sub campaigns found')}
                </div> :
                <div>
                    <table className="table-auto border-collapse w-full text-start mb-10">
                        <thead>
                            <tr className="rounded-lg text-sm font-medium text-gray-700">
                                <th className="px-4 py-2">
                                    <div className='flex gap-2'>{t('Name')}
                                    </div>
                                </th>
                                <th className="px-4 py-2">
                                    <div className='flex gap-2'>{t('Date')}
                                    </div>
                                </th>
                                <th className="px-4 py-2">
                                    <div className='flex gap-2'>{t('Total Raised')}
                                    </div>
                                </th>
                                <th className="px-4 py-2">{t('Goal')}</th>
                                <th className="px-4 py-2">
                                    <div className='flex gap-2'>{t('Donations')}
                                    </div>
                                </th>
                                <th className="px-4 py-2">
                                    <div className='flex gap-2'>{t('Description')}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-sm font-normal text-gray-700 bg-white rounded-2xl shadow-sm">
                            {subCampaigns.map((subCampaign, index) => (
                                <tr key={index} className={`hover:bg-gray-100 ${subCampaigns.length - 1 !== index && 'border-b'}  border-gray-200 pt-10`}>
                                    <td className={`px-4 py-4 ${index === subCampaigns.length - 1 && 'rounded-bs-2xl'} ${index === 0 && 'rounded-ts-2xl'}`}>
                                        <Link to={`/donations/campaigns/${name}/sub-campaigns/${subCampaign.name}`} className="underline underline-offset-4 hover:text-blue-900">
                                            {subCampaign.name}
                                        </Link>
                                    </td>
                                    <td className="px-4 py-4">{formatDate(subCampaign.end_date)}</td>
                                    <td className="px-4 py-4">{subCampaign.donations_amount && parseInt(subCampaign.donations_amount).toLocaleString(undefined)}</td>
                                    <td className="px-4 py-4">{subCampaign.goal && parseInt(subCampaign.goal).toLocaleString(undefined)}</td>
                                    <td className="px-4 py-4">{parseInt(subCampaign.donations_count).toLocaleString(undefined)}</td>
                                    <td className={`px-4 py-4 ${index === subCampaigns.length - 1 && 'rounded-be-2xl'} ${index === 0 && 'rounded-te-2xl'}`}>{subCampaign.description}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}
            <div className="flex justify-end">
                <div className='fixed bottom-10 text-end mx-10'>
                    <Fab color='primary' component={Link} to={`/donations/campaigns/${name}/sub-campaigns/new`}>
                        <AddIcon />
                    </Fab>
                </div>
            </div>
        </div>
    )

    const renderDonations = () => (
        <DonationListLeg
            donations={donations}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            checkAll={checkAll}
            setCheckAll={setCheckAll}
            currentPage={donationsCurrentPage}
            setCurrentPage={setDonationsCurrentPage}
            sort={donationSort}
            setSort={setDonationSort}
            totalPages={donationsTotalPages}
        />
    )

    const renderDonors = () => (
        <ContactList
            contacts={donors}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            checkAll={checkAll}
            setCheckAll={setCheckAll}
            currentPage={donorsCurrentPage}
            setCurrentPage={setDonorsCurrentPage}
            getSort={(field) => {
                const sortItem = donorSort.find((item) => item[0] === field);
                return sortItem ? sortItem[1] : '';
            }}
            setSort={(field, sortDir) => {
                setDonorSort((prevSort) => {
                    const newSort = prevSort.filter((item) => item[0] !== field);
                    if (sortDir !== '') {
                        return [...newSort, [field, sortDir]];
                    } else {
                        return newSort;
                    }
                });
            }}
            totalPages={donorsTotalPages}
        />
    )

    const renderOverview = () => {
        if (campaignLoading) return <LoadingComponent />;

        const pyData = campaignData.sub_campaigns.map(subCampaign => ({
            label: subCampaign.name,
            value: subCampaign.donations_sum,
        }));

        const donationsSumRatio = campaignData.donations_sum / campaignData.goal
        const donationsPercent = donationsSumRatio * 100

        const StyledText = styled('text')(({ theme }) => ({
            fill: theme.palette.text.primary,
            textAnchor: 'middle',
            dominantBaseline: 'central',
            fontSize: 20,
        }));

        function PieCenterLabel({ children }) {
            const { width, height, left, top } = useDrawingArea();
            return (
                <StyledText x={left + width / 2} y={top + height / 2}>
                    {children}
                </StyledText>
            );
        }

        const tooltipFormatter = (value) => {
            return value.value.toLocaleString(i18n.language, { style: 'currency', currency: campaignData.goal_currency, minimumFractionDigits: 0, maximumFractionDigits: 0 });
        }

        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography fontWeight='600' variant="h1" gutterBottom>
                            {campaignData.name}
                        </Typography>
                        <Typography fontWeight='regular' variant="h3" paragraph>
                            {campaignData.description}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                            {localDate(campaignData.start_date)}
                            - {localDate(campaignData.end_date)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box width='100%'>
                            <PieChart
                                series={[
                                    {
                                        startAngle: 0,
                                        endAngle: donationsSumRatio * 360,
                                        paddingAngle: 0,
                                        innerRadius: 60,
                                        outerRadius: 80,
                                        cornerRadius: 5,
                                        highlightScope: { faded: 'global', highlighted: 'item' },
                                        faded: { innerRadius: 60, additionalRadius: 0 },
                                        highlighted: { additionalRadius: 3 },
                                        valueFormatter: tooltipFormatter,
                                        data: pyData,
                                    },
                                ]}
                                margin={{ right: 5 }}
                                height={200}
                                slotProps={{
                                    legend: {
                                        hidden: true,
                                        position: { vertical: 'middle', horizontal: 'right' },
                                        padding: 0,
                                    },
                                }}
                            >
                                <PieCenterLabel>
                                    {donationsPercent.toFixed(2)}%
                                </PieCenterLabel>
                            </PieChart>
                        </Box>
                        <Box sx={{ textAlign: 'center', mt: 4 }}>
                            <Typography textAlign='center' variant="h4" component="p" gutterBottom>
                                {campaignData.donations_sum.toLocaleString(i18n.language, { style: 'currency', currency: campaignData.goal_currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })} {t('out of')} {campaignData.goal.toLocaleString(i18n.language, { style: 'currency', currency: campaignData.goal_currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            </Typography>
                            <Typography textAlign='center' variant="subtitle1" color="textSecondary">
                                {t('from')} {campaignData.donations_count} {t('donations')}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const renderFundraisers = () => {
        return (
            <div>
                <h2 className=" text-custom-blue font-bold text-xl">{t('Fundraisers')}</h2>
                <div className="text-center text-gray-500 text-2xl mt-10">
                    {t('No fundraisers found')}
                </div>
            </div>
        )
    }

    if (campaignLoading) return <LoadingComponent />;

    return (
        <>
            <div className="flex justify-between">
                <Typography variant="h1">{name === 'new' ? t('New Campaign') : name}</Typography>
                {name !== 'new' && <Options options={options} />}
            </div>
            <div className="bg-white rounded-2xl shadow-sm">
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', pt: '0.8rem' }}>
                        <TabList onChange={(e, value) => setTabValue(value)}>
                            <Tab label={t('Overview')} value="1" disabled={name === 'new'} />
                            {/* <Tab label={t('Campaign Details')} value="2" /> */}
                            {/* <Tab label={t('Sub Campaigns')} value="3" disabled={name === 'new'} />
                            <Tab label={t('Donations')} value="4" disabled={name === 'new'} />
                            <Tab label={t('Donors')} value="5" disabled={name === 'new'} /> */}
                            <Tab label={t('Fundraisers')} value="6" disabled={name === 'new'} />
                        </TabList>
                    </Box>
                    <TabPanel value="1">{renderOverview()}</TabPanel>
                    <TabPanel value="2">{renderCampaingDetails()}</TabPanel>
                    <TabPanel value="3">{renderSubCampaigns()}</TabPanel>
                    <TabPanel value="4">{renderDonations()}</TabPanel>
                    <TabPanel value="5">{renderDonors()}</TabPanel>
                    <TabPanel value="6">{renderFundraisers()}</TabPanel>
                </TabContext>
            </div>
            <DeleteDialog
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onDelete={deleteFunction}
                title={deleteTitle}
                text={deleteText}
            />
        </>
    );
}

export default CampaignView;