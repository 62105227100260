import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import H1 from "components/common/Typography/H1"
import { Link } from "react-router-dom"
import LoadingComponent from "components/Loading"
import currencies from 'data/currencies';
import AddIcon from '@mui/icons-material/Add';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, Fab, IconButton, Modal, TextField } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { mainStore } from "stores/MainStore"
import useApi from "hooks/useApi"

function TaskTypes() {
    const { t } = useTranslation()
    const api = useApi();
    const [taskTypes, setTaskTypes] = useState([])
    const [loading, setLoading] = useState(true)
    const [updated, setUpdated] = useState(false)
    const [addModalOpen, setAddModalOpen] = useState(false)

    const [newName, setNewName] = useState('')
    const [newDescription, setNewDescription] = useState('')
    const [savingNew, setSavingNew] = useState(false)
    const [newOrEdit, setNewOrEdit] = useState('new')
    const [prevName, setPrevName] = useState('')

    const [deleting, setDeleting] = useState(false)

    const fetchTasks = () => {
        api.get('/tasks/task-types/').then((response) => {
            setTaskTypes(response.data)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        fetchTasks()
    }, [updated])

    const handleSaveTaskType = () => {
        if (newOrEdit === 'new') {
            api.post('/tasks/task-types/', { name: newName, description: newDescription }).then((response) => {
                setUpdated(!updated)
                setAddModalOpen(false)
                setNewName('')
                setNewDescription('')
                setSavingNew(false)
            }).catch((error) => {
                console.log(error)
                setSavingNew(false)
            })
        } else {
            api.put('/tasks/task-types/', { name: newName, description: newDescription }, {
                params: {
                    name: prevName
                },
            }).then((response) => {
                setUpdated(!updated)
                setAddModalOpen(false)
                setNewName('')
                setNewDescription('')
                setSavingNew(false)
            }).catch((error) => {
                console.log(error)
                setSavingNew(false)
            })
        }
    }

    const handleDeleteTaskType = () => {
        setDeleting(true)
        api.delete('/tasks/task-types/', {
            params: {
                name: prevName
            },
        }).then((response) => {
            setUpdated(!updated)
            setAddModalOpen(false)
            setNewName('')
            setNewDescription('')
            setDeleting(false)
        }).catch((error) => {
            console.log(error)
            setDeleting(false)
        })
    }



    const renderTable = () => (
        <div className="container mx-auto text-start">
            <table className="table-auto border-collapse w-full mb-10">
                <thead>
                    <tr className="rounded-lg text-sm font-medium text-gray-700">
                        <th className="px-4 py-2">{t('Name')}</th>
                        {/* <th className="px-4 py-2">{t('Amount')}</th> */}
                        {/* <th className="px-4 py-2">{t('Count')}</th> */}
                        <th className="px-4 py-2">{t('Description')}</th>
                        <th className="px-4 py-2 w-4"></th>
                    </tr>
                </thead>
                <tbody className="text-sm font-normal text-gray-700 bg-white rounded-2xl shadow-sm">
                    {taskTypes.map((taskType, index) => (
                        <tr key={taskType.id} className={`hover:bg-gray-100 ${index === taskTypes.length - 1 ? '' : 'border-b '} border-gray-200 py-10`}>
                            <td className={`px-4 py-4 ${index === 0 && 'rounded-ts-2xl'}
                            ${index === taskTypes.length - 1 && 'rounded-bs-2xl'}`}>
                                {/* <Link to={`/tasks/task-types/${taskType.name}`} className="underline underline-offset-4 hover:text-blue-900"> */}
                                {taskType.name}
                                {/* </Link> */}
                            </td>
                            {/* <td className="px-4 py-4">
                                {parseFloat(taskType.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currencies[taskType.currency].symbol_native}
                            </td> */}
                            <td className="px-4 py-4">
                                <div className='flex-shrink min-w-0 max-w-xs truncate-2-lines'>
                                    {taskType.description}
                                </div>
                            </td>
                            <td className={`px-4 ${index === 0 && 'rounded-te-2xl'}
                            ${index === taskTypes.length - 1 && 'rounded-be-2xl'}`}>
                                <div className="">
                                    <IconButton
                                        onClick={() => {
                                            setNewOrEdit('edit')
                                            setPrevName(taskType.name)
                                            setNewName(taskType.name)
                                            setNewDescription(taskType.description)
                                            setAddModalOpen(true)
                                        }}
                                    >
                                        <EditRoundedIcon />
                                    </IconButton>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* <div className="flex justify-end">
                <div className='fixed bottom-10 text-end mx-10'>
                    <Fab
                        color='primary'
                        onClick={handleNewDonation}
                    >
                        <AddIcon />
                    </Fab>
                </div>
            </div>
            <div className="ltr flex items-center justify-center">
                {pagination().map((page, index) => (
                    <button
                        key={index}
                        className={`mx-1 px-3 py-1 rounded-md text-sm font-medium ${page === currentPage
                            ? 'bg-blue-900 text-white'
                            : 'text-gray-700 hover:bg-gray-200'
                            }`}
                        onClick={() => { typeof page === 'number' && handlePageChange(page) }}
                        disabled={typeof page !== 'number'}
                    >
                        {page}
                    </button>
                ))}
            </div> */}
        </div>
    );

    return (
        <>
            <H1>{t('Task Types')}</H1>
            <Modal
                open={addModalOpen}
                onClose={() => { setAddModalOpen(false) }}
            >
                <div className="bg-white w-2/5 absolute text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-3xl p-4" >
                    <div className="flex justify-end">
                        <IconButton
                            onClick={() => { setAddModalOpen(false) }}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    </div>
                    <div className="px-8 pb-8">
                        <div className="flex flex-col gap-y-8">
                            <h2 className="text-2xl font-bold mb-4">{`${newOrEdit === 'new' ? t('Add') : t('Edit')} ${t('Task Type')}`}</h2>
                            <TextField
                                label={t('Name')}
                                variant="standard"
                                value={newName}
                                onChange={(e) => { setNewName(e.target.value) }}
                            />
                            <TextField
                                label={t('Description')}
                                variant="standard"
                                value={newDescription}
                                onChange={(e) => { setNewDescription(e.target.value) }}
                            />
                            <div className="mt-4">
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    loading={false}
                                    sx={{ borderRadius: 99, mx: 2 }}
                                    onClick={handleSaveTaskType}
                                >
                                    {newOrEdit === 'new' ? t('Add') : t('Save')}
                                </LoadingButton>
                                {newOrEdit === 'edit' &&
                                    <LoadingButton
                                        variant="outlined"
                                        color="error"
                                        size="large"
                                        loading={deleting}
                                        sx={{ borderRadius: 99 }}
                                        onClick={handleDeleteTaskType}
                                    >
                                        {t('Delete')}
                                    </LoadingButton>}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {loading ? <LoadingComponent /> :
                <div>
                    {renderTable()}
                </div>}
            <div className="flex justify-end">
                <div className='fixed bottom-10 text-end mx-10'>
                    <Fab color='primary' onClick={() => {
                        setNewOrEdit('new')
                        setAddModalOpen(true)
                    }}>
                        <AddIcon />
                    </Fab>
                </div>
            </div>
        </>
    )
}

export default TaskTypes