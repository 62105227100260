import { useTranslation } from "react-i18next";
import Navbar from "./navbar";
import SectionTitle from "./sectionTitle";
import Hero from "./hero";
import Benefits from "./benefits";
import { benefitOne, benefitTwo, benefitThree, benefitFour } from "./data";
import Video from "./video";
import Testimonials from "./testimonials";
import Faq from "./faq";
import Cta from "./cta";
import Footer from "./footer";
import BenefitsCarousel from "./benefitsCarousel";
import Features from "./features";
import Contact from "./contact-us";
import { Helmet } from 'react-helmet';
import WaitingListModal from "./waitnigListForm";

function Home() {
  const { t } = useTranslation();

  return (
    <div className="bg-white dark:bg-gray-900 text-start dark:text-white font-poppins">
      <Helmet>
        <title>Donbar</title>
        <meta name="description" content="Donbar Home Page" />
        <meta name="keywords" content="Donbar, DRM, Donation managment, Charity, Organization, Non-profit" />
      </Helmet>
      <Navbar />
      <Hero />

      <SectionTitle
        title={t('section-2-headline')}>
        {t('section-2-subheadline')}
      </SectionTitle>
      <div id="features">
        <Features />
        {/* <BenefitsCarousel /> */}
      </div>
      <SectionTitle
        title={t('section-1-headline')}>
        {t('section-1-subheadline')}
      </SectionTitle>
      {/* <div className="flex flex-col items-center w-2/3 justify-center p-4 mx-auto">
        <mark className="text-center self-center text-xl justify-center p-4 mx-auto text-indigo-800 bg-indigo-100 rounded-md ring-indigo-100 ring-4 dark:ring-indigo-900 dark:bg-indigo-900 dark:text-indigo-200">
          Experience the ease and convenience of managing donations and donors with DonBar. Say hello to organization and goodbye to spreadsheets!
        </mark>
      </div> */}
      <div className="py-10 bg-gray-100 dark:bg-gray-800">
        <div id="product">
          <Benefits data={benefitFour} />
          <cite className="flex justify-center text-center text-xl w-2/3 lf:w-1/2 mx-auto mb-10">
            {t('cite-1')}
          </cite>
          <Benefits imgPos="right" data={benefitTwo} />
          <Benefits data={benefitThree} />
        </div>
      </div>
      {/* <Benefits imgPos="right" data={benefitTwo} /> */}
      {/* <Video /> */}
      {/* <Testimonials /> */}
      <SectionTitle pretitle={t('FAQ')} title={t('section-3-headline')}>
        {/* {t('section-3-subheadline')} */}
      </SectionTitle>
      <div id="faqs">
        <Faq />
      </div>
      <Cta />
      <Contact />
      <Footer />
      {/* <PopupWidget /> */}
    </div>
  );
}

export default Home;