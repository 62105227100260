import { LoadingButton } from "@mui/lab";
import { Alert, Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import InfoModal from "components/common/InfoModal";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { mainStore } from "stores/MainStore";
import { localDate } from "utils/dates";
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';

function SelectEmailPlan({ open, onClose, currentPlan, onSave, saving, saveText }) {
    const { t, i18n } = useTranslation();
    const { billingCurrency, roamingDays, subscription, emailPlans, emailPlan } = mainStore;
    const [selectedPlan, setSelectedPlan] = useState(currentPlan);

    const priceDifference = selectedPlan?.price - emailPlan?.price;
    const formattedPriceDifference = priceDifference.toLocaleString(i18n.language, {
        style: 'currency',
        currency: billingCurrency,
    });

    const localNextBillingDate = localDate(subscription?.next_billing_date)
    const priceWithVat = selectedPlan.price * 1.17;

    return (
        <InfoModal
            open={open}
            onClose={onClose}
            title={t('Donbar Email Plans')}
            description={
                <span>
                    {t('select-plan-header-part-1')}
                    <br />
                    {t('select-plan-header-part-2')}
                </span>
            }
        >
            <Box sx={{ mt: 3 }}>
                <div className="flex justify-center">
                    {subscription?.has_paid_subscription && !(selectedPlan?.amount === currentPlan?.amount && selectedPlan?.price === currentPlan?.price) &&
                        // currentPlan should not be the next plan!
                        (selectedPlan.price > emailPlan.price ? (
                            <Alert severity="warning" sx={{ mt: -2, textAlign: 'center' }} icon>
                                <Trans
                                    i18nKey="upgradingAlertMessage"
                                    components={{ strong: <strong /> }}
                                    values={{ roamingDays: roamingDays, price: formattedPriceDifference }}
                                />
                            </Alert>
                        ) : (selectedPlan.price < emailPlan.price) && (
                            <Alert severity="warning" sx={{ mt: -2, textAlign: 'center' }} icon>
                                <Trans
                                    i18nKey="downgrade-alert-message"
                                    components={{ strong: <strong /> }}
                                    values={{ roamingDays: roamingDays, date: localNextBillingDate }}
                                />
                            </Alert>
                        ))
                    }
                </div>
                <Typography variant="subtitle2" gutterBottom sx={{ textAlign: 'start', mt: 1 }}>
                    {t('Select your plan')}
                </Typography>
                <FormControl fullWidth>
                    <Select
                        size="small"
                        value={selectedPlan?.amount || ''}
                        onChange={(e) => setSelectedPlan(emailPlans.find(plan => plan.amount === e.target.value))}
                        IconComponent={UnfoldMoreRoundedIcon}
                        renderValue={() => {
                            return (
                                <div className="flex items-center gap-x-2">
                                    <b>{selectedPlan?.name}:</b>&nbsp;{selectedPlan?.amount.toLocaleString()} {t('emails / month')} - {selectedPlan?.price.toLocaleString(i18n.language, { style: 'currency', currency: billingCurrency })}
                                </div>
                            )
                        }}
                    >
                        {emailPlans.map((plan, index) => (
                            <MenuItem key={index} value={plan.amount}>
                                <b>{plan.name}:</b>&nbsp;{plan.amount.toLocaleString()} {t('emails / month')} - {plan.price.toLocaleString(i18n.language, { style: 'currency', currency: billingCurrency })}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {(subscription.country === 'IL' || billingCurrency === 'ILS' || (!subscription.country && i18n.language === 'he')) &&
                    <Typography variant="subtitle2" gutterBottom sx={{ textAlign: 'start', mt: 1 }}>
                        <strong>{priceWithVat.toLocaleString(i18n.language, { style: 'currency', currency: billingCurrency })}</strong> {t('VAT included')}
                    </Typography>}
            </Box>
            <Box sx={{ mt: 4, textAlign: 'start', maxWidth: 'fit-content', justifyContent: 'center', mx: 'auto' }}>
                <Typography><Trans i18nKey="plansInclude" /></Typography>
                <Box component="ul" sx={{ listStyleType: 'disc', ml: 4, mt: 1 }}>
                    <li><Typography><Trans i18nKey="emailStats" components={{ strong: <strong /> }} /></Typography></li>
                    <li><Typography><Trans i18nKey="emailDesigner" components={{ strong: <strong /> }} /></Typography></li>
                    <li><Typography><Trans i18nKey="personalization" components={{ strong: <strong /> }} /> <i>{t('Comming Soon')}</i></Typography></li>
                </Box>
            </Box>
            <Box sx={{ mt: 4, textAlign: 'center' }}>
                <LoadingButton
                    variant="contained"
                    loading={saving}
                    disabled={selectedPlan?.amount === currentPlan?.amount && selectedPlan?.price === currentPlan?.price}
                    onClick={() => {
                        onSave(selectedPlan);
                    }}
                >
                    {saveText || t('Confirm')}
                </LoadingButton>
            </Box>
        </InfoModal>
    )
}

export default SelectEmailPlan;