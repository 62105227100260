import { useTranslation } from "react-i18next";
import Details from "./Details/Details";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useApi from "hooks/useApi";
import { observer } from "mobx-react-lite";
import { uiStore } from "stores/UiStore";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { optionsClickOutside } from "utils/controls";
import { DeleteRounded, EditRounded } from "@mui/icons-material";
import CampaignForm from "./CampaignForm";
import { useSnackbar } from "notistack";
import WarningDialog from "components/common/DeleteDialog";
import { Typography } from "@mui/material";

function SubCampaignDetails() {
	const { t } = useTranslation();
	const { campaignName, subCampaignName } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const api = useApi();
	const { setOptions } = uiStore;
	const { enqueueSnackbar } = useSnackbar();
	const [editCampaign, setEditCampaign] = useState(false);
	const [deleteSubCampaignDialog, setDeleteSubCampaignDialog] = useState(false);

	const subCampaignQuery = useQuery(['subCampaign', campaignName, subCampaignName],
		() => api.get(`/campaigns/${campaignName}/sub-campaigns/${subCampaignName}`).then(res => res.data),
		{
			enabled: !!campaignName && !!subCampaignName,
			refetchOnWindowFocus: true,
		}
	);

	const { data: subCampaign, isLoading: subCampaignLoading } = subCampaignQuery;

	const { mutate: updateSubCampaign, isLoading: subCampaignUpdating } = useMutation(
		(data) => api.put(`/campaigns/sub-campaigns/${subCampaign?.id}`, { ...data, campaign: subCampaign.campaign }),
		{
			onSuccess: (res) => {
				enqueueSnackbar(t('Sub Campaign updated successfully.'), { variant: 'success' });
				navigate(location.pathname.split('/').slice(0, -1).join('/') + `/${res.data.name}`);
				subCampaignQuery.refetch();
				setEditCampaign(false);
				optionsClickOutside();
			},
			onError: () => enqueueSnackbar(t('Error updating sub campaign.'), { variant: 'error' }),
		}
	);

	const { mutate: deleteSubCampaign, isLoading: subCampaignDeleting } = useMutation(
		() => api.delete(`/campaigns/sub-campaigns/${subCampaign?.id}`),
		{
			onSuccess: () => {
				enqueueSnackbar(t('Sub Campaign deleted successfully.'), { variant: 'success' });
				navigate(location.pathname.split('/').slice(0, -1).join('/'));
				setDeleteSubCampaignDialog(false);
				optionsClickOutside();
			},
			onError: () => enqueueSnackbar(t('Error deleting sub campaign.'), { variant: 'error' }),
		}
	);

	useEffect(() => {
		setOptions([
			{
				key: 'edit-sub-campaign',
				text: t('Edit Sub Campaign'),
				icon: <EditRounded />,
				onClick: () => setEditCampaign(true),
			},
			{
				key: 'delete-sub-campaign',
				text: t('Delete Sub Campaign'),
				icon: <DeleteRounded />,
				onClick: () => setDeleteSubCampaignDialog(true),
			}
		]);

		return () => setOptions([]);
	}, []);

	return (
		<>
			<Details key='subCampaign' type='subCampaign' data={subCampaign} loading={subCampaignLoading} />
			{editCampaign &&
				<CampaignForm
					title={t('Edit Campaign')}
					data={subCampaign}
					onClose={() => setEditCampaign(false)}
					onSave={(data) => updateSubCampaign(data)}
					saving={subCampaignUpdating}
					type='sub-campaigns'
				/>
			}
			<WarningDialog
				requireInput
				open={deleteSubCampaignDialog}
				title={t('Delete Sub Campaign')}
				text={
					<div>
						<Typography variant='subtitle2' fontWeight={600} gutterBottom>
							{t('All groups and fundraisers under')} <strong>{subCampaign?.name}</strong> {t('will be deleted.')}
						</Typography>
						<Typography variant='subtitle2' fontWeight={600} gutterBottom>
							{t('All your associations with donations, pledges and donors will be lost. (They will not be deleted)')}
						</Typography>
					</div>
				}
				onClose={() => setDeleteSubCampaignDialog(false)}
				onDelete={() => deleteSubCampaign()}
				deleteInputText={t('delete sub campaign')}
				approveText={t('Delete')}
			/>
		</>
	);
}

export default observer(SubCampaignDetails);