import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import H1 from "./common/Typography/H1"
import { Link, useNavigate } from "react-router-dom"
import LoadingComponent from "./Loading"
import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, IconButton, Modal, TextField } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import useApi from "hooks/useApi"
import DynamicTable from "./general/DynamicTable"

const LIST_SET_DEPENDENCIES = {
    contacts: {
        page_header: 'Contact Groups',
    },
    donations: {
        page_header: 'Donation Groups',
    },
}

function Lists({ listSet }) {
    const { t } = useTranslation()
    const api = useApi();
    const navigate = useNavigate()
    const [lists, setLists] = useState([])
    const [loading, setLoading] = useState(true)
    const [updated, setUpdated] = useState(false)
    const [addModalOpen, setAddModalOpen] = useState(false)

    const [newName, setNewName] = useState('')
    const [newDescription, setNewDescription] = useState('')
    const [savingNew, setSavingNew] = useState(false)
    const [newOrEdit, setNewOrEdit] = useState('new')
    const [prevName, setPrevName] = useState('')

    const [deleting, setDeleting] = useState(false)

    const fetchLists = () => {
        api.get(`/${listSet}/lists/`).then((response) => {
            setLists(response.data)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        fetchLists()
    }, [updated])

    const handleSaveList = () => {
        if (newOrEdit === 'new') {
            api.post(`/${listSet}/groups/create`, { name: newName, description: newDescription }).then((response) => {
                setUpdated(!updated)
                setAddModalOpen(false)
                setNewName('')
                setNewDescription('')
                setSavingNew(false)
            }).catch((error) => {
                console.log(error)
                setSavingNew(false)
            })
        } else {
            api.put('/tasks/task-types/', { name: newName, description: newDescription }, {
                params: {
                    name: prevName
                },
            }).then((response) => {
                setUpdated(!updated)
                setAddModalOpen(false)
                setNewName('')
                setNewDescription('')
                setSavingNew(false)
            }).catch((error) => {
                console.log(error)
                setSavingNew(false)
            })
        }
    }

    const handleDeleteTaskType = () => {
        setDeleting(true)
        api.delete('/tasks/task-types/', {
            params: {
                name: prevName
            },
        }).then((response) => {
            setUpdated(!updated)
            setAddModalOpen(false)
            setNewName('')
            setNewDescription('')
            setDeleting(false)
        }).catch((error) => {
            console.log(error)
            setDeleting(false)
        })
    }



    const renderTable = () => (
        <DynamicTable
            columns={[
                {
                    value: t('Name'),
                    field: 'name',
                },
                {
                    value: t(listSet),
                    field: 'count',
                },
                {
                    value: t('To Do'),
                    field: 'todo_count',
                },
                {
                    value: t('Description'),
                    field: 'description',
                },
            ]}
            rows={lists}
            onRowClick={(list) => navigate(list.name)}
        />
    );

    return (
        <>
            <H1>{t(LIST_SET_DEPENDENCIES[listSet].page_header)}</H1>
            <Modal
                open={addModalOpen}
                onClose={() => { setAddModalOpen(false) }}
            >
                <div className="bg-white w-2/5 absolute text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-3xl p-4" >
                    <div className="flex justify-end">
                        <IconButton
                            onClick={() => { setAddModalOpen(false) }}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    </div>
                    <div className="px-8 pb-8">
                        <div className="flex flex-col gap-y-8">
                            <h2 className="text-2xl font-bold mb-4">{`${newOrEdit === 'new' ? t('Add') : t('Edit')} ${t('Group')}`}</h2>
                            <TextField
                                label={t('Name')}
                                variant="standard"
                                value={newName}
                                onChange={(e) => { setNewName(e.target.value) }}
                            />
                            <TextField
                                label={t('Description')}
                                variant="standard"
                                value={newDescription}
                                onChange={(e) => { setNewDescription(e.target.value) }}
                            />
                            <div className="mt-4">
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    loading={false}
                                    sx={{ borderRadius: 99, mx: 2 }}
                                    onClick={handleSaveList}
                                >
                                    {newOrEdit === 'new' ? t('Add') : t('Save')}
                                </LoadingButton>
                                {newOrEdit === 'edit' &&
                                    <LoadingButton
                                        variant="outlined"
                                        color="error"
                                        size="large"
                                        loading={deleting}
                                        sx={{ borderRadius: 99 }}
                                        onClick={handleDeleteTaskType}
                                    >
                                        {t('Delete')}
                                    </LoadingButton>}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {loading ? <LoadingComponent /> :
                <div>
                    {renderTable()}
                </div>}
            <div className="flex justify-end">
                <div className='fixed bottom-10 text-end mx-10'>
                    <Fab color='primary' onClick={() => {
                        setNewOrEdit('new')
                        setAddModalOpen(true)
                    }}>
                        <AddIcon />
                    </Fab>
                </div>
            </div>
        </>
    )
}

export default Lists