import { LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineConnectorClasses, timelineDotClasses, timelineItemClasses, timelineSeparatorClasses } from "@mui/lab";
import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, Popover, SpeedDial, SpeedDialAction, SpeedDialIcon, Stack, Table, TableBody, TableCell, TableRow, TextField, Typography, tableCellClasses } from "@mui/material";
import { useTranslation } from "react-i18next";
import { addressString } from "../ContactList";
import { Link as RouterLink } from "react-router-dom";
import { contactActivityTypeData, contactActivityTypes } from "data/general";
import { formatActivityDate } from "utils";
import { useAuthInfo } from "@propelauth/react";
import TaskDetails from "views/Contacts/Tasks/TaskDetails";
import { useState } from "react";
import { AddTaskRounded, EventRounded, MoreTimeRounded, TurnedInRounded } from "@mui/icons-material";
import { t } from "i18next";
import i18n from "i18n";
import { useMutation, useQueryClient } from "react-query";
import useApi from "hooks/useApi";
import { useSnackbar } from "notistack";
import { activityDateTime, localDateTime } from "utils/dates";
import PledgeDetails from "views/Donations/Pledges/PledgeDetails";
import AddTag from "components/AddTag";
import DonorStatus from "./Cards/DonorCard/DonorStatus";
import AddCard from "./Cards/AddCard";
import ContactViewCards from "./Cards/ContactViewCards";

const donationTitle = (donation) => {
    const { donation_type, amount, currency, payments } = donation;
    let donationTitle = '';
    if (donation_type === 2) donationTitle = `${t('Recurring Donation')} &middot; `;
    donationTitle = amount.toLocaleString(i18n.language, { style: 'currency', currency: currency })
    if (donation_type === 2 && payments !== 0) donationTitle += ` &times; ${payments} ${t('per month')}`;
    else if (donation_type === 2) donationTitle += ` ${t('no time limit')}`;

    return donationTitle;
}


const ContactFullname = ({ contact }) => {
    const { prefix, first_name, last_name, display_name, suffix, contact_id } = contact;
    const hasName = first_name || last_name;

    return (
        <Typography variant="h6">
            {prefix ? `${prefix} ` : null}
            <strong>{display_name}</strong>
            {suffix ? ` ${suffix} ` : null}
            {hasName ? ` (${contact_id})` : null}
        </Typography>
    );
};

function EditTask({ task, contact, onClose, handleSaveTask }) {
    const { t } = useTranslation();
    const api = useApi();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const [data, setData] = useState(task?.id ? { 'task_id': task?.id } : {});
    const [saving, setSaving] = useState(false);
    const [taskTypeOpen, setTaskTypeOpen] = useState(false);
    const [dueDateOpen, setDueDateOpen] = useState(false);
    const [selectDateOpen, setSelectDateOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [taskTypes, setTaskTypes] = useState([]);
    const [edit, setEdit] = useState(false);
    const [taskId, setTaskId] = useState(task?.id || null);
    const [title, setTitle] = useState(task?.title || '');
    const [description, setDescription] = useState(task?.description || '');
    const [taskType, setTaskType] = useState(task?.task_type || null);
    const [dueDate, setDueDate] = useState(task?.due_date ? localDateTime(task?.due_date) : null);
    const [pickDueDate, setPickDueDate] = useState('');
    const [submiting, setSubmiting] = useState(false);
    const [deleteSubmiting, setDeleteSubmiting] = useState(false);

    const { mutate: createTask, isLoading: newTaskLoading } = useMutation(
        (data) => api.post('/tasks/create/', data),
        {
            onSuccess: () => {
                queryClient.refetchQueries('contactActivity');
                onClose();
                enqueueSnackbar(t('Task created'), { variant: 'success' });
            },
            onError: () => enqueueSnackbar(t('Error creating task'), { variant: 'error' }),
        }
    );

    const { mutate: updateTask, isLoading: updateTaskLoading } = useMutation(
        (data) => api.put('/tasks/create/', data), // I know this wried. I will fix it later
        {
            onSuccess: () => {
                queryClient.refetchQueries('contactActivity');
                onClose();
                enqueueSnackbar(t('Task updated'), { variant: 'success' });
            },
            onError: () => enqueueSnackbar(t('Error updating task'), { variant: 'error' }),
        }
    );

    const savingTask = newTaskLoading || updateTaskLoading;


    const handleInputChange = (key, val) => {
        setData({ ...data, [key]: val });
    }

    const handleTaskDuedateChange = (event) => {
        let date = new Date();
        if (event === "hour") {
            date.setHours(date.getHours() + 1)
            const hour = date;
            setDueDate(localDateTime(hour))
            handleInputChange('due_date', hour.toLocaleInput());
        } else if (event === "tomorrow") {
            date.setHours(9, 0, 0, 0);
            date.setDate(date.getDate() + 1)
            const tomorrow = date;
            setDueDate(localDateTime(tomorrow))
            handleInputChange('due_date', tomorrow.toLocaleInput());
        } else if (event === "nextWeek") {
            date.setHours(9, 0, 0, 0);
            date.setDate(date.getDate() + (9 - date.getDay()))
            const nextWeek = date;
            setDueDate(localDateTime(nextWeek))
            handleInputChange('due_date', nextWeek.toLocaleInput());
        } else if (event === "none") {
            setDueDate(null)
            handleInputChange('due_date', null);
        }
        setSelectDateOpen(false);
        setAnchorEl(null);
    };

    const handleNewTaskOpen = (e) => {
        setTaskTypeOpen(true);
        setAnchorEl(e.currentTarget);
        api.get('/tasks/task-types/').then((response) => {
            setTaskTypes(response.data);
        }).catch((error) => {
            console.error('Error fetching task types:', error);
        });
    };

    return (
        <>
            <div className='w-full'>
                <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-blue-900 focus-within:ring-1 focus-within:ring-blue-900">
                    <input
                        type="text"
                        name="title"
                        id="title"
                        className="block w-full border-0 pt-2.5 px-3 pb-2 text-lg font-medium placeholder:text-gray-400 outline-none focus:ring-0"
                        placeholder={t("Title")}
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value)
                            handleInputChange('title', e.target.value)
                        }}
                    />
                    <textarea
                        rows={2}
                        name="description"
                        id="description"
                        className="block w-full border-spacing-0 px-3 resize-none border-0 py-0 text-gray-900 outline-none placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder={t("Write a description...")}
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value)
                            handleInputChange('description', e.target.value)
                        }}
                    />

                    <div className="flex flex-nowrap justify-end gap-2 px-2 py-2 sm:px-3">
                        <Chip
                            label={taskType?.name || t('Task Type')}
                            icon={<TurnedInRounded fontSize='small' />}
                            onClick={(e) => handleNewTaskOpen(e)}
                        />
                        <Chip
                            label={dueDate ? dueDate : t('Due Date')}
                            icon={<EventRounded fontSize='small' />}
                            onClick={(e) => {
                                setAnchorEl(e.currentTarget)
                                setSelectDateOpen(true)
                            }}
                        />
                        <Popover
                            open={selectDateOpen}
                            anchorEl={anchorEl}
                            onClose={() => {
                                setAnchorEl(null)
                                setSelectDateOpen(false)
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <ul className='py-2'>
                                <li
                                    className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
                                    onClick={() => handleTaskDuedateChange("hour")}>
                                    {t('Another hour')}
                                </li>
                                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer' onClick={() => handleTaskDuedateChange("tomorrow")}>
                                    {t('Tomorrow morning at 9am')}
                                </li>
                                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer' onClick={() => handleTaskDuedateChange("nextWeek")}>
                                    {t('Next week Monday at 9am')}
                                </li>
                                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer' onClick={() => !dueDateOpen && setDueDateOpen(true)}>
                                    {t('Pick a date and time')}
                                    <Dialog
                                        open={dueDateOpen}
                                        onClose={() => setDueDateOpen(false)}
                                        sx={{ textAlign: 'center' }}
                                    >
                                        <DialogTitle>{t('Pick a date and time')}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                <TextField
                                                    type='datetime-local'
                                                    value={pickDueDate}
                                                    onChange={(e) => {
                                                        setPickDueDate(e.target.value)
                                                    }}
                                                />
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions >
                                            <Button onClick={() => {
                                                setPickDueDate('')
                                                setDueDateOpen(false)
                                            }} color="primary">
                                                {t('Cancel')}
                                            </Button>
                                            <Button onClick={() => {
                                                setDueDate(localDateTime(pickDueDate))
                                                handleInputChange('due_date', pickDueDate)
                                                setPickDueDate('')
                                                setDueDateOpen(false)
                                                setSelectDateOpen(false);
                                                setAnchorEl(null);
                                            }} variant='contained' color="primary">
                                                {t('Select')}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </li>
                                <li
                                    className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
                                    onClick={() => handleTaskDuedateChange("none")}>
                                    {t('No due date')}
                                </li>
                            </ul>
                        </Popover>
                        <Popover
                            open={taskTypeOpen}
                            anchorEl={anchorEl}
                            onClose={() => {
                                setTaskTypeOpen(false)
                                setAnchorEl(null)
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <ul className='py-2 w-40 max-h-56 overflow-y-auto'>
                                <li
                                    className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
                                    onClick={() => {
                                        setTaskType(null)
                                        handleInputChange('task_type', null)
                                        setTaskTypeOpen(false)
                                        setAnchorEl(null)
                                    }}
                                >
                                    {t('No task type')}
                                </li>
                                {taskTypes.map((taskType, index) => (
                                    <li
                                        key={index}
                                        className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
                                        onClick={() => {
                                            setTaskType(taskType)
                                            handleInputChange('task_type', taskType.id)
                                            setTaskTypeOpen(false)
                                            setAnchorEl(null)
                                        }}
                                    >
                                        {taskType.name}
                                    </li>
                                ))}
                            </ul>
                        </Popover>
                    </div>
                </div>
                <div className="absolute inset-x-px bottom-0">
                </div>
                <div className='flex gap-2 mt-2'>
                    <LoadingButton
                        color='primary'
                        variant='contained'
                        size='small'
                        onClick={() => {
                            if (task?.id) {
                                updateTask(data)
                            } else {
                                createTask({
                                    title: title,
                                    description: description || null,
                                    due_date: data.due_date || null,
                                    task_type: taskType?.id || null,
                                    contact: contact.id
                                })
                            }
                        }}
                        loading={savingTask}
                    >
                        {t('Save')}
                    </LoadingButton>
                    <Button size='small' onClick={onClose}>
                        {t('Cancel')}
                    </Button>
                </div>
            </div>
        </>
    )
}

function NewActivity({ contact }) {
    const { t } = useTranslation();
    const api = useApi();
    const authInfo = useAuthInfo();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const [newActivity, setNewActivity] = useState('');
    const [newActivitySubmiting, setNewActivitySubmiting] = useState(false);
    const [newTask, setNewTask] = useState(false);
    const [newPledge, setNewPledge] = useState(false);
    const [pledgeOpen, setPledgeOpen] = useState(false);

    const { mutate: createActivityComment, isLoading: newComentLoading } = useMutation(
        () => api.post('/contact-activities/create', { log: newActivity }, { params: { contact_pk: contact.id } }),
        {
            onSuccess: () => {
                queryClient.refetchQueries(["contactActivity", contact.contact_id]);
                setNewActivity('');
            },
            onError: () => enqueueSnackbar(t('Error creating activity'), { variant: 'error' }),
        }
    );

    if (newPledge) {
        return (
            <PledgeDetails
                open
                onClose={() => setNewPledge(false)}
                initialData={{ contact: contact }}
                onUpdate={() => {
                    setNewPledge(false);
                    queryClient.refetchQueries('contactActivity');
                }}
                hideContact
            />
        )
    }

    return (
        <Stack direction='row' spacing={2} marginBottom='1rem'>
            <Avatar sx={{ width: '1.5rem', height: '1.5rem' }} src={authInfo.user.pictureUrl} />
            {newTask ? (
                <EditTask
                    onClose={() => setNewTask(false)}
                    contact={contact}
                />
            ) : (
                <>
                    <Box sx={{ width: '100%' }}>
                        <TextField
                            fullWidth
                            multiline
                            sx={{ '& .MuiInputBase-root': { p: '0.5rem', borderRadius: '0.5rem', fontSize: '0.875rem' } }}
                            minRows={2}
                            maxRows={8}
                            placeholder={t('Write a comment')}
                            disabled={newActivitySubmiting}
                            value={newActivity}
                            onChange={(e) => setNewActivity(e.target.value)}
                        />
                        {newActivity &&
                            <div className='flex gap-2 mt-2'>
                                <LoadingButton
                                    color='primary'
                                    variant='contained'
                                    size='small'
                                    onClick={createActivityComment}
                                    loading={newComentLoading}
                                >
                                    {t('Save')}
                                </LoadingButton>
                                <Button size='small' onClick={() => setNewActivity('')}>
                                    {t('Cancel')}
                                </Button>
                            </div>}
                    </Box>
                    <div className='relative h-8 w-8 end-3.5'>
                        <SpeedDial
                            ariaLabel="SpeedDial contact activity"
                            sx={{
                                position: 'absolute', bottom: '0',
                                '& .MuiSpeedDial-fab': {
                                    width: 28,
                                    height: 28,
                                    boxShadow: 'none',
                                    minHeight: 'unset',
                                }
                            }}
                            direction='up'
                            icon={<SpeedDialIcon />}
                        >
                            <SpeedDialAction
                                icon={<AddTaskRounded />}
                                tooltipTitle={t('Add Task')}
                                tooltipOpen
                                sx={{ '& .MuiSpeedDialAction-staticTooltipLabel': { minWidth: 'max-content' } }}
                                onClick={() => setNewTask(true)}
                            />
                            <SpeedDialAction
                                icon={<MoreTimeRounded />}
                                tooltipTitle={t('Add Pledge')}
                                tooltipOpen
                                noContact
                                sx={{ '& .MuiSpeedDialAction-staticTooltipLabel': { minWidth: 'max-content' } }}
                                onClick={() => {
                                    setNewPledge(true)
                                    setPledgeOpen(true)
                                }}
                            />
                        </SpeedDial>
                    </div>
                </>
            )}
        </Stack>
    )
}

function DotDivider() {
    return <Typography variant="caption" color='text.secondary'>•</Typography>
}

function ActivityActionButton({ label, sx, ...props }) {
    return (
        <Link
            component={Button}
            size="small"
            variant="caption"
            sx={{
                minWidth: 'auto',
                ...sx
            }}
            {...props}
        >
            {label}
        </Link>
    )

}

function ActivityBox({ overdue, children }) {
    return (
        <Box
            sx={{
                display: 'inline-block',
                py: 1,
                px: 1.5,
                border: '1px solid',
                borderColor: overdue ? 'error.main' : 'primary.main',
                borderRadius: '0.5rem',
                width: '100%',
            }}
        >
            {children}
        </Box>
    )
}

function AvatarDot({ color, src }) {
    return (
        <TimelineDot
            color={color}
            variant="outlined"
        >
            <Avatar sx={{ width: '1.5rem', height: '1.5rem' }} src={src} />
        </TimelineDot>
    )
}

function ActivityHeader({ activity, subTitle, headerItem }) {
    const { i18n } = useTranslation();

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="caption" fontWeight={600} color='text.secondary'>
                    {activity.user_name.split("@")[0]} {activity.user_last_name}
                </Typography>
                <Typography variant="caption" color='text.secondary'>
                    {subTitle}
                </Typography>
                {headerItem}
            </Box>
            <Typography variant="caption" color='text.secondary'>
                {activityDateTime(activity.activity_date, i18n.language)}
            </Typography>
        </Box>
    )
}

function ActivityComment({ activity }) {
    const { t } = useTranslation();
    const authInfo = useAuthInfo();
    const queryClient = useQueryClient();
    const api = useApi();
    const { enqueueSnackbar } = useSnackbar();

    const deleteActivityMutation = useMutation(
        (id) => api.delete(`/contact_activities/delete/${id}/`),
    );

    const handleDelete = () => {
        deleteActivityMutation.mutate(activity.id, {
            onSuccess: () => {
                queryClient.refetchQueries('contactActivity');
            },
            onError: () => enqueueSnackbar(t('Error deleting activity'), { variant: 'error' })
        });
    }

    return (
        <TimelineItem>
            <TimelineSeparator>
                <AvatarDot src={activity.user_picture_url} />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ width: '100%' }}>
                <ActivityBox>
                    <ActivityHeader activity={activity} subTitle={t('commented')} />
                    <Typography variant="body1" sx={{ py: '0.25rem' }}>
                        {activity.log}
                    </Typography>
                </ActivityBox>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Stack direction='row' alignItems='center' divider={<DotDivider />}>
                        {activity.user === authInfo.user.userId && <ActivityActionButton label={t('Edit')} />}
                        <ActivityActionButton
                            label={t('Delete')}
                            onClick={handleDelete}
                        />
                    </Stack>
                </Box>
            </TimelineContent>
        </TimelineItem>
    );
}

function ActivityFeedAction({ activity, headerItem }) {
    const { t } = useTranslation();

    return (
        <TimelineItem>
            <TimelineSeparator
            >
                <TimelineDot variant="outlined" />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <ActivityHeader activity={activity} subTitle={t(contactActivityTypeData[activity.activity_type].text)} headerItem={headerItem} />
            </TimelineContent>
        </TimelineItem>
    );
}

function ActivityTaskDue({ activity }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const api = useApi();
    const queryClient = useQueryClient();
    const [edit, setEdit] = useState(false);

    const overdue = new Date(activity.task.due_date) < new Date();

    const { mutate: deleteTask, isLoading: deleteTaskLoading } = useMutation(
        (id) => api.delete('/tasks/delete/', { params: { task_ids: JSON.stringify([id]) } }),
        {
            onSuccess: () => {
                queryClient.refetchQueries('contactActivity');
                enqueueSnackbar(t('Task deleted'), { variant: 'success' });
            },
            onError: () => enqueueSnackbar(t('Error deleting task'), { variant: 'error' }),
        }
    );

    const { mutate: markAsDone } = useMutation(
        () => api.put('/tasks/create/', { task_id: activity.task.id, completed_date: new Date().toISOString() }),
        {
            onSuccess: () => {
                queryClient.refetchQueries('contactActivity');
            },
        }
    );

    function TaskViewMode() {
        return (
            <Box>
                <ActivityBox overdue={overdue}>
                    <ActivityHeader activity={activity} subTitle={t(contactActivityTypeData[activity.activity_type].text)} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography fontWeight={500} variant="body1" sx={{ py: '0.5rem' }}>
                            {activity.task.title}
                        </Typography>
                        {activity.task.task_type?.name &&
                            <Chip
                                variant='outlined'
                                size='small'
                                label={activity.task.task_type?.name}
                                icon={<TurnedInRounded fontSize='small' />}
                            />}
                    </Box>
                    <Typography variant="body2" sx={{ py: '0.25rem' }}>
                        {activity.task.description}
                    </Typography>
                </ActivityBox>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Stack direction='row' alignItems='center' divider={<DotDivider />}>
                        <ActivityActionButton label={t('Edit')} onClick={() => setEdit(true)} />
                        <ActivityActionButton label={t('Delete')} onClick={() => deleteTask(activity.task.id)} />
                    </Stack>
                    <ActivityActionButton label={t('Mark as done')} onClick={() => markAsDone()} />
                </Box>
            </Box>
        )
    }

    function TaskEditMode() {
        return (
            <EditTask
                task={activity.task}
                contact={activity.contact}
                onClose={() => setEdit(false)}
            />
        )
    }

    const TaskComponent = edit ? TaskEditMode : TaskViewMode;

    return (
        <TimelineItem>
            <TimelineSeparator>
                <AvatarDot src={activity.user_picture_url} color={overdue ? 'error' : 'grey'} />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <TaskComponent />
            </TimelineContent>
        </TimelineItem>
    );
}

function ActivityHeaderItem({ buttonLable, ...props }) {
    return (
        <>
            <Link
                component={Button}
                size="small"
                variant="caption"
                fontWeight='bold'
                underline="hover"
                sx={{
                    minWidth: 'auto',
                }}
                {...props}
            >
                {buttonLable}
            </Link>
        </>
    );
}

function ActivityPledge({ activity }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const [edit, setEdit] = useState(false);

    return (
        <>
            <ActivityFeedAction activity={activity}
                headerItem={<ActivityHeaderItem buttonLable={donationTitle(activity.donation)} onClick={() => setEdit(true)} />}
            />
            {edit &&
                <PledgeDetails
                    open
                    onClose={() => setEdit(false)}
                    initialData={{ contact: activity.donation.contact }}
                    pledgeData={activity.donation}
                    onUpdate={() => {
                        setEdit(false);
                        queryClient.refetchQueries('contactActivity');
                    }}
                    hideContact
                />
            }
        </>
    );
}

function ActivityTask({ activity }) {
    return (
        <ActivityFeedAction activity={activity}
            // headerItem={<ActivityHeaderItem buttonLable={activity.task.title} />}
            headerItem={<Typography variant="caption" fontWeight={600} color='text.secondary'>{activity.task.title}</Typography>}
        />
    );
}

function ActivityPledgeDue({ activity }) {
    const overdue = new Date(activity.donation.due_date) < new Date();
    return (
        <ActivityFeedAction activity={activity}
            headerItem={<ActivityHeaderItem buttonLable={donationTitle(activity.donation)} />}
        />
    );
}

const Activity = {
    [contactActivityTypes.NOTE]: ActivityComment,
    [contactActivityTypes.TASK_CREATED]: ActivityTask,
    [contactActivityTypes.TASK_DUE]: ActivityTaskDue,
    [contactActivityTypes.TASK_COMPLETED]: ActivityTask,
    [contactActivityTypes.PLEDGE_CREATED]: ActivityPledge,
    [contactActivityTypes.PLEDGE_DUE]: ActivityPledge,
    [contactActivityTypes.PLEDGE_PAID]: ActivityPledge,
    [contactActivityTypes.PLEDGE_CANCELLED]: ActivityPledge,
}

function Tags({ tags, setElementTags, element_id, addTag, removeTag }) {

    return (
        <div className='flex flex-wrap gap-2'>
            {tags.map((tag, index) => (
                <Chip
                    key={index}
                    label={tag.name}
                    sx={{ borderRadius: '8px', backgroundColor: tag.color }}
                    color='primary'
                    size='small'
                    // variant='outlined'
                    onDelete={() => removeTag(tag)}
                />
            ))}
            <AddTag elementTags={tags} setElementTags={setElementTags} element_id={element_id} addTag={addTag} removeTag={removeTag} />
        </div>
    )
}


function Overview({ contact, activities = [] }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [tags, setTags] = useState(contact.tags || []);
    const api = useApi();

    const addTag = (tag) => {
        api.patch('/contacts/tags/', {}, {
            params: {
                contact_id: contact.contact_id,
                tag_id: tag.id,
            },
        }).then((response) => {
            setTags(prevTags => [...prevTags, tag])
        }).catch((error) => {
            enqueueSnackbar(t('Error adding tag'), { variant: 'error' });
        });
    }

    const removeTag = (tag) => {
        api.delete('/contacts/tags/', {
            params: {
                contact_id: contact.contact_id,
                tag_id: tag.id,
            },
        }).then((response) => {
            setTags(prevTags => prevTags.filter((prevTag) => prevTag.id !== tag.id))
        }).catch((error) => {
            enqueueSnackbar(t('Error removing tag'), { variant: 'error' });
        });
    }

    return (
        <>
            <Stack spacing={3}>
                <Grid container>
                    <Grid item xs={8}>
                        <ContactFullname contact={contact} />
                        <Table
                            size="small"
                            sx={{
                                mt: '1rem',
                                [`& .${tableCellClasses.root} `]: {
                                    borderBottom: "none"
                                }
                            }}
                        >
                            <TableBody>
                                <TableRow index>
                                    <TableCell sx={{ width: '10rem' }}>
                                        <Typography fontWeight={600} variant="body2">
                                            {t("Email Addresses")}:
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {contact.email_addresses?.map((email, index) => (
                                            <>
                                                <Link
                                                    key={index}
                                                    component="a"
                                                    href={`mailto:${email} `}
                                                    underline="hover"
                                                    fontWeight={600}
                                                >
                                                    {email}
                                                </Link>
                                                {index !== contact.email_addresses.length - 1 && ', '}
                                            </>
                                        ))}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography fontWeight={600} variant="body2">
                                            {t("Phone Numbers")}:
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {contact.phone_numbers?.map((phone, index) => (
                                            <>
                                                <Link
                                                    key={index}
                                                    component="a"
                                                    href={`tel:${phone} `}
                                                    underline="hover"
                                                    fontWeight={600}
                                                >
                                                    {phone}
                                                </Link>
                                                {index !== contact.phone_numbers.length - 1 && ', '}
                                            </>
                                        ))}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography fontWeight={600} variant="body2">
                                            {t("Address")}:
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {addressString(contact)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography fontWeight={600} variant="body2">
                                            {t("Connection")}:
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {contact.connections?.map((connection, index) => (
                                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Typography variant="body1">
                                                    {connection.relationship}
                                                </Typography>
                                                <Link
                                                    component={RouterLink}
                                                    to={`/ contacts / ${connection.related_to?.contact_id} `}
                                                    variant="body1"
                                                    underline="hover"
                                                    fontWeight={600}
                                                >
                                                    {connection.related_to?.display_name}
                                                </Link>
                                            </Box>
                                        ))}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ verticalAlign: 'top' }}>
                                        <Typography fontWeight={600} variant="body2">
                                            {t("Tags")}:
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Tags tags={tags} setElementTags={setTags} element_id={contact.contact_id} addTag={addTag} removeTag={removeTag} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography fontWeight={600} variant="body2">
                                            {t("Description")}:
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {contact.description}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={4}>
                        <ContactViewCards contact={contact} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                        <Typography variant="h6">{t('Activity')}</Typography>
                        <Timeline
                            sx={{
                                px: 0,
                                [`& .${timelineItemClasses.root}`]: {
                                    ': last-child': {
                                        [`& .${timelineConnectorClasses.root}`]: {
                                            display: 'none',
                                        },
                                    },
                                    ': before': {
                                        flex: 0,
                                        padding: 0,
                                    },
                                },
                                [`& .${timelineSeparatorClasses.root}`]: {
                                    minWidth: '2rem',
                                },
                                [`& .${timelineDotClasses.root}`]: {
                                    mx: 'auto',
                                },
                            }}
                        >
                            <NewActivity contact={contact} />
                            {
                                activities.map((activity) => {
                                    const ActivityComponent = Activity[activity.activity_type]
                                    return (
                                        <ActivityComponent key={activity.id} activity={activity} />
                                    )
                                })
                            }
                        </Timeline>
                    </Grid>
                    <Grid item xs={2} />
                </Grid>
            </Stack >
        </>
    )
}

export default Overview;