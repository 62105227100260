import { t } from 'i18next';

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}


function formatActivityDate(dateString) {
  // Input date string "2023-07-11T23:22:47.680710Z";

  // Convert input string to Date object
  const date = new Date(dateString);

  // Month names array
  const monthNames = [
    t("Jan"), t("Feb"), t("Mar"), t("Apr"), t("May"), t("Jun"), t("Jul"), t("Aug"), t("Sep"), t("Oct"), t("Nov"), t("Dec")
  ];

  // Get individual date components
  const year = date.getFullYear();
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  let hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hours %= 12;
  hours = hours || 12;

  // Format the final date string
  const formattedDate = `${month} ${day}, ${year} ${t('at')} ${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;

  return formattedDate;
}

function formatNumber(number) {
  parseFloat(number).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

function urlPath(url) {
  let domain = (new URL(url))
  return domain.pathname.replace('/api', '') + domain.search;
}

export { formatActivityDate, formatDate, formatNumber, urlPath };