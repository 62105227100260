import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import InfoModal from "components/common/InfoModal"
import useApi from "hooks/useApi"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"

function CreditCardForm({ onClose, onCardUpdated, subscription, alert, checkBillingStatus, currency, amount }) {
    const { enqueueSnackbar } = useSnackbar()
    const { t, i18n } = useTranslation()
    const api = useApi()
    const [iframeKey, setIframeKey] = useState(Date.now());
    const [loading, setLoading] = useState(true)
    const [responseAlert, setResponseAlert] = useState(alert)
    const [consentDialog, setConsentDialog] = useState(true)
    const [loadingText, setLoadingText] = useState(null)

    const hundlePcivaultError = (error) => {
        setResponseAlert({
            severity: 'error',
            message: t('Something went wrong.'),
        })
        api.post('/subscription/pcivault/error', error)
    }

    const submitCreditCard = useMutation(
        (data) => {
            setLoading(true)
            if (subscription) setLoadingText('Updating credit card...')
            else setLoadingText('Processing payment...')
            return api.post('/subscription/pcivault/success', data)
        },
        {
            onSuccess: (response) => {
                if (subscription) {
                    const billingId = response.data.billing_id;
                    const intervalId = setInterval(() => checkBillingStatus(billingId, intervalId), 1000);
                } else {
                    enqueueSnackbar(t('Credit card updated successfully'), { variant: 'success' })
                    onClose()
                }
            },
            onError: (error) => {
                setResponseAlert({
                    severity: 'error',
                    message: t('Something went wrong.'),
                })
                setLoading(false)
            }
        }
    )

    const hundlePcivaultSuccess = (pcivaultData) => {
        const data = {
            credit_card: pcivaultData.submittedData,
            response: pcivaultData.response,
            subscription,
        }
        submitCreditCard.mutate(data)
    }

    useEffect(() => {
        function pcivaultCallback(event) {
            if (event.data?.type === 'pcivault-error') hundlePcivaultError(event.data)
            if (event.data?.type === 'pcivault-success') hundlePcivaultSuccess(event.data)
        }
        window.addEventListener("message", pcivaultCallback, false)
        return () => {
            window.removeEventListener("message", pcivaultCallback, false)
        }
    }, [])

    return (
        <InfoModal
            open
            onClose={onClose}
            title={t("Credit Card")}
            sx={{
                width: '24rem',
            }}
        >
            {loading &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // Center horizontally in flex column mode
                        justifyContent: 'center', // Center vertically
                        height: '19rem',
                        textAlign: 'center',
                    }}
                >
                    <CircularProgress size={100} thickness={2} />
                    <Typography variant="h6" sx={{ mt: 8, textAlign: 'center' }}>
                        {t(loadingText || 'Loading...')}
                    </Typography>
                </Box>
            }
            <Box sx={{ height: '19rem', display: loading ? 'none' : 'block' }}>
                <iframe
                    key={iframeKey}
                    width="100%"
                    height="100%"
                    hidden={loading}
                    sandbox='allow-scripts allow-same-origin'
                    onLoad={() => setLoading(false)}
                    src={`https://api.pcivault.io/v1/capture/iframe/${i18n.language === 'he' ? 'he' : 'en'}-${process.env.REACT_APP_PCI_VAULT_ENDPOINT}?unique_id=${process.env.REACT_APP_PCI_VAULT_UNIQUE_ID}&secret=${process.env.REACT_APP_PCI_VAULT_SECRET}&`}
                    // src="http://localhost:5500/index.html"
                ></iframe>
            </Box>
            {!loading && responseAlert?.message &&
                <div className="flex justify-center">
                    <Alert severity={responseAlert?.severity} sx={{ mt: 1, mx: '0.5rem' }}>
                        {responseAlert.message}
                    </Alert>
                </div>}

            {/* <Dialog
                open={consentDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirm Payment</DialogTitle>
                <DialogContent>
                    You would be billed $117.00 VAT included every month starting today.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConsentDialog(false)} size="small">
                        Cancel
                    </Button>
                    <Button onClick={() => setConsentDialog(false)} sx={{ borderRadius: '0.25rem' }} size="small" variant="contained" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog> */}
        </InfoModal>
    )
}

export default CreditCardForm