import { useState } from 'react';

const useValidation = (validiations = []) => {
    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const handleChange = (e) => {
        setValue(e.target.value);
        setError(false);
        setErrorText('');
    };

    const validate = () => {
        for (let { validation, message } of validiations) {
            if (!validation(value)) {
                setError(true);
                setErrorText(message);
                return false;
            }
        }

        setError(false);
        setErrorText('');
        return true;
    };

    return { value, setValue, handleChange, validate, error, errorText };
};

export default useValidation;
