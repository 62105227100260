import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, urlPath } from '../../../../utils'
import H1 from 'components/common/Typography/H1';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { HiAdjustmentsHorizontal } from 'react-icons/hi2';
import { AiOutlineSearch } from 'react-icons/ai';
import { debounce } from "lodash";
import currencies from 'data/currencies';
import { set } from 'react-ga';
import LoadingModal from 'components/LoadingModal';
import AddIcon from '@mui/icons-material/Add';
import { Button, Fab, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Popover, Select, TextField } from '@mui/material';
import LoadingComponent from 'components/Loading';
import Total from '../../../../components/totals';
import Sort from '../../../../components/sort';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import Filters from '../../../../components/specific/Filters';
import DonationListLeg from '../../../../components/DonationListLeg';
import SelectOption from '../../../../components/SelectOption';
import ProgressModal from '../../../../components/ProgressModal';
import Options from 'components/Options';
import { useSnackbar } from 'notistack';
import useApi from 'hooks/useApi';
import DonationList from './DonationList';
import { uiStore } from 'stores/UiStore';

function DonationsTable() {
    const dir = document.dir;
    const api = useApi();
    const [searchParams, setSearchParams] = useSearchParams();
    const { setFabAction } = uiStore;
    const { enqueueSnackbar } = useSnackbar();
    const [donations, setDonations] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [donationsUploading, setDonationsUploading] = useState(false);
    const [searchSubmit, setSearchSubmit] = useState(false);
    const [donationsUpdated, setDonationsUpdated] = useState(false);
    const pageSizes = [50, 100, 250, 500];
    const [pageSize, setPageSize] = useState(localStorage.getItem('donationsPageSize') || pageSizes[0]);
    const [donationsLength, setDonationsLength] = useState(0);
    // const pagePadding = 2;
    // const visiblePages = 3;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const [totals, setTotals] = useState({});
    const [sort, setSort] = useState([]);

    const [exportingLength, setExportingLength] = useState(0);
    const [exportingProgress, setExportingProgress] = useState(0);

    const [optionsOpen, setOptionsOpen] = useState(false);
    const [lists, setLists] = useState([]);
    const [selectedList, setSelectedList] = useState(null);
    const closeOptions = useRef();
    const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
    const [selectListAnchorEl, setSelectListAnchorEl] = useState(null);
    const [checkedItems, setCheckedItems] = useState([]);
    const [checkAll, setCheckAll] = useState(false);

    const [filtersAnchorEl, setFiltersAnchorEl] = useState(null);

    useEffect(() => {
        if (selectListAnchorEl) {
            api.get('/donations/lists/').then(res => {
                setLists(res.data);
            }).catch(err => console.error('Error fetching lists:', err));
        }
    }, [selectListAnchorEl]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (closeOptions.current && !closeOptions.current.contains(event.target)) {
                setOptionsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [closeOptions]);

    const handleDelete = async () => {
        try {
            const response = await api.post('/donations/delete/', { donation_ids: checkedItems });
            if (response.status === 200) {
                enqueueSnackbar(t('Donations Deleted Successfully'), { variant: 'success' });
            }
            setCheckAll(false);
            setCheckedItems([]);
            setOptionsAnchorEl(null);
            if (donations.length === checkedItems.length) {
                if (currentPage === totalPages && currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                }
            }
            setDonationsUpdated(!donationsUpdated);
        } catch (error) {
            enqueueSnackbar(t('Error deleting donations'), { variant: 'error' });
        }
    };

    const options = [
        {
            text: `${t('Delete')} ${checkedItems.length > 0 ? checkedItems.length : ''} ${t('Donations')}`,
            icon: <DeleteOutlineRoundedIcon />,
            onClick: () => handleDelete(),
            disabled: checkedItems.length === 0,
        },
        {
            text: t('Add to Group'),
            icon: <PlaylistAddRoundedIcon />,
            onClick: (e) => setSelectListAnchorEl(e.currentTarget),
            disabled: checkedItems.length === 0,
        },
    ];

    const handleAddToList = (DonationList) => {
        api.post('/donations/lists/add-donations/', { donation_ids: checkedItems, donation_list_id: DonationList }).then(res => {
            enqueueSnackbar(t('Donations Added to Group Successfully'), { variant: 'success' });
            setCheckAll(false);
            setCheckedItems([]);
            setOptionsAnchorEl(null);
            setSelectListAnchorEl(null);
        }).catch(err => console.error('Error adding donations to list:', err));
    };

    const handleSearchChange = (search) => {
        setSearchValue(search);
        // setSearchActive(true);
    };

    useEffect(() => {
        if (searchValue) {
            const searchDonations = debounce(() => {
                fetchDonations(null, '10').then(res => {
                    setSearchSuggestions(res.data.results);
                }).catch(err => console.error('Error fetching search results:', err));
            }, 100);

            if (searchActive) {
                searchDonations();
            }

            setSearchActive(false);
            return () => searchDonations.cancel();
        } else {
            setSearchSuggestions([]);
        }
    }, [searchValue]);

    useEffect(() => {
        setFabAction(handleNewDonation);
        return () => setFabAction(null);
    }, []);

    const exportDonations = () => {
        const csvHeader = 'Donation ID,Donor,Date,Amount,Currency,Payments,Donation Method,payment Method,Fundraiser,Phone Number,Email,Donor Notes,Donation Notes,Donor ID, Fundraiser ID\n'
        let csv = csvHeader
        const encodeToCSV = (data) => {
            data.map(row => {
                let rowArray = [
                    row?.donation_id,
                    row?.contact && row?.contact?.first_name + ' ' + row?.contact?.last_name,
                    row?.donation_date,
                    row?.amount,
                    row?.currency,
                    row?.payments === 0 ? t('No Time Limit') : row.payments,
                    row?.donation_type === 1 ? t('Regular') : t('Recurring'),
                    row?.payment_method?.payment_method_type,
                    row.fundraiser && row?.fundraiser?.first_name + ' ' + row?.fundraiser?.last_name,
                    row?.contact?.phone_numbers?.[0],
                    row?.contact?.email_addresses?.[0],
                    row?.donor_note,
                    row?.donation_notes,
                    row?.contact?.status === 1 ? row?.contact?.contact_id : '',
                    row?.fundraiser?.contact_id,
                ];
                return csv += rowArray.map(item => item && `"${item}"`).join(',') + '\n';
            })
        }

        const saveCSV = () => {
            let BOM = "\uFEFF";
            const blob = new Blob([BOM + csv], { type: 'text/csv;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'donations.csv');
            document.body.appendChild(link);
            link.click();

            setExportingProgress(0)
            setExportingLength(0)
        }

        let params = {
            page: 1,
            page_size: 250,
            search: searchValue,
            sort: JSON.stringify(sort),
        }

        Array.from(searchParams.keys()).forEach((key) => {
            params[key] = JSON.stringify(searchParams.get(key).split(','));
        });

        const fetchExport = api.get('/donation-list/donations/', {
            params,
        });
        fetchExport.then(res => {
            setExportingLength(Math.floor(res.data.count / 250))
            setExportingProgress(prev => prev + 1)
            encodeToCSV(res.data.results)
            if (!res.data.next) return saveCSV()
            const fetchRecursion = (next) => api.get(urlPath(next)).then(rres => {
                setExportingProgress(prev => prev + 1)
                encodeToCSV(rres.data.results)
                if (!rres.data.next) return saveCSV()
                fetchRecursion(rres.data.next)
            })
            fetchRecursion(res.data.next)
        })
    }

    const fetchDonations = async (all, limit) => {
        try {
            let params = {
                page: currentPage,
                page_size: limit ? limit : pageSize,
                search: searchValue,
                sort: JSON.stringify(sort),
                all,
            };
            Array.from(searchParams.keys()).forEach((key) => {
                params[key] = JSON.stringify(searchParams.get(key).split(','));
            });
            const response = await api.get('/donation-list/donations/', {
                params: params,
            });
            if (all) {
                const csvData = response.data
                const csvHeader = 'Donation ID,Donor,Date,Amount,Currency,Payments,payment Method,Fundraiser,Phone Number,Email,Donor Notes,Donation Notes,Donor ID, Fundraiser ID\n'
                let csv = csvData.map(row => {
                    let rowArray = [
                        row?.donation_id,
                        row?.contact && row?.contact?.first_name + ' ' + row?.contact?.last_name,
                        row?.donation_date,
                        row?.amount,
                        row?.currency,
                        row?.payments,
                        row?.payment_method?.payment_method_type,
                        row.fundraiser && row?.fundraiser?.first_name + ' ' + row?.fundraiser?.last_name,
                        row?.contact?.phone_numbers?.length > 0 ? row?.contact?.phone_numbers[row?.contact?.phone_numbers?.length - 1]?.phone_number : '',
                        row?.contact?.email_addresses?.length > 0 ? row?.contact?.email_addresses[row?.contact?.email_addresses?.length - 1]?.email : '',
                        row?.donor_note,
                        row?.donation_notes,
                        row?.contact?.contact_id,
                        row?.fundraiser?.contact_id,
                    ];
                    return rowArray.map(item => item && item).join(',');
                }).join('\n');

                csv = csvHeader + csv;

                let BOM = "\uFEFF";
                const blob = new Blob([BOM + csv], { type: 'text/csv;' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'donations.csv');
                document.body.appendChild(link);
                link.click();
            }
            return response;
        } catch (error) {
            console.error(`Error fetching donations: ${error.message}`);
        }
    };

    const handleExport = () => {
        exportDonations();
    };

    const handleSearchBlur = () => {
        setTimeout(() => {
            setSearchSuggestions([]);
        }, 200);
    };

    const handleSearchSelect = (result) => {
        const selectSearchValue = result?.contact?.first_name ? result?.contact?.first_name + ' ' + result.contact?.last_name : result?.contact?.last_name
        setSearchValue(selectSearchValue);
        setSearchSubmit(!searchSubmit)
        setSearchSuggestions([]);
    };

    const handleNewDonation = () => {
        navigate('/donations/new');
    };

    const handlePageSizeChange = (e) => {
        setPageSize(e.target.value);
        localStorage.setItem('donationsPageSize', e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchDonations().then(res => {
            setDonations(res.data.results);
            setTotalPages(Math.ceil(res.data.count / pageSize));
            setDonationsLength(res.data.count);
            setLoading(false);
            setCheckAll(false);
            setCheckedItems([]);
            const resTotals = {
                total_amount: res.data.total_amount,
                count: res.data.count,
                total_count_donors: res.data.total_count_donors,
                org_currency: res.data.org_currency,
            };
            setTotals(resTotals);

        }).catch(err => console.error('Error fetching donations:', err));
    }, [donationsUpdated, searchSubmit, currentPage, pageSize, sort, searchParams]);

    useEffect(() => {
        setCurrentPage(1);
    }, [searchParams]);

    const handleUpload = async (e) => {
        e.preventDefault();
        setUploading(true);
        const formData = new FormData();
        const file = document.getElementById('file').files[0];
        formData.append('csv_file', file);
        try {
            const response = await api.post('/donations/upload_csv/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 202) {
                const task_id = response.data.task_id;
                let count = 0;
                while (count < 300) {
                    const taskResponse = await api.get(`/task-status/${task_id}/`);
                    if (taskResponse.data.state === 'SUCCESS') {
                        if (taskResponse.data.status === 'SUCCESS') {
                            setUploadModalOpen(false);
                            setDonationsUpdated(!donationsUpdated);
                            setUploading(false);
                            enqueueSnackbar(t('Donations Uploaded Successfully'), { variant: 'success' });
                        } else {
                            alert(t('Some Donations Were Not Uploaded: See Downloaded File'));
                            let BOM = '\uFEFF';
                            const url = window.URL.createObjectURL(new Blob([BOM + taskResponse.data.result]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'donation_errors.csv');
                            document.body.appendChild(link);
                            link.click();
                            setUploadModalOpen(false);
                            setDonationsUpdated(!donationsUpdated);
                            setUploading(false);
                        }
                        break;
                    } else if (taskResponse.data.state === 'FAILURE') {
                        enqueueSnackbar(t('Error Uploading Donations'), { variant: 'error' });
                        break;
                    } else if (taskResponse.data.state === 'PENDING') {
                        count += 1;
                        await new Promise((resolve) => setTimeout(resolve, 2000));
                        continue;
                    } else {
                        setUploading(false);
                        enqueueSnackbar(t('Error Uploading Donations'), { variant: 'error' });
                        break;
                    }
                }
            } else {
                setUploading(false);
                enqueueSnackbar(t('Error Uploading Donations'), { variant: 'error' });
            }
        } catch (error) {
            setUploading(false);
            enqueueSnackbar(t('Error Uploading Donations'), { variant: 'error' });
        }
    };

    const renderUploadingModal = () => (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                {/* <!--Background overlay, show/hide based on modal state.--> */}
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                <div className="inline-block align-bottom bg-white rounded-lg text-start overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="mb-4">
                            <p className="text-gray-700 text-sm font-bold mb-2">{t('Uploading Donations')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderUploadModal = () => (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                {/* <!--Background overlay, show/hide based on modal state.--> */}
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                <div className="inline-block align-bottom bg-white rounded-lg text-start overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <form onSubmit={handleUpload}>
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="file">
                                    {t('Upload Donations File')}
                                </label>
                                <input
                                    type="file"
                                    name="file"
                                    id="file"
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </div>
                            <a
                                href="/static/donations-template.csv"
                                download
                                className="text-blue-900 underline"
                            >
                                {t('Download Donations Template')}
                            </a>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                                type="submit"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ms-3 sm:w-auto sm:text-sm"
                            >
                                {t('Upload')}
                            </button>
                            <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ms-3 sm:w-auto sm:text-sm"
                                onClick={() => setUploadModalOpen(false)}
                            >
                                {t('Cancel')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );


    const renderSearchBar = () => (
        <div className="flex flex-wrap gap-7 bg-white p-7 mb-9 rounded-2xl items-center">
            <div className='flex gap-7'>
                <div className="w-1/2 min-w-[24rem] flex items-center relative">
                    <TextField
                        fullWidth
                        variant='standard'
                        type='search'
                        placeholder={t('Search donations') + '...'}
                        onChange={(e) => handleSearchChange(e.target.value)}
                        onBlur={handleSearchBlur}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setCurrentPage(1)
                                setSearchSubmit(!searchSubmit)
                                setSearchSuggestions([])
                            }
                        }}
                        value={searchValue}
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start">
                                    <button onClick={() => {
                                        setCurrentPage(1)
                                        setSearchSubmit(!searchSubmit)
                                        setSearchSuggestions([])
                                    }}>
                                        <AiOutlineSearch />
                                    </button>
                                </InputAdornment>,
                        }}
                    />
                    {/* {searchSuggestions.length > 0 && (
                        <ul className="absolute text-start top-12 -ms-2 self-start bg-white shadow-lg border border-gray-200 rounded py-1 z-10 w-full max-h-60 overflow-y-auto">
                            {searchSuggestions.map((result, index) => (
                                <li
                                    key={index}
                                    className="py-1 hover:bg-gray-200 cursor-pointer"
                                    onClick={() => handleSearchSelect(result)}
                                >
                                    <div className="px-3 font-medium">
                                        {result?.contact?.first_name ? result?.contact?.first_name + ' ' + result?.contact?.last_name : result?.contact?.last_name}
                                        <span className="font-normal">
                                            {result?.contact?.connections[0]?.relationship && ` • ${result?.contact?.connections[0]?.relationship}`}
                                            {result?.contact?.connections[0]?.related_to?.full_name && ` ${result?.contact?.connections[0]?.related_to?.full_name}`}
                                            {
                                                (result?.contact.street || result?.contact.house_number || result?.contact.city)
                                                && " • "
                                            }
                                            {
                                                `${result?.contact.street ? result?.contact.street + ' ' : ''}` +
                                                `${result?.contact.house_number ? result?.contact.house_number + (result?.contact.street ? ',' : '') : ''}` +
                                                `${result?.contact.city ? result?.contact.city : ''}`
                                            }
                                        </span>
                                    </div>
                                    {searchSuggestions.length !== index + 1 && <hr />}
                                </li>
                            ))}
                        </ul>
                    )} */}
                </div>
                <div className="flex items-center">
                    <select
                        className=""
                        value={pageSize}
                        onChange={handlePageSizeChange}
                    >
                        {pageSizes.map((size) => (
                            <option key={size} value={size}>
                                {`${size} ${t('Donations Per Page')}`}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex items-center">
                    <button
                        className="ms-2 text-xl text-custom-blue"
                        onClick={(e) => setFiltersAnchorEl(e.currentTarget)}
                    >
                        <HiAdjustmentsHorizontal />
                    </button>
                </div>
            </div>
            <Filters
                filtersAnchorEl={filtersAnchorEl}
                setFiltersAnchorEl={setFiltersAnchorEl}
                filterSet='donations'
            />
        </div>
    );

    const renderTotals = () => {
        const [whole, fraction] = totals.total_amount.toString().split('.');
        return (
            <div className="flex bg-white p-7 mb-9 rounded-2xl gap-7 items-center">
                <Total
                    prefix={currencies[totals.org_currency].symbol_native}
                    amount={parseInt(whole).toLocaleString()}
                    description={t('Sum of Donations')}
                />
                <Total
                    amount={parseInt(totals.count).toLocaleString()}
                    description={t('Donations')}
                />
                <Total
                    amount={parseInt(totals.total_count_donors).toLocaleString()}
                    description={t('Donors')}
                />
            </div>
        );
    }

    const renderTable = () => {
        const getSort = (field) => {
            const sortItem = sort.find((item) => item[0] === field);
            if (sortItem) {
                return sortItem[1];
            } else {
                return '';
            }
        };

        const onSort = (field, sort) => {
            console.log(field, sort);
            setSort((prevSort) => {
                const newSort = prevSort.filter((item) => item[0] !== field);
                if (sort !== '') {
                    return [...newSort, [field, sort]];
                } else {
                    return newSort;
                }
            });
        };

        return (
            <>
                <DonationList
                    donations={donations}
                    checkedDonations={checkedItems}
                    setCheckedDonations={setCheckedItems}
                    checkAll={checkAll}
                    setCheckAll={setCheckAll}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    getSort={getSort}
                    setSort={onSort}
                    totalPages={totalPages}
                    addDonation={
                        <div className="flex justify-end">
                            <div className='fixed bottom-10 text-end mx-10'>
                                <Fab
                                    color='primary'
                                    onClick={handleNewDonation}
                                >
                                    <AddIcon />
                                </Fab>
                            </div>
                        </div>}
                />
            </>
        )
    };

    return (
        <>
            <LoadingModal open={uploading} />
            <div className="flex float-end items-center">
                <button
                    className="border-2 border-blue-900 text-blue-900 rounded-full px-4 py-1 mx-2 hover:bg-violet-200"
                    onClick={() => setUploadModalOpen(true)}
                >
                    {t('Upload Donations')}
                </button>
                <button
                    className="border-2 border-blue-900 text-blue-900 rounded-full px-4 py-1 mx-2 hover:bg-violet-200"
                    onClick={handleExport}
                >
                    {t('Export')}
                </button>
                <Options options={options} />
                <SelectOption
                    title={t('Select Group')}
                    text={t('Select a group to add the selected donations to')}
                    optionAnchorEl={selectListAnchorEl}
                    setOptionAnchorEl={setSelectListAnchorEl}
                    selectedOption={selectedList}
                    setSelectedOption={setSelectedList}
                    options={lists}
                    handleAddToOption={handleAddToList}
                    placeholder={t('Select group')}
                />
            </div>
            <H1>{t('Donations')}</H1>
            {renderSearchBar()}
            {Loading ? <LoadingComponent className='h-96' /> :
                donations.length === 0 ?
                    <div className='flex-1 justify-center'>
                        <p className="text-gray-500 mb-32">{t('No donations found')}</p>
                        <button
                            onClick={handleNewDonation}
                            className="bg-blue-900 text-white py-3 px-6 rounded-full text-xl shadow-lg hover:bg-blue-600 active:bg-blue-700 focus:outline-none"
                        >
                            {t('Add your first donation')}
                        </button>
                    </div> :
                    <>
                        {renderTotals()}
                        {renderTable()}
                    </>
            }
            {uploadModalOpen && renderUploadModal()}
            {donationsUploading && renderUploadingModal()}
            <ProgressModal
                open={exportingLength > 0}
                title={t('Exporting Donations')}
                text={t('Exporting donations to CSV')}
                length={exportingLength}
                progress={exportingProgress}
            />
        </>
    );
}

export default DonationsTable;