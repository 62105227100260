import { mainStore } from "stores/MainStore";
import List from "./List"
import NoCampaignSubscription from "../NoCampaignSubscription";
import { Link as RouterLink, useParams } from "react-router-dom";
import useCampaign from "hooks/useCampaign";
import { useQuery } from "react-query";
import useApi from "hooks/useApi";
import { uiStore } from "stores/UiStore";
import { useEffect, useMemo } from "react";
import { SystemUpdateAltRounded, VolunteerActivismRounded } from "@mui/icons-material";
import { optionsClickOutside } from "utils/controls";
import { useTranslation } from "react-i18next";
import LoadingSkeleton from "../LoadingSkeleton";
import { Link, Stack } from "@mui/material";
import DonationsSumCard from "../DonationsSumCard";
import DonorsCountCard from "../DonorsCountCard";
import ProgressCard from "../ProgressCard";
import NoResultsFound from "components/general/NoResultsFound";

const onExport = (data) => {
    const csvHeader = 'שם מתרים, מספר מתרים, יעד';
    let csv = data.map(row => {
        let rowArray = [
            `${row.name} (${row.fundraiser_id})`,
            row.fundraiser_id,
            row.goal || 0
        ];
        return rowArray.map(item => item && item.toString().replace(/,/g, ".")).join(',').replace(/[\n\r]/g, " ");
    }).join('\n');

    csv = csvHeader + '\n' + csv

    let BOM = '\uFEFF';
    const blob = new Blob([BOM + csv], { type: 'text/csv;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'fundraisers.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
    optionsClickOutside();
};

function FundraiserList() {
    const { t } = useTranslation();
    const { subscription } = mainStore;
    const { campaignName, subCampaignName, groupName } = useParams();
    const { setOptions, setTitle } = uiStore;
    const api = useApi();

    const fundraiserParams = useMemo(() => {
        const params = {}
        if (subCampaignName) params.sub_campaign__name = subCampaignName
        else params.sub_campaign__isnull = true
        if (groupName) params.group__name = groupName
        else params.group__isnull = true
        return params
    }, [campaignName, subCampaignName, groupName]);


    const fundraiserQuery = useQuery(['fundraisers'],
        () => api.get(`/campaigns/${campaignName}/fundraisers`, { params: fundraiserParams }).then(res => res.data),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
            enabled: !!campaignName,
            keepPreviousData: true,
        },
    );

    const { data: fundraiserData, isLoading: fundraiserLoading } = fundraiserQuery;

    useEffect(() => {
        if (subscription.addons?.nedarim_plus) {
            setOptions([
                {
                    key: 'export-np-fundraisers',
                    text: t('Export Np Fundraisers'),
                    icon: <SystemUpdateAltRounded />,
                    onClick: () => {
                        onExport(fundraiserData.results);
                    }
                }
            ])
        }
        return () => {
            setOptions([]);
        };
    }, [fundraiserData]);

    if (!subscription.campaigns) return <div className="relative top-20"><NoCampaignSubscription /></div>;

    if (fundraiserLoading) return <LoadingSkeleton />

    const { parent, results } = fundraiserData

    if (results.length === 0) return (
        <div className="relative top-20">
            <NoResultsFound
                title={t('No Fundraisers Yet!')}
                subtitle={<> {t('Go to')} <strong><Link underline="hover" component={RouterLink} to="/contacts">{t('Contacts')}</Link>, {t('Select >> Options >> Add as Fundraiser')}</strong></>}
                Icon={VolunteerActivismRounded}
            />
        </div>
    );

    return (
        <>
            <Stack direction="row" spacing={2} sx={{ my: '1rem', justifyContent: 'space-between' }}>
                <DonationsSumCard value={parent.donations_sum} currency='ILS' />
                <DonorsCountCard value={parent.donors_count} />
                <ProgressCard value={parent.goal ? parent.donations_sum / parent.goal : '--'} />
            </Stack>
            <List name='Fundraiser' listData={results} type='fundraisers' />
        </>
    )
}

export default FundraiserList