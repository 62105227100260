function Thead({ children }) {
    return (
        <thead>
            <tr className="rounded-lg text-sm font-medium text-gray-700">
                {children}
            </tr>
        </thead>
    )
}

export default Thead;