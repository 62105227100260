import { AddRounded, AlarmOnRounded, DoneAllRounded, EventBusyRounded, HourglassDisabledRounded, HourglassTopRounded, PublishedWithChangesRounded } from "@mui/icons-material";
import { Avatar, Box, Card, Checkbox, Chip, Fab, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup, Tooltip, styled, toggleButtonGroupClasses } from "@mui/material";
import Pagination from "components/common/Pagination";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { localDateTime, natrualDateTime } from "utils/dates";
import { pledgeStatuses, transactionTypes } from "data/general";
import currencies from "data/currencies";
import useApi from "hooks/useApi";
import { useQuery } from "react-query";
import TabTableLoading from "components/general/TabTableLoading";
import NoResultsFound from "components/general/NoResultsFound";
import PledgeDetails from "./PledgeDetails";

const groupedNames = {
    overdue: 'Overdue',
    nextHour: 'Next Hour',
    today: 'Today',
    tomorrow: 'Tomorrow',
    thisWeek: 'This Week',
    nextWeek: 'Next Week',
    thisMonth: 'This Month',
    later: 'Later',
    noDate: 'No Date',
    completed: 'Completed',
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: '0.5rem',
        textTransform: 'none',
        fontSize: theme.typography.subtitle2.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        '&.Mui-selected': {
            backgroundColor: '#e4e7f1',
        },
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
        marginLeft: -1,
        borderLeft: '1px solid transparent',
    },
}));

const StyledChip = styled(Chip)(({ theme, active, color }) => ({
    'borderRadius': '6px',
    'marginLeft': theme.spacing(1),
    color: 'white',
    backgroundColor: color === 'default' ? theme.palette.grey[800] : theme.palette[color]?.main,
    ...(!active && {
        color: theme.palette[color]?.main,
        backgroundColor: color === 'default' ? theme.palette.grey[800] + '22' : theme.palette[color]?.main + '22',
    }),
}));


function StatusTabs({ statuses, activeStatus, setActiveStatus }) {

    return (
        <>
            <Paper
                elevation={0}
                sx={{
                    display: 'flex',
                    borderRadius: '0.5rem',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                }}
            >
                <StyledToggleButtonGroup
                    value={activeStatus}
                    exclusive
                    color="primary"
                    size="small"
                    onChange={(e, value) => value !== null && setActiveStatus(value)}
                >
                    {statuses.map(status => (
                        <ToggleButton key={status.value} value={status.value} list>
                            {status.label}
                            <StyledChip
                                active={activeStatus === status.value}
                                color={status.color}
                                size="small"
                                label={status.count}
                                sx={{ borderRadius: '6px' }}
                            />
                        </ToggleButton>
                    ))}
                </StyledToggleButtonGroup>
            </Paper>
        </>
    )
}

function PledgeList({ filters, checkedPledges, setCheckedPledges, checkboxes, directPledgesButton }) {
    const { t, i18n } = useTranslation();
    const api = useApi();
    const [currentPage, setCurrentPage] = useState(1);
    const [openedPledge, setOpenedPledge] = useState(null);
    const [activeStatus, setActiveStatus] = useState('PENDING');

    const params = {
        ...filters,
        page: currentPage,
        pledge_status: activeStatus,
    }

    const { data: pledgesData, isLoading: pledgesLoading, isRefetching: pledgesRefetching, refetch } = useQuery(['pledges', params],
        () => api.get('/donations/pledge-list', { params }).then(res => res.data),
        {
            refetchOnWindowFocus: true,
        }
    );

    const pledges = pledgesData?.results || [];
    const totalPages = Math.ceil(pledgesData?.count / 50);

    const statuses = [
        {
            value: 'PENDING',
            label: t('Pending'),
            color: 'info',
            count: pledgesData?.status_counts?.PENDING || 0,
            noResultsTitle: t('No Pending Pledges'),
            noResultsSubtitle: t('Add A New Pledge By Clicking The + Button Below'),
            icon: HourglassTopRounded,
        },
        {
            value: 'OVERDUE',
            label: t('Overdue'),
            color: 'error',
            count: pledgesData?.status_counts?.OVERDUE || 0,
            noResultsTitle: t('You are all caught up!'),
            noResultsSubtitle: t('No overdue pledges'),
            icon: DoneAllRounded,
            props: { icon: { color: 'success' } }
        },
        {
            value: 'NEXT_HOUR',
            label: t('Next Hour'),
            color: 'warning',
            count: pledgesData?.status_counts?.NEXT_HOUR || 0,
            noResultsTitle: t('No Pledges Due Next Hour'),
            noResultsSubtitle: t('You are all caught up!'),
            icon: AlarmOnRounded,
            props: { icon: { color: 'success' } }
        },
        {
            value: 'FULFILLED',
            label: t('Fulfilled'),
            color: 'success',
            count: pledgesData?.status_counts?.FULFILLED || 0,
            noResultsTitle: t('No Fulfilled Pledges Yet'),
            icon: HourglassDisabledRounded,
        },
        {
            value: 'TRANSFORMED_TO_DONATION',
            label: t('Transformed to Donation'),
            color: 'primary',
            count: pledgesData?.status_counts?.TRANSFORMED_TO_DONATION || 0,
            noResultsTitle: t('No Pledges Transformed to Donation'),
            icon: PublishedWithChangesRounded,
        },
        {
            value: 'CANCELED',
            label: t('Canceled'),
            color: 'default',
            count: pledgesData?.status_counts?.CANCELED || 0,
            noResultsTitle: t('No Canceled Pledges'),
            icon: EventBusyRounded,
        },
    ];

    const handleCheckAll = () => {
        const items = pledges.map(pledge => pledge.donation_id);
        if (checkedPledges.length === items.length) setCheckedPledges([]);
        else setCheckedPledges(items)
    };

    const handleCheck = (pledge) => {
        const itemExists = checkedPledges.includes(pledge);
        if (itemExists) setCheckedPledges(prev => prev.filter(item => item !== pledge));
        else setCheckedPledges(prev => [...prev, pledge]);
    };

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                gap: '1rem',
            }}>
                <StatusTabs
                    statuses={statuses}
                    activeStatus={activeStatus}
                    setActiveStatus={setActiveStatus}
                />
                {directPledgesButton}
            </Box>
            {pledgesLoading ? <TabTableLoading /> : (
                <>
                    {pledges.length === 0 ? (
                        <Box mt='4rem'>
                            <NoResultsFound
                                title={t(statuses.find(status => status.value === activeStatus)?.noResultsTitle)}
                                subtitle={t(statuses.find(status => status.value === activeStatus)?.noResultsSubtitle)}
                                Icon={statuses.find(status => status.value === activeStatus)?.icon}
                                {...statuses.find(status => status.value === activeStatus)?.props}
                            />
                        </Box>
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {checkboxes &&
                                            <TableCell>
                                                <Checkbox
                                                    indeterminate={checkedPledges.length > 0 && checkedPledges.length < pledges.length}
                                                    checked={checkedPledges.length === pledges.length}
                                                    onChange={handleCheckAll}
                                                />
                                            </TableCell>}
                                        <TableCell>
                                            {t('Contact')}
                                        </TableCell>
                                        <TableCell>
                                            {t('Due Date')}
                                        </TableCell>
                                        <TableCell>
                                            {t('Donation Type')}
                                        </TableCell>
                                        <TableCell>
                                            {t('Amount')}
                                        </TableCell>
                                        <TableCell>
                                            {t('Payments')}
                                        </TableCell>
                                        <TableCell>
                                            {t('User')}
                                        </TableCell>
                                        <TableCell>
                                            {t('Description')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <Card
                                    component={TableBody}
                                    sx={{ backgroundColor: 'unset' }}
                                >
                                    {pledges.map((pledge, index) => (
                                        <TableRow
                                            key={pledge.id}
                                            onClick={() => setOpenedPledge(pledge)}
                                            hover
                                            sx={{
                                                backgroundColor: 'white',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {checkboxes &&
                                                <TableCell
                                                    className={`${pledges.length - 1 === index && 'first:rounded-bs-2xl last:rounded-be-2xl'} ${index === 0 && 'first:rounded-ts-2xl last:rounded-te-2xl'}`}
                                                >
                                                    <Checkbox
                                                        role="checkbox"
                                                        checked={checkedPledges.includes(pledge.donation_id)}
                                                        onClick={(e) => e.stopPropagation()}
                                                        onChange={() => handleCheck(pledge.donation_id)}
                                                    />
                                                </TableCell>}
                                            <TableCell
                                                className={`${pledges.length - 1 === index && 'first:rounded-bs-2xl last:rounded-be-2xl'} ${index === 0 && 'first:rounded-ts-2xl last:rounded-te-2xl'}`}
                                            >
                                                <Box height='2.5rem' display='flex' alignItems='center'>
                                                    <Link
                                                        component={RouterLink}
                                                        fontWeight={600}
                                                        to={`/contacts/${pledge?.contact?.contact_id}`}
                                                        underline="hover"
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        {pledge?.contact?.first_name} {pledge?.contact?.last_name}
                                                    </Link>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                {natrualDateTime(new Date(pledge.donation_date), i18n.language)}
                                                {/* {pledge.donation_date && localDateTime(pledge.donation_date)} */}
                                            </TableCell>
                                            <TableCell>
                                                {t(transactionTypes.find(type => type.value == pledge.donation_type)?.label)}
                                            </TableCell>
                                            <TableCell>
                                                {pledge.amount.toLocaleString(i18n.language, { style: 'currency', currency: pledge.currency })}
                                            </TableCell>
                                            <TableCell>
                                                {pledge.payments === 0 ? t('No Time Limit') : (pledge.payments)}
                                            </TableCell>
                                            <TableCell>
                                                {pledge.user_name &&
                                                    <Tooltip title={pledge.user_name} placement="top">
                                                        <Avatar
                                                            src={pledge.user_picture_url}
                                                        />
                                                    </Tooltip>}
                                            </TableCell>
                                            <TableCell
                                                className={`${pledges.length - 1 === index && 'first:rounded-bs-2xl last:rounded-be-2xl'} ${index === 0 && 'first:rounded-ts-2xl last:rounded-te-2xl'}`}
                                            >
                                                {pledge.donation_notes}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </Card>
                            </Table>
                        </TableContainer>
                    )}
                    {openedPledge &&
                        <PledgeDetails
                            pledgeData={openedPledge}
                            onClose={() => setOpenedPledge(null)}
                            onUpdate={refetch}
                        />}
                    {totalPages ? <Pagination sx={{ mt: '2rem' }} totalPages={totalPages} currentPage={currentPage} onChange={setCurrentPage} /> : null}
                </>
            )}
        </Box>
    )
}

export default PledgeList;
