import { Box, Button, Chip, Grid, Link, Paper, Skeleton, Switch, Tab, Tabs, ToggleButton, ToggleButtonGroup, Typography, styled, toggleButtonGroupClasses } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import NoResultsFound from "components/general/NoResultsFound";
import useApi from "hooks/useApi";
import { useContacts } from "hooks/useContacts";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { contactsStore } from "stores/ContactsStore";
import { Diversity1Rounded, GroupRemoveRounded, IosShareRounded, SystemUpdateAltRounded } from "@mui/icons-material";
import ContactList from "views/Contacts/Contacts/ContactList";
import TabTableLoading from "components/general/TabTableLoading";
import { uiStore } from "stores/UiStore";
import { observer } from "mobx-react-lite";
import DirectItemsFilter from "../DirectItemsFilter";
import { mainStore } from "stores/MainStore";
import NoCampaignSubscription from "../NoCampaignSubscription";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Options from "components/Options";
import { useSnackbar } from "notistack";
import { optionsClickOutside } from "utils/controls";
import LoadingModal from "components/LoadingModal";
import { onExport } from "views/Contacts/Contacts/ContactList/contacts";
import InnerSearch from "components/general/InnerSearch";

// set the colors and labels for all, in progress, committed, donated, did not donate
const statusColors = {
    all: 'default',
    pending: 'info',
    in_progress: 'warning',
    committed: 'primary',
    donated: 'success',
    did_not_donate: 'error',
}

const donorStatusMapping = {
    PENDING: '0',
    IN_PROGRESS: '1',
    COMMITTED: '2',
    DONATED: '3',
    DID_NOT_DONATE: '4',
}

const statusDonorMapping = {
    0: 'Pending',
    1: 'In Progress',
    2: 'Committed',
    3: 'Donated',
    4: 'Did Not Donate',
}

const statusToHebrewExport = {
    all: 'כל התורמים',
    0: 'תורמים בהמתנה',
    1: 'תורמים בתהליך',
    2: 'תורמים עם התחייבות',
    3: 'תורמים שתרמו',
    4: 'תורמים שלא תרמו',
}

const priorities = {
    LOW: { label: 'Low', color: 'default' },
    MEDIUM: { label: 'Medium', color: 'warning' },
    HIGH: { label: 'High', color: 'error' },
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: '0.5rem',
        textTransform: 'none',
        fontSize: theme.typography.subtitle2.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        '&.Mui-selected': {
            backgroundColor: '#e4e7f1',
        },
        // [`&.${toggleButtonGroupClasses.disabled}`]: {
        //     border: 0,
        // },
        // '&.Mui-selected, &.Mui-selected:hover': {
        //     backgroundColor: theme.palette.primary.main,
        //     color: theme.palette.primary.contrastText,
        // },
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
        marginLeft: -1,
        borderLeft: '1px solid transparent',
    },
}));

const StyledChip = styled(Chip)(({ theme, active, color }) => ({
    'borderRadius': '6px',
    'marginLeft': theme.spacing(1),
    color: 'white',
    backgroundColor: color === 'default' ? theme.palette.grey[800] : theme.palette[color]?.main,
    ...(!active && {
        color: theme.palette[color]?.main,
        backgroundColor: color === 'default' ? theme.palette.grey[800] + '22' : theme.palette[color]?.main + '22',
    }),
    // '&.MuiChip-colorPrimary': {
    //     backgroundColor: theme.palette.error.main + '22',
    // },
}));

function Donors({ view, checkedDonors, setCheckedDonors }) {
    const { subscription } = mainStore;
    const { campaignName, subCampaignName, groupName, fundraiserId } = useParams();
    const { t, i18n } = useTranslation();
    const api = useApi();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { setFabAction } = uiStore;
    const [donorParams, setDonorParams] = useSearchParams('')
    const { currentPage, setCurrentPage, pageSize, getSort, setSort, setParams, getParams, reset, referrer, setReferrer, listType, setListType, setLocation, search, setSearch } = contactsStore;
    const [isInitialised, setIsInitialised] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const [directDataOnly, setDirectDataOnly] = useState(true);
    const [status, setStatus] = useState('all');

    const location = useLocation();

    const { contactsQuery, allContactsQuery, fetchAllContacts } = useContacts({ onFetchAllSuccess: onExport, enabled: isInitialised });
    const { data: donorsData, isLoading: donorsLoading, error: donorsError, isFetching: donorsFetching } = contactsQuery;
    const donors = donorsData?.results || [];

    const pageCount = Math.ceil(donorsData?.count / 50);

    const statusParams = useMemo(() => {
        const params = { campaign__name: campaignName };

        if (subCampaignName) params.sub_campaign__name = subCampaignName;
        else if (directDataOnly) params.sub_campaign_isnull = true;
        if (groupName) params.group__name = groupName;
        else if (directDataOnly) params.group_isnull = true;
        if (fundraiserId) params['fundraiser__fundraiser__contact_id'] = fundraiserId;
        else if (directDataOnly) params.fundraiser_isnull = true;

        return params;
    }, [campaignName, subCampaignName, groupName, fundraiserId, directDataOnly]);

    const donorsStatusQuery = useQuery(['donorsStatus', 'campaignOverview', statusParams],
        () => api.get('/campaigns/donors-status', { params: statusParams }).then(res => res.data),
        {
            enabled: isInitialised,
            refetchOnWindowFocus: true,
            onSuccess: () => queryClient.invalidateQueries('contacts'),
        }
    );

    const { data: donorsStatusData } = donorsStatusQuery;

    const exportParams = () => {
        const params = { ...getParams };
        params.all = true;
        if (checkedDonors.length > 0) params.contact_id__in = checkedDonors;
        return params;
    }

    const exporDonorsQuery = useQuery(['exportDonors'],
        () => api.get('/contacts/', { params: { ...exportParams(), donor_list: true } }).then(res => res.data),
        {
            enabled: false,
            onSuccess: (data) => onExport(data),
            onError: () => enqueueSnackbar(t('Error exporting donors.'), { variant: 'error' }),
        }
    );

    const { refetch: exportDonors, isRefetching: exportingDonors } = exporDonorsQuery;

    useEffect(() => {
        donorParams.set('donor_list', true);
        donorParams.set('donors__campaign__name', campaignName);
        if (subCampaignName) donorParams.set('donors__sub_campaign__name', subCampaignName);
        else if (directDataOnly) donorParams.set('donors__sub_campaign__name__isnull', true);
        else donorParams.delete('donors__sub_campaign__name__isnull');
        if (groupName) donorParams.set('donors__group__name', groupName);
        else if (directDataOnly) donorParams.set('donors__group__name__isnull', true);
        else donorParams.delete('donors__group__name__isnull');
        if (fundraiserId) donorParams.set('donors__fundraiser__fundraiser__contact_id', fundraiserId);
        else if (directDataOnly) donorParams.set('donors__fundraiser__isnull', true);
        else donorParams.delete('donors__fundraiser__isnull');

        if (status === 'all') donorParams.delete('donors__status');
        else donorParams.set('donors__status', status);

        if (referrer !== `donors-${donorParams.toString()}`) {
            reset();
            setListType(`donors-${donorParams.toString()}`);
        }
        setReferrer('');
        setLocation(location.pathname);
        setParams(donorParams);
        setCheckAll(false);
        setCheckedDonors([]);
        setIsInitialised(true);
    }, [campaignName, subCampaignName, groupName, fundraiserId, directDataOnly, status]);

    useEffect(() => {
        setCheckedDonors([]);
        setCheckAll(false);
    }, [currentPage]);

    const { mutate: removeDonors } = useMutation(
        (data) => api.post(`/campaigns/${campaignName}/donors/delete`, data),
        {
            onSuccess: () => {
                setCheckedDonors([]);
                setCheckAll(false);
                enqueueSnackbar(t('Donors removed successfully.'), { variant: 'success' });
                optionsClickOutside();
                queryClient.invalidateQueries('campaignOverview');
                queryClient.refetchQueries('contacts');
            },
            onError: () => enqueueSnackbar(t('Error removing donors.'), { variant: 'error' }),
        }
    );

    if (!subscription.campaigns) return <NoCampaignSubscription />;

    if (donorsLoading || !isInitialised) return <TabTableLoading />;

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                gap: '1rem',
            }}>
                <Paper
                    elevation={0}
                    sx={{
                        // display: 'none',
                        display: 'flex',
                        borderRadius: '0.5rem',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                    }}
                >
                    <StyledToggleButtonGroup
                        value={status}
                        exclusive
                        color="primary"
                        size="small"
                        onChange={(e, value) => value !== null && setStatus(value)}
                    >
                        <ToggleButton value="all" list>
                            {t('All Donors')}
                            <StyledChip
                                active={status === 'all'}
                                color={statusColors.all}
                                size="small"
                                label={donorsStatusData?.all || 0}
                                sx={{ borderRadius: '6px' }}
                            />
                        </ToggleButton>
                        <ToggleButton value={donorStatusMapping.PENDING} list>
                            {t('Pending')}
                            <StyledChip
                                active={status === 'pending'}
                                color={statusColors.pending}
                                size="small"
                                label={donorsStatusData?.[donorStatusMapping.PENDING] || 0}
                                sx={{ borderRadius: '6px' }}
                            />
                        </ToggleButton>
                        <ToggleButton value={donorStatusMapping.IN_PROGRESS} list>
                            {t('In Progress')}
                            <StyledChip
                                active={status === 'in_progress'}
                                color={statusColors.in_progress}
                                size="small"
                                label={donorsStatusData?.[donorStatusMapping.IN_PROGRESS] || 0}
                                sx={{ borderRadius: '6px' }}
                            />
                        </ToggleButton>
                        <ToggleButton value={donorStatusMapping.COMMITTED} list>
                            {t('Committed')}
                            <StyledChip
                                active={status === 'committed'}
                                color={statusColors.committed}
                                size="small"
                                label={donorsStatusData?.[donorStatusMapping.COMMITTED] || 0}
                                sx={{ borderRadius: '6px' }}
                            />
                        </ToggleButton>
                        <ToggleButton value={donorStatusMapping.DONATED} list>
                            {t('Donated')}
                            <StyledChip
                                active={status === 'donated'}
                                color={statusColors.donated}
                                size="small"
                                label={donorsStatusData?.[donorStatusMapping.DONATED] || 0}
                                sx={{ borderRadius: '6px' }}
                            />
                        </ToggleButton>
                        <ToggleButton value={donorStatusMapping.DID_NOT_DONATE} list>
                            {t('Did Not Donate')}
                            <StyledChip
                                active={status === 'did_not_donate'}
                                color={statusColors.did_not_donate}
                                size="small"
                                label={donorsStatusData?.[donorStatusMapping.DID_NOT_DONATE] || 0}
                                sx={{ borderRadius: '6px' }}
                            />
                        </ToggleButton>
                    </StyledToggleButtonGroup>
                </Paper>
                <DirectItemsFilter
                    directDataOnly={directDataOnly}
                    setDirectDataOnly={setDirectDataOnly}
                    label={t('Direct Donors Only')}
                    options={
                        <Options variant='secondary' options={[
                            {
                                key: 'export',
                                text: i18n.language === 'he' ?
                                    `ייצוא ${checkedDonors.length ? 'תורמים נבחרים' : statusToHebrewExport[status]}` :
                                    `${t('Export')} ${checkedDonors.length ? t('Selected') : t(statusDonorMapping[status] || 'All')} ${t('Donors')}`,
                                icon: <SystemUpdateAltRounded />,
                                disabled: donors.length === 0,
                                onClick: exportDonors,
                            },
                            {
                                key: 'remove-donors',
                                disabled: !checkedDonors.length,
                                text: `${t('Remove')} ${checkedDonors.length || ''} ${t('Donors')}`,
                                icon: <GroupRemoveRounded />,
                                onClick: () => removeDonors({ donors: checkedDonors }),
                            },
                        ]} />
                    }
                >
                    <InnerSearch searchValue={search} onSubmit={setSearch} placholder={t('Search Donors')} />
                </DirectItemsFilter>
            </Box>
            {donors.length === 0 ?
                <NoResultsFound
                    title={t('Add your First Donor!')}
                    subtitle={<>{t('Go to')} <strong><Link underline="hover" component={RouterLink} to="/contacts">{t('Contacts')}</Link>, {t('Select >> Options >> Add Donor Prospect')}</strong></>}
                    Icon={Diversity1Rounded}
                /> :
                <ContactList
                    contacts={donors}
                    checkedContacts={checkedDonors}
                    setCheckedContacts={setCheckedDonors}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    getSort={getSort}
                    setSort={setSort}
                    totalPages={pageCount}
                    additionalColumns={[
                        {
                            value: t('Priority'),
                            sort: 'donors__priority',
                            field: "donor.priority",
                        },
                        ...(status === 'all' ? [{
                            value: t('Status'),
                            sort: 'donors__status',
                            field: "donor.status",
                        }] : [])
                    ]}
                />}

            <LoadingModal
                open={exportingDonors}
                title={t('Exporting Donors')}
                message={t('This may take a few minutes.')}
            />
        </Box>
    );
}

export default observer(Donors);
export { StyledChip };