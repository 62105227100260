import { Route, Routes } from "react-router-dom";
import { DarkThemeProvider } from "../themeContext";
import Home from "./HomePage/Home";
import RedirectToWaitingList from "../components/RedirectToWaitingList";

function HomePage() {
    return (
        <div dir='ltr'>
            <DarkThemeProvider>
                    <Routes>
                        <Route index element={<Home />} />
                    </Routes>
            </DarkThemeProvider>
        </div>
    )
}

export default HomePage;