import { Alert, Box, Button, Divider, FormControl, IconButton, Link, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import Section from "components/common/Section";
import InfoModal from "components/common/InfoModal";
import { useEffect, useState } from "react";
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import { mainStore } from "stores/MainStore";
import { localDate } from "utils/dates";
import { useQueryClient } from "react-query";
import { LoadingButton } from "@mui/lab";
import useSubscription from "hooks/useSubscription";
import { useSnackbar } from "notistack";
import CreditCardForm from "./creditCardForm";
import OrganizationDetails from "./OrganizationDetails";
import { getCountryByCode } from "data/countries";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import UpdateEmailPlan from "./UpdateEmailPlan";
import SelectEmailPlan from "./SelectEmailPlan";


function Billing() {
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { subscription, emailPlan, nextEmailPlan, billingCurrency, nextTotalBilling } = mainStore;
  const [openedPlan, setOpenedPlan] = useState(null);
  const [editOrganizationDetails, setEditOrganizationDetails] = useState(false);
  const [creditCardFormOpen, setCreditCardFormOpen] = useState(false);

  const nextBillingAmountWithVat = (subscription.country === 'IL' || subscription.currency === 'ILS') && nextTotalBilling * 1.17 || nextTotalBilling;


  useEffect(() => {
    queryClient.refetchQueries('subscription');
    queryClient.refetchQueries('emailPlans');
  }, []);

  return (
    <Box>
      <Typography variant="h1">{t('Billing')}</Typography>
      <Typography variant="h2">{t('Subscription')}</Typography>
      <Section>
        <Stack divider={<Divider />} spacing={2}>
          <div className="flex gap-x-4 text-start text-lg">
            <Typography variant="h6" minWidth={'12rem'}>{t('Current Plan')}</Typography>
            <div className="font-bold">Free</div>
            <div>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  minWidth: 'unset',
                }}
                disabled
              >
                <UnfoldMoreRoundedIcon fontSize="small" />
              </Button>
            </div>
            <div className="flex w-full">
              <Alert severity="info" sx={{
                backgroundColor: '#eeeeee',
                placeItems: 'center',
                '& .MuiAlert-icon': {
                  color: '#424242',
                  fontSize: '1.125rem',
                }
              }}>
                {t('you-cannot-upgrade-at-the-moment')} <Link href="mailto:info@donbar.org">info@donbar.org</Link>
              </Alert>
            </div>
          </div>
          <div className="flex gap-x-4 text-start text-lg">
            <Typography variant="h6" minWidth={'12rem'} >{t('Billing Information')}</Typography>
            <Box sx={{ width: '100%' }}>
              {subscription.subscription_credit_card &&
                <Paper variant="outlined" sx={{ p: 2, mb: 2, borderRadius: '0.5rem' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CreditCardRoundedIcon />
                      <Typography variant="subtitle2">
                        {t('You are billed to')}
                        <br />
                        <b>{subscription?.subscription_credit_card?.brand} {t('ending in')} {subscription?.subscription_credit_card?.last_four}</b>
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      {/* {!subscription?.has_paid_subscription &&
                      <Button variant="outlined" size="small" color="error">Remove Card</Button>} */}
                      {/* <Button variant="outlined" size="small" onClick={() => setCreditCardFormOpen(true)}>{t('Change Card')}</Button> */}
                    </Box>
                  </Box>
                </Paper>}
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ verticalAlign: 'top' }} width={200}>
                      {t('Organization Details')}
                    </TableCell>
                    <TableCell>
                      {subscription?.organization_name && `${subscription.organization_name}`}
                      {subscription?.organization_id && `${subscription.organization_name ? ', ' : ''}${subscription.organization_id}`}
                      {subscription?.country && `${(subscription.organization_name || subscription.organization_id) ? ', ' : ''}${t(getCountryByCode(subscription.country)?.label)}`}
                      {subscription?.address && `${(subscription.organization_name || subscription.organization_id || subscription.coutry) ? ', ' : ''}${subscription.address}`}
                      <IconButton size="small" onClick={() => setEditOrganizationDetails(true)}>
                        <EditRoundedIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {subscription.emails && subscription.email_subscription &&
                    <TableRow>
                      <TableCell>{t('Emails Sent')}</TableCell>
                      <TableCell>
                        {subscription?.email_subscription?.usage.toLocaleString() || 0} {t('emails')}
                      </TableCell>
                    </TableRow>}
                  <TableRow>
                    <TableCell>{t('Next Billing Date')}</TableCell>
                    <TableCell>
                      {localDate(subscription?.next_billing_date) || '--'}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>{t('Next Billing Amount')}</TableCell>
                    <TableCell>
                      {nextBillingAmountWithVat?.toLocaleString(i18n.language, { style: 'currency', currency: billingCurrency }) || '--'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </div>
        </Stack>
      </Section>
      {subscription.emails && subscription.email_subscription &&
        <>
          <Typography variant="h2">{t('Addons')}</Typography>
          {subscription.emails && emailPlan &&
            <Section>
              <div className="flex gap-x-4 text-start text-lg">
                <div className="font-medium w-40">{t('Emails')}:</div>
                <b>{t(emailPlan.name)}: </b>{emailPlan.amount.toLocaleString()} {t('emails per month')}
                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      minWidth: 'unset',
                    }}
                    onClick={() => setOpenedPlan('emails')}
                  >
                    <UnfoldMoreRoundedIcon fontSize="small" />
                  </Button>
                </div>
                {nextEmailPlan &&
                  <Alert severity="warning">
                    {t('On')} <b>{localDate(subscription?.email_subscription?.next_email_subscription?.date)}</b> {`${t('your plan will downgrade to')}`} <b>{t(nextEmailPlan.name)}</b> {`${nextEmailPlan.amount.toLocaleString()} ${t('emails per month')}`}
                  </Alert>
                }
              </div>
            </Section>}
        </>}
      {creditCardFormOpen &&
        <CreditCardForm
          onClose={() => setCreditCardFormOpen(false)}
          currency={billingCurrency}
        />}
      {editOrganizationDetails &&
        <OrganizationDetails open={editOrganizationDetails} onClose={() => setEditOrganizationDetails(false)} />}
      {openedPlan === 'emails' &&
        <UpdateEmailPlan onClose={() => setOpenedPlan(null)} />}
    </Box >
  );
}

export default Billing;