import { useMutation, useQuery, useQueryClient } from "react-query";
import List from "./List"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useApi from "hooks/useApi";
import { uiStore } from "stores/UiStore";
import CampaignForm from "../CampaignForm";
import LoadingSkeleton from "../LoadingSkeleton";
import NoResultsFound from "components/general/NoResultsFound";
import AddFab from "components/specific/AddFab";
import { CampaignRounded } from "@mui/icons-material";

function CampaignList() {
    const { t } = useTranslation();
    const api = useApi();
    const { setFabAction } = uiStore;
    const { enqueueSnackbar } = useSnackbar();
    const [newCampaign, setNewCampaign] = useState(false);

    const campaignsQuery = useQuery(['campaigns'],
        () => api.get('/campaigns/').then(res => res.data),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
            keepPreviousData: true,
        }
    );

    const { data: campaignsData, isLoading: campaignsLoading } = campaignsQuery;

    const { mutate: createCampaign, isLoading: campaignLoading } = useMutation(
        (data) => api.post('/campaigns/create', data),
        {
            onSuccess: (res) => {
                enqueueSnackbar(t('Campaign created.'), { variant: 'success' });
                campaignsQuery.refetch();
                setNewCampaign(false);
            },
            onError: () => enqueueSnackbar(t('Error creating campaign.'), { variant: 'error' }),
        }
    );

    useEffect(() => {
        setFabAction(() => setNewCampaign(true))

        return () => {
            setFabAction(null);
        };
    }, []);

    if (campaignsLoading) return <LoadingSkeleton />

    return (
        <>
            {campaignsData.length === 0 ? (
                <div className="relative top-20">
                    <NoResultsFound
                        title={t('No Campaigns Yet!')}
                        subtitle={t('Start by creating your first campaign.')}
                        Icon={CampaignRounded}
                    >
                        <AddFab block />
                    </NoResultsFound>
                </div>
            ) : (
                <List listData={campaignsData} name='Campaign' type='campaigns' />
            )}
            {newCampaign &&
                <CampaignForm
                    title={t('Add Campaign')}
                    onClose={() => setNewCampaign(false)}
                    onSave={(data) => createCampaign(data)}
                    saving={campaignLoading}
                    type='campaigns'
                />}
        </>
    )
}

export default CampaignList