import React from "react";
import { Button, ListSubheader, MenuItem, Popover, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function SelectOption({ title, text, placeholder, optionAnchorEl, setOptionAnchorEl, selectedOption, setSelectedOption, options, handleAddToOption }) {
    const dir = document.dir;
    const { t } = useTranslation();

    return (
        <Popover
            open={optionAnchorEl}
            anchorEl={optionAnchorEl}
            onClose={() => setOptionAnchorEl(null)}
            sx={{ '& .MuiPopover-paper': { borderRadius: '0.5rem' } }}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: dir === 'rtl' ? 'left' : 'right',
            }}
        >
            <div className='p-4 min-w-64'>
                <Typography variant='h3'>{title}</Typography>
                <p className='text-sm text-gray-500'>{text}</p>
                <Select
                    className='w-full mt-4'
                    value={selectedOption || 0}
                    placeholder={placeholder}
                    onChange={(event, child) => setSelectedOption(event.target.value)}
                >
                    <MenuItem disabled value={0}>{placeholder}</MenuItem>
                    {options.map((option) => (
                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                        ))}
                </Select>
                <div className='flex justify-end gap-2 mt-4'>
                    <Button
                        onClick={() => {
                            setOptionAnchorEl(null)
                            setSelectedOption(null)
                        }}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            handleAddToOption(selectedOption)
                            setSelectedOption(null)
                        }}
                        disabled={!selectedOption}
                    >
                        {t('Add')}
                    </Button>
                </div>
            </div>
        </Popover>
    )
}

export default SelectOption;