import { Autocomplete, Box, TextField } from "@mui/material";
import { countries } from "data/countries";
import { useTranslation } from "react-i18next";

function CountryInput({ country = 'UK', setCountry, ...props }) {
    const { t } = useTranslation();

    const selectedCountry = countries.find(c => c.code === country) || null;

    return (
        <Autocomplete
            id="country-select-demo"
            options={countries}
            autoHighlight
            getOptionLabel={(option) => t(option.label)}
            size="small"
            value={selectedCountry}
            onChange={(event, newValue) => {
                setCountry(newValue?.code || null);
            }}
            {...props}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                    />
                    {t(option.label)}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    variant="standard"
                    {...params}
                    label={t("Country")}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}

export default CountryInput;