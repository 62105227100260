import { useTranslation } from "react-i18next";
import Table from "../../../../components/common/TableComponents/Table";
import Thead from "../../../../components/common/TableComponents/Thead";
import Th from "../../../../components/common/TableComponents/Th";
import { Tbody, Td, Tr } from "../../../../components/common/TableComponents/Tbody";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { Box, Fab } from "@mui/material";
import useApi from "hooks/useApi";
import { uiStore } from "stores/UiStore";
import DynamicTable from "components/general/DynamicTable";
import { BrokenImageRounded } from "@mui/icons-material";
import { PhotoSynth } from "photosynth-react";

function EmailImage({ row }) {
    const [error, setError] = useState(false);

    if (error) return <BrokenImageRounded color="disabled" />

    return (
        <Box sx={{ m: '-1rem', width: 44, height: 56 }}>
            <PhotoSynth
                // src={`https://ps.temperal.co/ps?u=https://media.donbar.org/${row.image}&k=Cu0qCBD4UAv7&h=46&w=40`}
                sourceUrl={`https://media.donbar.org/${row.image}`}
                psKey="Cu0qCBD4UAv7"
                width={44}
                height={56}
                cssStyle={{
                    backgroundSize: 'cover',
                    borderRadius: '4px',
                }}
                onError={() => setError(true)}
            />
        </Box>
    )
}

function Emails() {
    const { t } = useTranslation();
    const api = useApi();
    const [loading, setLoading] = useState(false);
    const { setTitle } = uiStore;
    const navigate = useNavigate();
    const [emails, setEmails] = useState([]);

    useEffect(() => {
        setTitle(t('Emails'));
        setLoading(true)
        api.get('/emails/list/').then((response) => {
            setEmails(response.data)
            setLoading(false)
        }).catch((error) => {
            console.log(error);
            setLoading(false)
        })

        return () => setTitle(null);
    }, []);

    return (
        <div>
            <DynamicTable
                columns={[
                    {
                        value: t('Subject'),
                        field: 'subject'
                    },
                    {
                        value: t('Description'),
                        field: 'description'
                    },
                    {
                        value: t('Sent'),
                        field: 'email_stat.sends'
                    },
                    {
                        value: t('Opened'),
                        field: 'email_stat.opens'
                    },
                    {
                        value: t('Clicks'),
                        field: 'email_stat.clicks'
                    },
                    {
                        field: 'image',
                        renderField: (row) => <EmailImage row={row} />
                    }
                ]}
                rows={emails}
                onRowClick={(email) => navigate(email.id)}
            />
            <div className="flex justify-end">
                <div className='fixed bottom-10 text-end mx-10'>
                    <Fab
                        color='primary'
                        onClick={() => navigate('new')}
                    >
                        <AddIcon />
                    </Fab>
                </div>
            </div>
        </div >
    );
}

export default Emails;

