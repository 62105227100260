const paymentMethods = [
    { value: 'Cash', label: 'Cash' },
    { value: 'Check', label: 'Check' },
    { value: 'CreditCard', label: 'Credit Card' },
    { value: 'BankTransfer', label: 'Bank Transfer' },
    { value: 'StandingBankOrder', label: 'Standing Bank Order' },
    { value: 'PayPal', label: 'PayPal' },
    { value: 'GooglePay', label: 'Google Pay' },
    { value: 'ApplePay', label: 'Apple Pay' },
    { value: 'NedarimPlus', label: 'Nedarim Plus' },
    { value: 'Other', label: 'Other' },
];

const paymentStatuses = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Received', label: 'Received' },
    { value: 'Canceled', label: 'Canceled' },
    { value: 'Refunded', label: 'Refunded' },
    { value: 'Failed', label: 'Failed' },
    { value: 'Other', label: 'Other' },
];

const expenseMethods = [
    { value: 1, label: 'Cash' },
    { value: 2, label: 'Check' },
    { value: 3, label: 'Credit Card' },
    { value: 4, label: 'Bank Transfer' },
    { value: 6, label: 'PayPal' },
    { value: 7, label: 'Other' },
];

// activity types methods
const contactActivityTypes = {
    NOTE: 1,
    TASK_CREATED: 2,
    TASK_DUE: 3,
    TASK_COMPLETED: 4,
    PLEDGE_CREATED: 10,
    PLEDGE_DUE: 11,
    PLEDGE_PAID: 12,
    PLEDGE_CANCELLED: 13,
    DONATION_CREATED: 20,
    DONATION_PAID: 21,
}

const contactActivityTypeData = {
    [contactActivityTypes.NOTE]: {
        text: 'commented'
    },
    [contactActivityTypes.TASK_CREATED]: {
        text: 'created task'
    },
    [contactActivityTypes.TASK_DUE]: {
        text: 'task is due'
    },
    [contactActivityTypes.TASK_COMPLETED]: {
        text: 'completed task'
    },
    [contactActivityTypes.PLEDGE_CREATED]: {
        text: 'created pledge'
    },
    [contactActivityTypes.PLEDGE_DUE]: {
        text: 'pledge is due'
    },
    [contactActivityTypes.PLEDGE_PAID]: {
        text: 'paid pledge'
    },
    [contactActivityTypes.PLEDGE_CANCELLED]: {
        text: 'cancelled pledge'
    },
}

const transactionTypes = [
    { value: '1', label: 'Regular' },
    { value: '2', label: 'Recurring' },
];
const donationTypes = {
    1: { label: 'Regular' },
    2: { label: 'Recurring' },
}

const donationStatus = [
    { value: '-1', label: 'Inactive' },
    { value: '0', label: 'Active' },
    { value: '3', label: 'Rejected' },
]

const itemStatuss = {
    0: { label: 'To Do', color: 'error' },
    1: { label: 'In Progress', color: 'warning' },
    2: { label: 'Done', color: 'success' },
    3: { label: 'Removed', color: 'default' }
}

const itemStatus = [
    { label: 'To Do', variant: 'filled', color: 'error', value: 0 },
    { label: 'In Progress', variant: 'filled', color: 'warning', value: 1 },
    { label: 'Done', variant: 'filled', color: 'success', value: 2 },
];

const getItemsStatus = (value) => {
    return itemStatuss[value];
}


const pledgeStatuses = {
    1: { label: 'Pending', color: 'yellow', muiColor: 'warning' },
    2: { label: 'Fulfilled', color: 'green', muiColor: 'success' },
    3: { label: 'Transformed to Donation', color: 'green', muiColor: 'primary' },
    4: { label: 'Canceled', color: 'gray', muiColor: 'default' },
}

const priorities = [
    { value: 0, label: 'Low', color: 'default' },
    { value: 1, label: 'Medium', color: 'warning' },
    { value: 2, label: 'High', color: 'error' },
];

const getPriority = (value) => {
    return priorities.find(p => p.value === value);
}

export {
    paymentMethods,
    paymentStatuses,
    expenseMethods,
    contactActivityTypes,
    contactActivityTypeData,
    transactionTypes,
    donationStatus,
    itemStatus,
    donationTypes,
    priorities,
    getPriority,
    getItemsStatus,
    pledgeStatuses
};