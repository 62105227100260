// Refactored: 01/28
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation, Outlet, Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import LoadingComponent from './components/Loading';
import RedirectToWaitingList from './components/RedirectToWaitingList';
import { CacheProvider } from '@emotion/react';
import theme from './theme';
import {
	experimental_extendTheme as materialExtendTheme,
	Experimental_CssVarsProvider as MaterialCssVarsProvider,
	THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import { SnackbarProvider } from 'notistack';
import { useInitialData } from './hooks/useInitialData';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { mainStore } from './stores/MainStore';
import { observer } from 'mobx-react-lite';
import rtlPlugin from 'stylis-plugin-rtl';
import { useTranslation } from 'react-i18next';
import MainView from './layout/MainContent';

const materialTheme = materialExtendTheme(theme);

const TRACKING_ID = "G-RXWDK8P0M7";

ReactGA.initialize(TRACKING_ID);


const PageViewTracker = () => {
	const location = useLocation();

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Home Page" });
	}, [location]);

	return null;
};


const cache = {
	rtl: createCache({
		key: 'muirtl',
		stylisPlugins: [prefixer, rtlPlugin],
	}),
	ltr: createCache({
		key: 'muiltr',
		stylisPlugins: [prefixer],
	}),
}

function App() {
	const { orgId, appLoaded, organizations } = mainStore;
	const { i18n } = useTranslation();

	useInitialData();

	if (!appLoaded) {
		return <LoadingComponent className='h-screen' size='48' />
	}

	if (appLoaded && organizations?.length === 0) {
		return <RedirectToWaitingList />;
	}


	return (
		<CacheProvider value={cache[i18n.dir()]}>
			<MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
				<JoyCssVarsProvider>
					{/* <div className="App bg-[#5ed08e]"> */}
					<div className="App bg-[#F5F6FA]">
						<SnackbarProvider maxSnack={3}>
							<Routes>
								{/* <Route path='/emails' element={<NewEmailEditor />} /> */}
								<Route path='/*' element={<MainView />} />
							</Routes>
						</SnackbarProvider>
					</div>
				</JoyCssVarsProvider>
			</MaterialCssVarsProvider>
		</CacheProvider>
	);
}

export default observer(App);
