import { NavigateNextRounded } from "@mui/icons-material";
import { Breadcrumbs, List, MenuItem, Select, Typography } from "@mui/material";
import useApi from "hooks/useApi";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

function CampaignsDropdown({ onChange }) {
    const api = useApi();
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState('[]')

    const campaignsDropdownQuery = useQuery(['campaignsDropdown'],
        () => api.get('/campaigns/dropdown').then(res => res.data),
        { refetchOnWindowFocus: true }
    );

    const { data: campaignsData, isLoading: campaignsLoading } = campaignsDropdownQuery;

    const campaigns = campaignsData || [];

    useEffect(() => {
        onChange(JSON.parse(selectedValue));
    }, [selectedValue]);

    const parsedDropdown = useMemo(() => {
        const data = campaigns.flatMap(campaign => {
            const campaignItem = {
                label: campaign.name,
                value: JSON.stringify([
                    { id: campaign.id, label: 'Campaign', type: 'campaign', name: campaign.name }
                ]),
                type: 'campaign',
                level: 0,
            };

            const subCampaigns = campaign.sub_campaigns.flatMap(subCampaign => {
                const subCampaignItem = {
                    label: subCampaign.name,
                    value: JSON.stringify([
                        { id: campaign.id, label: 'Campaign', type: 'campaign', name: campaign.name },
                        { id: subCampaign.id, label: 'Sub Campaign', type: 'sub_campaign', name: subCampaign.name }
                    ]),
                    type: 'sub_campaign', level: 1,
                };

                const subCampaignGroups = subCampaign.groups.map(group => {
                    return {
                        label: group.name,
                        value: JSON.stringify([
                            { id: campaign.id, label: 'Campaign', type: 'campaign', name: campaign.name },
                            { id: subCampaign.id, label: 'Sub Campaign', type: 'sub_campaign', name: subCampaign.name },
                            { id: group.id, label: 'Group', type: 'group', name: group.name }
                        ]),
                        type: 'group',
                        level: 2,
                    };
                });
                return [subCampaignItem, ...subCampaignGroups];
            });

            const campaignGroups = campaign.groups.map(group => {
                return {
                    label: group.name,
                    value: JSON.stringify([
                        { id: campaign.id, label: 'Campaign', type: 'campaign', name: campaign.name },
                        { id: group.id, label: 'Group', type: 'group', name: group.name }
                    ]),
                    type: 'group',
                    level: 1,
                };
            });

            return [campaignItem, ...subCampaigns, ...campaignGroups];
        });

        return data;
    }, [campaigns]);

    const renderBreadcrumb = (selected) => {
        const parsedSelected = JSON.parse(selected);

        if (parsedSelected.length === 0) return <Typography color="text.secondary">{t('Select a Campaign, Sub Campaign, Group or Fundraiser')}</Typography>;

        return (
            <Breadcrumbs separator={<NavigateNextRounded fontSize="small" />} aria-label="breadcrumb">
                {parsedSelected.map((part, index) => (
                    <span key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography fontWeight='bold' variant="caption" color="text.secondary" component="div" sx={{ lineHeight: '1.2' }}>
                            {t(part.label)}
                        </Typography>
                        <Typography color="text.primary" component="div" sx={{ display: 'block' }}>
                            {part.name}
                        </Typography>
                    </span>
                ))}
            </Breadcrumbs>
        );
    }


    return (
        <Select
            fullWidth
            sx={{ mt: 2 }}
            value={selectedValue}
            onChange={(e) => setSelectedValue(e.target.value)}
            renderValue={(selected) => renderBreadcrumb(selected)}
            placeholder={t('Select a Campaign, Sub Campaign, Group or Fundraiser')}
            MenuProps={{
                PaperProps: {
                    style: {
                        maxHeight: 224,
                    },
                },
            }}
        >
            {parsedDropdown.length === 0 && <MenuItem disabled>{t('No campaigns yet')}</MenuItem>}
            <MenuItem value={'[]'} disabled>{t('Select a Campaign, Sub Campaign, Group or Fundraiser')}</MenuItem>
            {parsedDropdown.map((item, index) => {
                return <MenuItem key={index} value={item.value} sx={{ pl: `${1.5 + item.level * 1.5}rem` }}>{item.label}</MenuItem>
            })}
        </Select>
    )
}

export default CampaignsDropdown