import { DonutLargeRounded, GroupRounded, HelpRounded, VolunteerActivismRounded } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Section from "components/common/Section";

function TotalCard({ title, value, icon, key }) {

    return (
        <Section
            key={key}
            sx={{
                maxWidth: '18rem',
                width: '100%',
                borderRadius: '1rem',
                p: '1rem',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Stack spacing={1}>
                    <Typography fontWeight='bold' variant="subtitle2" color="text.secondary">
                        {title}
                    </Typography>
                    <Typography variant="h3">
                        {value}
                    </Typography>
                </Stack>
                <Box
                    sx={{
                        width: '3rem',
                        height: '3rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '0.5rem',
                        color: 'white',
                        backgroundColor: theme => theme.palette.info.light,
                        boxShadow: theme => theme.components.MuiPaper,
                    }}
                >
                    {icon}
                </Box>
            </Box>
        </Section >
    )
}

export default TotalCard;