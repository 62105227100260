import { useTranslation } from "react-i18next";
import List from "./List"
import useApi from "hooks/useApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { uiStore } from "stores/UiStore";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import CampaignForm from "../CampaignForm";
import { useParams } from "react-router-dom";
import useCampaign from "hooks/useCampaign";
import LoadingSkeleton from "../LoadingSkeleton";
import DonorsCountCard from "../DonorsCountCard";
import { Stack } from "@mui/material";
import DonationsSumCard from "../DonationsSumCard";
import ProgressCard from "../ProgressCard";
import AddFab from "components/specific/AddFab";
import NoResultsFound from "components/general/NoResultsFound";
import { CampaignTwoTone } from "@mui/icons-material";

function SubCampaignList() {
    const { t } = useTranslation();
    const { campaignName } = useParams();
    const api = useApi();
    const { setFabAction } = uiStore;
    const { enqueueSnackbar } = useSnackbar();
    const [newSubCamapign, setNewSubCamapign] = useState(false);

    const { campaignQuery } = useCampaign(campaignName);

    const subCampaignsQuery = useQuery(['subCampaigns'],
        () => api.get(`/campaigns/${campaignName}/sub-campaigns`).then(res => res.data),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
            enabled: !!campaignName,
            keepPreviousData: true,
        }
    );

    const { data: subCampaignsData, isLoading: subCampaignsLoading } = subCampaignsQuery;

    // const { data: campaign, isLoading: campaignLoading } = campaignQuery;


    const { mutate: createSubCamapign, isLoading: subCamapignLoading } = useMutation(
        (data) => api.post('/campaigns/sub-campaigns/create', { ...data, campaign: campaign.id }),
        {
            onSuccess: (res) => {
                enqueueSnackbar(t('Sub Campaign created.'), { variant: 'success' });
                subCampaignsQuery.refetch();
                campaignQuery.refetch();
                setNewSubCamapign(false);
            },
            onError: () => enqueueSnackbar(t('Error creating sub campaign.'), { variant: 'error' }),
        }
    );

    useEffect(() => {
        setFabAction(() => setNewSubCamapign(true))

        return () => {
            setFabAction(null);
        };
    }, []);

    if (subCampaignsLoading) return <LoadingSkeleton />

    const { campaign, results } = subCampaignsData

    return (
        <>
            {results.length === 0 ? (
                <div className="relative top-20">
                    <NoResultsFound
                        title={t('No Sub Campaigns Yet!')}
                        subtitle={t('Start by creating your first sub campaign.')}
                        Icon={CampaignTwoTone}
                    >
                        <AddFab block />
                    </NoResultsFound>
                </div>
            ) : (
                <>
                    <Stack direction="row" spacing={2} sx={{ my: '1rem', justifyContent: 'space-between' }}>
                        <DonationsSumCard value={campaign.donations_sum} currency='ILS' />
                        <DonorsCountCard value={campaign.donors_count} />
                        <ProgressCard value={campaign.goal ? campaign.donations_sum / campaign.goal : '--'} />
                    </Stack>
                    <List listData={results} name='Sub Campaign' type='subCampaigns' />
                </>
            )}
            {newSubCamapign &&
                <CampaignForm
                    title={t('Add Sub Campaign')}
                    onClose={() => setNewSubCamapign(false)}
                    onSave={(data) => createSubCamapign(data)}
                    saving={subCamapignLoading}
                    type='sub-campaigns'
                />}
        </>
    )
}

export default SubCampaignList