import { useTranslation } from "react-i18next";
import Container from "./container";
import hero from '../../img/hero.svg';
import WaitingListModal from "./waitnigListForm";
import { useState } from "react";
import { HiCheckCircle } from "react-icons/hi";
import DRMAlert from "./alerts";

const Hero = () => {
  const [waitngListFormOpen, setWaitingListFormOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const { t } = useTranslation();
  return (
    <>
      <DRMAlert
        open={showAlert}
        title={"Thank You!"}
        text={"You have succesfully joined the waiting list. We will get back to you soon!"}
        type={"success"}
        icon={<HiCheckCircle />}
      />
      <WaitingListModal
        open={waitngListFormOpen}
        setOpen={setWaitingListFormOpen}
        setAlert={setShowAlert}
      />
      <Container className="flex flex-wrap text-start">
        <div className="flex items-center w-full lg:w-1/2">
          <div className="max-w-2xl mb-8">
            <h1 className="text-4xl font-bold leading-snug tracking-tight text-gray-800 lg:text-4xl lg:leading-tight xl:text-6xl xl:leading-tight dark:text-white">
              {t('main-headline')}
            </h1>
            <p className="py-5 text-xl leading-normal text-gray-500 lg:text-xl xl:text-2xl dark:text-gray-300">
              {t('main-subheadline')}
              <br />
              <br />
              <mark className="text-indigo-800 bg-indigo-100 rounded-md ring-indigo-100 ring-4 dark:ring-indigo-900 dark:bg-indigo-900 dark:text-indigo-200">
                {t('main-highlight')}
              </mark>
            </p>

            <div className="flex items-center mt-6">
              <a
                onClick={() => setWaitingListFormOpen(true)}
                className="px-8 py-4 text-lg font-medium cursor-pointer text-center text-white bg-indigo-600 rounded-md ">
                {t('Request Early Access')}
              </a>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center w-full lg:w-1/2">
          <div className="">
            <img
              src={hero}
              width="616"
              height="617"
              className={"object-cover"}
              alt="Hero Illustration"
              loading="eager"
              placeholder="blur"
            />
          </div>
        </div>
      </Container>
      <Container>
        <div className="flex flex-col justify-center">
          {/* <div className="text-xl text-center text-gray-700 dark:text-white">
            Trusted by <span className="text-indigo-600">2000+</span>{" "}
            customers worldwide
          </div> */}

        </div>
      </Container>
    </>
  );
}

export default Hero;