import AddIcon from '@mui/icons-material/Add';
import { debounce } from "lodash";
import { Button, Chip, Icon, IconButton, LinearProgress, Popover, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteDialog from './common/DeleteDialog';
import useApi from 'hooks/useApi';

function AddTag({ elementTags, setElementTags, element_id, addTag }) {
    const api = useApi();
    const [anchorEl, setAnchorEl] = useState(null);
    const openAddTag = Boolean(anchorEl);
    const [openNewTag, setOpenNewTag] = useState(false);
    const [tags, setTags] = useState([]);
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [newTag, setNewTag] = useState(false);
    const [editTag, setEditTag] = useState();
    const [openDelete, setOpenDelete] = useState(false);

    useEffect(() => {
        if (!openNewTag) {
            setEditTag(null);
            setNewTag(false);
            return;
        }
        if (editTag) {
            api.get('/contacts/tags/crud/', { params: { tag_id: editTag.id } }).then((response) => {
                setEditTag(response.data);
            }).catch((error) => {
                console.error('Error fetching tag:', error);
            });
        } else {
            setEditTag({
                name: '',
                color: '#124ef3',
            });
        }
    }, [openNewTag]);

    useEffect(() => {
        if (!openAddTag || openNewTag) {
            return;
        }
        const fetchTags = debounce(() => {
            setSearchLoading(true);
            api.get('/contacts/tags/', {
                params: {
                    search: searchValue,
                    contact_id: element_id,
                },
            }).then((response) => {
                setTags(response.data.results);
                setSearchLoading(false);
            }).catch((error) => {
                console.error('Error fetching tags:', error);
                searchLoading(false);
            });
        }, 100);

        fetchTags();
        return () => fetchTags.cancel();
    }, [searchValue, openAddTag, openNewTag, elementTags]);

    const saveTag = () => {
        if (editTag.id) {
            api.put('/contacts/tags/crud/', editTag, {
                params: {
                    tag_id: editTag.id,
                },
            }).then((response) => {
                setElementTags(elementTags.map((tag) => tag.id === editTag.id ? response.data : tag));
                setOpenNewTag(false);
            }).catch((error) => {
                console.error('Error updating tag:', error);
            });
        } else {
            api.post('/contacts/tags/crud/', editTag, {
                params: {
                    contact_id: element_id,
                },
            }).then((response) => {
                addTag(response.data);
                setOpenNewTag(false);
                setEditTag(null);
            }).catch((error) => {
                console.error('Error creating tag:', error);
            });
        }
    }

    const handleDeleteTag = () => {
        setOpenDelete(false);
        api.delete('/contacts/tags/crud/', {
            params: {
                tag_id: editTag.id,
            },
        }).then((response) => {
            setElementTags(elementTags.filter((tag) => tag.id !== editTag.id));
            setOpenNewTag(false);
        }).catch((error) => {
            console.error('Error deleting tag:', error);
        });
    }

    const renderNewEditTag = () => (
        <Popover
            open={openNewTag}
            anchorEl={anchorEl}
            onClose={() => {
                setAnchorEl(null)
                setOpenNewTag(false)
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <div className='p-4'>
                <div className='flex justify-between mb-3'>
                    <div className='rtl:rotate-180'>
                        <IconButton
                            size='small'
                            onClick={() => setOpenNewTag(false)}
                        >
                            <ArrowBackIosIcon sx={{ fontSize: '1rem' }} />
                        </IconButton>
                    </div>
                    <div className='h-full my-auto align-middle justify-center font-bold text-sm text-gray-600'>{editTag?.id ? t('Edit Tag') : t('Create New Tag')}</div>
                    <div className='w-[26px]'></div>
                </div>
                <div className='flex gap-2'>
                    <div>
                        <TextField
                            fullWidth
                            label={t('Tag Name')}
                            variant='outlined'
                            size='small'
                            value={editTag?.name}
                            onChange={(e) => setEditTag({ ...editTag, name: e.target.value })}
                        />
                    </div>
                </div>
                <div className='mt-4 flex gap-2'>
                    <TextField
                        fullWidth
                        label={t('Tag Color')}
                        variant='outlined'
                        size='small'
                        type='color'
                        value={editTag?.color}
                        onChange={(e) => setEditTag({ ...editTag, color: e.target.value })}
                    />
                </div>
                <div className='flex justify-center'>
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        sx={{ textTransform: 'none', mt: '1rem' }}
                        onClick={() => saveTag()}
                    >
                        {t('Save')}
                    </Button>
                    {editTag?.id &&
                        <Button
                            variant='contained'
                            color='error'
                            size='small'
                            sx={{ textTransform: 'none', mt: '1rem', ml: '1rem' }}
                            onClick={() => {
                                // confirm(t('Are you sure you want to delete this tag?'))
                                setOpenDelete(true)
                            }}
                        >
                            {t('Delete')}
                        </Button>}
                    <DeleteDialog
                        open={openDelete}
                        onClose={() => setOpenDelete(false)}
                        onDelete={handleDeleteTag}
                        title={`${t('Delete')} '${editTag?.name}'?`}
                        text={t('WARNING: Deleting this tag will remove it from all contacts!')}
                    />

                </div>
            </div>
        </Popover >
    )

    return (
        <div>
            <Chip
                label={t('Add Tag')}
                sx={{ borderRadius: '8px' }}
                color='primary'
                size='small'
                variant='outlined'
                icon={<AddIcon />}
                onClick={(e) => setAnchorEl(e.currentTarget)}
            />
            <Popover
                open={openAddTag}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className='p-4'>
                    <div className='flex justify-between mb-3'>
                        <IconButton
                            size='small'
                            onClick={() => setAnchorEl(null)}
                        >
                            <CloseIcon sx={{ fontSize: '1rem' }} />
                        </IconButton>
                        <div className='h-full my-auto align-middle justify-center font-bold text-sm text-gray-600'>{t('Add Tag')}</div>
                        <div className='w-[26px]'></div>
                    </div>
                    <div className='flex gap-2'>
                        <div>
                            <TextField
                                fullWidth
                                label={t('Tag Name')}
                                variant='outlined'
                                size='small'
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                            {searchLoading ? <LinearProgress
                                className='mt-2'
                                sx={{ borderRadius: '99', backgroundColor: '#fff', height: '2px' }}
                            /> :
                                <div className='h-2.5'>
                                </div>}
                        </div>
                    </div>
                    <div className='mt-4 flex flex-col gap-2 max-h-48 overflow-auto'>
                        {tags.map((tag, index) => (
                            <div key={index} className='flex gap-2'>
                                <div
                                    className={'flex justify-between rounded-md w-full max-w-[11rem] px-2 py-1 items-center gap-2 text-white text-sm cursor-pointer'}
                                    style={{ backgroundColor: tag.color }}
                                >
                                    <div className='truncate-2-lines'>{tag.name}</div>
                                    <div className='flex float-right'>
                                        <IconButton
                                            size='small'
                                            onClick={() => {
                                                setEditTag(tag);
                                                setOpenNewTag(true);
                                            }}
                                        >
                                            <EditIcon fontSize='small' sx={{ color: 'white' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                <IconButton
                                    size='small'
                                    onClick={() => addTag(tag)}
                                >
                                    <AddIcon color='primary' />
                                </IconButton>
                            </div>
                        ))}
                        {tags.length === 0 && !searchLoading &&
                            <div className='text-center text-sm text-gray-500'>
                                {t('No tags found')}
                                <br />
                                <span className='text-xs'></span>
                            </div>}
                    </div>
                    <div className='flex gap-2 mt-4'>
                        {/* <TextField
                            fullWidth
                            label={t('Tag Color')}
                            variant='outlined'
                            size='small'
                            type='color'
                        /> */}
                        {/* <Button
                            variant='contained'
                            color='primary'
                            size='small'
                        >
                            {t('Add')}
                        </Button> */}
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            className='w-full'
                            sx={{ textTransform: 'none', mt: '1rem' }}
                            onClick={() => setOpenNewTag(true)}
                        >
                            {t('Create New Tag')}
                        </Button>
                    </div>
                </div>
                {renderNewEditTag()}
            </Popover>
        </div >
    )
}

export default AddTag