import { Card, Link } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import NoResultsFound from "components/general/NoResultsFound";
import logo from 'img/logo.svg';
import { useTranslation } from "react-i18next";
import Section from "components/common/Section";

function NoCampaignSubscription() {
    const { t } = useTranslation();

    return (
        <Section
            component={Card}
            sx={{
                width: '46rem',
                margin: 'auto',
            }}
        >
            <NoResultsFound
                title={t('Unlock Full Campaign Management Features')}
                subtitle={<>{t('To access all features, contact us at:')} <strong><Link underline="hover" href="mailto:info@donbar.org">info@donbar.org</Link></strong></>}
                image={<img className='h-20 mx-auto' src={logo} alt="logo" />}
            />
        </Section>
    )
}

export default NoCampaignSubscription;