import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { uiStore } from "stores/UiStore"
// import { default as LegPledgeList } from "components/specific/PledgeListLeg";
import PledgeList from "views/Donations/Pledges/PledgeList";
import { observer } from "mobx-react-lite";
import PledgeDetails from "views/Donations/Pledges/PledgeDetails";
import { useMutation, useQueryClient } from "react-query";
import { DeleteRounded } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import useApi from "hooks/useApi";
import WarningDialog from "components/common/DeleteDialog";
import { optionsClickOutside } from "utils/controls";

function Pledges() {
    const { setTitle, setFabAction, addOption, setOptions } = uiStore;
    const { t } = useTranslation();
    const api = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const [newPledge, setNewPledge] = useState(false);
    const [checkedPledges, setCheckedPledges] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const { mutate: deletePledges } = useMutation(
        (ids) => api.post('/pledges/remove/', { donation_ids: ids }),
        {
            onSuccess: () => {
                setDeleteDialog(false);
                enqueueSnackbar(t('Pledges deleted'), { variant: 'success' });
                queryClient.invalidateQueries('pledges');
                setCheckedPledges([]);
                optionsClickOutside();
            },
            onError: () => {
                enqueueSnackbar(t('Error deleting pledges'), { variant: 'error' });
            }
        }
    );


    useEffect(() => {
        setTitle(t('Pledges'));
        setFabAction(() => setNewPledge(true));
        return () => {
            setTitle(null);
            setFabAction(null);
        };
    }, []);

    useEffect(() => {
        addOption({
            key: 'delete-pledges',
            text: `${t('Delete')} ${checkedPledges.length} ${t('pledges')}`,
            icon: <DeleteRounded />,
            onClick: () => setDeleteDialog(true),
            disabled: !checkedPledges.length
        });
        return () => setOptions([]);
    }, [checkedPledges]);

    return (
        <>
            <PledgeList
                checkedPledges={checkedPledges}
                setCheckedPledges={setCheckedPledges}
                checkboxes
            />
            {newPledge && <PledgeDetails
                onClose={() => setNewPledge(false)}
                onUpdate={() => queryClient.invalidateQueries('pledges')}
            />}
            <WarningDialog
                open={deleteDialog}
                onClose={() => setDeleteDialog(false)}
                onDelete={() => deletePledges(checkedPledges)}
                title={t('Delete Pledges')}
                text={t('Are you sure you want to delete these pledges?')}
            />                
        </>
    );
}

export default observer(Pledges)