import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { HiAdjustmentsHorizontal } from 'react-icons/hi2';
import logo from 'img/logo.svg';
import Filters from 'components/specific/Filters';
import LoadingModal from 'components/LoadingModal';
import AddIcon from '@mui/icons-material/Add';
import LoadingComponent from 'components/Loading';
import { Box, Button, Fab, LinearProgress, Typography } from '@mui/material';
import DeleteDialog from 'components/common/DeleteDialog';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import VolunteerActivismRounded from '@mui/icons-material/VolunteerActivismRounded';
import SelectOption from 'components/SelectOption';
import Options from 'components/Options';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { useContacts } from 'hooks/useContacts';
import { contactsStore } from 'stores/ContactsStore';
import { observer } from 'mobx-react-lite';
import NoResultsFound from 'components/general/NoResultsFound';
import useApi from 'hooks/useApi';
import SearchContact from 'components/general/SearchContact';
import Section from 'components/common/Section';
import AddDonorProspect from './AddDonorProspect';
import AddFundraiser from './AddFundraiser';
import { optionsClickOutside } from 'utils/controls';
import ContactList from '../ContactList';
import { uiStore } from 'stores/UiStore';
import i18n from 'i18n';
import { t } from 'i18next';

const simulateClickOutside = () => {
    document.querySelector("#options-menu > div.MuiBackdrop-root.MuiBackdrop-invisible.MuiModal-backdrop").click()
};

const priorities = {
    2: 'High',
    1: 'Medium',
    0: 'Low',
}

export const onExport = (data, closeOptions) => {
    let csvHeader = `${t('Prefix')},${t('First Name')},${t('Last Name')},${t('Suffix')},${t('Description')},${t('Phone Number')},${t('Email')},${t('Connection')},${t('Country')},${t('State')},${t('City')},${t('Street')},${t('House')},${t('Apartment')},${t('Zipcode')},${t('Address Notes')},${t('Contact ID')}`
    if (data?.[0]?.donor) {
        csvHeader += `,${t('Sub Campaign')},${t('Group')},${t('Fundraiser ID')},${t('Fundraiser Name')},${t('Donor Status')},${t('Amount')},${t('Priority')}`
    }
    let csv = data.map(row => {
        let rowArray = [
            row?.prefix,
            row?.first_name,
            row?.last_name,
            row?.suffix,
            row?.description,
            row?.phone_numbers?.map(phone => phone).join('; '),
            row?.email_addresses?.map(email => email).join('; '),
            row?.connections.map(connection => connection?.relationship + ' ' + (connection?.related_to?.display_name || '')).join('; '),
            row.country,
            row.state,
            row.city,
            row.street,
            row.house_number,
            row.apartment_number,
            row.zipcode,
            row.address_notes,
            row?.contact_id,
        ];
        if (row?.donor) {
            const donorFields = [
                row.donor?.sub_campaign?.name,
                row.donor?.group?.name,
                row.donor?.fundraiser?.fundraiser_id,
                row.donor?.fundraiser?.name,
                t(row.donor?.status),
                row.donor?.amount ? row.donor?.amount.toLocaleString(i18n.language, { style: 'currency', currency: row.donor?.currency || 'USD' }) : '',
                row.donor?.priority ? t(priorities[row.donor?.priority]) : ''
            ];
            rowArray = rowArray.concat(donorFields);
        }
        return rowArray.map(item => item && item.toString().replace(/,/g, ".")).join(',').replace(/[\n\r]/g, " ");
    }).join('\n');

    csv = csvHeader + '\n' + csv

    let BOM = '\uFEFF';
    const blob = new Blob([BOM + csv], { type: 'text/csv;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'contacts.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
    if (closeOptions) optionsClickOutside();
};

const ContactsTable = observer(() => {
    const api = useApi();
    const { setFabAction } = uiStore;
    const queryClient = useQueryClient();
    const [searchParams, setSearchParams] = useSearchParams();
    const { currentPage, setCurrentPage, getSort, setSort, setContactId, setSearch, pageSize, setPageSize, setParams, search, contactId, referrer, setReferrer, reset, listType, setListType, setLocation } = contactsStore;
    const [isInitialised, setIsInitialised] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (referrer !== 'contacts') {
            reset()
            setPageSize(localStorage.getItem('contactsPageSize') || 50)
            setListType('contacts');
        }
        setReferrer('');
        setParams(searchParams)
        setIsInitialised(true)
    }, []);

    useEffect(() => setLocation(location.pathname + location.search), [location.search]);

    const [uploading, setUploading] = useState(false);
    const [uploadingMessage, setUploadingMessage] = useState('');
    const [contactsUpdated, setContactsUpdated] = useState(true);
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const pageSizes = [50, 100, 250, 500];
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [filtersAnchorEl, setFiltersAnchorEl] = useState(null);

    const [checkedItems, setCheckedItems] = useState([]);
    const [checkAll, setCheckAll] = useState(false);

    const [filterPopup, setFilterPopup] = useState(false);

    const [addDonorProspectOpen, setAddDonorProspectOpen] = useState(false);
    const [addFundraiserOpen, setAddFundraiserOpen] = useState(false);

    const [campaignAnchorEl, setCampaignAnchorEl] = useState(null);
    const [campaigns, setCampaigns] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [selectListAnchorEl, setSelectListAnchorEl] = useState(null);
    const [selectedList, setSelectedList] = useState(null);
    const [lists, setLists] = useState([]);

    const { contactsQuery, allContactsQuery, fetchAllContacts } = useContacts({ onFetchAllSuccess: onExport, enabled: isInitialised });

    const { data: contactsData, isLoading: contactsLoading, error: contactsError, isFetching: contactsFetching } = contactsQuery;
    const contacts = contactsData?.results || [];
    const { isFetching: allContactsFetching, isError: allContactsError } = allContactsQuery;

    const pageCount = Math.ceil(contactsData?.count / pageSize) || 1;

    useEffect(() => {
        setFabAction(() => navigate('/contacts/new'))
        return () => setFabAction(null);
    }, []);

    const { mutate: deleteContacts } = useMutation(
        () => api.post(`/contacts/delete/`, { contact_ids: checkedItems }),
        {
            onSuccess: () => {
                if (contacts.length === checkedItems.length) {
                    if (currentPage === pageCount && currentPage > 1) {
                        setCurrentPage(currentPage - 1);
                    }
                }
                queryClient.invalidateQueries('contacts');
                enqueueSnackbar(t('Contacts Deleted Successfully'), { variant: 'success' });
                setCheckAll(false);
                setCheckedItems([]);
                simulateClickOutside();
                setDeleteDialogOpen(false)
            },
            onError: (error) => {
                enqueueSnackbar(t('Error deleting contacts'), { variant: 'error' });
            }
        }
    );

    const { mutate: addContactsToCampaign } = useMutation(
        () => api.post(`/campaign-donors-update/add/`, { campaign_id: selectedCampaign.split(',')[0], donor_ids: checkedItems }),
        {
            onSuccess: () => {
                enqueueSnackbar(t('Contacts added to campaign successfully'), { variant: 'success' });
                setCheckAll(false);
                setCheckedItems([]);
                simulateClickOutside();
                setCampaignAnchorEl(null);
                setSelectedCampaign(null);
            },
            onError: (error) => {
                enqueueSnackbar(t('Error adding contacts to campaign'), { variant: 'error' });
            }
        }
    );

    const { mutate: addContactsToSubCampaign } = useMutation(
        () => api.post(`/sub-campaign-donors-update/add/`, { sub_campaign_id: selectedCampaign.split(',')[0], donor_ids: checkedItems }),
        {
            onSuccess: () => {
                enqueueSnackbar(t('Contacts added to sub campaign successfully'), { variant: 'success' });
                setCheckAll(false);
                setCheckedItems([]);
                simulateClickOutside();
                setCampaignAnchorEl(null);
                setSelectedCampaign(null);
            },
            onError: (error) => {
                enqueueSnackbar(t('Error adding contacts to sub campaign'), { variant: 'error' });
            }
        }
    );

    const { mutate: addContactsToGroup } = useMutation(
        (contactGroup) => api.post(`/contacts/lists/add-contacts/`, { contact_ids: checkedItems, contact_list_id: contactGroup }),
        {
            onSuccess: () => {
                enqueueSnackbar(t('Contacts added to group successfully'), { variant: 'success' });
                setCheckAll(false);
                setCheckedItems([]);
                simulateClickOutside();
                setSelectListAnchorEl(null);
                setSelectedList(null);
            },
            onError: (error) => {
                enqueueSnackbar(t('Error adding contacts to group'), { variant: 'error' });
            }
        }
    );

    const { mutate: addDonorProspect, isLoading: addDonorProspectLoading } = useMutation(
        (data) => api.post(`/campaigns/add-donor-prospects`, data),
        {
            onSuccess: () => {
                enqueueSnackbar(t('Donor prospects added successfully'), { variant: 'success' });
                setAddDonorProspectOpen(false);
                simulateClickOutside();
                setCheckAll(false);
                setCheckedItems([]);
            },
            onError: (error) => {
                enqueueSnackbar(t('Error adding donor prospects'), { variant: 'error' });
            }
        }
    );

    const { mutate: addFundraiser, isLoading: addFundraiserLoading } = useMutation(
        (data) => api.post(`/campaigns/add-fundraisers`, data),
        {
            onSuccess: () => {
                enqueueSnackbar(t('Fundraisers added successfully'), { variant: 'success' });
                setAddFundraiserOpen(false);
                simulateClickOutside();
                setCheckAll(false);
                setCheckedItems([]);
            },
            onError: (error) => {
                enqueueSnackbar(t('Error adding fundraisers'), { variant: 'error' });
            }
        }
    );


    const options = [
        {
            text: `${t('Delete')} ${checkedItems.length > 0 ? checkedItems.length : ''} ${t('Contacts')}`,
            icon: <DeleteOutlineRoundedIcon />,
            onClick: () => setDeleteDialogOpen(true),
            disabled: checkedItems.length === 0,
        },
        {
            text: t('Add to Group'),
            icon: <PlaylistAddRoundedIcon />,
            onClick: (e) => setSelectListAnchorEl(e.currentTarget),
            disabled: checkedItems.length === 0,
        },
        {
            text: t('Add Donor Prospect'),
            icon: <CampaignRoundedIcon />,
            onClick: (e) => setAddDonorProspectOpen(true),
            disabled: checkedItems.length === 0,
        },
        {
            text: t('Add as Fundraiser'),
            icon: <VolunteerActivismRounded />,
            onClick: (e) => setAddFundraiserOpen(e.currentTarget),
            disabled: checkedItems.length === 0,
        }
    ];

    useEffect(() => {
        if (allContactsError) {
            enqueueSnackbar(t('Error exporting contacts'), { variant: 'error' });
        }
    }, [allContactsError]);

    useEffect(() => {
        setParams(searchParams);
    }, [searchParams]);

    useEffect(() => {
        if (selectListAnchorEl) {
            api.get('/contacts/lists/').then(res => {
                setLists(res.data);
            }).catch(err => console.error('Error fetching lists:', err));
        }
    }, [selectListAnchorEl]);

    const handleAddToCampaign = () => {
        if (selectedCampaign.split(',')[1] === 'campaign') {
            addContactsToCampaign();
        } else if (selectedCampaign.split(',')[1] === 'sub_campaign') {
            addContactsToSubCampaign();
        }
    };

    const handleSearchChange = (v) => {
        if (v?.contact_id) {
            setSearch(v?.first_name + ' ' + v?.last_name);
            setContactId(v.contact_id);
        } else {
            setSearch(v?.value || '');
        }
    }


    const handleNewContact = () => {
        navigate('/contacts/new')
    }

    const handleUpload = async (e) => {
        e.preventDefault();
        setUploading(true);
        setUploadingMessage(t('uploading-contacts-please-wait'));
        const formData = new FormData();
        const file = document.getElementById('file').files[0];
        formData.append('csv_file', file);
        try {
            const response = await api.post('/contacts/upload_csv/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 202) {
                const task_id = response.data.task_id;
                setUploadingMessage(
                    <span>
                        {t('processing-contacts-this-may-take-a-few-minutes')}
                        <br />
                        {t(t('you-can-close-this-window-and-you-will-be-notified-when-the-process-is-complete'))}
                    </span>
                );
                let count = 0;
                while (count < 300) {
                    const taskResponse = await api.get(`/task-status/${task_id}/`);
                    if (taskResponse.data.state === 'SUCCESS') {
                        alert(t('your-contacts-have-been-uploaded-see-downloaded-file'));
                        let BOM = '\uFEFF';
                        const url = window.URL.createObjectURL(new Blob([BOM + taskResponse.data.result]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'invalid_rows.csv');
                        document.body.appendChild(link);
                        setUploadModalOpen(false);
                        setContactsUpdated(!contactsUpdated);
                        setUploading(false);
                        link.click();
                        break;
                    } else if (taskResponse.data.state === 'FAILURE') {
                        setUploading(false);
                        enqueueSnackbar(t('An error occurred while uploading contacts'), { variant: 'error' });
                        break;
                    } else if (taskResponse.data.state === 'PENDING') {
                        await new Promise(resolve => setTimeout(resolve, 2000));
                        count += 1;
                        continue;
                    } else {
                        setUploading(false);
                        enqueueSnackbar(t('An error occurred while uploading contacts'), { variant: 'error' });
                        break;
                    }
                }
            } else {
                setUploading(false);
                enqueueSnackbar(t('An error occurred while uploading contacts'), { variant: 'error' });
            }
        } catch (error) {
            setUploading(false);
            if (error.response.status === 400) {
                enqueueSnackbar(error.response.data?.message, { variant: 'error' });
            } else {
                enqueueSnackbar(`${t('Error uploading contacts')}:  ${error.message}`, { variant: 'error' });
            }
        }
    };

    const renderUploadModal = () => (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                {/* <!--Background overlay, show/hide based on modal state.--> */}
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                <div className="inline-block align-bottom bg-white rounded-lg text-start overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <form onSubmit={handleUpload}>
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="file">
                                    {t('Upload Contacts File')}
                                </label>
                                <input
                                    type="file"
                                    name="file"
                                    id="file"
                                    accept=".xlsx, .csv"
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </div>
                            <a
                                href={`https://fiels.donbar.org/app/csv-templates/contacts-template-${i18n.language}.csv`}
                                download
                                className="text-blue-900 underline"
                            >
                                {t('Download Contacts Template')}
                            </a>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                                type="submit"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ms-3 sm:w-auto sm:text-sm"
                            >
                                {t('Upload')}
                            </button>
                            <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ms-3 sm:w-auto sm:text-sm"
                                onClick={() => setUploadModalOpen(false)}
                            >
                                {t('Cancel')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

    const renderSearchBar = () => (
        <div>
            <Section>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '1.75rem',
                        alignItems: 'center',
                    }}
                >
                    <div className="w-1/2 min-w-[24rem] flex items-center">
                        <div className="w-full">
                            <SearchContact
                                placeholder={t('Search by name, address, tag, and more') + '...'}
                                freeSolo
                                onChange={(v) => handleSearchChange(v)}
                                value={search}
                            />
                        </div>
                    </div>
                    <div className="flex items-center">
                        <select
                            className=""
                            value={pageSize}
                            onChange={(e) => setPageSize(e.target.value)}
                        >
                            {pageSizes.map((size) => (
                                <option key={size} value={size}>
                                    {`${size} ${t('Contacts Per Page')}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex items-center">
                        <button className="ms-2 text-xl text-custom-blue"
                            onClick={
                                (e) => {
                                    setFilterPopup(!filterPopup)
                                    setFiltersAnchorEl(e.currentTarget)
                                }
                            }
                        >
                            <HiAdjustmentsHorizontal />
                        </button>
                    </div>
                    <Filters
                        filtersAnchorEl={filtersAnchorEl}
                        setFiltersAnchorEl={setFiltersAnchorEl}
                        filterSet='contacts'
                    />
                </Box>
            </Section>
            <div className='relative bottom-6 w-[calc(100%-2rem)] justify-center mx-auto'>
                {contactsFetching ? <LinearProgress sx={{ height: '2px' }} /> : <div className='h-0.5' />}
            </div>
        </div>
    );

    const renderTable = () => (
        <ContactList
            contacts={contacts}
            totalPages={pageCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            getSort={getSort}
            setSort={setSort}
            checkedContacts={checkedItems}
            setCheckedContacts={setCheckedItems}
        />
    );

    return (
        <div>
            <LoadingModal open={uploading} title={t("Uploading Contacts")} message={uploadingMessage} />
            <LoadingModal open={allContactsFetching} title={t("Exporting Contacts")} message={
                t('Exporting all contacts, this may take a few minutes')
            } />
            <div className='mb-10'>
                <div className="flex float-end items-center">
                    <button
                        className="border-2 border-blue-900 text-blue-900 rounded-full px-4 py-1 mx-2 hover:bg-violet-200"
                        onClick={() => setUploadModalOpen(true)}
                    >
                        {t('Upload Contacts')}
                    </button>
                    <button
                        className="border-2 border-blue-900 text-blue-900 rounded-full px-4 py-1 mx-2 hover:bg-violet-200"
                        onClick={fetchAllContacts}
                    >
                        {t('Export')}
                    </button>
                    <SelectOption
                        title={t('Add to Campaign')}
                        text={t('Select a campaign to add these contacts to')}
                        optionAnchorEl={campaignAnchorEl}
                        setOptionAnchorEl={setCampaignAnchorEl}
                        selectedOption={selectedCampaign}
                        setSelectedOption={setSelectedCampaign}
                        options={
                            Object.entries(campaigns).flatMap(([key, campaign]) => {
                                const mainCampaign = {
                                    id: `${key},campaign`,
                                    name: campaign.name
                                };

                                const subCampaigns = Object.entries(campaign.sub_campaigns).map(([subKey, subName]) => ({
                                    id: `${subKey},sub_campaign`,
                                    name: <span className="ms-4">{subName}</span>
                                }));

                                return [mainCampaign, ...subCampaigns];
                            })
                        }
                        handleAddToOption={handleAddToCampaign}
                        placeholder={t('Select Campaign')}
                    />
                    <SelectOption
                        title={t('Select Group')}
                        text={t('Select a group to add the selected contacts')}
                        optionAnchorEl={selectListAnchorEl}
                        setOptionAnchorEl={setSelectListAnchorEl}
                        selectedOption={selectedList}
                        setSelectedOption={setSelectedList}
                        options={lists}
                        handleAddToOption={addContactsToGroup}
                        placeholder={t('Select group')}
                    />
                    <DeleteDialog
                        open={deleteDialogOpen}
                        onClose={() => setDeleteDialogOpen(false)}
                        onDelete={deleteContacts}
                        title={`${t('Delete')} ${checkedItems.length > 0 ? checkedItems.length : ''} ${t('Contacts')}`}
                        text={`${t('Are you sure you want to delete these contacts')}?`}
                    />
                    <Options options={options} />
                </div>
                <Typography variant="h1">{t('Contacts')}</Typography>
            </div>
            {renderSearchBar()}
            {contactsLoading ? (<LoadingComponent className='h-96' />
            ) : (
                <>
                    {contacts.length === 0 ? (
                        (searchParams.size || search) ? (
                            <>
                                <NoResultsFound
                                    title={t('No Contacts Found')}
                                    subtitle={t("We couldn't find any contacts matching your search or filter.")}
                                    Icon={SearchOffRoundedIcon}
                                >
                                    <Button variant="outlined" onClick={() => {
                                        setSearch('');
                                        setSearchParams({});
                                    }}>
                                        {t('Clear Filters')}
                                    </Button>
                                </NoResultsFound>
                                <div className="flex justify-end">
                                    <div className='fixed bottom-10 text-end mx-10'>
                                        <Fab
                                            color='primary'
                                            onClick={handleNewContact}
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <NoResultsFound
                                title={t('Welcome to Donbar!')}
                                subtitle={t('Start by adding your first contact.')}
                                image={<img className='h-20 mx-auto' src={logo} alt="logo" />}
                            >
                                <Button
                                    variant='contained'
                                    color='primary'
                                    sx={{ borderRadius: 99, height: 48, width: 48, minWidth: 48 }}
                                    onClick={handleNewContact}
                                >
                                    <AddIcon />
                                </Button>
                            </NoResultsFound>
                        )
                    ) : (
                        renderTable()
                    )}
                </>
            )}
            {uploadModalOpen && renderUploadModal()}
            <AddDonorProspect
                open={addDonorProspectOpen}
                onClose={() => setAddDonorProspectOpen(false)}
                onConfirm={(data) => addDonorProspect({ ...data, contact_ids: checkedItems })}
                processing={addDonorProspectLoading}
            />
            <AddFundraiser
                open={addFundraiserOpen}
                onClose={() => setAddFundraiserOpen(false)}
                onConfirm={(data) => addFundraiser({ ...data, contact_ids: checkedItems })}
                processing={addFundraiserLoading}
            />
        </div>
    );
})

export default ContactsTable;