import { useQueries, useQuery } from 'react-query';
import useApi from './useApi';
import { mainStore } from 'stores/MainStore';
import { useEffect } from 'react';
import { log } from '../utils/devUtils';

export const useInitialData = () => {
    const api = useApi();
    const { orgId, settings, subscription, organizations } = mainStore;

    const fetchOrganizations = async () => {
        const { data } = await api.get('/organizations');
        return data;
    };

    const { isLoading: orgLoading } = useQuery('organizations', fetchOrganizations, {
        onSuccess: (data) => {
            mainStore.setOrganizations(data);
        },
        refreshOnMount: false,
        refreshOnWindowFocus: false,
        cacheTime: 0,
        staleTime: Infinity,
    });


    const settingsRes = useQueries([
        {
            queryKey: ['settings', orgId],
            queryFn: () => api.get(`/settings`).then(res => res.data),
            onSuccess: (data) => mainStore.setSettings(data),
            enabled: !!orgId,
            refreshOnMount: false,
            refreshOnWindowFocus: false,
            cacheTime: 0,
            staleTime: Infinity,
        },
        {
            queryKey: ['subscription', orgId],
            queryFn: () => api.get('/subscription/').then(res => res.data),
            onSuccess: (data) => mainStore.setSubscription(data),
            enabled: !!orgId,
            refreshOnMount: false,
            refreshOnWindowFocus: false,
            cacheTime: 0,
            staleTime: Infinity,
        },
        {
            queryKey: ['contactViewCards'],
            queryFn: () => api.get('/settings/contact-cards').then(res => res.data),
            enabled: !!orgId,
            refreshOnMount: false,
            refreshOnWindowFocus: false,
        }
    ]);

    const appLoading = settingsRes.some(res => res.isLoading) || orgLoading || !settings?.language || !subscription;

    useEffect(() => {
        mainStore.setAppLoaded(!appLoading || organizations?.length === 0);
        log("useInitialData", "appLoading", appLoading);
    }, [appLoading, organizations]);

    useQuery('emailPlans', () => api.get('/plans/email').then(res => res.data), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: Infinity,
        cacheTime: 0,
        enabled: !!orgId,
        onSuccess: (data) => {
            mainStore.setEmailPlans(data);
        },
    });
};