import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import Connections from "components/ConnectionInput";
import TagInput from "components/TagInput";
import useApi from "hooks/useApi";
import { values } from "mobx";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import ConnectionInput from "./ConnectionInput";
import { useSnackbar } from "notistack";
import { useDebounce } from "use-debounce";

function ContactForm({ contact, contactMutation, backLocation }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const api = useApi();
    const navigate = useNavigate();
    const [phoneNumbers, setPhoneNumbers] = useState(contact?.phone_numbersa || []);
    const [emails, setEmails] = useState(contact?.email_addresses || []);
    const [connections, setConnections] = useState(contact?.connections || []);
    const [fieldName, setFieldName] = useState(null);

    const queryClient = useQueryClient();

    const {
        clearErrors,
        formState: { dirtyFields, errors, isDirty, isValid, isSubmitted, isSubmitting },
        register,
        unregister,
        watch,
        getValues,
        handleSubmit,
        control,
        setError,
        reset,
    } = useForm({
        defaultValues: contact,
    });

    const { mutate, isLoading } = contactMutation;

    const formData = watch(['country', 'city', 'state', 'street', 'neighborhood']);

    const getAutocompleteParams = () => {
        const formAcData = getValues();
        const params = {
            country__icontains: formAcData?.country || undefined,
            city__icontains: formAcData?.city || undefined,
            state__icontains: formAcData?.state || undefined,
            street__icontains: formAcData?.street || undefined,
            neighborhood__icontains: formAcData?.neighborhood || undefined,
        };
        return { params };
    }

    const autocompleteParams = useMemo(() => {
        return getAutocompleteParams();
    }, [formData]);
    
    const debouncedAutocompletParams = useDebounce(autocompleteParams, 250);

    const AutocompleteQuery = useQuery(["autocomplete", fieldName, debouncedAutocompletParams],
        () => api.get(`/contacts/field-search/${fieldName}`, autocompleteParams).then((res) => res.data).then((data) => ({ [fieldName]: data })),
        {
            enabled: !!fieldName,
            keepPreviousData: true,
        }
    );

    const { data: autocompleteData, remove: removeAutocompleteData } = AutocompleteQuery;
    const fieldAutoCompleteData = autocompleteData?.[fieldName] || [];

    // useEffect(() => {
    //     if (isSubmitted) {
    //         clearErrors();
    //     }
    // }, [isSubmitted]);

    useEffect(() => setPhoneNumbers(contact?.phone_numbers || []), [contact?.phone_numbers]);
    useEffect(() => setEmails(contact?.email_addresses || []), [contact?.email_addresses]);
    useEffect(() => setConnections(contact?.connections || []), [contact?.connections]);

    const isEmailsDirty = useMemo(() => JSON.stringify(emails) !== JSON.stringify(contact?.email_addresses), [emails]);
    const isPhoneNumbersDirty = useMemo(() => JSON.stringify(phoneNumbers) !== JSON.stringify(contact?.phone_numbers), [phoneNumbers]);
    const isConnectionsDirty = useMemo(() => JSON.stringify(connections) !== JSON.stringify(contact?.connections) || true, [connections]);

    // useEffect(() => console.log({ connections }), [connections]);

    const isFormDirty = useMemo(() => {
        return isDirty || isEmailsDirty || isPhoneNumbersDirty || isConnectionsDirty;
    }, [isEmailsDirty, isPhoneNumbersDirty, isDirty, isConnectionsDirty]);

    const getUpdateData = (data) => {
        const dirtyValues = Object.keys(data).reduce((acc, key) => {
            if (dirtyFields[key]) {
                acc[key] = data[key] || null;
            }
            return acc;
        }, {});
        if (isEmailsDirty) {
            dirtyValues.email_addresses = emails;
        }
        if (isPhoneNumbersDirty) {
            dirtyValues.phone_numbers = phoneNumbers;
        }
        if (isConnectionsDirty) {
            const parsedConnections = connections.map((connection) => {
                const acc = {};
                acc.id = connection.id;
                acc.relationship = connection.relationship;
                acc.related_to_id = connection.related_to?.id;
                return acc;
            });
            dirtyValues.connections = parsedConnections;
        }
        return dirtyValues;
    }

    const handleSave = (callback) => {
        const data = getValues();
        mutate(getUpdateData(data),
            {
                onSuccess: (data) => {
                    reset(data);
                    if (typeof callback === "function") {
                        callback(data);
                    }
                },
                onError: (error) => {
                    if (error.response?.status === 400) {
                        const errors = error.response.data;
                        Object.keys(errors).forEach((key) => {
                            setError(key, {
                                type: "manual",
                                message: errors[key],
                            });
                        });
                    }
                    enqueueSnackbar(t("Error saving contact"), { variant: "error" });
                },
            }
        );
    };

    // const handleSave = (callback) => {
    //     if (typeof callback === "function") {
    //         setSubmitCallback(callback);
    //     } else {
    //         setSubmitCallback(null);
    //     }
    //     document.getElementById("contact-form").dispatchEvent(new Event("submit"));
    // }

    // const save = (d) => {
    //     console.log(d);
    // }


    return (
        // <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <form>
            <Stack spacing={4}>
                <Box>
                    <Typography variant="h2">{t("Name")}</Typography>
                    <Stack direction="row" spacing={4}>
                        <TextField
                            fullWidth
                            variant="standard"
                            label={t("Prefix")}
                            placeholder={t("Prefix")}
                            {...register("prefix")}
                            error={!!errors.prefix}
                            helperText={errors.prefix?.message}
                        />
                        <TextField
                            fullWidth
                            variant="standard"
                            label={t("First Name")}
                            placeholder={t("First Name")}
                            {...register("first_name")}
                            error={!!errors.first_name}
                            helperText={errors.first_name?.message}
                        />
                        <TextField
                            fullWidth
                            variant="standard"
                            label={t("Last Name")}
                            placeholder={t("Last Name")}
                            {...register("last_name")}
                            error={!!errors.last_name}
                            helperText={errors.last_name?.message}
                        />
                        <TextField
                            fullWidth
                            variant="standard"
                            label={t("Suffix")}
                            placeholder={t("Suffix")}
                            {...register("suffix")}
                            error={!!errors.suffix}
                            helperText={errors.suffix?.message}
                        />
                    </Stack>
                </Box>
                <Box>
                    <Typography variant="h2">{t("Comunication")}</Typography>
                    <Stack direction="row" spacing={4}>
                        <TagInput
                            label={t("Phone Number")}
                            placeholder={t("Phone Number")}
                            tags={phoneNumbers}
                            setTags={setPhoneNumbers}
                        />
                        <TagInput
                            label={t("Email")}
                            placeholder={t("Email")}
                            tags={emails}
                            setTags={setEmails}
                        />
                    </Stack>
                </Box>
                <Box>
                    <Typography variant="h2">{t("Address")}</Typography>
                    <Stack spacing={4}>
                        <Stack direction="row" spacing={4}>
                            <Controller
                                name="street"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        fullWidth
                                        freeSolo
                                        disableClearable
                                        options={fieldAutoCompleteData || []}
                                        getOptionLabel={(option) => option}
                                        onFocus={() => {
                                            removeAutocompleteData();
                                            setFieldName("street");
                                        }}
                                        onBlur={() => setFieldName(null)}
                                        value={field.value}
                                        onChange={(_, value) => field.onChange(value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...register("street")}
                                                variant="standard"
                                                label={t("Street")}
                                                placeholder={t("Street")}
                                                error={!!errors.street}
                                                helperText={errors.street?.message}
                                            />
                                        )}
                                    />
                                )}
                            />
                            <TextField
                                fullWidth
                                variant="standard"
                                label={t("House")}
                                placeholder={t("House")}
                                {...register("house_number")}
                                error={!!errors.house_number}
                                helperText={errors.house_number?.message}
                            />
                            <TextField
                                fullWidth
                                variant="standard"
                                label={t("Apt.")}
                                placeholder={t("Apt.")}
                                {...register("apartment_number")}
                                error={!!errors.apartment_number}
                                helperText={errors.apartment_number?.message}
                            />
                            <Controller
                                name="neighborhood"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        fullWidth
                                        freeSolo
                                        disableClearable
                                        options={fieldAutoCompleteData || []}
                                        getOptionLabel={(option) => option}
                                        onFocus={() => setFieldName("neighborhood")}
                                        onBlur={() => setFieldName(null)}
                                        value={field.value}
                                        onChange={(_, value) => field.onChange(value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...register("neighborhood")}
                                                fullWidth
                                                variant="standard"
                                                label={t("Neighborhood")}
                                                placeholder={t("Neighborhood")}
                                                error={!!errors.neighborhood}
                                                helperText={errors.neighborhood?.message}
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name="city"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        fullWidth
                                        freeSolo
                                        disableClearable
                                        options={fieldAutoCompleteData || []}
                                        getOptionLabel={(option) => option}
                                        onFocus={() => setFieldName("city")}
                                        onBlur={() => setFieldName(null)}
                                        value={field.value}
                                        onChange={(_, value) => field.onChange(value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...register("city")}
                                                fullWidth
                                                variant="standard"
                                                label={t("City")}
                                                placeholder={t("City")}
                                                error={!!errors.city}
                                                helperText={errors.city?.message}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack direction="row" spacing={4}>
                            <Controller
                                name="state"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        fullWidth
                                        freeSolo
                                        disableClearable
                                        options={fieldAutoCompleteData || []}
                                        getOptionLabel={(option) => option}
                                        onFocus={() => setFieldName("state")}
                                        onBlur={() => setFieldName(null)}
                                        value={field.value}
                                        onChange={(_, value) => field.onChange(value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...register("state")}
                                                fullWidth
                                                variant="standard"
                                                label={t("State")}
                                                placeholder={t("State")}
                                                error={!!errors.state}
                                                helperText={errors.state?.message}
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name="country"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        fullWidth
                                        freeSolo
                                        disableClearable
                                        options={fieldAutoCompleteData || []}
                                        getOptionLabel={(option) => option}
                                        onFocus={() => setFieldName("country")}
                                        onBlur={() => setFieldName(null)}
                                        value={field.value}
                                        onChange={(_, value) => field.onChange(value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...register("country")}
                                                fullWidth
                                                variant="standard"
                                                label={t("Country")}
                                                placeholder={t("Country")}
                                                {...register("country")}
                                                error={!!errors.country}
                                            />
                                        )}
                                    />
                                )}
                            />
                            <TextField
                                fullWidth
                                variant="standard"
                                label={t("Zip Code")}
                                placeholder={t("Zip Code")}
                                {...register("zip_code")}
                                error={!!errors.zip_code}
                            />
                            <TextField
                                fullWidth
                                variant="standard"
                                label={t("Address Notes")}
                                placeholder={t("Address Notes")}
                                {...register("address_notes")}
                                error={!!errors.address_notes}
                                helperText={errors.address_notes?.message}
                            />
                        </Stack>
                    </Stack>
                </Box>
                <Box>
                    <Typography variant="h2">{t("More Details")}</Typography>
                    <ConnectionInput connections={connections} setConnections={setConnections} />
                    <TextField
                        fullWidth
                        variant="standard"
                        label={t("Description")}
                        multiline
                        maxRows={8}
                        placeholder={t("Description")}
                        {...register("description")}
                        error={!!errors.description}
                        helperText={errors.description?.message}
                        sx={{ '& .MuiInputBase-root': { mt: '1.5rem' } }}
                    />
                </Box>
                <Box>
                    <Stack sx={{ mt: '2rem' }} direction='row' justifyContent='center' spacing={2}>
                        <LoadingButton
                            loading={isLoading}
                            variant="outlined"
                            disabled={!isFormDirty}
                            size="large"
                            // type="submit"
                            sx={{ borderRadius: 99, mt: '2rem' }}
                            // onClick={handleSave}
                            onClick={handleSubmit(handleSave)}
                        // name="action"
                        // value="save"
                        >
                            {t("Save")}
                        </LoadingButton>
                        <LoadingButton
                            loading={isLoading}
                            variant="contained"
                            disabled={!isFormDirty}
                            size="large"
                            // type="submit"
                            sx={{ borderRadius: 99, mt: '2rem' }}
                            // onClick={() => handleSave(() => navigate(backLocation || "/contacts"))}
                            onClick={handleSubmit(() => handleSave(() => navigate(backLocation || "/contacts")))}
                        // name="action"
                        // value="save-close"
                        >
                            {t("Save and Close")}
                        </LoadingButton>
                    </Stack>
                </Box>
            </Stack>
        </form>
    )
}

export default ContactForm;