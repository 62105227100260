import { Button, Chip, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingComponent from "components/Loading";
import SearchContact from "../../../components/general/SearchContact";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { LoadingButton } from "@mui/lab";
import { Form } from "react-router-dom";
import { Input } from "postcss";
import useApi from "hooks/useApi";

function TaskDetails({ open, onClose, newTask, id, setUpdated }) {
    const { t } = useTranslation();
    const api = useApi();
    const [loading, setLoading] = useState(true);
    const [taskTypes, setTaskTypes] = useState([]);

    const [searchResultsWithInitialValue, setSearchResultsWithInitialValue] = useState();
    const [contact, setContact] = useState('');
    const [contactId, setContactId] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [dueDate, setDueDate] = useState(null);
    const [taskType, setTaskType] = useState('');
    const [completed, setCompleted] = useState(false);
    const [data, setData] = useState({ 'task_id': id });

    const [saving, setSaving] = useState(false);

    const handleInputChange = (key, val) => {
        setData({ ...data, [key]: val });
    }

    useEffect(() => {
        if (!open) return;
        setSaving(false);
        setContactId('');
        setContact('');
        setTitle('');
        setDescription('');
        setDueDate(null);
        setTaskType('');
        setCompleted(false);
        setSearchResultsWithInitialValue(null);
        api.get('/tasks/task-types/').then(res => {
            setTaskTypes(res.data);
        }).catch(err => {
            console.log(err);
        })

        if (newTask) return setLoading(false);
        api.get(`/tasks/details/${id}`).then(res => {
            setTitle(res.data.title);
            setDescription(res.data?.description);
            setDueDate(new Date(res.data?.due_date).toLocaleInput());
            // setDueDate(new Date(res.data?.due_date).toISOString().slice(0, 16));
            setTaskType(res.data?.task_type?.id || '');
            setCompleted(res.data?.completed_date ? true : false);
            setContactId(res.data?.contact?.id || '');
            setSearchResultsWithInitialValue((res.data?.contact));
            setContact((res.data?.contact?.first_name || '') + (res.data?.contact?.first_name ? ' ' : '') + (res.data?.contact?.last_name || ''));
            setLoading(false);
        }).catch(err => {
            console.log(err);
        })
    }, [id, open])

    useEffect(() => {
        console.log({ searchResultsWithInitialValue });
    }, [searchResultsWithInitialValue])

    const handleComplete = () => {
        const updateCompleted = (c) => {
            api.put('/tasks/create/', {
                task_id: id,
                completed_date: c
            }).then(res => {
                setUpdated()
            }).catch(err => {
                console.log(err);
            })
        }

        if (!completed) {
            // const date = new Date().toISOString().slice(0, 16)
            updateCompleted(new Date().toISOString().slice(0, 16));
        } else {
            updateCompleted(null);
        }

        setCompleted(!completed);
    }


    const handleSave = () => {
        setSaving(true);
        if (newTask) {
            api.post('/tasks/create/', {
                title: title,
                description: description,
                due_date: dueDate !== '' ? dueDate : null,
                task_type: taskType || null,
                contact: contactId || null,
            }).then(res => {
                setSaving(false);
                setUpdated()
                onClose(true);
            }).catch(err => {
                console.log(err);
                setSaving(false);
            })
        } else {
            api.put('/tasks/create/', data).then(res => {
                setSaving(false);
                setUpdated()
                onClose(true);
            }).catch(err => {
                console.log(err);
                setSaving(false);
            })
        }
    }


    if (!open) return null;

    return (
        <div>{open && !loading &&
            <Modal open={open} onClose={() => onClose(false)}>
                <div className="bg-white text-start w-[40rem] absolute text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-3xl p-4" >
                    <div className="flex justify-end">
                        <IconButton
                            onClick={() => onClose(false)}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    </div>
                    <div className="px-8 pb-8">
                        {loading ? <LoadingComponent /> :
                            <div className="flex flex-col gap-y-8">
                                <h2 className="text-2xl text-center font-bold mb-4">{`${newTask ? t('Add') : t('Edit')} ${t('Task')}`}</h2>
                                <div className="flex justify-end">
                                    {!newTask &&
                                        <Chip
                                            label={completed ? t('Done') : t('Mark as Done')}
                                            color={completed ? 'primary' : 'secondary'}
                                            onDelete={handleComplete}
                                            onClick={handleComplete}
                                            deleteIcon={<DoneRoundedIcon />}
                                        />}
                                </div>
                                <fieldset className="flex gap-4 w-full">
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        label={t('Title')}
                                        value={title}
                                        onChange={(e) => {
                                            setTitle(e.target.value)
                                            handleInputChange('title', e.target.value)
                                        }}
                                    />
                                    <div className="w-full">
                                        <SearchContact
                                            onChange={(e) => {
                                                setContactId(e?.id || '')
                                                setContact((e?.first_name || '') + (e?.first_name ? ' ' : '') + (e?.last_name || ''))
                                                handleInputChange('contact', e?.id || null)
                                            }}
                                            value={contact}
                                            placeholder={t('Contact')}
                                            label={t('Contact')}
                                            initialValue={searchResultsWithInitialValue}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset className="flex gap-4">
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        type="datetime-local"
                                        label={t('Due Date')}
                                        InputLabelProps={{ shrink: true }}
                                        value={dueDate}
                                        onChange={(e) => {
                                            setDueDate(e.target.value || null)
                                            handleInputChange('due_date', e.target.value || null)
                                        }}
                                    />
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel id="task-type-label">{t('Task Type')}</InputLabel>
                                        <Select
                                            labelId="task-type-label"
                                            id="task-type"
                                            value={taskType}
                                            onChange={(e) => {
                                                setTaskType(e.target.value)
                                                handleInputChange('task_type', e.target.value || null)
                                            }}
                                            label={t('Task Type')}
                                        >
                                            <MenuItem value="">{t('None')}</MenuItem>
                                            {taskTypes.map((taskType, index) => (
                                                <MenuItem key={index} value={taskType.id}>{taskType.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </fieldset>
                                <fieldset className="flex gap-4">
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        rows={4}
                                        multiline
                                        label={t('Description')}
                                        value={description}
                                        onChange={(e) => {
                                            setDescription(e.target.value)
                                            handleInputChange('description', e.target.value)
                                        }}
                                    />
                                </fieldset>
                                <div className="mt-4 text-center">
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        loading={saving}
                                        sx={{ borderRadius: 99 }}
                                        onClick={handleSave}
                                    >
                                        {newTask ? t('Add') : t('Save')}
                                    </LoadingButton>
                                </div>
                            </div>}
                    </div>
                </div>
            </Modal>}
        </div>
    )
}

export default TaskDetails;