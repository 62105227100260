import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import H1 from "components/common/Typography/H1";
import LoadingComponent from "components/Loading";
import { formatDate, formatNumber } from "utils";
import { Link } from "react-router-dom";
import { Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import useApi from "hooks/useApi";

function CampaignsTable() {
    const [campaigns, setCampaigns] = useState([]);
    const api = useApi();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        api.get('/campaigns/').then(res => {
            setCampaigns(res.data);
            setLoading(false);
        }).catch(err => {
            console.error(err);
        });
    }, []);

    const renderTable = () => (
        <div className="container mx-auto text-start">
            <table className="table-auto border-collapse w-full mb-10">
                <thead>
                    <tr className="rounded-lg text-sm font-medium text-gray-700">
                        <th className="px-4 py-2">
                            <div className='flex gap-2'>{t('Name')}
                            </div>
                        </th>
                        <th className="px-4 py-2">
                            <div className='flex gap-2'>{t('Date')}
                            </div>
                        </th>
                        <th className="px-4 py-2">
                            <div className='flex gap-2'>{t('Total Raised')}
                            </div>
                        </th>
                        <th className="px-4 py-2">{t('Goal')}</th>
                        <th className="px-4 py-2">
                            <div className='flex gap-2'>{t('Donations')}
                            </div>
                        </th>
                        <th className="px-4 py-2">
                            <div className='flex gap-2'>{t('Description')}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody className="text-sm font-normal text-gray-700 bg-white rounded-2xl shadow-sm">
                    {campaigns.map((campaign, index) => (
                        <tr key={index} className="hover:bg-gray-100 border-b border-gray-200 py-10">
                            <td className={`px-4 py-4 ${index === campaigns.length - 1 && 'rounded-bs-2xl'} ${index === 0 && 'rounded-ts-2xl'}`}>
                                <Link to={`/donations/campaigns/${campaign.name}`} className="underline underline-offset-4 hover:text-blue-900">
                                    {campaign.name}
                                </Link>
                            </td>
                            <td className="px-4 py-4">{formatDate(campaign.end_date)}</td>
                            <td className="px-4 py-4">{campaign.donations_amount && parseInt(campaign.donations_amount).toLocaleString(undefined)}</td>
                            <td className="px-4 py-4">{campaign.goal && parseInt(campaign.goal).toLocaleString(undefined)}</td>
                            <td className="px-4 py-4">{parseInt(campaign.donations_count).toLocaleString(undefined)}</td>
                            <td className={`px-4 py-4 ${index === campaigns.length - 1 && 'rounded-be-2xl'} ${index === 0 && 'rounded-te-2xl'}`}>{campaign.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )

    if (loading) return

    return (
        <>
            <H1>{t('Campaigns')}</H1>
            {campaigns.length > 0 ? renderTable() :
                <div className="text-center text-gray-500 text-2xl mt-10">
                    {t('No campaigns found')}
                </div>}
            <div className='fixed bottom-10 end-10'>
                <Fab color='primary' component={Link} to='/donations/campaigns/new'>
                    <AddIcon />
                </Fab>
            </div>
        </>
    )
}

export default CampaignsTable