import { debounce } from "lodash";
import { Autocomplete, CircularProgress, ListItem, ListItemText, TextField, createFilterOptions } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { contactsStore } from "stores/ContactsStore";
import useApi from "hooks/useApi";
import { addressString } from "views/Contacts/Contacts/ContactList";

function SearchContact({ onChange, value, placeholder, label, setContactId, freeSolo, variant, size, initialValue, ...props }) {
    const { t } = useTranslation();
    const api = useApi();
    const { filters } = contactsStore;
    const [searchValue, setSearchValue] = useState('');
    const [debouncedValue, setDebouncedValue] = useState(searchValue);
    // const [searchResults, setSearchResults] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [contact, setContact] = useState(value);

    const filterOptions = createFilterOptions({
        matchFrom: 'any', // Match from anywhere in the string
        stringify: (option) => {
            // This is where you return the string that will be used to match against the input
            return `${option.display_name} ${option.contact_id}`
        },
    });

    const getSearchAutocompleteParams = () => {

        const params = {
            page: 1,
            status: 1,
            full_name__icontains: searchValue,
        };

        return params;
    }

    const searchAutocomplete = useQuery(
        ['searchAutocomplete', debouncedValue],
        () => api.get('/contacts/', { params: getSearchAutocompleteParams() }),
        {
            enabled: !!debouncedValue,
        }
    );

    const searchResults = searchAutocomplete.data?.data?.results || [];

    useEffect(() => {
        const debouncer = debounce((value) => {
            setDebouncedValue(value);
        }, 200);
        debouncer(searchValue);
        return () => debouncer.cancel();
    }, [searchValue]);

    const handleContactChange = (e) => {
        if (e?.id) {
            if (setContactId) {
                setContactId(e?.id);
            }
            setContact(e);
        } else {
            if (setContactId) {
                setContactId('');
            }
            setContact('');
        }
        onChange(e)
    }

    const isOptionEqualToValue = (option, value) => option?.id === value?.id;

    return (
        <Autocomplete
            clearOnEscape
            options={searchResults}
            loading={searchAutocomplete.isLoading}
            open={searchOpen}
            autoHighlight={false}
            openOnFocus={false}
            freeSolo={freeSolo}
            autoSelect={searchValue !== '' && !freeSolo}
            size={size}
            onOpen={() => {
                setSearchOpen(true)
                setSearchValue('')
            }}
            onClose={() => setSearchOpen(false)}
            getOptionLabel={(option) => (typeof option === 'string') ? option : `${option.display_name} (${option.contact_id})`}
            renderOption={(props, option) => (
                <ListItem dense key={option.contact_id} {...props}>
                    <ListItemText
                        primary={`${option.display_name} (${option.contact_id})`}
                        secondary={addressString(option) || '' + ((addressString(option) && option?.connections) ? ' • ' : '') + `${option?.connections?.[0]?.relationship || ''} ${option?.connections?.[0]?.related_to?.name || ''}`
                        }
                    />
                </ListItem>
            )}
            // onInputChange={(e, newValue) => {
            //     console.log('input changed', newValue);
            // }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    variant={variant || "standard"}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    onChange={(e) => {
                        setSearchValue(e.target.value);
                        handleContactChange(e.target.value);
                    }}
                    helperText={props?.helperText}
                    error={props?.error}
                />}
            filterOptions={filterOptions}
            popupIcon={null}
            filterSelectedOptions={false}
            value={contact}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    newValue = { value: newValue, first_name: newValue, last_name: '', contact_id: '' };
                }
                handleContactChange(newValue)
            }}
            isOptionEqualToValue={isOptionEqualToValue}
        />
    );
}

export default SearchContact;