import { Autocomplete, Button, Card, CardContent, CircularProgress, Divider, FormControl, Grid, Icon, IconButton, InputLabel, ListSubheader, MenuItem, Select, TextField, TextareaAutosize, Tooltip, Typography } from "@mui/material";
import H1 from "components/common/Typography/H1";
import Box from "components/common/Box";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import TagInput from "../../../../components/TagInput";
import { useCallback, useEffect, useState } from "react";
import EmailEditor from "./EmailEditor/Editor";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Modal from "components/common/Modal";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEditorContext } from "easy-email-pro-theme";
import { log } from "../../../../utils/devUtils";
import mjml from "mjml-browser";
import { EditorCore } from "easy-email-pro-core";
import { useEditorProps } from "easy-email-pro-editor";
import isEmpty from 'lodash/isEmpty';
import { Validate, removeExtraSpaces } from "../../../../components/utils/utils";
import { InView } from "react-intersection-observer";
import { enqueueSnackbar, useSnackbar } from "notistack";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import WarningDialog from "components/common/DeleteDialog";
import Options from "components/Options";
import UpdateEmailPlan from "views/Settings/Billing/UpdateEmailPlan";
import useApi from "hooks/useApi";

const limit = 50;

function EmailView() {
    const { id } = useParams();
    const api = useApi();
    const { i18n, t } = useTranslation();
    const { universalElementSetting } = useEditorProps();
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [openUpgradePlan, setOpenUpgradePlan] = useState(false);

    const [EmailEditorOpen, setEmailEditorOpen] = useState(false);
    const [selectEmailTemplateOpen, setSelectEmailTemplateOpen] = useState(false);
    const [recipients, setRecipients] = useState([]);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [templatesLoading, setTemplatesLoading] = useState(true);

    const [testEmailOpen, setTestEmailOpen] = useState(false);
    const [testEmail, setTestEmail] = useState('');
    const [sendingTestEmail, setSendingTestEmail] = useState(false);

    const [header, setHeader] = useState('');
    const [email, setEmail] = useState({});
    const [fromEmail, setFromEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [template, setTemplate] = useState({});
    const [image, setImage] = useState('');
    const [body, setBody] = useState('');
    const [language, setLanguage] = useState(i18n.language);
    const [fromEmails, setFromEmails] = useState([]);

    const [recipientsInputValue, setRecipientsInputValue] = useState('');
    const [recipientsOptions, setRecipientsOptions] = useState([]);
    const [reload, setReload] = useState(false);

    const [optionsOffset, setOptionsOffset] = useState(0);
    const [moreOptions, setMoreOptions] = useState(true);
    const [optionsLoading, setOptionsLoading] = useState(false);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [warningParams, setWarningParams] = useState({});

    const options = [
        {
            text: t('Delete Email'),
            icon: <DeleteOutlineRoundedIcon />,
            onClick: () => {
                setDeleteDialogOpen(true);
                setWarningParams({
                    title: t('Delete Email'),
                    text: t('Are you sure you want to delete this email?'),
                    approveText: t('Delete'),
                    onDelete: () => {
                        api.delete(`/emails/delete/${id}/`).then((response) => {
                            setDeleteDialogOpen(false)
                            setWarningParams({})
                            navigate('/contacts/emails')
                            enqueueSnackbar(t('Email Deleted'), { variant: 'success' })
                        }).catch((error) => {
                            console.error(error);
                            enqueueSnackbar(t('Error Deleting Email'), { variant: 'error' })
                        })
                    }
                })
            }
        }
    ]

    useEffect(() => {
        api.get('/emails/flat-from-emails/').then((response) => {
            setFromEmails(response.data)
        }).catch((error) => {
            console.error(error);
        })
    }, [])

    useEffect(() => {
        setRecipientsInputValue('')
        setRecipientsOptions([])
        setOptionsOffset(0)
        setMoreOptions(true)
    }, [recipients])

    useEffect(() => {
        // if (recipientsInputValue) {
        setOptionsOffset(0)
        setMoreOptions(true)
        const searchOptions = debounce(() => {
            loadOptions(0)
        }, 500)
        searchOptions()
        return () => searchOptions.cancel()
        // }
    }, [recipientsInputValue])

    useEffect(() => {
        if (id === 'new') {
            setHeader(t('New Email'))
            setSubject(t('Blank Email'))
            setImage('email-global-templates/en-template-blank.png')
        } else {
            setLoading(true)
            api.get(`/emails/details/${id}/`).then((response) => {
                setEmail({ ...response.data })
                setHeader(response.data.subject)
                setSubject(response.data.subject)
                setFromEmail(response.data.from_email)
                setDescription(response.data.description)
                setLanguage(response.data.language)
                setTemplate({ ...response.data.template })
                setImage(response.data.image || 'email-global-templates/en-template-blank.png')
                setLoading(false)
                setBody(response.data.body)
                const recipients_contact_list = response.data.recipients_contact_list.map((list) => {
                    const label = `${list.name} (${list.count}) -  ${list?.email_count} ${t('Emails')}`
                    return (
                        {
                            key: list.id,
                            group: 'contact_lists',
                            label: removeExtraSpaces(label.trim())
                        }
                    )
                })
                const recipients_donation_list = response.data.recipients_donation_list.map((list) => {
                    const label = `${list.name} (${list.count}) -  ${list?.email_count} ${t('Emails')}`
                    return (
                        {
                            key: list.id,
                            group: 'donation_lists',
                            label: removeExtraSpaces(label.trim())
                        }
                    )
                })
                const recipients_contacts = response.data.recipients_contacts.map((list) => {
                    const label = `${list.first_name} ${list.last_name} ${list.email_addresses.length === 0 ? ` (${t('No Email')})` : ''}`
                    return (
                        {
                            key: list.id,
                            group: 'contacts',
                            label: removeExtraSpaces(label.trim())
                        }
                    )
                })
                setRecipients([...recipients_contact_list, ...recipients_donation_list, ...recipients_contacts, ...response.data.recipients || []])
            }).catch((error) => {
                console.error(error);
                setLoading(false)
            })
        }
    }, [id, reload]);

    const loadOptions = async (offset) => {
        setOptionsLoading(true)
        await api.get('/emails/recipients/options/', {
            params: {
                limit: limit,
                offset: offset ?? optionsOffset,
                search: recipientsInputValue
            },
        }).then((response) => {
            const data = response.data
            const contacts = data.contacts.map((list) => {
                const label = `${list.first_name} ${list.last_name} ${list.email_addresses.length === 0 ? ` (${t('No Email')})` : ''}`
                return (
                    {
                        key: list.id,
                        group: 'contacts',
                        label: removeExtraSpaces(label.trim()),
                        component: (
                            <div>
                                {removeExtraSpaces(label.trim())}
                            </div>
                        )
                    }
                )
            })
            const donation_lists = data.donation_lists.map((list) => {
                const label = `${list.name} (${list.count}) -  ${list?.email_count} ${t('Emails')}`
                return (
                    {
                        key: list.id,
                        group: 'donation_lists',
                        label: removeExtraSpaces(label.trim()),
                        component: (
                            <div>
                                {removeExtraSpaces(label.trim())}
                            </div>
                        )
                    }
                )
            })
            const contact_lists = data.contact_lists.map((list) => {
                const label = `${list.name} (${list.count}) -  ${list?.email_count} ${t('Emails')}`
                return (
                    {
                        key: list.id,
                        group: 'contact_lists',
                        label: removeExtraSpaces(label.trim()),
                        component: (
                            <div>
                                {removeExtraSpaces(label.trim())}
                            </div>
                        )
                    }
                )
            })
            setRecipientsOptions(prevState => (
                [...prevState, ...contact_lists, ...donation_lists, ...contacts]
            ))
            setOptionsOffset(prevState => prevState + limit)
            setMoreOptions(Boolean(response.data.items_left_count))
            setOptionsLoading(false)
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        if (selectEmailTemplateOpen) {
            setTemplatesLoading(true)
            api.get(`/emails/templates/global/${language}/`).then((response) => {
                setEmailTemplates(response.data)
                setTemplatesLoading(false)
            }).catch((error) => {
                console.log(error);
                setTemplatesLoading(false)
            })
        }
    }, [selectEmailTemplateOpen]);

    const handleUpdate = (data) => {
        api.put(`/emails/update/${id}/`, data).then((response) => {
            setHeader(response.data.subject)
            setSaving(false)
            return response.data
        }).catch((error) => {
            console.log(error);
            setSaving(false)
        })
    }

    // const test = () => {
    //     log(JSON.stringify(template.content) === JSON.stringify(email.template.content))
    //     log(subject === email.subject)
    //     log(description === email.description)
    //     log(body === email.body)
    //     log(image === email.image)
    // }

    const sendTestEmail = () => {
        setSendingTestEmail(true)
        api.post('/emails/send-test/', {
            subject: subject,
            body: body,
            from_email: fromEmail,
            to_email: testEmail,
        }).then((response) => {
            log(response.data)
            setTestEmailOpen(false)
            setSendingTestEmail(false)
            enqueueSnackbar(t('Test Email Sent'), { variant: 'success' })
        }).catch((error) => {
            console.error(error);
            setSendingTestEmail(false)
            enqueueSnackbar(t('Test Email Failed'), { variant: 'error' })
        })
    }

    const handleSave = async () => {
        setSaving(true)
        let data = {}
        const addRecipients = () => {
            data.recipients = {
                contact_list: [],
                donation_list: [],
                contacts: [],
                emails: []
            }
            recipients.forEach((recipient, index) => {
                if (typeof recipient === 'string') data.recipients.emails.push(recipient)
                else if (recipient.group === 'contact_lists') data.recipients.contact_list.push(recipient.key)
                else if (recipient.group === 'donation_lists') data.recipients.donation_list.push(recipient.key)
                else if (recipient.group === 'contacts') data.recipients.contacts.push(recipient.key)
            })
        }
        if (id === 'new') {
            try {
                data.subject = subject
                data.language = language
                if (recipients.length > 0) addRecipients()
                if (description) data.description = description
                data.from_email = fromEmail
                const response = await api.post('/emails/create/', data)
                setEmail(response.data)
                navigate(`/contacts/emails/${response.data.id}`)
                setSaving(false)
                return response
            } catch (error) {
                setSaving(false)
                console.error(error);
                const exampleDjango400Error = { "subject": ["This field may not be blank."] } // a sample error from django
                if (error.response?.status === 400) {
                    Object.keys(error.response.data).forEach((key) => {
                        const errorText = error.response.data[key][0]
                        enqueueSnackbar(`${t(key)}: ${t(errorText)}`, { variant: 'error' })
                    })
                } else {
                    enqueueSnackbar(t('Error Creating Email'), { variant: 'error' })
                }
            }
        } else {
            if (subject !== email.subject) data.subject = subject
            if (description !== email.description) data.description = description
            if (fromEmail !== email.from_email) data.from_email = fromEmail
            if (language !== email.language) data.language = language
            addRecipients()
            handleUpdate(data)
            return { status: 201 }
        }
    }

    useEffect(() => {
        if (!saving) return
        setSaving(true)
        let data = {}
        data.template = template
        data.body = body
        data.image = image
        handleUpdate(data)
    }, [image])

    useEffect(() => {
        log({ recipientsOptions })
    }, [recipientsOptions])

    const handleSend = () => {
        handleSave().then(() => {
            api.post(`/emails/send/${id}`, {}).then((response) => {
                log(response.data)
                setReload(!reload)
                setDeleteDialogOpen(false)
                setWarningParams({})
                enqueueSnackbar(t('Email Sent'), { variant: 'success' })
            }).catch((error) => {
                console.log(error);
                setDeleteDialogOpen(false)
                setWarningParams({})
                if (error.response?.status === 429) enqueueSnackbar(t(error.response.data.message), {
                    variant: 'error',
                    action:
                        <Button
                            size="small"
                            sx={{ color: '#d32f2f', backgroundColor: 'white', mx: 2, fontWeight: 'bold' }}
                            variant="contained"
                            onClick={() => setOpenUpgradePlan(true)}
                        >
                            {t('Upgrade Plan')}
                        </Button>
                })
                else if (error.response?.status === 400) enqueueSnackbar(t(error.response.data.message), { variant: 'error' })
                else enqueueSnackbar(t('Email Failed'), { variant: 'error' })
            })
        })
    }

    const jsonToHTML = (values) => {
        const mjmlStr = EditorCore.toMJML({
            element: values.content,
            mode: "production",
            universalElements: universalElementSetting,
        });

        const html = mjml(mjmlStr).html;
        return html;
    };

    const uploadImage = async (file) => {
        const data = new FormData();
        data.append("image", file);
        data.append("model", "email");
        data.append("row_id", id)
        try {
            const response = await api.post('/upload-image/', data);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const htmlToImage = async (values) => {
        const html2canvas = (await import("html2canvas")).default;
        const container = document.createElement("div");
        container.style.position = "absolute";
        if (i18n.dir() === 'rtl') {
            container.style.right = "-9999px";
        } else {
            container.style.left = "-9999px";
        }
        const mjmlStr = EditorCore.toMJML({
            element: values.content,
            mode: "production",
            universalElements: universalElementSetting,
        });

        const html = mjml(mjmlStr).html;

        container.innerHTML = html;
        document.body.appendChild(container);

        const blob = await new Promise((resolve) => {
            html2canvas(container, { useCORS: true }).then((canvas) => {
                return canvas.toBlob(resolve, "png", 0.1);
            });
        });

        document.body.removeChild(container);

        return blob;
    };

    const handleSaveTemplate = async (values, close) => {
        setSaving(true)
        // Save html (as body)
        setBody(jsonToHTML(values))
        // Save json (as template)
        setTemplate(values)
        // Save image
        try {
            const imageBlob = await htmlToImage(values);
            const imagePublicId = await uploadImage(imageBlob);
            await setImage(imagePublicId)
            if (close === true) setEmailEditorOpen(false)
        } catch (error) {
            console.error(error);
            setSaving(false)
            enqueueSnackbar(t('Error Saving Email'), { variant: 'error' })
        }
    }

    return (
        <div>
            <div className="flex float-end items-center">
                <Options options={options} />
            </div>
            <H1>{header}</H1>
            <Box className="pt-0 px-0">
                <div className="relative -top-10">
                    <div className="relative w-[calc(100%+80px)] -start-10 mb-8">
                        <div className="flex justify-between items-center px-4">
                            <div className="font-semibold">
                                {id === 'new' ? t('New Email') : (
                                    email.sent ? (
                                        <div className="text-green-700">
                                            {t('Sent at')} {new Date(email.sent_at).toLocaleString()}
                                        </div>
                                    ) : (
                                        <div className="text-yellow-700">
                                            {t('Draft')}
                                        </div>
                                    )
                                )}
                            </div>
                            <div className="flex justify-end gap-x-4 my-4">
                                {/* <LoadingButton
                                    sx={{ borderRadius: 99 }}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    loading={false}
                                    // onClick={() => {
                                    //     enqueueSnackbar(t('Email Cloned'), {
                                    //         variant: 'success',

                                    //     });
                                    // }}
                                >
                                    {t('Clone')}
                                </LoadingButton> */}
                                <Button
                                    sx={{ borderRadius: 99 }}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    disabled={!Validate.email(fromEmail) || !body || !subject || !fromEmail}
                                    onClick={() => setTestEmailOpen(true)}
                                >
                                    {t('Send Test Email')}
                                </Button>
                                <LoadingButton
                                    sx={{ borderRadius: 99 }}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    loading={saving}
                                    disabled={!Validate.email(fromEmail) || !body || !subject || !fromEmail}
                                    onClick={() => {
                                        handleSave().then(() => {
                                            if (email.sent) {
                                                setWarningParams({
                                                    title: t('Resend Email'),
                                                    text: t('Are you sure you want to resend this email?'),
                                                    approveText: t('Resend'),
                                                    onDelete: handleSend
                                                })
                                            } else {
                                                setWarningParams({
                                                    title: t('Send Email'),
                                                    text: t('Are you sure you want to send this email?'),
                                                    approveText: t('Send'),
                                                    onDelete: handleSend
                                                })
                                            }
                                            setDeleteDialogOpen(true)
                                        })
                                    }}
                                >
                                    {email.sent ? t('Resend') : t('Send')}
                                </LoadingButton>
                                {/* <LoadingButton
                                    sx={{ borderRadius: 99 }}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    loading={saving}
                                // onClick={handleSave}
                                >
                                    {t('Scedual Send')}
                                </LoadingButton> */}
                            </div>
                        </div>
                        <hr />
                    </div>
                    <fieldset className="flex gap-x-8">
                        <TextField
                            fullWidth
                            variant="standard"
                            label={t('Subject')}
                            placeholder={t('Subject')}
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        />
                        <FormControl variant="standard" sx={{ width: '20rem' }}>
                            <InputLabel id="from-email">{t('From Email')}</InputLabel>
                            <Select
                                variant="standard"
                                labelId="from-email"
                                label={t('From Email')}
                                value={fromEmail}
                                onChange={(e) => setFromEmail(e.target.value)}
                            >
                                {fromEmails.map((email, index) => {
                                    return (
                                        <MenuItem key={index} value={email}>{email}</MenuItem>
                                    )
                                })}
                                {fromEmails.length === 0 && (
                                    <MenuItem onClick={(e) => {
                                        window.open('/settings/emails', '_blank')
                                    }}>
                                        <div className="flex items-center gap-x-2">
                                            <AddRoundedIcon />
                                            {t('Add From Email')}
                                        </div>
                                    </MenuItem>
                                )
                                }
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" sx={{ width: '10rem' }}>
                            <InputLabel id="email-language">{t('Email Language')}</InputLabel>
                            <Select
                                variant="standard"
                                labelId="email-language"
                                label={t('Email Language')}
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                            >
                                <MenuItem value={'en'}>{t('English')}</MenuItem>
                                <MenuItem value={'he'}>{t('Hebrew')}</MenuItem>
                            </Select>
                        </FormControl>
                    </fieldset>
                    <fieldset className="flex gap-x-8">
                        <TextField
                            variant="standard"
                            label={t('Description')}
                            placeholder={t('Description')}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                        />
                    </fieldset>
                    <fieldset className="flex gap-x-8">
                        <TagInput
                            tags={recipients}
                            setTags={setRecipients}
                            name='recipients'
                            label={t('Recipients')}
                            options={recipientsOptions}
                            loading={optionsLoading}
                            getOptionLabel={(option) => option.label}
                            groupBy={(option) => option.group}
                            addTagKeys={['Enter', 'Tab', ',']}
                            onFocus={() => {
                                if (recipientsOptions.length === 0) {
                                    setOptionsOffset(0)
                                    loadOptions()
                                }
                            }}
                            onInputChange={(e, value) => {
                                log({ value })
                                setRecipientsInputValue(value)
                            }}
                            renderOption={(props, option, { index }) => {
                                if (recipients.some((recipient) => recipient.key === option.key && recipient.group === option.group)) return null
                                return (
                                    index === recipientsOptions.length - 1 && moreOptions ? (
                                        <div {...props}>
                                            {log(props.className)}
                                            <InView as="div" className="flex justify-between align-middle w-full " onChange={inView => inView && loadOptions()}>
                                                <div>{t('Loading')}...</div>
                                                <CircularProgress color="inherit" size={20} />
                                            </InView>
                                        </div>
                                    ) : (
                                        <div {...props}>
                                            <div>{option.component}</div>
                                        </div>
                                    )
                                )
                            }}
                        />
                    </fieldset>
                    <div className="flex gap-x-8">
                        <div
                            className="cursor-pointer p-2 flex flex-col items-center justify-center"
                            onClick={() => {
                                if (id === 'new' || isEmpty(template)) {
                                    setSelectEmailTemplateOpen(true)
                                } else {
                                    setTemplate(template)
                                    setEmailEditorOpen(true)
                                }
                            }}
                        >
                            <div
                                className="w-56 h-72 bg-white border rounded-lg border-gray-200 drop-shadow-sm flex items-center justify-center"
                            >
                                <img
                                    // src={`https://res.cloudinary.com/hlih83l4w/image/upload/c_fill,g_north,w_192/c_fill,g_north,w_224/c_crop,g_north,h_288,w_224/${image}`}
                                    src={`https://ps.temperal.co/ps?u=https://media.donbar.org/${image}&k=Cu0qCBD4UAv7&h=288&w=192`}
                                    className='object-top max-h-72 rounded-lg w-full'
                                />
                                <div className="absolute inset-0 flex flex-col items-center justify-center">
                                    <div className="flex flex-col items-center justify-center bg-white/60 hover:bg-white/80 p-6 rounded-xl">
                                        <BorderColorRoundedIcon fontSize="large" />
                                        <p className="text-sm pt-2 font-semibold text-gray-700">
                                            {id === 'new' || isEmpty(template) ? t('Create Email') : t('Edit Email')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <StatsList stats={email?.email_stat} />
                    </div>
                </div>
            </Box >
            <LoadingButton
                sx={{ borderRadius: 99 }}
                variant="contained"
                color="primary"
                size="large"
                loading={saving}
                onClick={handleSave}
            >
                {t('Save')}
            </LoadingButton>
            <Modal
                title={t('Select Email Template')}
                open={selectEmailTemplateOpen}
                onClose={() => setSelectEmailTemplateOpen(false)}
            >
                {templatesLoading ?
                    <div className="flex justify-center">
                        <CircularProgress size={64} />
                    </div>
                    :
                    <div className="flex flex-wrap justify-between gap-y-10">
                        {emailTemplates.map((template, index) => {
                            return (
                                <div
                                    key={index}
                                    className="cursor-pointer w-48 h-64 text-start bg-white border rounded-lg border-gray-200 drop-shadow-sm"
                                    onClick={() => {
                                        handleSave().then((res) => {
                                            if (res?.status === 201) {
                                                setTemplate(template.template);
                                                setSelectEmailTemplateOpen(false);
                                                setEmailEditorOpen(true);
                                            } else {
                                                enqueueSnackbar(t('Error Creating Email'), { variant: 'error' })
                                                setSelectEmailTemplateOpen(false)
                                            }
                                        })
                                    }}
                                >
                                    <img
                                        // src={`https://res.cloudinary.com/hlih83l4w/image/upload/c_fill,g_north,w_192/c_crop,g_north,h_224,w_192/${template.image}`}
                                        src={`https://ps.temperal.co/ps?u=https://media.donbar.org/${template.image}&k=Cu0qCBD4UAv7&h=288&w=192`}
                                        className='object-top max-h-56 rounded-lg w-full'
                                    />
                                    <div className="p-2 bg-white absolute top-52 w-full h-11 rounded-b-lg">
                                        <h2 className="">{t(template.name)}</h2>
                                        {/* <p className="">{t(template.subject)}</p> */}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
            </Modal>
            {
                EmailEditorOpen &&
                <div className="fixed top-0 start-0 w-full">
                    <div className="unset">
                        <EmailEditor
                            locale={i18n.language}
                            template={template}
                            emailId={id}
                            handleSave={handleSaveTemplate}
                            EmailHeader={(props) =>
                                <EmailHeader
                                    {...props}
                                    subject={subject}
                                    template={template}
                                    setTemplate={setTemplate}
                                    setBody={setBody}
                                    setImage={setImage}
                                    setEmailEditorOpen={setEmailEditorOpen}
                                    saving={saving}
                                    setSaving={setSaving}
                                    handleSave={handleSaveTemplate}
                                    emailId={id}
                                />
                            }
                        />
                    </div>
                </div>
            }
            <Modal
                className="w-96"
                title={t('Send Test Email')}
                open={testEmailOpen}
                onClose={() => setTestEmailOpen(false)}
            >
                <div className="flex flex-col gap-y-8">
                    <TextField
                        fullWidth
                        variant="standard"
                        label={t('Test Email')}
                        placeholder={t('Test Email')}
                        value={testEmail}
                        onChange={(e) => setTestEmail(e.target.value)}
                    />
                    <div className="flex gap-4 justify-center">
                        <LoadingButton
                            sx={{ borderRadius: 99 }}
                            variant="contained"
                            color="primary"
                            disabled={!Validate.email(testEmail)}
                            loading={sendingTestEmail}
                            onClick={sendTestEmail}
                        >
                            {t('Send')}
                        </LoadingButton>
                        <Button
                            sx={{ borderRadius: 99 }}
                            color="primary"
                            onClick={() => setTestEmailOpen(false)}
                        >
                            {t('Cancel')}
                        </Button>
                    </div>
                </div >
            </Modal>
            <WarningDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                title={warningParams?.title}
                text={warningParams?.text}
                approveText={warningParams?.approveText}
                onDelete={warningParams?.onDelete}
            />
            {openUpgradePlan && <UpdateEmailPlan onClose={() => setOpenUpgradePlan(false)} />}
        </div >
    )
}

const Stat = ({ stats }) => {
    const { t } = useTranslation();
    return (
        <div className="my-4">
            {stats.map((stat) => (
                <div className="flex my-3">
                    <div key={t(stat.title)} className="font-semibold">
                        {`${t(stat.title)}:`}
                        {/* {`${stat.value} ${stat.description}`} */}
                    </div>
                    <div className="ms-1">
                        {stat.value}
                    </div>
                </div>
            ))}
        </div>
    );
};


const StatsList = ({ stats }) => {
    const statsData = [
        { iconName: 'send', title: 'Sends', value: stats?.sends, description: 'Total emails sent out.' },
        { iconName: 'check_circle_outline', title: 'Deliveries', value: stats?.deliveries, description: 'Successfully delivered emails.' },
        { iconName: 'visibility', title: 'Opens', value: stats?.opens, description: 'Emails opened by recipients.' },
        { iconName: 'touch_app', title: 'Clicks', value: stats?.clicks, description: 'Links within the email that were clicked.' },
        { iconName: 'report', title: 'Spam Reports', value: stats?.complaints, description: 'Marked as spam or reported by a recipient.' },
        { iconName: 'unsubscribe', title: 'Unsubscribes', value: stats?.unsubscribe, description: 'Recipients who unsubscribed.' },
        { iconName: 'warning', title: 'Bounces', value: stats?.bounces, description: 'Emails that couldn\'t be delivered.' },
        { iconName: 'error_outline', title: 'Rejects', value: stats?.rejects, description: 'Emails rejected by the server.' },
    ];

    return (
        <div className="container mx-auto">
            <Stat stats={statsData} />
        </div>
    );
};


function EmailHeader({ emailId, subject, template, handleSave, setTemplate, setBody, setImage, saving, setSaving, setEmailEditorOpen }) {
    const { values } = useEditorContext();
    // universalElements is for futre use
    const { universalElementSetting } = useEditorProps();
    const { t, i18n } = useTranslation();
    const [unsavedWarning, setUnsavedWarning] = useState(false)

    const unsavedChanges = JSON.stringify(template.content) !== JSON.stringify(values.content)

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [unsavedChanges])

    const handleBeforeUnload = (e) => {
        if (unsavedChanges) {
            const message = t('You have unsaved changes. Are you sure you want to leave?');
            e.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
        }
    };


    return (
        <div className="flex bg-white text-start p-2 justify-between">
            <div className="flex gap-2 items-center">
                <IconButton className="rtl:rotate-180" onClick={() => unsavedChanges ? setUnsavedWarning(true) : setEmailEditorOpen(false)}>
                    <ArrowBackIosNewRoundedIcon />
                </IconButton>
                <h2>{subject || template.subject}</h2>
            </div>
            <div className="flex gap-2">
                <LoadingButton
                    variant="outlined"
                    onClick={() => handleSave(values)}
                    loading={saving}
                >
                    {t('Save')}
                </LoadingButton>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={() => handleSave(values, true)}
                    loading={saving}
                >
                    {t('Save and Close')}
                </LoadingButton>
            </div>
            <WarningDialog
                open={unsavedWarning}
                onClose={() => setUnsavedWarning(false)}
                title={t('Unsaved Changes')}
                text={t('Are you sure you want to leave without saving?')}
                onDelete={() => setEmailEditorOpen(false)}
                approveText={t('Leave')}
            />
        </div>
    )
}

export default EmailView;
