import { Box, Paper, Stack, Switch, Typography } from "@mui/material";

function DirectItemsFilter({ directDataOnly, setDirectDataOnly, label, options, children }) {
    return (
        <Paper
            elevation={0}
            sx={{
                // display: 'none',
                display: 'flex',
                borderRadius: '0.5rem',
                alignItems: 'center',
                px: '1rem',
                py: '0.5rem',
            }}
        >
            <Box marginRight={4}>
                {children}
            </Box>
            {label ? (
                <>
                    <Typography fontWeight='bold' variant="subtitle2" color="text.secondary">
                        {label}
                    </Typography>
                    <Switch color="primary" checked={directDataOnly} onChange={(e) => setDirectDataOnly(e.target.checked)} />
                </>
            ) : null}
            <Stack >
                {options}
            </Stack>
        </Paper>
    );
}

export default DirectItemsFilter;
