import { Typography } from '@mui/material';
import React from 'react';

const H1 = ({ children }) => {
  return (
      <Typography className='text-start text-3xl text-custom-blue' variant='h1'>{children}</Typography>
  );
};

export default H1;
