import React from "react";
import { Button, ListSubheader, MenuItem, Popover, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

function ActionModal({ open,
    onClose,
    handleOk,
    okButtonText,
    title,
    text,
    children,
    anchorEl,
    anchorOrigin = { vertical: 'center', horizontal: 'center' },
    transformOrigin = { vertical: 'center', horizontal: 'center' },
    submiting = false,
    width = 'sm'
}) {
    const { t } = useTranslation();

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            sx={{ '& .MuiPopover-paper': { borderRadius: '0.5rem' } }}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            <div className='p-4 min-w-[20rem]'>
                <p className='text-lg font-bold'>{title}</p>
                <p className='text-sm text-gray-500'>{text}</p>
                <div className="my-4">{children}</div>
                <div className='flex justify-end gap-2'>
                    <Button
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <LoadingButton
                        variant='contained'
                        color='primary'
                        onClick={handleOk}
                        loading={submiting}
                    >
                        {okButtonText || t('Add')}
                    </LoadingButton>
                </div>
            </div>
        </Popover>
    )
}

export default ActionModal;