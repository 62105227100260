import { ArrowDropDownRounded } from "@mui/icons-material";
import { Card, Checkbox, Chip, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { ListItemChangeStatus } from "components/ListItemStatus";
import Pagination from "components/common/Pagination";
import Section from "components/common/Section";
import DynamicTable from "components/general/DynamicTable";
import Sort from "components/sort";
import { itemStatus } from "data/general";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Link as RouterLink, useLocation } from "react-router-dom";


export const addressString = (address) => {
    const { street, city, house_number } = address;
    let addressStreet = `${street || ''} ${house_number || ''}`.trim();
    return [addressStreet, city].filter(Boolean).join(', ') || '';
}

function ContactList({ contacts, totalPages, currentPage, setCurrentPage, getSort, setSort, checkedContacts, setCheckedContacts, additionalColumns = []}) {
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.slice(1));
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.hash]);


    const handleCheck = (contact) => {
        const contactChecked = checkedContacts.includes(contact);
        if (contactChecked) setCheckedContacts(prev => prev.filter(c => c !== contact));
        else setCheckedContacts(prev => [...prev, contact]);
    };

    const handleCheckAll = () => {
        if (checkedContacts.length === contacts.length) setCheckedContacts([]);
        else setCheckedContacts(contacts.map(contact => contact.contact_id));
    };

    const columns = [
        {
            value: t("Name"),
            sort: "name",
            field: "display_name"
        },
        {
            value: t("Address"),
            sort: "address",
            field: "address"
        },
        {
            value: t("Connections"),
            sort: "relationship",
            field: "relationship"
        },
        {
            value: t("Email"),
            field: "email_addresses"
        },
        {
            value: t("Phone"),
            field: "phone_numbers"
        },
        {
            value: t("Description"),
            sort: "description",
            field: "description"
        },
        ...additionalColumns
        // listStatusField && { name: t("Done"), field: "list_status" }
    ]

    return (
        <>
            <DynamicTable
                columns={columns}
                rows={contacts || []}
                checkField={'contact_id'}
                checkedRows={checkedContacts}
                handleCheck={(contact) => handleCheck(contact.contact_id)}
                handleCheckAll={handleCheckAll}
                getSort={getSort}
                onSort={setSort}
            />
            <Pagination sx={{ mt: '2rem' }} totalPages={totalPages} currentPage={currentPage} onChange={setCurrentPage} />
        </>
    );
}


export default ContactList;

function OldTable() {
    const { t } = useTranslation();
    const contacts = [];
    const [listStatusAnchorEl, setListStatusAnchorEl] = useState(null);
    const [listStatusIndex, setListStatusIndex] = useState(0);
    const [listStatus, setListStatus] = useState(0);
    const handleChangeStatus = () => { };
    const removeListItem = () => { };

    if (!true) return (
        <ListItemChangeStatus
            anchorEl={listStatusAnchorEl}
            onClose={() => setListStatusAnchorEl(null)}
            listStatusIndex={listStatusIndex}
            handleChangeStatus={handleChangeStatus}
            listStatus={listStatus}
            removeListItem={removeListItem}
        />
    )

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {false && <TableCell>
                            <div className="flex gap-2 items-center">
                                {"Done"}<Sort sort onSort field="list_status" />
                            </div>
                        </TableCell>}
                    </TableRow>
                </TableHead>
                <Section
                    component={TableBody}
                    sx={{ backgroundColor: "unset" }}
                >
                    {contacts.map((contact, index) => (
                        <TableRow>
                            {false && <TableCell
                                className={`${contacts.length - 1 === index && 'first:rounded-bs-2xl last:rounded-be-2xl'} ${index === 0 && 'first:rounded-ts-2xl last:rounded-te-2xl'}`}
                            >
                                <Chip
                                    label={t(itemStatus[contact?.list_status].label)}
                                    variant="filled"
                                    size="small"
                                    color={itemStatus[contact?.list_status].color}
                                    deleteIcon={<ArrowDropDownRounded />}
                                    onDelete={(e) => {
                                        setListStatusAnchorEl(e.currentTarget)
                                        setListStatusIndex(contact?.contact_id)
                                        setListStatus(contact?.list_status)
                                    }}
                                    onClick={(e) => {
                                        setListStatusAnchorEl(e.currentTarget)
                                        setListStatusIndex(contact?.contact_id)
                                        setListStatus(contact?.list_status)
                                    }}
                                />
                            </TableCell>}
                        </TableRow>
                    ))}
                </Section>
            </Table>
        </TableContainer>
    )
}