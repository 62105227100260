import { HiCheckCircle, HiMail } from "react-icons/hi";
import Container from "./container";
import { useState } from "react";
import '../../App.css';
import { Alert } from "@material-tailwind/react";
import DRMAlert from "./alerts";


const Contact = () => {
    const [submiting, setSubmiting] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [formSubmited, setFormSubmited] = useState(false);

    const handleSubmit = (event) => {
        setSubmiting(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        fetch('/contact_us_form/', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(() => setName(''))
            .then(() => setEmail(''))
            .then(() => setMessage(''))
            .then(() => setFormSubmited(true))
            .then(() => setSubmiting(false))
            .catch(error => alert(error));
    }

    return (
        <Container>
            <DRMAlert
            open={formSubmited}
            title={"Thank You!"}
            text={"Your message has been submitted successfully. We will get back to you soon!"}
            type={"success"}
            icon={<HiCheckCircle />}/>
            <section id="contact" className="overflow-hidden py-16 lg:mx-12 xl:mx-36">
                <div className="container">
                    <div className="-mx-4 flex flex-wrap">
                        <div className="w-full px-4 lg:w-2/3 mx-auto">
                            <div
                                className="wow fadeInUp mb-12 rounded-md bg-primary/[3%] py-11 px-8 dark:bg-dark sm:p-[55px] lg:mb-5 lg:px-8 xl:p-[55px]"
                                data-wow-delay=".15s
                "
                            >
                                <h2 className="text-center mb-4 font-bold text-black dark:text-white text-3xl xl:text-4xl">
                                    Let's talk 💬
                                </h2>
                                <p className="text-center mb-8 text-xl font-medium text-body-color">
                                    Wan't to know more about Donbar? We'd love to hear from you!
                                </p>
                                <p className="mb-8 flex"><HiMail size={24} className="my-auto" /><a href="mailto:info@donbar.org" className="no-underline font-bold">&nbsp;&nbsp;info@donbar.org</a></p>
                                <form onSubmit={handleSubmit}>
                                    <div className="-mx-4 flex flex-wrap">
                                        <div className="w-full px-4 md:w-1/2">
                                            <div className="mb-8">
                                                <label
                                                    htmlFor="name"
                                                    className="mb-3 block text-sm font-medium text-dark dark:text-white"
                                                >
                                                    Your Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    placeholder="Enter your name"
                                                    className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full px-4 md:w-1/2">
                                            <div className="mb-8">
                                                <label
                                                    htmlFor="email"
                                                    className="mb-3 block text-sm font-medium text-dark dark:text-white"
                                                >
                                                    Your Email
                                                </label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Enter your email"
                                                    className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full px-4">
                                            <div className="mb-8">
                                                <label
                                                    htmlFor="message"
                                                    className="mb-3 block text-sm font-medium text-dark dark:text-white"
                                                >
                                                    Your Message
                                                </label>
                                                <textarea
                                                    name="message"
                                                    rows={5}
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    placeholder="Enter your Message"
                                                    className="w-full resize-none rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp"
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="w-full flex justify-center mx-auto px-4">
                                            <div>

                                            <button className="w-72 px-6 py-2 mt-3 text-center text-white bg-indigo-700 rounded-md lg:ms-5" type="submit" >
                                                {submiting ? <div className="w-full mx-auto spinner"></div>: "Send"}
                                            </button>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Container>
    );
};

export default Contact;
