import React, { useEffect } from "react";
import { Alert, Button, Typography } from "@material-tailwind/react";

const alertTypes = {
    success: "bg-green-200 border-green-600",
    error: "bg-red-500",
    warning: "bg-yellow-500",
    info: "bg-blue-500",
};

export default function DRMAlert({ open, type, title, text, icon }) {
    const [isOpen, setIsOpen] = React.useState(open);

    useEffect(() => {
        setIsOpen(open);
        if (open) {
            const timer = setTimeout(() => {
                setIsOpen(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [open]);

    return (
        <React.Fragment>
            {isOpen && (
                <div className={`fixed grid grid-cols-12 top-2 -translate-x-1/2 left-1/2 z-50 px-4 py-2 border-2 rounded-lg ${alertTypes[type]} w-4/5 md:w-2/3 lg:w-[580px] text-custom-blue flex flex-wrap mx-auto`}>
                    <div className="col-span-1 max-w-screen-md text-3xl text-green-600 align-middle my-auto mr-4">{icon}</div>
                    <div className="col-span-9">
                        <div className="text-xl font-medium">
                            {title}
                        </div>
                        <div className="mt-2 font-normal">
                            {text}
                        </div>
                    </div>
                    <button
                    onClick={() => setIsOpen(false)}
                        className="absolute right-4 -translate-y-1/2 top-1/2 text-2xl font-extralight text-gray-700 hover:text-gray-900"
                    >
                        ×
                    </button>
                </div>
            )}
        </React.Fragment>
    );
}