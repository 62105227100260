import { Box, CircularProgress, Modal, Typography } from "@mui/material";

function ProgressModal({ open, title, text, progress, length }) {
    return (
        <Modal
            open={open}
        >
            <div className="bg-white w-96 h-80 absolute text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-3xl p-6" >
                <h2 className="text-2xl text-gray-600 font-bold mb-10">{title}</h2>
                <CircularProgressWithLabel value={
                    progress / length * 100
                    // 50
                } />
                <p className="text-lg text-gray-600 mt-10">{text}</p>
            </div>
        </Modal>
    );
}

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                size={120}
                thickness={2}
                variant="determinate"
                sx={{ strokeLinecap: 'round' }}
                {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" fontSize={20} color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

export default ProgressModal;