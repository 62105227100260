import { Skeleton, Stack } from "@mui/material";
import NavCard from "./NavCard";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const mappings = {
    sub_campaigns: {
        title: 'Sub Campaigns',
        loc: 'sub-campaigns',
    },
    groups: {
        title: 'Teams',
        loc: 'groups',
    },
    fundraisers: {
        title: 'Fundraisers',
        loc: 'fundraisers',
    },
}

const listViews = ['campaigns', 'sub-campaigns', 'groups', 'fundraisers']

function NavigationCards({ data, loading }) {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const currentLocation = location.pathname.split('/').filter(Boolean).slice(-2, -1).join('');

    const cardData = useMemo(() => {
        if (loading) return [];

        const navbarTooltips = {
            ...(currentLocation === 'campaigns' &&
            {
                groups: {
                    title: t('Direct Groups Only.'),
                    content: t('Does not include sub campaign groups.'),
                }
            }),
            ...(currentLocation !== 'groups' &&
            {
                fundraisers: {
                    title: t('Direct Fundraisers Only.'),
                    content: t('Does not include sub campaign or groups fundraisers.'),
                }
            })
        }

        return ['sub_campaigns', 'groups', 'fundraisers'].reduce((acc, key) => {
            const list = data?.[key];
            const donationsSumTotal = list?.reduce((acc, item) => acc + item.donations_sum, 0);
            const donationsSumK = donationsSumTotal >= 1000 ? donationsSumTotal / 1000 : donationsSumTotal;
            const donationsSumFormated = donationsSumK?.toLocaleString(i18n.language, { style: 'currency', currency: data?.goal_currency, minimumFractionDigits: 0, maximumFractionDigits: donationsSumK >= 100 ? 0 : 2 });
            const sumLetter = i18n.language === 'he' ? `k${donationsSumFormated}` : `${donationsSumFormated}k`;
            const donationsSum = donationsSumTotal >= 1000 ? sumLetter : donationsSumFormated;

            if (list) {
                acc.push({
                    loc: mappings[key].loc,
                    title: t(mappings[key].title),
                    data: list?.map((item) => ({ label: item.name, value: item.donations_sum || 0.0001, currency: item.goal_currency })) || [],
                    donationCount: list?.reduce((acc, item) => acc + item.donations_count, 0),
                    donorCount: list?.reduce((acc, item) => acc + item.donors_count, 0),
                    donationsSum,
                    tooltipTitle: navbarTooltips?.[key]?.title,
                    tooltipContent: navbarTooltips?.[key]?.content,
                });
            }
            return acc;
        }, []);
    }, [data]);


    return (
        <Stack direction="row" spacing={2} sx={{ my: '1rem', justifyContent: cardData.length === 3 ? 'space-between' : 'flex-start' }}>
            {loading && listViews.slice(listViews.indexOf(currentLocation) + 1).map((view) => (
                <Skeleton key={view} variant="rectangular" width={302} height={130} sx={{ borderRadius: '1rem' }} />
            ))}
            {cardData.map(({ loc, title, data, donationCount, donorCount, donationsSum, tooltipTitle, tooltipContent }) => (
                <NavCard
                    key={loc}
                    title={title}
                    data={data}
                    to={loc}
                    donationCount={donationCount}
                    donorCount={donorCount}
                    donationsSum={donationsSum}
                    tooltipTitle={tooltipTitle}
                    tooltipContent={tooltipContent}
                />
            ))}
        </Stack>
    );
}

export default NavigationCards;