import React, { useState } from "react";
import Container from "./container";
import { useTranslation } from "react-i18next";
import WaitingListModal from "./waitnigListForm";
import DRMAlert from "./alerts";
import { HiCheckCircle } from "react-icons/hi";

const Cta = () => {
  const [waitngListFormOpen, setWaitingListFormOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const { t } = useTranslation();

  return (
    <Container>
      <DRMAlert
        open={showAlert}
        title={"Thank You!"}
        text={"You have succesfully joined the waiting list. We will get back to you soon!"}
        type={"success"}
        icon={<HiCheckCircle />}
      />
      <WaitingListModal
        open={waitngListFormOpen}
        setOpen={setWaitingListFormOpen}
        setAlert={setShowAlert}
      />
      <div className="flex flex-wrap items-center justify-between w-full max-w-4xl gap-5 mx-auto text-white bg-indigo-700 px-7 py-7 lg:px-12 lg:py-12 lg:flex-nowrap rounded-xl">
        <div className="flex-grow text-center lg:text-start">
          <h2 className="text-2xl font-medium lg:text-3xl">
            {t('cta-headline')}
          </h2>
          <p className="mt-2 font-medium text-white text-opacity-90 lg:text-xl">
            {t('cta-text')}
          </p>
        </div>
        <div className="flex-shrink-0 w-full text-center lg:w-auto">
          <a
            onClick={() => setWaitingListFormOpen(true)}
            className="inline-block py-3 mx-auto cursor-pointer text-lg font-medium text-center text-indigo-700 bg-white rounded-md px-7 lg:px-10 lg:py-5 ">
            {t('Request Early Access')}
          </a>
        </div>
      </div>
    </Container>
  );
}

export default Cta;