import { Chip, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover } from "@mui/material";
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useTranslation } from "react-i18next";

const options = [
    { label: 'To Do', variant: 'filled', color: 'error', value: 0 },
    { label: 'In Progress', variant: 'filled', color: 'warning', value: 1 },
    { label: 'Done', variant: 'filled', color: 'success', value: 2 },
];

function ListItemChangeStatus({ anchorEl, onClose, handleChangeStatus, listStatusIndex, listStatus, removeListItem }) {
    const { t } = useTranslation();
    const dir = document.dir;

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            sx={{ '& .MuiPopover-paper': { borderRadius: '0.5rem' } }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: dir === 'rtl' ? 'left' : 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: dir === 'rtl' ? 'left' : 'right',
            }}
        >
            <List dense sx={{ maxWidth: 270, bgcolor: 'background.paper' }}>
                {options.map((option) => (
                    <ListItem disablePadding key={option.value} >
                        <ListItemButton onClick={() => {
                            handleChangeStatus(listStatusIndex, option.value);
                            onClose()
                        }} >
                            <ListItemIcon>
                                {listStatus === option.value && <CheckRoundedIcon />}
                            </ListItemIcon>
                            <ListItemText primary={<Chip label={t(option.label)} variant={option.variant} size="small" color={option.color} />} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItem disablePadding >
                    <ListItemButton onClick={() => {
                        removeListItem(listStatusIndex);
                        onClose()
                    }} >
                        <ListItemIcon>
                            <DeleteSweepRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Remove')} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Popover>
    );
}

export { ListItemChangeStatus };