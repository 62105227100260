import { useTranslation } from "react-i18next";
import { Alert, Box, Button, Checkbox, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import LoadingComponent from "components/Loading";
import { LoadingButton } from "@mui/lab";
import Section from "components/common/Section";
import { useSnackbar } from "notistack";
import WarningDialog from "components/common/DeleteDialog";
import useApi from "hooks/useApi";
import { useMutation, useQueries } from "react-query";
import Options from "components/Options";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import HelperTooltip from "components/common/HelperTooltip";

function NedarimPlus() {
    const { t } = useTranslation();
    const api = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const [apiPassword, setApiPassword] = useState('');

    const [editGroup, setEditGroup] = useState();
    const [editGroupName, setEditGroupName] = useState('');
    const [editGroupCampaign, setEditGroupCampaign] = useState('');
    const [editGroupSubCampaign, setEditGroupSubCampaign] = useState('');

    const [newGroup, setNewGroup] = useState(false);

    const [deleteCallbackWarning, setDeleteCallbackWarning] = useState(false);

    const queries = useQueries([
        {
            queryKey: ['npData'],
            queryFn: () => api.get('/addons/nedarim-plus/').then((res) => res.data),
            onSuccess: (data) => setApiPassword(data.api_key || '')
        },
        {
            queryKey: ['npGroups'],
            queryFn: () => api.get('/addons/nedarim-plus/groups/'),
        },
        {
            queryKey: ['campaignsDropdown'],
            queryFn: () => api.get('/campaigns/dropdown'),
        },
    ]);

    const { data: npData, isLoading: npDataLoading } = queries[0];
    const npGroups = queries[1]
    const campaignsQuery = queries[2]
    const campaigns = campaignsQuery?.data?.data

    const npLoading = npDataLoading || npGroups.isLoading || campaignsQuery.isLoading;

    const { mutate: updateNpData, isLoading: npDataUpdating } = useMutation(
        (data) => api.put('/addons/nedarim-plus/', data),
        {
            onSuccess: () => queries[0].refetch(),
            onerror: () => enqueueSnackbar(t('Failed to update.'), { variant: 'error' }),
        }
    );

    const { mutate: deleteGroup } = useMutation(
        (id) => api.delete('/addons/nedarim-plus/groups/update/', { params: { id } }),
        {
            onerror: () => enqueueSnackbar(t('Failed to delete.'), { variant: 'error' }),
            onSettled: () => {
                queries[1].refetch();
            }
        }
    );

    const { mutate: updateGroup } = useMutation(
        (data) => api.put('/addons/nedarim-plus/groups/update/', data),
        {
            onerror: () => enqueueSnackbar(t('Failed to update.'), { variant: 'error' }),
            onSettled: () => {
                setEditGroup(null);
                queries[1].refetch();
                setEditGroupName('');
                setEditGroupCampaign('');
                setEditGroupSubCampaign('');
            }
        }
    );

    const { mutate: addGroup } = useMutation(
        (data) => api.post('/addons/nedarim-plus/groups/update/', data),
        {
            onerror: () => enqueueSnackbar(t('Failed to add.'), { variant: 'error' }),
            onSettled: () => {
                queries[1].refetch();
                setNewGroup(false);
                setEditGroupName('');
                setEditGroupCampaign('');
                setEditGroupSubCampaign('');
            }
        }
    );

    const { mutate: generateLink, isLoading: generateLinkLoading } = useMutation(
        () => api.post('/addons/nedarim-plus/callback-url'),
        {
            onerror: () => enqueueSnackbar(t('Failed to generate link.'), { variant: 'error' }),
            onSettled: () => queries[0].refetch()
        }
    );

    const { mutate: deleteLink, isLoading: deleteLinkLoading } = useMutation(
        () => api.delete('/addons/nedarim-plus/callback-url'),
        {
            onerror: () => enqueueSnackbar(t('Failed to delete link.'), { variant: 'error' }),
            onSettled: () => {
                queries[0].refetch();
                setDeleteCallbackWarning(false);
                enqueueSnackbar(t('Link deleted successfully'), { variant: 'success' });
            }
        }
    );

    const npUrl = `https://www.donbar.org/callback/donations/create/np/${npData?.callback_url_key}`;

    const handleCopy = (value) => {
        navigator.clipboard.writeText(value);
        enqueueSnackbar(t("Copied to clipboard"), { variant: 'success' });
    }

    if (npLoading) {
        return <LoadingComponent className='h-[40rem]' size='48' />
    }

    const renderEditGroup = (group, index) => (
        <TableRow key={index}>
            <TableCell>
                <TextField
                    placeholder={t("Np Category")}
                    size="small"
                    value={editGroupName}
                    onChange={(e) => setEditGroupName(e.target.value)}
                />
            </TableCell>
            <TableCell><SyncAltIcon className="mb-2 text-gray-500" /></TableCell>
            <TableCell>
                <Select
                    size="small"
                    placeholder={t("Campaign")}
                    fullWidth
                    value={editGroupCampaign}
                    onChange={(e) => {
                        setEditGroupCampaign(e.target.value)
                        setEditGroupSubCampaign('')
                    }}
                >
                    <MenuItem value="">{t("None")}</MenuItem>
                    {campaigns.map((campaign) => (
                        <MenuItem key={campaign.id} value={campaign.id}>
                            {campaign.name}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell>
                <Select
                    size="small"
                    fullWidth
                    placeholder={t("Sub Campaign")}
                    value={editGroupSubCampaign}
                    onChange={(e) => setEditGroupSubCampaign(e.target.value)}
                >
                    <MenuItem value="">{t("None")}</MenuItem>
                    {group.campaign && campaigns.find(campaign => campaign.id === editGroupCampaign)?.sub_campaigns?.map((subCampaign) => (
                        <MenuItem key={subCampaign.id} value={subCampaign.id}>
                            {subCampaign.name}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                <div>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        size="small"
                        loading={updateGroup.isLoading}
                        onClick={() => {
                            const data = {
                                id: group.id,
                                group_name: editGroupName,
                                campaign: editGroupCampaign,
                                sub_campaign: editGroupSubCampaign,
                            }
                            if (newGroup) {
                                addGroup(data);
                            } else {
                                updateGroup(data);
                            }
                        }}
                    >
                        {t('Save')}
                    </LoadingButton>
                </div>
                <IconButton
                    onClick={() => {
                        setEditGroup(null);
                        setNewGroup(false);
                        setEditGroupName('');
                        setEditGroupCampaign('');
                        setEditGroupSubCampaign('');

                    }}
                >
                    <CloseRoundedIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )

    return (
        <>
            <Typography variant="h1">{t('Nedarim Plus Settings')}</Typography>
            <Typography variant="h2">{t('Connection Settings')}</Typography>
            <Section>
                <Stack spacing={2}>
                    <div className='font-semibold text-xl'>
                        {t('Nedarim Plus connection settings')}
                    </div>
                    <div className="">
                        <TextField
                            variant="standard"
                            placeholder={t("Mosad ID")}
                            label={t("Mosad ID")}
                            sx={{ width: '20rem' }}
                            value={npData.np_id}
                            disabled
                        />
                    </div>
                    <div className="">
                        <TextField
                            variant="standard"
                            placeholder={t("API Password")}
                            label={t("API Password")}
                            sx={{ width: '20rem' }}
                            value={apiPassword}
                            onChange={(e) => setApiPassword(e.target.value)}
                        />
                    </div>
                    <div className='flex justify-center'>
                        <Button
                            variant="contained"
                            color="primary"
                            loading={npDataUpdating}
                            disabled={apiPassword.includes('••')}
                            onClick={() => updateNpData({ api_key: apiPassword })}
                        >
                            {t('Save')}
                        </Button>
                    </div>
                </Stack>
            </Section>
            <Typography variant="h2">{t('Callback Link')}</Typography>
            <Typography variant="subtitle1">
                {t('Send this link to Nedarim Plus to start receiving live donations')}
            </Typography>
            <Section>
                <Stack spacing={2}>
                    {npData.callback_url_key ? (
                        <>
                            <div className='flex justify-center'>
                                <Alert severity="warning">
                                    {t('keep-link-secret-alert')}
                                </Alert>
                            </div>
                            <Box sx={{ display: 'flex' }}>
                                <Tooltip title={t("Copy")}>
                                    <IconButton
                                        onClick={() => handleCopy(npUrl)}
                                        size="small"
                                    >
                                        <ContentCopyIcon fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>
                                <Typography maxWidth={512} noWrap variant="subtitle1">
                                    {npUrl}
                                </Typography>
                            </Box>
                            <div className='text-start'>
                                <LoadingButton
                                    variant="outlined"
                                    color="error"
                                    loading={deleteLinkLoading}
                                    onClick={() => setDeleteCallbackWarning(true)}
                                >
                                    {t('Delete')}
                                </LoadingButton>
                            </div>
                        </>
                    ) : (
                        <div className='text-start'>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                loading={generateLinkLoading}
                                onClick={() => generateLink()}
                            >
                                {t('Generate Link')}
                            </LoadingButton>
                        </div>
                    )}
                </Stack>
                <WarningDialog
                    open={deleteCallbackWarning}
                    onClose={() => setDeleteCallbackWarning(false)}
                    onDelete={() => deleteLink()}
                    title={t('Delete Callback Link')}
                    text={t('You would not be able to receive live donations from Nedarim Plus.')}
                />
            </Section>
            <Typography variant="h2">{t('np-default-campaign')}</Typography>
            <Typography variant="subtitle1">
                {t('np-default-campaign-description')}
            </Typography>
            <Section>
                <Table
                    sx={{
                        tableLayout: 'auto',
                        width: 'auto',
                        '& .MuiTableCell-root': {
                            borderBottom: 'none',
                        },
                    }}
                    size="small"
                >
                    <TableBody>
                        <TableRow>
                            <TableCell><Typography variant="body1">{t("Campaign")}</Typography></TableCell>
                            <TableCell>
                                <FormControl size="small" sx={{ width: '14rem' }}>
                                    <InputLabel
                                        sx={{
                                            '&.MuiInputLabel-shrink': {
                                                backgroundColor: 'white',
                                                padding: '0 5px',
                                            },
                                        }}
                                    >{t("Select Campaign")}</InputLabel>
                                    <Select
                                        value={npData.default_campaign}
                                        onChange={(e) => updateNpData({ default_campaign: e.target.value || null, default_sub_campaign: null })}
                                    >
                                        <MenuItem value="">{t("None")}</MenuItem>
                                        {campaigns?.map((campaign) => (
                                            <MenuItem key={campaign.id} value={campaign.id}>
                                                {campaign.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><Typography variant="body1">{t("Sub Campaign")}</Typography></TableCell>
                            <TableCell>
                                <FormControl size="small" sx={{ width: '14rem' }}>
                                    <InputLabel
                                        sx={{
                                            '&.MuiInputLabel-shrink': {
                                                backgroundColor: 'white',
                                                padding: '0 5px',
                                            },
                                        }}
                                    >{t("Select Sub Campaign")}</InputLabel>
                                    <Select
                                        value={npData.default_sub_campaign}
                                        onChange={(e) => updateNpData({ default_sub_campaign: e.target.value || null })}
                                    >
                                        <MenuItem value="">{t("None")}</MenuItem>
                                        {npData.default_campaign && campaigns.find(campaign => campaign.id === npData.default_campaign)?.sub_campaigns?.map((subCampaign) => (
                                            <MenuItem key={subCampaign.id} value={subCampaign.id}>
                                                {subCampaign.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant="body1">
                                    {t('Donor-List Auto-Assignment')}
                                    <HelperTooltip content={t("If enabled, automatically assigns to the donor's sub-campaign")} />
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={npData.assign_donors}
                                    onChange={(e) => {
                                        updateNpData({ assign_donors: e.target.checked })
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Section>
            <Typography variant="h2">{t('Nedarim Plus Categories')}</Typography>
            <Typography variant="subtitle1">
                {t('Map your Nedarim Plus categories to your campaigns and sub-campaigns')}
            </Typography>
            <Section>
                <Table sx={{ tableLayout: 'auto', width: 'auto' }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell width={220}>{t("Np Category")}</TableCell>
                            <TableCell></TableCell>
                            <TableCell width={220}>{t("Campaign")}</TableCell>
                            <TableCell width={220}>{t("Sub Campaign")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {npGroups.data.data.map((group, index) => (
                            editGroup === index ? (
                                renderEditGroup(group, index)
                            ) : (
                                <TableRow key={index}>
                                    <TableCell>{group.group_name}</TableCell>
                                    <TableCell><SyncAltIcon className="mb-2 text-gray-500" /></TableCell>
                                    <TableCell>{group.campaign__name}</TableCell>
                                    <TableCell>{group.sub_campaign__name}</TableCell>
                                    <TableCell>
                                        <Options
                                            options={[
                                                {
                                                    text: t('Edit'),
                                                    icon: <EditRoundedIcon />,
                                                    onClick: () => {
                                                        setEditGroup(index);
                                                        setEditGroupName(group.group_name);
                                                        setEditGroupCampaign(group.campaign);
                                                        setEditGroupSubCampaign(group.sub_campaign);
                                                    },
                                                },
                                                {
                                                    text: t('Delete'),
                                                    icon: <DeleteRoundedIcon />,
                                                    onClick: () => deleteGroup(group.id),
                                                }
                                            ]}
                                            dense
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        ))}
                        {newGroup ? (
                            renderEditGroup({
                                group_name: editGroupName,
                                campaign: editGroupCampaign,
                                sub_campaign: editGroupSubCampaign,
                            })
                        ) : (
                            <div className='flex justify-start mt-2'>
                                <Button onClick={() => {
                                    setEditGroupName('');
                                    setEditGroupCampaign('');
                                    setEditGroupSubCampaign('');
                                    setNewGroup(true);
                                    setEditGroup(null);
                                }}>{t('Add new connection')}</Button>
                            </div>)}
                    </TableBody>
                </Table >
            </Section >
        </>
    )
}
export default NedarimPlus;