import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import DirectItemsFilter from "../DirectItemsFilter";
import { useTranslation } from "react-i18next";
import NoCampaignSubscription from "../NoCampaignSubscription";
import { mainStore } from "stores/MainStore";
import { uiStore } from "stores/UiStore";
import { useSnackbar } from "notistack";
import PledgeDetails from "views/Donations/Pledges/PledgeDetails";
import PledgeList from "views/Donations/Pledges/PledgeList";

function Pledges({ view }) {
	const { t } = useTranslation();
	const { subscription } = mainStore;
	const { enqueueSnackbar } = useSnackbar();
	const { campaignName, subCampaignName, groupName, fundraiserId } = useParams();
	const [pledgeParams, setPledgeParams] = useSearchParams('')
	const [filters, setFilters] = useState({});

	const [directDataOnly, setDirectDataOnly] = useState(true);

	useEffect(() => {
		pledgeParams.set('campaign__name', campaignName);
		if (subCampaignName) pledgeParams.set('sub_campaign__name', subCampaignName);
		else if (directDataOnly) pledgeParams.set('sub_campaign__name__isnull', true);
		else pledgeParams.delete('sub_campaign__name__isnull');
		if (fundraiserId) pledgeParams.set('fundraiser__contact_id', fundraiserId);
		else if (directDataOnly) pledgeParams.set('fundraiser__isnull', true);
		else pledgeParams.delete('fundraiser__isnull');

		const params = {};
		Array.from(pledgeParams.keys()).forEach((key) => {
			params[key] = JSON.stringify(pledgeParams.get(key).split(','));
		});
		setFilters(params);
	}, [campaignName, subCampaignName, groupName, fundraiserId, directDataOnly]);

	if (!subscription.campaigns) return <NoCampaignSubscription />;

	if (Object.keys(filters).length === 0) return null;

	return (
		<Box>
			<PledgeList
				directPledgesButton={view !== 'fundraisers' &&
					<DirectItemsFilter
						directDataOnly={directDataOnly}
						setDirectDataOnly={setDirectDataOnly}
						label={t('Direct Pledges Only')}
					/>
				}
				filters={filters}
			/>
		</Box>
	)
}

export default Pledges