import { Box, Card, LinearProgress, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";


function List({ name, type, listData }) {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const onCampaignClick = (campaign) => {
        if (type === 'fundraisers') {
            navigate(`${location.pathname}/${campaign.fundraiser_id}`);
        } else {
            navigate(`${location.pathname}/${campaign.name}`);
        }
    }

    return (
        <Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {t(name)}
                            </TableCell>
                            <TableCell width={280}>
                                {t('Progress')}
                            </TableCell>
                            <TableCell>
                                {t('Donations')}
                            </TableCell>
                            <TableCell>
                                {t('Donors')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <Card
                        component={TableBody}
                        sx={{ backgroundColor: 'unset' }}
                    >
                        {listData.map((campaign, index) => (
                            <TableRow
                                key={campaign.id}
                                onClick={() => onCampaignClick(campaign)}
                                sx={{
                                    height: '2.5rem',
                                    transition: 'all 0.3s',
                                    backgroundColor: 'white',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: theme => theme.palette.action.hover,
                                    },
                                }}
                            >
                                <TableCell
                                    className={`${listData.length - 1 === index && 'first:rounded-bs-2xl last:rounded-be-2xl'} ${index === 0 && 'first:rounded-ts-2xl last:rounded-te-2xl'}`}
                                    sx={{ maxWidth: '10rem' }}
                                >
                                    <Stack height='2.5rem'>
                                        <Typography sx={{ fontWeight: '600', fontSize: '0.875rem' }}>
                                            {campaign.name}
                                        </Typography>
                                        <Typography noWrap color="text.secondary" variant="body2">
                                            {campaign.description}
                                        </Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell sx={{ py: '14px' }}>
                                    <Stack spacing={0.5}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={Math.min((campaign.donations_sum / campaign.goal) * 100, 100)}
                                            color="info"
                                            sx={{
                                                height: '6px',
                                                // backgroundColor: 'secondary.light',
                                                '& .MuiLinearProgress-bar': {
                                                    borderRadius: 99,
                                                },
                                            }}
                                        />
                                        <Typography textAlign='center' variant="body2" color="text.secondary">
                                            {campaign.goal ? (
                                                <>
                                                    {(campaign.donations_sum / campaign.goal).toLocaleString(i18n.language, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 })} {t('out of')} {campaign.goal?.toLocaleString(i18n.language, { style: 'currency', currency: campaign.goal_currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                                </>
                                            ) : (
                                                campaign.donations_sum.toLocaleString(i18n.language, { style: 'currency', currency: campaign.goal_currency, minimumFractionDigits: 0, maximumFractionDigits: 2 })
                                            )}
                                        </Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Typography sx={{ fontWeight: '600', fontSize: '0.875rem' }}>
                                        {campaign.donations_sum.toLocaleString(i18n.language, { style: 'currency', currency: campaign.goal_currency, minimumFractionDigits: 0, maximumFractionDigits: 2 })}
                                    </Typography>
                                    <Typography noWrap color="text.secondary" variant="body2">
                                        {t('From')} {campaign.donations_count} {t('Donations')}
                                    </Typography>
                                </TableCell>
                                <TableCell className={`${listData.length - 1 === index && 'first:rounded-bs-2xl last:rounded-be-2xl'} ${index === 0 && 'first:rounded-ts-2xl last:rounded-te-2xl'}`}>
                                    <Typography sx={{ fontWeight: '600', fontSize: '0.875rem' }}>
                                        {campaign.donors_count}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </Card>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default List;