import { mainStore } from "stores/MainStore";
import List from "./List"
import NoCampaignSubscription from "../NoCampaignSubscription";
import { useTranslation } from "react-i18next";
import useApi from "hooks/useApi";
import { useMutation, useQuery } from "react-query";
import { uiStore } from "stores/UiStore";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import CampaignForm from "../CampaignForm";
import { useParams } from "react-router-dom";
import useCampaign from "hooks/useCampaign";
import LoadingSkeleton from "../LoadingSkeleton";
import { Stack } from "@mui/material";
import DonationsSumCard from "../DonationsSumCard";
import DonorsCountCard from "../DonorsCountCard";
import ProgressCard from "../ProgressCard";
import NoResultsFound from "components/general/NoResultsFound";
import AddFab from "components/specific/AddFab";
import { GroupsRounded } from "@mui/icons-material";

function GroupList() {
    const { subscription } = mainStore;
    const { campaignName, subCampaignName } = useParams();
    const { t } = useTranslation();
    const api = useApi();
    const { setFabAction } = uiStore;
    const { enqueueSnackbar } = useSnackbar();
    const [newGroup, setNewGroup] = useState(false);

    const { campaignQuery: parentQuery } = useCampaign(campaignName, { sub_campaign_name: subCampaignName })

    const groupParams = useMemo(() => {
        const params = {}
        if (subCampaignName) params.sub_campaign__name = subCampaignName
        else params.sub_campaign__isnull = true
        return params
    }, [campaignName, subCampaignName]);

    const groupQuery = useQuery(['groups'],
        () => api.get(`/campaigns/${campaignName}/groups`, { params: groupParams }).then(res => res.data),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
            enabled: !!campaignName,
            keepPreviousData: true,
        },
    );

    const { data: groupData, isLoading: groupLoading } = groupQuery;

    const createGroupParams = {
        campaign: groupData?.parent?.campaign,
        sub_campaign: groupData?.parent?.sub_campaign
    }

    const { mutate: createGroup, isLoading: groupUpdating } = useMutation(
        (data) => api.post('/campaigns/groups/create', { ...data, ...createGroupParams }),
        {
            onSuccess: (res) => {
                enqueueSnackbar(t('Group created.'), { variant: 'success' });
                groupQuery.refetch();
                parentQuery.refetch();
                setNewGroup(false);
            },
            onError: () => enqueueSnackbar(t('Error creating group.'), { variant: 'error' }),
        }
    );

    useEffect(() => {
        if (subscription.campaigns) setFabAction(() => setNewGroup(true))

        return () => {
            setFabAction(null);
        };
    }, []);

    if (!subscription.campaigns) return <div className="relative top-20"><NoCampaignSubscription /></div>;

    if (groupLoading) return <LoadingSkeleton />

    const { parent, results } = groupData

    return (
        <>
            {results.length === 0 ? (
                <div className="relative top-20">
                    <NoResultsFound
                        title={t('No Teams Yet!')}
                        subtitle={t('Start by creating your first team.')}
                        Icon={GroupsRounded}
                    >
                        <AddFab block />
                    </NoResultsFound>
                </div>
            ) : (
                <>
                    <Stack direction="row" spacing={2} sx={{ my: '1rem', justifyContent: 'space-between' }}>
                        <DonationsSumCard value={parent.donations_sum} currency='ILS' />
                        <DonorsCountCard value={parent.donors_count} />
                        <ProgressCard value={parent.goal ? parent.donations_sum / parent.goal : '--'} />
                    </Stack>
                    <List listData={results} name='Team' type='groups' />
                </>
            )}
            {newGroup &&
                <CampaignForm
                    title={t('Create Team')}
                    onClose={() => setNewGroup(false)}
                    onSave={(data) => createGroup(data)}
                    saving={groupUpdating}
                    type='sub-campaigns'
                />}
        </>
    )
}

export default GroupList