import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, MenuItem, Popover, Select, TextField } from "@mui/material";
import currencies from "data/currencies";
import { donationStatus, paymentMethods, transactionTypes } from "data/general";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import CreditScoreRoundedIcon from '@mui/icons-material/CreditScoreRounded';
import AddCardRoundedIcon from '@mui/icons-material/AddCardRounded';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import PriceCheckRoundedIcon from '@mui/icons-material/PriceCheckRounded';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import TurnedInNotRoundedIcon from '@mui/icons-material/TurnedInNotRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import SignpostOutlinedIcon from '@mui/icons-material/SignpostOutlined';
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import { log } from "utils/devUtils";
import useApi from "hooks/useApi";

const FILTER_COMPONENETS = {
    text: TextFilter,
    checklist: ChecklistFilter,
    number: TextFilter,
    date: TextFilter,
    payments: TextFilter,
}

function convertDateFormat(inputDate) {
    const parts = inputDate.split('-');
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
}

const FilterOptionsNames = {
    icontains: 'Contains',
    iexact: 'Is Exactly',
    istartswith: 'Starts With',
    iendswith: 'Ends With',
    in: 'Is',
    gte: 'Greater Than',
    lte: 'Less Than',
    date: 'Is',
    'icontains!': 'Does Not Contain',
    'iexact!': 'Is Not',
}

const paymentsOptions = ['0', '1', '6', '12', '24', '36'];

const paymentsLabels = [{ label: 'No Time Limit', value: '0' }]

const filterFields = {
    donations: [
        [
            {
                header: 'Donation Filters',
                filters: [
                    { name: 'donor_name', type: 'text', label: 'Donor Name', icon: <PersonIcon />, filterOptions: ['icontains'] },
                    { name: 'amount', type: 'number', label: 'Amount', icon: <PinOutlinedIcon />, decimalPoints: 2, filterOptions: ['gte', 'lte', 'iexact'] },
                    { name: 'currency', type: 'checklist', label: 'Currency', icon: <PaidOutlinedIcon />, filterOptions: ['in'] },
                    { name: 'payments', type: 'payments', label: 'Payments', icon: <CurrencyExchangeRoundedIcon />, filterOptions: ['gte', 'lte', 'iexact'] },
                    { name: 'payments_months', type: 'number', label: 'Payments Charged', icon: <CreditScoreRoundedIcon />, decimalPoints: 0, filterOptions: ['gte', 'lte', 'iexact'] },
                    { name: 'payments_left', type: 'number', label: 'Payments Left', icon: <AddCardRoundedIcon />, decimalPoints: 0, filterOptions: ['gte', 'lte', 'iexact'] },
                    { name: 'donation_method', type: 'checklist', label: 'Donation Method', icon: <ContactlessOutlinedIcon />, filterOptions: ['in'] },
                    { name: 'donation_status', type: 'checklist', label: 'Donation Status', icon: <PriceCheckRoundedIcon />, filterOptions: ['in'] },
                    { name: 'donation_type', type: 'checklist', label: 'Donation Type', icon: <SyncRoundedIcon />, filterOptions: ['in'] },
                    { name: 'donation_date', type: 'date', label: 'Donation Date', icon: <EventRoundedIcon />, filterOptions: ['gte', 'lte', 'date'] },
                    { name: 'fundraiser_name', type: 'text', label: 'Fandraiser', icon: <SupervisorAccountIcon />, filterOptions: ['icontains'] },
                    { name: 'donation_notes', type: 'text', label: 'Donation Notes', icon: <RequestQuoteOutlinedIcon />, filterOptions: ['icontains', 'iexact', 'istartswith', 'iendswith'] },
                    { name: 'donor_note', type: 'text', label: 'Donor Notes', icon: <ContactPageOutlinedIcon />, filterOptions: ['icontains', 'iexact', 'istartswith', 'iendswith'] },
                ]
            }
        ]
    ],
    contacts: [
        [
            {
                header: 'Contact Details',
                filters: [
                    { name: 'full_name', type: 'text', label: 'Name', icon: <PersonIcon />, filterOptions: ['icontains', 'icontains!'] },
                    { name: 'description', type: 'text', label: 'Description', icon: <DescriptionOutlinedIcon />, filterOptions: ['icontains', 'iexact', 'istartswith', 'iendswith', 'icontains!', 'iexact!'] },
                    { name: 'connections__relationship', type: 'text', label: 'Relationship', icon: <PeopleOutlineRoundedIcon />, filterOptions: ['icontains', 'iexact', 'istartswith', 'iendswith', 'icontains!', 'iexact!'] },
                    { name: 'tags__name', type: 'checklist', label: 'Tags', icon: <LocalOfferOutlinedIcon />, filterOptions: ['in'] },
                ]
            },
        ],
        [
            {
                header: 'Address Details',
                filters: [
                    { name: 'country', type: 'text', label: 'Country', icon: <PublicRoundedIcon />, filterOptions: ['icontains', 'iexact', 'istartswith', 'iendswith', 'icontains!', 'iexact!'] },
                    { name: 'city', type: 'text', label: 'City', icon: <LocationCityRoundedIcon />, filterOptions: ['icontains', 'iexact', 'istartswith', 'iendswith', 'icontains!', 'iexact!'] },
                    { name: 'state', type: 'text', label: 'State', icon: <LocationCityRoundedIcon />, filterOptions: ['icontains', 'iexact', 'istartswith', 'iendswith', 'icontains!', 'iexact!'] },
                    { name: 'street', type: 'text', label: 'Street', icon: <SignpostOutlinedIcon />, filterOptions: ['icontains', 'iexact', 'istartswith', 'iendswith', 'icontains!', 'iexact!'] },
                ]
            }
        ],
        [
            {
                header: 'Contact Donations',
                filters: [
                    { name: 'donations__donation_date', type: 'date', label: 'Donation Date', icon: <EventRoundedIcon />, filterOptions: ['gte', 'lte', 'date'] },
                    { name: 'donations__amount', type: 'number', label: 'Donation Amount', icon: <PriceChangeOutlinedIcon />, decimalPoints: 2, filterOptions: ['gte', 'lte', 'iexact', 'iexact!'] },
                    { name: 'donations__currency', type: 'checklist', label: 'Donation Currency', icon: <PaidOutlinedIcon />, filterOptions: ['in'] },
                    { name: 'donations__donation_type', type: 'checklist', label: 'Donation Type', icon: <SyncRoundedIcon />, filterOptions: ['in'] },
                    { name: 'donations__donation_method', type: 'checklist', label: 'Donation Method', icon: <ContactlessOutlinedIcon />, filterOptions: ['in'] },
                ]
            }
        ]
    ]
}


function Filters({ filterSet, filtersAnchorEl, setFiltersAnchorEl, jsonFilters, setJsonFilters, saveFilterButton = true }) {
    const { t } = useTranslation();
    const api = useApi();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [anchorEl, setAnchorEl] = useState(null);
    const [openedFilter, setOpendFilter] = useState(null);
    const [savedFiltersAnchorEl, setSavedFiltersAnchorEl] = useState(null);

    const [filters, setFilters] = useState([]);
    const [savedFilters, setSavedFilters] = useState([]);

    const [tagsOptions, setTagsOptions] = useState([]);

    const flattenedFilters = filterFields?.[filterSet]?.reduce((acc, sectionGroup) => {
        return [...acc, ...sectionGroup.reduce((innerAcc, section) => [...innerAcc, ...section.filters], [])];
    }, []) || [];


    const isLastOddFilter = (index) => {
        return index % 2 === 0 && index === filterFields[filterSet].length - 1;
    }

    useEffect(() => {
        if (!savedFiltersAnchorEl) return;
        api.get(`/${filterSet}/filters/`).then(res => {
            setSavedFilters(res.data);
        }).catch(err => console.error('Error fetching filters:', err));
    }, [savedFiltersAnchorEl]);


    const decodeFiltersFromUrlParams = (paramsStr) => {
        const params = new URLSearchParams(paramsStr || location.search);
        log({ params })
        const newFilters = Array.from(params.keys()).map(param => {
            const [key, value] = param.split('=');
            const name = key.split('__').slice(0, -1).join('__');
            const filterOption = key.split('__').pop();
            return params.get(param).split(',').map(v => {
                log({ name })
                const label = flattenedFilters.find((f) => f.name === name)?.label;
                const equality = FilterOptionsNames[filterOption];
                const valueLabel = options[name]?.find((o) => o.value === v)?.label || name === 'donation_date' && convertDateFormat(v)
                const icon = flattenedFilters.find((f) => f.name === name)?.icon;
                return {
                    name,
                    label,
                    equality,
                    filterOption,
                    value: v,
                    valueLabel,
                    icon
                };
            });
        });
        return newFilters.flat();
    }

    const decodeFiltersJson = () => {
        const newFilters = Object.keys(jsonFilters).map((key) => {
            const name = key.split('__').slice(0, -1).join('__');
            const filterOption = key.split('__').pop();
            return jsonFilters[key].map(v => {
                const label = flattenedFilters.find((f) => f.name === name)?.label;
                const equality = FilterOptionsNames[filterOption];
                const valueLabel = options[name]?.find((o) => o.value === v)?.label || name === 'donation_date' && convertDateFormat(v)
                const icon = flattenedFilters.find((f) => f.name === name)?.icon;
                return {
                    name,
                    label,
                    equality,
                    filterOption,
                    value: v,
                    valueLabel,
                    icon
                };
            });
        });
        return newFilters.flat();
    }

    const encodeFiltersToJson = (filtersArray) => {
        const newJsonFilters = {};
        filtersArray.forEach((filter) => {
            const key = `${filter.name}__${filter.filterOption}`;
            if (newJsonFilters[key]) {
                newJsonFilters[key] = [...newJsonFilters[key], filter.value];
            } else {
                newJsonFilters[key] = [filter.value];
            }
        });
        return newJsonFilters;
    }

    const currencyValues = Object.keys(currencies).map(key => ({
        label: currencies[key].name,
        value: key
    }));

    const tagOptions = () => {
        api.get('/contacts/tags/', {
            params: {
                all: true,
            },
        }).then((response) => {
            const fetchedTags = response.data.map((tag) => ({
                label: tag.name,
                value: tag.name,
                color: tag.color,
            }));
            setTagsOptions(fetchedTags);
        }).catch((error) => {
            console.error('Error fetching tags:', error);
        });
    }

    useEffect(() => {
        if (openedFilter === 'tags__name') {
            tagOptions();
        }
    }, [openedFilter])

    const options = {
        currency: currencyValues,
        donation_type: transactionTypes,
        donation_method: paymentMethods,
        payments: paymentsLabels,
        donation_status: donationStatus,
        tags__name: tagsOptions,
        donations__currency: currencyValues,
        donations__donation_type: transactionTypes,
        donations__donation_method: paymentMethods,
    }

    const saveFilter = () => {
        if (savedFilters.some((f) => f.filter == location.search)) return;
        const filter = location.search;
        const name = prompt(t('Enter filter name'));
        if (name) {
            api.post(`/${filterSet}/filters/`, { name, filter }).then((res) => {
                setSavedFilters([...savedFilters, res.data]);
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    const removeSavedFilter = (id) => {
        api.delete(`/${filterSet}/filters/`, {
            params: {
                id
            },
        }).then((res) => {
            setSavedFilters(savedFilters.filter((f) => f.id !== id));
        }).catch((err) => {
            console.log(err);
        })
    }

    const addFilter = (key, value, type) => {
        if (jsonFilters) {
            const newJsonFilters = { ...jsonFilters };
            if (newJsonFilters[key]) {
                newJsonFilters[key] = [...newJsonFilters[key], value];
            } else {
                newJsonFilters[key] = [value];
            }
            setJsonFilters({ ...newJsonFilters });
        } else {
            const existingValues = searchParams.get(key)?.split(',') || [];
            existingValues.push(value);
            searchParams.set(key, existingValues.join(','));
            setSearchParams(searchParams);
        }
        if (type === 'checklist') {
            return;
        }
        setAnchorEl(null);
        setFiltersAnchorEl(null);
        setOpendFilter(null);
    }

    const removeFilter = (key, value) => {
        if (jsonFilters) {
            const newJsonFilters = { ...jsonFilters };
            newJsonFilters[key] = newJsonFilters[key].filter((v) => v !== value);
            if (newJsonFilters[key].length === 0) {
                delete newJsonFilters[key];
            }
            setJsonFilters({ ...newJsonFilters });
        } else {
            const existingValues = searchParams.get(key)?.split(',') || [];
            const newValues = existingValues.filter((v) => v !== value);
            if (newValues.length === 0) {
                searchParams.delete(key);
            } else {
                searchParams.set(key, newValues.join(','));
            }
            setSearchParams(searchParams);
        }
    }

    useEffect(() => {
        if (jsonFilters) {
            setFilters(decodeFiltersJson());
        } else if (location.search) {
            setFilters(decodeFiltersFromUrlParams());
        } else {
            setFilters([]);
        }
    }, [searchParams, jsonFilters])

    return (
        <>
            <div className="flex flex-wrap gap-3">
                {filters.map((filter, index) => (
                    <Chip
                        key={index}
                        label={<p>{t(filter.label)} {t(FilterOptionsNames[filter.filterOption])} <b>{filter.valueLabel ? t(filter.valueLabel) : filter.value}</b></p>}
                        onDelete={() => {
                            removeFilter(`${filter.name}__${filter.filterOption}`, filter.value);
                        }}
                        icon={filter.icon}
                    />
                ))}
                {filters.length > 0 && saveFilterButton &&
                    <Chip
                        label={<p>{t('Save Filter')}</p>}
                        deleteIcon={savedFilters.some((f) => f.filter == location.search) ? <BookmarkRoundedIcon /> : <TurnedInNotRoundedIcon />}
                        color="primary"
                        variant="outlined"
                        onDelete={saveFilter}
                        onClick={saveFilter}
                    />}
            </div>
            <Popover
                open={filtersAnchorEl}
                anchorEl={filtersAnchorEl}
                onClose={() => setFiltersAnchorEl(null)}
                sx={{ '& .MuiPopover-paper': { borderRadius: '0.75rem', width: '28rem' } }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ p: 2 }}>
                    <Grid container>
                        {filterFields[filterSet].map((column, colIndex) => (
                            <Grid key={colIndex} item xs={isLastOddFilter(colIndex) ? 12 : 6}>
                                {column.map((filterGroup, index) => (
                                    <List
                                        key={index}
                                        dense
                                        subheader={<ListSubheader>{t(filterGroup.header)}</ListSubheader>}
                                    >

                                        <Grid container>
                                            {filterGroup.filters.map((filterField, fieldIndex) => {
                                                const FilterComponent = FILTER_COMPONENETS[filterField.type];

                                                return (
                                                    <React.Fragment key={fieldIndex}>
                                                        <Grid item xs={isLastOddFilter(colIndex) ? 6 : 12}>
                                                            <ListItemButton
                                                                key={fieldIndex}
                                                                onClick={(e) => {
                                                                    setOpendFilter(filterField.name);
                                                                    setAnchorEl(e.currentTarget);
                                                                }}
                                                            >
                                                                <ListItemIcon>{filterField.icon}</ListItemIcon>
                                                                <ListItemText primary={t(filterField.label)} />
                                                            </ListItemButton>
                                                        </Grid>
                                                        {openedFilter === filterField.name && (
                                                            <FilterComponent
                                                                open={openedFilter === filterField.name}
                                                                anchorEl={anchorEl}
                                                                setAnchorEl={setAnchorEl}
                                                                filterField={filterField}
                                                                type={filterField.type}
                                                                addFilter={addFilter}
                                                                removeFilter={removeFilter}
                                                                options={options[filterField.name]}
                                                                checked={jsonFilters ? jsonFilters[`${filterField.name}__in`] || [] : searchParams.get(`${filterField.name}__in`)?.split(',') || []}
                                                                filters={filters}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Grid>
                                    </List>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                    <div className="ms-2">
                        <Button
                            onClick={(e) => { setSavedFiltersAnchorEl(e.currentTarget) }}
                            endIcon={<KeyboardArrowDownRoundedIcon />}
                            size="large"
                        >
                            {t('Select Saved Filter')}
                        </Button>
                        <Popover
                            open={savedFiltersAnchorEl}
                            anchorEl={savedFiltersAnchorEl}
                            onClose={() => { setSavedFiltersAnchorEl(null) }}
                            sx={{ '& .MuiPopover-paper': { borderRadius: '0.5rem' } }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                        >
                            <List
                                sx={{ width: 270, maxHeight: 360, overflow: 'auto' }}
                            >
                                {savedFilters.length === 0 && (
                                    <ListItem>
                                        <ListItemText primary={t('No Saved Filters')} />
                                    </ListItem>
                                )}
                                {savedFilters.map((filter, index) => (
                                    <ListItem
                                        key={index}
                                        className="flex justify-between"
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="delete" onClick={() => removeSavedFilter(filter.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton onClick={() => {
                                            if (jsonFilters) {
                                                setJsonFilters({ ...encodeFiltersToJson(decodeFiltersFromUrlParams(filter.filter)) })
                                            } else {
                                                navigate(filter.filter)
                                            }
                                            setSavedFiltersAnchorEl(null)
                                            setFiltersAnchorEl(null)
                                        }}>
                                            <ListItemText primary={filter.name} />
                                        </ListItemButton>
                                    </ListItem>))}
                            </List>
                        </Popover>
                    </div>
                </Box>
            </Popover>
        </>
    )
}

function TextFilter({ open, anchorEl, setAnchorEl, filterField, type, addFilter }) {
    const { t } = useTranslation();
    const inputRef = useRef();
    const buttonRef = useRef();
    const [filterOption, setFilterOption] = useState(filterField?.filterOptions?.[0]);
    const [value, setValue] = useState('');

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (open && inputRef.current) {
                inputRef.current.focus();
            }
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }, [open, anchorEl]);

    const handleEnter = () => {
        if (open && buttonRef.current) {
            buttonRef.current.click()
        }
    }

    return (
        <Popover
            open={open && anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            sx={{ '& .MuiPopover-paper': { borderRadius: '0.75rem' } }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className="flex flex-col p-8">
                <h3 className="font-semibold">{t(filterField.label)}</h3>
                <div className="flex gap-2 items-end">
                    <div className="w-32 mb-1">
                        <FormControl
                            fullWidth
                            variant="standard"
                            sx={{
                                pb: 0,
                                '& .MuiInput-underline:before': {
                                    borderBottom: 'none',
                                    pb: 0,
                                },
                                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottom: 'none',
                                    pb: 0,
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottom: 'none',
                                },
                                '& .Mui-focused:after': {
                                    borderBottom: 'none',
                                },
                            }}>
                            <Select
                                fullWidth
                                value={filterOption}
                                onChange={(e) => setFilterOption(e.target.value)}
                                size="small"
                                sx={{ pb: 0 }}
                            >
                                {filterField?.filterOptions?.map((filterOption, index) => (
                                    <MenuItem key={index} value={filterOption}>{t(FilterOptionsNames[filterOption])}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="w-48">
                        {filterField.type === 'payments' ? (
                            <Autocomplete
                                clearOnEscape
                                disableClearable
                                freeSolo
                                fullWidth
                                options={paymentsOptions}
                                getOptionLabel={(option) => (option === '0' ? t('No Time Limit') : option) || (value === '0' ? t('No Time Limit') : value)}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label={t('Payments')}
                                        variant="standard"
                                    />}
                                filterOptions={(options) => options}
                                filterSelectedOptions={false}
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue(newValue)
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setValue(newInputValue)
                                }}
                            />
                        ) : (
                            <TextField
                                fullWidth
                                variant="standard"
                                placeholder={t(filterField.label)}
                                size="small"
                                type={type}
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                inputRef={inputRef}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleEnter();
                                    }
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className="mt-2">
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ textTransform: 'none', borderRadius: '0.5rem' }}
                        onClick={() => addFilter(`${filterField.name}__${filterOption}`, type === 'number' ? parseFloat(value).toFixed(filterField.decimalPoints) : value, type)}
                        ref={buttonRef}
                    >
                        {t('Add')}
                    </Button>
                </div>
            </div>
        </Popover>
    )
}


function ChecklistFilter({ open, anchorEl, setAnchorEl, filterField, onOpen, options, checked, addFilter, removeFilter, filters }) {
    const { t } = useTranslation();
    const [filterOption, setFilterOption] = useState(filterField?.filterOptions?.[0]);

    return (
        <Popover
            open={open && anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            sx={{ '& .MuiPopover-paper': { borderRadius: '0.75rem' } }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className="p-8">
                <label className="font-semibold">{t(filterField?.label)}</label>
                <FormGroup className="mt-2">
                    <div className="flex flex-col max-h-48 overflow-auto">
                        {options.map((option, index) => (
                            <div key={index} >
                                <FormControlLabel
                                    control={<Checkbox checked={checked.includes(option.value)} />}
                                    label={
                                        <div
                                            className="w-48 px-2 py-1 rounded-md text-sm"
                                            style={{ backgroundColor: option.color, color: option.color && '#FFFFFF' }}
                                        >
                                            {t(option.label)}
                                        </div>
                                    }
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            addFilter(`${filterField.name}__${filterOption}`, option.value, filterField.type)
                                        } else {
                                            removeFilter(`${filterField.name}__${filterOption}`, option.value)
                                        }
                                    }}
                                />
                            </div>))}
                    </div>
                </FormGroup>
            </div>
        </Popover>
    )
}


export default Filters;
export { ChecklistFilter }