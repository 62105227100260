import { Card, CardContent, Typography, Box } from '@mui/material';

const NoResultsFound = ({ title, subtitle, Icon, image, children, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <Card elevation={0} sx={{ maxWidth: '70%', margin: 'auto', boxShadow: 'none', backgroundColor: 'unset' }}>
        <CardContent>
          {Icon && <Icon {...props.icon} sx={{ fontSize: '60px', color: props.icon?.color || '#9e9e9e' }} />}
          {image}
          <Typography variant="h6" sx={{ marginTop: '16px', marginBottom: '8px', textAlign: 'center' }}>
            {title}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '24px', textAlign: 'center' }}>
            {subtitle}
          </Typography>
          {children}
        </CardContent>
      </Card>
    </Box>
  );
};

export default NoResultsFound;
