import { DonutLargeRounded, GroupRounded, HelpRounded } from "@mui/icons-material";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { PieChart } from "@mui/x-charts";
import { PieCenterLabel } from "components/common/PieChart";
import { useTranslation } from "react-i18next";

function NavCard({ title, data, to, key, donationCount, donorCount, donationsSum, tooltipTitle, tooltipContent }) {
    const { t, i18n } = useTranslation();
    const tooltipFormatter = (value) => {
        return value.value.toLocaleString(i18n.language, { style: 'currency', currency: value.currency || 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }

    return (
        <Button
            key={key}
            component={RouterLink}
            to={to}
            // variant="contained"
            sx={{
                textTransform: 'none',
                maxWidth: '24rem',
                width: '100%',
                backgroundColor: 'white',
                borderRadius: '1rem',
                p: '1rem',
                // pb: 0
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    {/* Set the title to stay in one line */}
                    <Typography display="flex" variant="h6">
                        {/* The variant should be conditional */}
                        {title}
                        {tooltipTitle && <Tooltip
                            title={<>
                                <Typography gutterBottom>
                                    {tooltipTitle}
                                </Typography>
                                <Typography variant="body2">
                                    {tooltipContent}
                                </Typography>
                            </>}
                            placement="top"
                        >
                            <HelpRounded color="disabled" sx={{ fontSize: '1rem' }} />
                        </Tooltip>}
                    </Typography>
                    <Box>
                        <Typography variant="body1" color="text.secondary">
                            {data.length} {t('Items')}
                        </Typography>
                        {/* <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                            <DonutLargeRounded sx={{ mr: 1 }} />
                            {donationCount} {t('Donations')}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                            <GroupRounded sx={{ mr: 1 }} />
                            {donorCount} {t('Donors')}
                        </Typography> */}
                    </Box>
                </Grid>
                <Grid item xs={6} borderRadius={999}>
                    <PieChart
                        series={[
                            {
                                startAngle: -90,
                                endAngle: 90,
                                paddingAngle: 0,
                                innerRadius: 50,
                                outerRadius: 60,
                                cornerRadius: 3,
                                highlightScope: { faded: 'global', highlighted: 'item' },
                                faded: { innerRadius: 50, additionalRadius: 0 },
                                highlighted: { additionalRadius: 2, cornerRadius: 4 },
                                cy: 60,
                                valueFormatter: tooltipFormatter,
                                data: data.length > 0 ? data : [
                                    {
                                        label: t('No Items'),
                                        value: 1,
                                        color: 'rgba(0, 0, 0, 0.1)',
                                    },
                                ],
                            },
                        ]}
                        margin={{ right: 5 }}
                        height={66}
                        
                        slotProps={{
                            legend: {
                                hidden: true,
                                position: { vertical: 'middle', horizontal: 'right' },
                                padding: 0,
                            },
                        }}
                    >
                        <PieCenterLabel position="bottom">
                            {donationsSum}
                        </PieCenterLabel>
                    </PieChart>
                </Grid>
            </Grid>
        </Button>
    )
}

export default NavCard;