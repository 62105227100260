import { useQuery } from "react-query";
import api from "api/axiosInstance"
import { donationsStore } from "stores/DonationsStore";
import { useState } from "react";

const fetchDonations = async (params) => {
    const { data } = await api.get("/donations/donations", { params })
    return data
}

export const useDonations = (options = {}) => {
    const { getParams, setDonationsCount } = donationsStore;
    const [fetchAllEnabled, setFetchAllEnabled] = useState(false);

    const { onFetchAllSuccess } = options;

    const donationsQuery = useQuery(["donations", getParams], () => fetchDonations(getParams), {
        enabled: options?.enabled,
        onSuccess: (data) => setDonationsCount(data.count)
    });

    const allDonationsQuery = useQuery(["allDonations", fetchAllEnabled], () => fetchDonations({...getParams, all: true}), {
        enabled: fetchAllEnabled,
        onSuccess: onFetchAllSuccess,
    });

    const fetchAllDonations = () => {
        if (!fetchAllEnabled) allDonationsQuery.refetch();
        else setFetchAllEnabled(true);
    }

    return {
        donationsQuery,
        fetchAllDonations,
        allDonationsQuery
    }
}
