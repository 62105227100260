import { makeAutoObservable } from 'mobx';

class ContactsStore {
    contactsList = [];
    filters = [];
    params = new URLSearchParams('');
    search = '';
    contactId = '';
    pageSize = 50;
    sort = [];
    currentPage = 1;
    totalPages = 1;
    listType = '';
    location = null;
    count = 0;
    nextPageContactId = null;
    prevPageContactId = null;
    referrer = '';

    constructor() {
        makeAutoObservable(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.setSort = this.setSort.bind(this)
        this.getSort = this.getSort.bind(this)
        this.setFilters = this.setFilters.bind(this)
        this.setContactsList = this.setContactsList.bind(this)
        this.setSearchActive = this.setSearchActive.bind(this)
        this.setContactId = this.setContactId.bind(this)
        this.setSearch = this.setSearch.bind(this)
        this.setPageSize = this.setPageSize.bind(this)
        this.setParams = this.setParams.bind(this)
        this.reset = this.reset.bind(this)
        this.setListType = this.setListType.bind(this)
        this.setLocation = this.setLocation.bind(this)
        this.setCount = this.setCount.bind(this)
        this.setNextPageContactId = this.setNextPageContactId.bind(this)
        this.setPrePageContactId = this.setPrePageContactId.bind(this)
        this.setReferrer = this.setReferrer.bind(this)
    }

    setReferrer(referrer) {
        this.referrer = referrer;
    }

    setNextPageContactId(contactId) {
        this.nextPageContactId = contactId;
    }

    setPrePageContactId(contactId) {
        this.prevPageContactId = contactId;
    }

    setCount(count) {
        this.count = count;
    }

    setLocation(location) {
        this.location = location;
    }

    setListType(listType) {
        this.listType = listType;
    }

    setParams(params) {
        this.params = params;
    }

    setSearch(search) {
        this.currentPage = 1;
        this.contactId = '';
        this.search = search;
    }

    setContactId(contactId) {
        this.currentPage = 1;
        // this.search = '';
        this.contactId = contactId;
    }

    setPageSize(pageSize) {
        this.currentPage = 1;
        this.pageSize = pageSize;
        localStorage.setItem('contactsPageSize', pageSize);
    }

    setSearchActive(searchActive) {
        this.searchActive = searchActive;
    }

    setContactsList(contactsList) {
        this.contactsList = contactsList.map(contact => contact.contact_id);
    }

    setFilters(filters) {
        this.filters = filters;
    }

    setCurrentPage(page) {
        this.currentPage = page;
    }

    getSort(field) {
        const sortItem = this.sort.find((item) => item[0] === field);
        return sortItem ? sortItem[1] : '';
    }

    setSort(field, direction) {
        const existingSortIndex = this.sort.findIndex((item) => item[0] === field);
        if (existingSortIndex > -1) {
            if (direction !== '') {
                // Update the existing sort direction
                this.sort[existingSortIndex][1] = direction;
            } else {
                // Remove the sort if direction is empty
                this.sort.splice(existingSortIndex, 1);
            }
        } else if (direction !== '') {
            // Add a new sort if the field is not currently being sorted
            this.sort.push([field, direction]);
        }
    }

    setFilters(filters) {
        this.filters = filters
    }

    reset() {
        this.pageSize = 50;
        this.location = null;
        this.contactsList = [];
        this.filters = [];
        this.params = new URLSearchParams('');
        this.search = '';
        this.contactId = '';
        this.pageSize = 50;
        this.sort = [];
        this.currentPage = 1;
        this.totalPages = 1;
    }

    get getParams() {
        const params = {
            page: this.currentPage,
            page_size: this.pageSize,
            status__in: 1,
            order_by: JSON.stringify(this.sort)
        }
        if (this.contactId) {
            params.contact_id = this.contactId;
            return params
        }
        if (this.search) params.search = this.search
        // this.filters.forEach(filter => {
        //     const paramName = `${filter.name}__${filter.filterOption}`;
        //     const paramValue = JSON.stringify(filter.value.split(','));
        //     params[paramName] = paramValue;
        // });
        // Array.from(this.params.keys()).forEach((key) => {
        //     params[key] = JSON.stringify(this.params.get(key).split(','));
        // });
        // return params

        Object.keys(Object.fromEntries(this.params)).forEach((key) => {
            const value = this.params.get(key);
            params[key] = value.split(',');
        });
        return params;
    }
}

export const contactsStore = new ContactsStore();