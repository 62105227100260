import { Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";

function OptionsButton({ onClick, button }) {
    if (button) return (
        <Box sx={{ cursor: 'pointer' }} onClick={onClick}>{button}</Box>
    );

    return (
        <IconButton onClick={onClick} >
            <MoreVertIcon />
        </IconButton>
    );
}



function Options({ options = [], dense = false, variant, optionsButton, ...props }) {
    const dir = document.dir;
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <div>
            <OptionsButton onClick={(e) => setAnchorEl(e.currentTarget)} button={optionsButton} />
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                sx={{ '& .MuiPopover-paper': { borderRadius: '0.5rem' } }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: dir === 'rtl' ? 'left' : 'right',
                }}
                {...props}
                id="options-menu"
            >
                <List dense={variant === 'secondary' || dense} sx={{ maxWidth: 270, bgcolor: 'background.paper' }}>
                    {options.map((option, index) => {
                        return (
                            <ListItem key={index} disablePadding >
                                <ListItemButton onClick={(e) => option.onClick(e)} disabled={option.disabled} >
                                    {option?.icon ? <ListItemIcon sx={{
                                        ...(variant === 'secondary' ? { minWidth: '2.25rem' } : {})
                                    }}>{option.icon}</ListItemIcon> : null}
                                    <ListItemText primary={option.text} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </List>
            </Popover>
        </div>
    );
}

export default Options;