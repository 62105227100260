import { ArrowDropDownRounded, CheckRounded } from "@mui/icons-material";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, Link, Tooltip, Checkbox, Stack, Chip } from "@mui/material";
import Options from "components/Options";
import Section from "components/common/Section";
import Sort from "components/sort";
import { donationTypes, getItemsStatus, getPriority, itemStatus } from "data/general";
import useApi from "hooks/useApi";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { localDate } from "utils/dates";
import { getNestedValue } from "utils/helpers";
import { donorStatusMapping } from "views/Contacts/Contacts/ContactDetails/Cards/DonorCard/DonorStatus";
import { addressString } from "views/Contacts/Contacts/ContactList";

function GroupedField({ items = [], renderItem = (item) => item }) {
    return (
        <>
            {items.length !== 0 ? (
                <div className="flex gap-1 min-w-max">
                    <Typography>
                        {renderItem(items?.[0])}
                        <Tooltip
                            placement="top"
                            title={items.map((item, index) => (
                                <Typography variant="inherit" key={index}>
                                    {renderItem(item)}
                                </Typography>
                            ))}
                        >
                            <Link
                                underline="none"
                            >
                                {items.length > 1 ? ` +${items.length - 1}` : null}
                            </Link>
                        </Tooltip>
                    </Typography>
                </div>
            ) : null}
        </>
    );
}

function DisplayName({ contact }) {
    if (!contact) return null;

    return (
        <Link
            component={RouterLink}
            to={`/contacts/${contact.contact_id}`}
            underline="hover"
            fontWeight={600}
            id={contact.contact_id}
        >
            {contact.display_name}
        </Link>
    );
}

function DefaultCell({ row, field }) {
    return <Typography>{getNestedValue(row, field)}</Typography>;
}

function ContactName({ row }) {
    return <Typography><DisplayName contact={row} /></Typography>;
}

function ContactAddress({ row }) {
    return (
        <Typography>
            {addressString(row)}
        </Typography>
    );
}

function Relationship({ row }) {
    return (
        <GroupedField
            items={row.connections}
            renderItem={(connection) => (
                <span>
                    <span className="me-1">{connection.relationship}</span>
                    {connection.related_to ? (
                        <DisplayName contact={connection.related_to} />
                    ) : null}
                </span>
            )}
        />
    );
}

function Emails({ row }) {
    return (
        <GroupedField
            items={row.email_addresses}
            renderItem={(email) => (
                <Link
                    component={RouterLink}
                    to={`mailto:${email}`}
                    underline="hover"
                    fontWeight={600}
                >
                    {email}
                </Link>
            )}
        />
    );
}

function Phones({ row }) {
    return (
        <GroupedField
            items={row.phone_numbers}
            renderItem={(phone) => (
                <Link
                    component={RouterLink}
                    to={`tel:${phone}`}
                    underline="hover"
                    fontWeight={600}
                >
                    {phone}
                </Link>
            )}
        />
    );
}

function DonationId({ row }) {
    return (
        <Typography>
            <Link
                component={RouterLink}
                to={`/donations/${row.donation_id}`}
                underline="hover"
                fontWeight={600}
            >
                {row.donation_id}
            </Link>
        </Typography>
    );
}

function ExpenseId({ row }) {
    return (
        <Typography>
            <Link
                component={RouterLink}
                to={`/expenses/${row.expense_id}`}
                underline="hover"
                fontWeight={600}
            >
                {row.expense_id}
            </Link>
        </Typography>
    );
}

function ItemContact({ row }) {
    if (row.contact?.status === 1) return (
        <Typography>
            <DisplayName contact={row.contact} />
        </Typography>
    );

    return (
        <Typography>
            {row.contact?.display_name}
        </Typography>
    );
}

function DateField({ row, field }) {
    return (
        <Typography>
            {localDate(row[field])}
        </Typography>
    );
}

function DonationType({ row }) {
    const { t } = useTranslation();

    return (
        <Typography>
            {t(donationTypes[row.donation_type]?.label)}
        </Typography>
    );
}

function CurrencyAmount({ row }) {
    const { i18n } = useTranslation();

    if (!row.amount) return null;

    return (
        <Typography>
            {row.amount.toLocaleString(
                i18n.language,
                { style: "currency", currency: row.currency, minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}
        </Typography>
    );
}

function DonationPayments({ row }) {
    const { t } = useTranslation();

    return (
        <Typography sx={{ direction: "ltr" }} >
            <span dir="ltr">
                {row.payments > 1 && (`${row.payments_months} / `)}
                {row.payments === 0 ? `${row.payments_months} / ${t('No Time Limit')}` : (row.payments)}
            </span>
        </Typography>
    );
}

function Fundraiser({ row }) {
    return (
        <Typography>
            <DisplayName contact={row.fundraiser} />
        </Typography>
    );
}

function DonorPriority({ row }) {
    const { t } = useTranslation();
    const priority = getPriority(row.donor.priority);

    return (
        <Chip
            label={t(priority.label)}
            color={priority.color}
            variant="filled"
            size="small"
        />
    );
}

function DonorStatus({ row }) {
    const { t } = useTranslation();
    const status = donorStatusMapping[row.donor.status];

    return (
        <Chip
            label={t(row.donor.status)}
            color={status.color}
            variant="filled"
            size="small"
        />
    );
}

const cellComponents = {
    display_name: ContactName,
    address: ContactAddress,
    relationship: Relationship,
    email_addresses: Emails,
    phone_numbers: Phones,
    donation_id: DonationId,
    donation_type: DonationType,
    donation_payments: DonationPayments,
    fundraiser: Fundraiser,
    expense_id: ExpenseId,
    'donor.priority': DonorPriority,
    'donor.status': DonorStatus,
};

const defaultComponents = {
    date: DateField,
    item_contact: ItemContact,
    currency_amount: CurrencyAmount,
};

function DynamicCell({ row, field, componentType, renderField }) {
    if (renderField) return <TableCell>{renderField(row)}</TableCell>;

    const Cell = componentType ? defaultComponents?.[componentType] : cellComponents?.[field] || DefaultCell;

    return (
        <TableCell
            sx={{
                // whiteSpace: "nowrap",
            }}
        >
            {<Cell row={row} field={field} />}
        </TableCell>
    );
}

// TODO: truncate
// TODO: donation_status
function DynamicTable({ columns = [], rows, onRowClick, checkField, checkedRows, handleCheck, handleCheckAll, getSort, onSort }) {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {checkField &&
                            <TableCell>
                                <Checkbox
                                    indeterminate={checkedRows.length > 0 && checkedRows.length < rows.length}
                                    checked={checkedRows.length === rows.length}
                                    onChange={handleCheckAll}
                                />
                            </TableCell>
                        }
                        {columns.map((col, index) => (
                            <TableCell key={index}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <span>{col.value}</span>
                                    {col.sort && <Sort sort={getSort(col.sort)} onSort={onSort} field={col.sort} />}
                                </Stack>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <Section
                    component={TableBody}
                    sx={{ backgroundColor: "unset" }}
                >
                    {rows.map((row, index) => (
                        <TableRow
                            key={index}
                            hover
                            sx={{
                                backgroundColor: "white",
                                ...(onRowClick ? { cursor: "pointer" } : {}),
                                "&:last-child td, &:last-child th": {
                                    ":first-child": { borderBottomLeftRadius: 16 },
                                    ":last-child": { borderBottomRightRadius: 16 },
                                },
                                "&:first-child td, &:first-child th": {
                                    ":first-child": { borderTopLeftRadius: 16 },
                                    ":last-child": { borderTopRightRadius: 16 },
                                },
                            }}
                            onClick={() => onRowClick && onRowClick(row)}
                        >
                            {checkField &&
                                <TableCell>
                                    <Checkbox
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() => handleCheck(row)}
                                        checked={checkedRows.includes(row[checkField])}
                                    />
                                </TableCell>
                            }
                            {columns.map((col, index) => (
                                <DynamicCell key={index} row={row} field={col.field} componentType={col?.componentType} renderField={col.renderField} />
                            ))}
                        </TableRow>
                    ))}
                </Section>
            </Table>
        </TableContainer>
    );
}

export default DynamicTable;