import { Autocomplete, Chip, CircularProgress, InputAdornment, TextField } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { log } from '../utils/devUtils';
import { Validate } from './utils/utils';

function TagInput({
    tags,
    setTags,
    name,
    label,
    validationText,
    options = [],
    getOptionLabel = (option) => typeof option === 'string' ? option : '',
    renderOption,
    groupBy,
    loading = false,
    onFocus,
    onInputChange,
    addTagKeys = ['Tab', ',', ' ', 'Enter'],
}) {
    const [currentTag, setCurrentTag] = useState('');
    const [valid, setValid] = useState(true);

    const validate = () => {
        if (name === 'phone_number') {
            return Validate.phone_number(currentTag)// && limit && tags.length < limit;
        } else if (name === 'email') {
            return Validate.email(currentTag)
        } else {
            return true
        }
    };

    const addTag = () => {
        if (validate()) {
            if (currentTag.trim()) {
                setTags([...tags, currentTag.trim()]);
                setCurrentTag('');
            }
        }
    };

    const handleKeyPress = (e) => {
        if (addTagKeys.includes(e.key)) {
            e.preventDefault();
            addTag();
        }
    };

    // useEffect(() => {
    //     log('options.length', Boolean(options.length));
    //     log('tags', tags);
    // }, [tags]);

    return (
        <Autocomplete
            multiple
            fullWidth
            freeSolo
            clearIcon={null}
            limitTags={3}
            loading={loading}
            loadingText={t('Loading') + '...'}
            onFocus={onFocus}
            {...(options.length ? { filterSelectedOptions: true } : {})}
            options={options}
            {...(renderOption ? { renderOption } : {})}
            {...(groupBy ? { groupBy } : {})}
            {...(options.length ? { getOptionLabel } : {})}
            {...(name === 'recipients' ? {
                renderGroup: (params) => {
                    const groupData = {
                        contact_lists: t('Contact Groups'),
                        donation_lists: t('Donation Groups'),
                        contacts: t('Contacts'),
                    };
                    return (
                        <li key={params.key}>
                            <ul>
                                <li style={{
                                    backgroundColor: '#fafafa',
                                    lineHeight: '3em',
                                    paddingLeft: '1em',
                                    paddingRight : '1em',
                                    fontWeight: 'bold',
                                    position: 'sticky',
                                    top: -10,
                                    zIndex: 2
                                }}>
                                    {groupData[params.group]}
                                </li>
                                {params.children}
                            </ul>
                        </li>
                    );
                }
            } : {})}
            value={tags}
            onBlur={addTag}
            onChange={(event, value, reason) => {
                if (reason === 'removeOption') {
                    setTags(value);
                } else {
                    if (validate()) {
                        setTags(value);
                    } else {
                        setValid(false);
                    }
                }
            }}
            inputValue={currentTag}
            onInputChange={(event, newInputValue) => {
                setCurrentTag(newInputValue)
                onInputChange && onInputChange(event, newInputValue)
            }}
            onKeyDown={handleKeyPress}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={!valid}
                    variant='standard'
                    placeholder={t(label)}
                    label={t(label)}
                    helperText={t(validationText)}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        color='primary'
                        size='medium'
                        label={option.label || option[name] || option} // change recipients to label
                        {...getTagProps({ index })}
                        sx={{
                            borderRadius: '0.25rem',
                            fontSize: '1rem',
                        }}
                        deleteIcon={<CloseIcon />}
                    />
                ))
            }
            sx={{
                '& .MuiInputBase-root': {
                    mt: '1.5rem',
                    '& .MuiChip-root': {
                        '& .MuiChip-deleteIcon': {
                            color: 'white',
                            height: '1rem',
                        },
                    },
                }
            }}
        />
    );
}

export default TagInput;