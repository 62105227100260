import React, { useState, useRef, useEffect } from "react"
import ReactDOM from "react-dom"
import { use } from "i18next";
import DRMAlert from "./alerts";
import { HiCheckCircle, HiMail } from "react-icons/hi";

const WaitingListForm = ({ setClose, setAlert }) => {

    const [submiting, setSubmiting] = useState(false);
    const [formSubmited, setFormSubmited] = useState(false);

    const handleSubmit = (event) => {
        setSubmiting(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        fetch('/waiting_list_form/', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(() => setFormSubmited(true))
            .then(() => setAlert())
            .then(() => setClose(true))
            .then(() => setSubmiting(false))
            .catch(error => alert(error));
    }

    return (
        <>
            <section className="overflow-x-hidden mb-6">
                <div className="flex flex-wrap">
                    <div className="w-full">
                        <div
                            className=" text-black dark:text-white rounded-md bg-primary/[3%] px-8 dark:bg-dark"
                            data-wow-delay=".15s
                "
                        >


                            {/* <p className="mb-8 flex"><HiMail size={24} className="my-auto" /><a href="mailto:info@donbar.org" className="no-underline font-bold">&nbsp;&nbsp;info@donbar.org</a></p> */}
                            <form onSubmit={handleSubmit}>
                                <div className="-mx-4 flex flex-wrap">
                                    <div className="w-full px-4 md:w-1/2">
                                        <div className="mb-8">
                                            <label
                                                htmlFor="name"
                                                className="mb-3 block text-sm font-medium text-dark dark:text-white"
                                            >
                                                Your Name
                                            </label>
                                            <input
                                                required
                                                type="text"
                                                name="name"
                                                placeholder="Enter your name"
                                                className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp"
                                            />
                                        </div>
                                        <div className="mb-8">
                                            <label
                                                htmlFor="email"
                                                className="mb-3 block text-sm font-medium text-dark dark:text-white"
                                            >
                                                Your Email
                                            </label>
                                            <input
                                                required
                                                type="email"
                                                name="email"
                                                placeholder="Enter your name"
                                                className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp"
                                            />
                                        </div>
                                        <div className="mb-8">
                                            <label
                                                htmlFor="donations"
                                                className="mb-3 block text-sm font-medium text-dark dark:text-white"
                                            >
                                                Estimated Donations per Year
                                            </label>
                                            <input
                                                required
                                                type="number"
                                                name="donations"
                                                placeholder="Expected Donations per Year"
                                                className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full px-4 md:w-1/2">
                                        <div className="mb-8">
                                            <label
                                                htmlFor="organization"
                                                className="mb-3 block text-sm font-medium text-dark dark:text-white"
                                            >
                                                Organization Name
                                            </label>
                                            <input
                                                required
                                                type="text"
                                                name="organization"
                                                placeholder="Enter your organization name"
                                                className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp"
                                            />
                                        </div>
                                        <div className="mb-8">
                                            <label
                                                htmlFor="text"
                                                className="mb-3 block text-sm font-medium text-dark dark:text-white"
                                            >
                                                Your Phone Number
                                            </label>
                                            <input
                                                required
                                                type="phone"
                                                name="phone"
                                                placeholder="Enter your phone number"
                                                className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp"
                                            />
                                        </div>
                                        <div className="mb-8">
                                            <label
                                                htmlFor="contacts"
                                                className="mb-3 block text-sm font-medium text-dark dark:text-white"
                                            >
                                                Estimated Contacts (Donors) per Year
                                            </label>
                                            <input
                                                required
                                                type="number"
                                                name="contacts"
                                                placeholder="Expected Contacts per Year"
                                                className="w-full rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full px-4">
                                        <div className="mb-8">
                                            <label
                                                htmlFor="message"
                                                className="mb-3 block text-sm font-medium text-dark dark:text-white"
                                            >
                                                Notes
                                            </label>
                                            <textarea
                                                name="message"
                                                rows={3}
                                                placeholder="Start typing..."
                                                className="w-full resize-none rounded-md border border-transparent py-3 px-6 text-base text-body-color placeholder-body-color shadow-one outline-none focus:border-primary focus-visible:shadow-none dark:bg-[#242B51] dark:shadow-signUp"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="w-full flex justify-center mx-auto px-4">
                                        <div>

                                            <button className="w-72 px-6 py-2 mt-3 text-center text-white bg-indigo-700 rounded-md lg:ms-5" type="submit" >
                                                {submiting ? <div className="w-full mx-auto spinner"></div> : "Send"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default function WaitingListModal({ open, setOpen, setAlert }) {

    const wrapperRef = useRef(null)

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [wrapperRef, setOpen])

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [wrapperRef])

    useEffect(() => {
        let html = document.querySelector("html")

        if (html) {
            if (open && html) {
                html.style.overflowY = "hidden"

                const focusableElements =
                    'button, [href], input, select, textarea, [tabIndex]:not([tabIndex="-1"])'

                const modal = document.querySelector("#modal") // select the modal by it's id

                const firstFocusableElement =
                    modal.querySelectorAll(focusableElements)[0] // get first element to be focused inside modal

                const focusableContent = modal.querySelectorAll(focusableElements)

                const lastFocusableElement =
                    focusableContent[focusableContent.length - 1] // get last element to be focused inside modal

                document.addEventListener("keydown", function (e) {
                    if (e.keyCode === 27) {
                        setOpen(false);
                    }

                    let isTabPressed = e.key === "Tab" || e.keyCode === 9

                    if (!isTabPressed) {
                        return
                    }

                    if (e.shiftKey) {
                        // if shift key pressed for shift + tab combination
                        if (document.activeElement === firstFocusableElement) {
                            lastFocusableElement.focus() // add focus for the last focusable element
                            e.preventDefault()
                        }
                    } else {
                        // if tab key is pressed
                        if (document.activeElement === lastFocusableElement) {
                            // if focused has reached to last focusable element then focus first focusable element after pressing tab
                            firstFocusableElement.focus() // add focus for the first focusable element
                            e.preventDefault()
                        }
                    }
                })

                firstFocusableElement.focus()
            } else {
                html.style.overflowY = "visible"
            }
        }
    }, [open])

    return (
        <>
            {open && typeof document !== "undefined"
                ? ReactDOM.createPortal(
                    <div
                        className="fixed top-0 start-0 z-20 flex h-screen w-screen items-center justify-center bg-slate-300/20 backdrop-blur-sm"
                        aria-labelledby="header-3a content-3a"
                        aria-modal="true"
                        tabIndex="-1"
                        role="dialog"
                    >
                        <div
                            ref={wrapperRef}
                            className="flex max-h-[90vh] w-11/12 max-w-3xl flex-col gap-6 overflow-hidden rounded-3xl p-4 bg-white dark:bg-gray-900 shadow-xl shadow-slate-700/10"
                            id="modal"
                            role="document"
                        >
                            <div className="flex">
                                <h2 className="text-center  pt-4 text-black dark:text-white ms-auto mb-4 font-bold text-3xl xl:text-4xl">
                                    Request Early Access.
                                </h2>
                                <button
                                    onClick={() => setOpen(false)}
                                    className="h-10 ms-auto w-10 flex items-center justify-center rounded-full bg-slate-300/20 hover:bg-slate-300/30 focus:bg-slate-300/30 transition-colors duration-200 focus:outline-none"
                                    aria-label="close dialog"
                                >
                                    <span className="">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            role="graphics-symbol"
                                            aria-labelledby="title-79 desc-79"
                                        >
                                            <title id="title-79">Icon title</title>
                                            <desc id="desc-79">
                                                A more detailed description of the icon
                                            </desc>
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                            <WaitingListForm
                                setClose={(e) => setOpen(false)}
                                setAlert={() => setAlert(true)}
                            />
                        </div>
                    </div>,
                    document.body
                )
                : null}
        </>
    )
}