import logo from '../img/logo.svg';

function LoadingComponent({ size, className }) {
  
  return (
    <>
      <div className={`${className} w-full flex justify-center items-center align-middle`}>
        <div className={`loading h-${size || '32'} w-${size || '32'}`}>
          <img src={logo} alt="loading" />
        </div>
      </div>
    </>
  );
}

export default LoadingComponent;