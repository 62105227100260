import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HiOutlineX } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { debounce } from "lodash";
import { Button, CircularProgress, TextField } from '@mui/material';
import SearchContact from './general/SearchContact';
import useApi from 'hooks/useApi';


function Connections({ connections, setConnections }) {
    const api = useApi();
    const [relatedTosearchResults, setRelatedTosearchResults] = useState([]);
    const [relationshipSearchResults, setRelationshipSearchResults] = useState([]);
    const [focusedInputIndex, setFocusedInputIndex] = useState(null);
    const [relatedToName, setRelatedToName] = useState('*');
    const [relationshipSearch, setRelationshipSearch] = useState('');
    const [relatedToSearch, setRelatedToSearch] = useState('');
    const [searchActive, setSearchActive] = useState(false);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [activeInput, setActiveInput] = useState(null);

    useEffect(() => {
        const searchRelationship = debounce(() => {
            api.get('/search_relationships/', {
                params: {
                    search: relationshipSearch,
                    status: 1,
                },
            }).then(res => {
                setRelationshipSearchResults(res.data);
            }).catch(err => {
                console.log(err);
            })
        }, 100);

        if (searchActive) {
            searchRelationship();
        }

        setSearchActive(false);
        return () => searchRelationship.cancel();
    }, [relationshipSearch, activeInput]);

    // useEffect(() => {
    //     const searchRelatedTo = debounce(() => {
    //         axios.get('/contacts/', {
    //             params: {
    //                 org_id: orgId,
    //                 search: relatedToSearch,
    //                 status: 1,
    //                 page_size: 20,
    //             },
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //             },
    //         }).then(res => {
    //             setRelatedTosearchResults(res.data.results);
    //         }).catch(err => {
    //             console.log(err);
    //         })
    //     }, 100);

    //     if (searchActive) {
    //         searchRelatedTo();
    //     }
    //     return () => searchRelatedTo.cancel();
    // }, [relatedToSearch, activeInput]);

    const handleAddConnection = () => {
        setConnections([...connections, { related_to: { name: '' }, relationship: '' }]);
    };

    useEffect(() => {
        // console.log(activeInput)
    }, [activeInput])

    const handleRemoveConnection = (index) => {
        const updatedConnections = [...connections];

        if (!updatedConnections[index].id) {
            updatedConnections.splice(index, 1);
        } else {
            updatedConnections[index].relationship = null;
            updatedConnections[index].related_to = null;
        }

        setConnections(updatedConnections);
    };

    const handleRelationshipFocus = (e, index) => {
        setActiveInput('relationship' + index);
        setRelationshipSearch(e);
        setSearchActive(true);
    }

    // const handleRelatedToFocus = (e, index) => {
    //     setActiveInput('related_to' + index);
    //     setRelatedToSearch(e);
    //     setSearchActive(true);
    // }

    const handleRelationshipChange = (e, index) => {
        setRelationshipSearch(e || '')
        setSearchActive(true);
        const updatedConnections = [...connections];
        // console.log({ updatedConnections })
        updatedConnections[index].relationship = e;
        setConnections(updatedConnections);
    };

    const handleRelatedToChange = (e, index) => {
        const updatedConnections = [...connections];
        updatedConnections[index].related_to = { id: e.id, name: e.first_name + ' ' + e.last_name }
        setConnections(updatedConnections);
    };

    // const handleRelatedToSelect = (result, index) => {
    //     const updatedConnections = [...connections];
    //     updatedConnections[index].related_to = { id: result.id, name: result.first_name + ' ' + result.last_name }
    //     setConnections(updatedConnections);
    // }

    const handleRelationshipBlur = (index) => {
        setTimeout(() => {
            setActiveInput((prev) => {
                if (prev === 'relationship' + index) {
                    return null;
                }
                return prev;
            })
        }, 200);
    }

    useEffect(() => {
        // console.log({connections})
        if (connections.length === 0) {
            handleAddConnection();
        }
    }, []);

    // const handleRelatedToBlur = (index) => {
    //     if (!connections[index].related_to.id) {
    //         const updatedConnections = [...connections];
    //         updatedConnections[index].related_to = { name: '' };
    //         setConnections(updatedConnections);
    //     }
    //     setTimeout(() => {
    //         setActiveInput((prev) => {
    //             if (prev === 'related_to' + index) {
    //                 return null;
    //             }
    //             return prev;
    //         })
    //     }, 200);
    // }

    return (
        <div>
            {connections.map((connection, index) => {
                if (!connection.relationship && !connection.related_to) return null;
                return (
                    <div key={index} className="items-center mb-2">
                        <fieldset className="relative flex mb-2">
                            <div className="relative max-w-4xl me-8">
                                <TextField
                                    variant='standard'
                                    label={t('Relationship')}
                                    value={connection.relationship || ''}
                                    onChange={(e) => handleRelationshipChange(e.target.value || '', index)}
                                    placeholder={t('Relationship')}
                                    onFocus={(e) => handleRelationshipFocus(e.target.value || '', index)}
                                    onBlur={() => handleRelationshipBlur(index)}
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            </>)
                                    }}
                                />
                                {relationshipSearchResults.length > 0 && activeInput === 'relationship' + index && (
                                    <ul className="absolute -mt-2 bg-white shadow-lg border border-gray-200 rounded py-1 z-10 w-full max-h-60 overflow-y-auto">
                                        {relationshipSearchResults.map((result, mapIndex) => (
                                            <li
                                                key={mapIndex}
                                                className="px-3 py-1 hover:bg-gray-200 cursor-pointer"
                                                onClick={() => handleRelationshipChange(result.relationship, index)}
                                            >
                                                {result.relationship}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <div className="relative w-1/4">
                                <SearchContact
                                    onChange={(e) => handleRelatedToChange(e || '', index)}
                                    label={t('Name')}
                                    placeholder={t('Name')}
                                    value={connection.related_to?.name}
                                />
                            </div>
                            <button
                                type="button"
                                className="relative top-2"
                                onClick={() => handleRemoveConnection(index)}
                            >
                                <HiOutlineX />
                            </button>
                        </fieldset>
                    </div>
                );
            })}
            <div className='text-start'>
                <Button
                    onClick={handleAddConnection}
                >
                    {t('Add Connection')}
                </Button>
            </div>
        </div>
    );
}

export default Connections;