import { useTranslation } from "react-i18next";
import Details from "./Details/Details";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useApi from "hooks/useApi";
import { uiStore } from "stores/UiStore";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { optionsClickOutside } from "utils/controls";
import { DeleteRounded, EditRounded } from "@mui/icons-material";
import CampaignForm from "./CampaignForm";
import { useSnackbar } from "notistack";
import WarningDialog from "components/common/DeleteDialog";
import { Typography } from "@mui/material";
import useCampaign from "hooks/useCampaign";

function GroupDetails() {
    const { t } = useTranslation();
    const { campaignName, subCampaignName, groupName } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const api = useApi();
    const { setOptions } = uiStore;
    const { enqueueSnackbar } = useSnackbar();
    const [editGroup, setEditGroup] = useState(false);
    const [deleteGroupDialog, setDeleteGroupDialog] = useState(false);

    const params = { sub_campaign__name: subCampaignName };

    const groupQuery = useQuery(['group', campaignName, subCampaignName, groupName],
        () => api.get(`/campaigns/${campaignName}/groups/${groupName}`, { params }).then(res => res.data),
        {
            enabled: !!campaignName && (!!subCampaignName || !!groupName),
            refetchOnWindowFocus: true,
        }
    );

    const { data: group, isLoading: groupLoading } = groupQuery;

    const { mutate: updateGroup, isLoading: groupUpdating } = useMutation(
        (data) => api.put(`/campaigns/groups/${group?.id}`, { ...data, campaign: group?.campaign }),
        {
            onSuccess: (res) => {
                enqueueSnackbar(t('Group updated successfully.'), { variant: 'success' });
                navigate(location.pathname.split('/').slice(0, -1).join('/') + `/${res.data.name}`);
                groupQuery.refetch();
                setEditGroup(false);
                optionsClickOutside();
            },
            onError: () => enqueueSnackbar(t('Error updating group.'), { variant: 'error' }),
        }
    );

    const { mutate: deleteGroup, isLoading: groupDeleting } = useMutation(
        () => api.delete(`/campaigns/groups/${group?.id}`),
        {
            onSuccess: () => {
                enqueueSnackbar(t('Group deleted successfully.'), { variant: 'success' });
                navigate(location.pathname.split('/').slice(0, -1).join('/'));
                setDeleteGroupDialog(false);
                optionsClickOutside();
            },
            onError: () => enqueueSnackbar(t('Error deleting group.'), { variant: 'error' }),
        }
    );

    useEffect(() => {
        setOptions([
            {
                key: 'edit-group',
                text: t('Edit Group'),
                icon: <EditRounded />,
                onClick: () => setEditGroup(true),
            },
            {
                key: 'delete-group',
                text: t('Delete Group'),
                icon: <DeleteRounded />,
                onClick: () => setDeleteGroupDialog(true),
            }
        ]);

        return () => setOptions([]);
    }, []);

    return (
        <>
            <Details key='group' type='group' data={group} loading={groupLoading} />
            {editGroup &&
                <CampaignForm
                    title={t('Edit Group')}
                    data={group}
                    onClose={() => setEditGroup(false)}
                    onSave={(data) => updateGroup(data)}
                    saving={groupUpdating}
                    type='groups'
                />
            }
            <WarningDialog
                requireInput
                open={deleteGroupDialog}
                title={t('Delete Group')}
                text={
                    <div>
                        <Typography variant='subtitle2' fontWeight={600} gutterBottom>
                            {t('All fundraisers under')} <strong>{group?.name}</strong> {t('will be deleted.')}
                        </Typography>
                        <Typography variant='subtitle2' fontWeight={600} gutterBottom>
                            {t('All your associations with donations, pledges and donors will be lost. (They will not be deleted)')}
                        </Typography>
                    </div>
                }
                onClose={() => setDeleteGroupDialog(false)}
                onDelete={() => deleteGroup()}
                deleteInputText={t('delete group')}
                approveText={t('Delete')}
            />
        </>
    );
}

export default observer(GroupDetails);