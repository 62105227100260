import { makeAutoObservable } from "mobx";

class UiStore {
    title = null;
    fabAction = null;
    options = [];
    quickActions = [];
    viewNavigation = null;


    constructor() {
        makeAutoObservable(this);
        this.setFabAction = this.setFabAction.bind(this);
        this.setOptions = this.setOptions.bind(this);
        this.setQuickActions = this.setQuickActions.bind(this);
        this.addOption = this.addOption.bind(this);
        this.removeOption = this.removeOption.bind(this);
        this.setTitle = this.setTitle.bind(this);
        this.setViewNavigation = this.setViewNavigation.bind(this);
    }

    setViewNavigation(viewNavigation) {
        this.viewNavigation = viewNavigation;
    }

    setTitle(title) {
        this.title = title;
    }

    setFabAction(fabAction) {
        this.fabAction = fabAction;
    }

    setOptions(options) {
        this.options = options;
    }

    addOption(option) {
        const index = this.options.findIndex((o) => o.key === option.key);
        if (index > -1) {
            this.options[index] = option;
        } else {
            this.options.push(option);
        }
    }

    removeOption(key) {
        this.options = this.options.filter((o) => o.key !== key);
    }

    setQuickActions(quickActions) {
        this.quickActions = quickActions;
    }
}

export const uiStore = new UiStore();