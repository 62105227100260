import { Box, Grid, Skeleton } from "@mui/material";

function TabTableLoading() {
    return (
        <Box width="100%">
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Skeleton width="100%" height={30} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                </Grid>
                <Grid item xs={1}>
                    <Skeleton width="100%" height={30} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton width="100%" height={30} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton width="100%" height={30} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton width="100%" height={30} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                </Grid>
                <Grid item xs={1}>
                    <Skeleton width="100%" height={30} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton width="100%" height={30} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                    <Skeleton width="100%" height={60} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default TabTableLoading;