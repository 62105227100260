// Refactored: 01/28
import { Outlet, Route, Routes } from "react-router-dom";
import SecondaryNav from "./MainView/SecondaryNav";
import SideNav from "./MainView/SideNav";
import ContactsTable from "views/Contacts/Contacts/ContactList/contacts";
import ContactView from "views/Contacts/Contacts/ContactDetails/ContactViewLeg";
import Tasks from "views/Contacts/Tasks/Tasks";
import TaskTypes from "views/Contacts/Tasks/TaskTypes";
import ContactsLists from "views/Contacts/Groups/GroupsList/ContacstLists";
import ContactListView from "views/Contacts/Groups/GroupDetails/ContactListView";
import Emails from "views/Contacts/Emails/EmailList/Emails";
import EmailView from "views/Contacts/Emails/EmailDetails/EmailView";
import DonationsTable from "views/Donations/Donations/DonationList/donations";
import DonationDetails from "views/Donations/Donations/DonationDetails/DonationDetails";
// import Pledges from "views/Donations/Pledges/pledgesleg";
import DonationsLists from "views/Donations/Groups/GroupList/DonationsLists";
import DonationListView from "views/Donations/Groups/GroupDetails/DonationListView";
import CampaignsTable from "views/Donations/Campaigns/CampaignList/Campaigns";
import CampaignView from "views/Donations/Campaigns/CampaignDetails/CampaignView";
import SubCampaignView from "views/Donations/Campaigns/CampaignDetails/SubCampaigns/SubCampaignView";
import FixContacts from "views/Donations/FixAndMerge/FixDonations";
import Expenses from "views/Expenses/Expenses/ExpensesList/expenses";
import ExpenseView from "views/Expenses/Expenses/ExpenseDetails/ExpenseView";
import ExpenseTypes from "views/Expenses/ExpenseTypes/ExpenseTypes";
import Addons from "views/Addons/Addons";
import NedarimPlus from "views/Addons/NedarimPlus/NedarimPlus";
import Settings from "views/Settings/Account/settings";
import EmailSettings from "views/Settings/Emails/EmailSettings";
import Billing from "views/Settings/Billing/billing";
import NavigationHeader from "views/Campaigns/NavigationHeader";
import SubCampaignDetails from "views/Campaigns/SubCampaignDetails";
import GroupDetails from "views/Campaigns/GroupDetails";
import FundraiserDetails from "views/Campaigns/FundraiserDetails";
import CampaignDetails from "views/Campaigns/CampaignDetails";
import AddFab from "components/specific/AddFab";
import CampaignList from "views/Campaigns/Lists/Campaigns";
import SubCampaignList from "views/Campaigns/Lists/SubCampaigns";
import GroupList from "views/Campaigns/Lists/Groups";
import FundraiserList from "views/Campaigns/Lists/Fundraisers";
import Pledges from "views/Donations/Pledges/Pledges";
import ViewHeader from "components/general/ViewHeader";
import NewContact from "views/Contacts/Contacts/ContactDetails/NewContact";
import ContactDetails from "views/Contacts/Contacts/ContactDetails/ContactDetails";

import { EmailEditor } from "donbar-mail"
function MainView() {
    // return <EmailEditor />;

    return (
        <div className="App flex h-screen">
            <SideNav />
            <div className='flex-1 flex flex-col'>
                <SecondaryNav />
                <div id="layout-content" className="flex-1 overflow-y-auto max-w-[calc(100vw-5rem)]">
                    <div className='m-10'>
                        <div className="container mx-auto p-6">
                            <ViewHeader />
                            <Routes>
                                <Route path='/contacts' >
                                    <Route index element={<ContactsTable />} />
                                    <Route path='new' element={<NewContact />} />
                                    <Route path=':contactId/:tab' element={<ContactDetails />} />
                                    <Route path=':contactId' element={<ContactDetails />} />
                                    <Route path='tasks' element={<Tasks />} />
                                    <Route path='task-types' element={<TaskTypes />} />
                                    <Route path='groups' >
                                        <Route index element={<ContactsLists />} />
                                        <Route path=':name' element={<ContactListView />} />
                                    </Route>
                                    <Route path='emails' >
                                        <Route index element={<Emails />} />
                                        <Route path=':id' element={<EmailView />} />
                                    </Route>
                                </Route>
                                <Route path='/donations' >
                                    <Route index element={<DonationsTable />} />
                                    <Route path=':id' element={<DonationDetails />} />
                                    {/* <Route path='pledges' element={<Pledges />} /> */}
                                    <Route path='pledges' element={<Pledges />} />
                                    <Route path='groups' >
                                        <Route index element={<DonationsLists />} />
                                        <Route path=':name' element={<DonationListView />} />
                                    </Route>
                                    <Route path='campaigns' >
                                        <Route index element={<CampaignsTable />} />
                                        <Route path=':name' >
                                            <Route index element={<CampaignView />} />
                                            <Route path='sub-campaigns/:scName' element={<SubCampaignView />} />
                                        </Route>
                                    </Route>
                                    <Route path='fix-and-merge' element={<FixContacts />} />
                                </Route>
                                <Route path='/expenses' >
                                    <Route index element={<Expenses />} />
                                    <Route path=':id' element={<ExpenseView />} />
                                    <Route path='expense-types' element={<ExpenseTypes />} />
                                </Route>
                                <Route path='campaigns' element={<NavigationHeader />}>
                                    <Route index element={<CampaignList />} />
                                    <Route path=':campaignName'>
                                        <Route index element={<CampaignDetails />} />
                                        <Route path='sub-campaigns' >
                                            <Route index element={<SubCampaignList />} />
                                            <Route path=':subCampaignName'>
                                                <Route index element={<SubCampaignDetails />} />
                                                <Route path='groups'>
                                                    <Route index element={<GroupList />} />
                                                    <Route path=':groupName'>
                                                        <Route index element={<GroupDetails />} />
                                                        <Route path='fundraisers'>
                                                            <Route index element={<FundraiserList />} />
                                                            <Route path=':fundraiserId' element={<FundraiserDetails />} />
                                                        </Route>
                                                    </Route>
                                                </Route>
                                                <Route path='fundraisers'>
                                                    <Route index element={<FundraiserList />} />
                                                    <Route path=':fundraiserId' element={<FundraiserDetails />} />
                                                </Route>
                                            </Route>
                                        </Route>
                                        <Route path='groups'>
                                            <Route index element={<GroupList />} />
                                            <Route path=':groupName'>
                                                <Route index element={<GroupDetails />} />
                                                <Route path='fundraisers'>
                                                    <Route index element={<FundraiserList />} />
                                                    <Route path=':fundraiserId' element={<FundraiserDetails />} />
                                                </Route>
                                            </Route>
                                        </Route>
                                        <Route path='fundraisers'>
                                            <Route index element={<FundraiserList />} />
                                            <Route path=':fundraiserId' element={<FundraiserDetails />} />
                                        </Route>
                                    </Route>
                                </Route>
                                <Route path='/addons/*' element={<Addons />} />
                                <Route path='/addons/nedarim_plus' element={<NedarimPlus />} />
                                <Route path='/settings'>
                                    <Route path="account" element={<Settings />} />
                                    <Route path='emails' element={<EmailSettings />} />
                                    <Route path='billing' element={<Billing />} />
                                </Route>
                            </Routes>
                            <AddFab />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainView;