import { Box, IconButton, Modal, Typography } from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


function InfoModal({ open, onClose, title, description, sx, children }) {

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: '1rem',
                display: 'flex',
                flexDirection: 'column',
                p: 4,
                width: '28rem',
                boxSizing: 'content-box',
                ...sx,
            }}>
                <IconButton onClick={onClose} sx={{
                    float: 'right',
                    position: 'absolute',
                    top: '0.25rem',
                    right: '0.25rem',
                }}>
                    <CloseRoundedIcon />
                </IconButton>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography sx={{ textAlign: 'center' }} variant="h6">{title}</Typography>
                    <Typography sx={{
                        mt: 2,
                        textAlign: 'center',
                    }}>
                        {description}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        height: '100%',
                        flex: 1,
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Modal>
    )
}

export default InfoModal;