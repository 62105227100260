import GroupDetails from "components/specific/GroupDetails";
import TableSkeleton from "components/specific/TableSkeleton";
import ListView from "components/specific/ListView";
import useApi from "hooks/useApi";
import { useDonations } from "hooks/useDonations";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { uiStore } from "stores/UiStore";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { optionsClickOutside } from "utils/controls";
import ListStatus from "components/specific/ListStatus";
import { DeleteOutlineRounded, PlaylistRemoveRounded, RecentActorsRounded } from "@mui/icons-material";
import WarningDialog from "components/common/DeleteDialog";
import NoResultsFound from "components/general/NoResultsFound";
import { Link, Skeleton } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { donationsStore } from "stores/DonationsStore";
import DonationList from "views/Donations/Donations/DonationList/DonationList";


function DonationListView() {
    const { name } = useParams();
    const api = useApi();
    const { setTitle, setOptions } = uiStore;
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const [donationParams, setDonationParams] = useSearchParams('');
    const [statusFilters, setStatusFilters] = useState([0, 1]);

    const [isInitialised, setIsInitialised] = useState(false);
    const [checkedDonations, setCheckedDonations] = useState([]);
    const [deleteWarningOpen, setDeleteWarningOpen] = useState(false);

    const { donationsQuery } = useDonations({ enabled: isInitialised });
    const { currentPage, setCurrentPage, getSort, setSort, setParams, reset } = donationsStore;

    const { data: donationsData, isLoading: donationsLoading } = donationsQuery;
    const { results: donations, count: donationsCount } = donationsData || {};


    useEffect(() => {
        reset();
        setTitle(name);
        donationParams.set('donationlistitems__donation_list__name', name);
        if (statusFilters.length === 0) donationParams.delete('list_status__in');
        else donationParams.set('list_status__in', statusFilters.join(','));
        setParams(donationParams);
        setIsInitialised(true);

        return () => {
            setTitle(null);
        };
    }, [name, statusFilters]);

    useEffect(() => {
        setOptions([
            {
                text: `${t('Remove')} ${checkedDonations.length > 0 ? checkedDonations.length : ''} ${t('donations')}`,
                icon: <PlaylistRemoveRounded />,
                onClick: () => removeItems(checkedDonations),
                disabled: checkedDonations.length === 0,
            },
            {
                text: t('Delete Group'),
                icon: <DeleteOutlineRounded />,
                onClick: () => setDeleteWarningOpen(true),
            },
        ]);

        return () => setOptions([]);
    }, [checkedDonations]);


    const groupQuery = useQuery(["donationGroup", name],
        () => api.get('/donations/lists/', { params: { name } }).then(res => res.data[0]),
        {}
    );

    const { data: group, isLoading: groupLoading } = groupQuery;

    const loading = groupLoading;

    const { mutate: updateStatus } = useMutation(({ donation_id, status }) => api.put('/donations/lists/item/', { status }, {
        params: { donation_id, donation_list_id: group.id },
    }), {
        onSuccess: () => {
            donationsQuery.refetch();
            groupQuery.refetch();
            optionsClickOutside();
        },
        onError: () => enqueueSnackbar(t('Failed to update status'), { variant: 'error' }),
    }
    );

    const { mutate: removeItems } = useMutation((donation_ids) => api.post('/donations/lists/delete/',
        { donation_ids, donation_list_id: group.id }
    ), {
        onSuccess: () => {
            setCheckedDonations([]);
            donationsQuery.refetch();
            groupQuery.refetch();
            optionsClickOutside();
        },
        onError: () => enqueueSnackbar(t('Failed to remove item'), { variant: 'error' }),
    }
    );

    const { mutate: deleteGroup } = useMutation(() => api.delete(`/donations/lists/${group.id}/update`), {
        onSuccess: () => {
            enqueueSnackbar(t('Group deleted successfully'), { variant: 'success' });
            setDeleteWarningOpen(false);
            navigate('/donations/groups');
        },
        onError: () => enqueueSnackbar(t('Failed to delete group'), { variant: 'error' }),
    }
    );

    const updateGroupMutation = useMutation(
        (data) => api.put(`/donations/lists/${group.id}/update`, data).then(res => res.data),
        {
            onSuccess: (res) => {
                if (res.name !== name) navigate(`/donations/groups/${res.name}`);
                else groupQuery.refetch();
            },
            onError: () => enqueueSnackbar(t('Failed to update group'), { variant: 'error' }),
        }
    );


    if (loading || !isInitialised) return <Skeleton variant="rounded" height={96} sx={{ borderRadius: 4 }} />;

    const pageCount = Math.ceil(donationsCount / 50);

    return (
        <div>
            <GroupDetails
                group={group}
                type='donations'
                statusFilters={statusFilters}
                setStatusFilters={setStatusFilters}
                saveQuery={updateGroupMutation}
            />
            {donationsLoading ? <TableSkeleton /> : (
                donationsCount === 0 ? (
                    <NoResultsFound
                        title={t('No Donations')}
                        subtitle={<>{t('Go to')} <Link fontWeight="bold" underline="hover" component={RouterLink} to="/donations">{t('Donations')}</Link> {t('to add donations to this group')}</>}
                        Icon={RecentActorsRounded}
                    />
                ) : (
                    <DonationList
                        donations={donations}
                        totalPages={pageCount}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        getSort={getSort}
                        setSort={setSort}
                        checkedDonations={checkedDonations}
                        setCheckedDonations={setCheckedDonations}
                        {...(group.type ? {
                            additionalColumns: [{
                                value: "Status",
                                sort: "list_status",
                                field: "list_status",
                                renderField: (donation) => <ListStatus currentStatus={donation.list_status || 0} updateStatus={(status) => updateStatus({ donation_id: donation.donation_id, status: status })} removeItem={() => removeItems([donation.donation_id])} />
                            }]
                        } : {})}
                    />
                )
            )}
            <WarningDialog
                open={deleteWarningOpen}
                onClose={() => setDeleteWarningOpen(false)}
                onDelete={deleteGroup}
                title={t('Delete Group')}
                text={t('Are you sure you want to delete this group') + '?'}
            />
            {/* <ListView listType="donation" /> */}
        </div>
    )
}

export default observer(DonationListView);