import { useTranslation } from "react-i18next";
import nedarim_plus from '../../img/addons_images/nedarim_plus.png';
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingComponent from "components/Loading";
import H1 from "components/common/Typography/H1";
import { Link, Outlet } from "react-router-dom";
import useApi from "hooks/useApi";

const addons = [
    {
        name: 'Nedarim Plus',
        key: 'nedarim_plus',
        description: 'Sync and manage donations from Nedarim Plus',
        image: nedarim_plus,
        background: 'bg-[#efefef]',
    },
]

function Addons() {
    const { t } = useTranslation();
    const api = useApi();
    const [loading, setLoading] = useState(false);
    const [activeAddons, setActiveAddons] = useState({});

    useEffect(() => {
        api.get('/addons/').then(res => {
            setActiveAddons(res.data);
            setLoading(false);
        }).catch(err => {
            console.error(err);
        });
    }, []);


    if (loading) {
        return <LoadingComponent className='h-[40rem]' size='48' />
    }

    if (Object.keys(activeAddons).length === 0) {
        return (
            <div className="m-20">
                <h1 className="text-3xl">{t('No addons yet')}</h1>
                <div
                    className="mt-10 text-xl"
                >{t('Please contact us to at')}&nbsp;
                    <a href="mailto:info@donbar.org" className="underline text-blue-700">info@donbar.org</a>
                    &nbsp;{t('for more details')}.
                </div>
            </div>
        )
    }

    return (
        <>
            <H1>{t('Addons')}</H1>
            {addons.map((addon, index) => {
                if (!(addon.key in activeAddons)) return null;
                return (
                    <div key={index} className="w-80 h-96 text-start bg-white border rounded-2xl p-4 border-gray-50 drop-shadow-sm">
                        <img src={addon.image} alt="hero" className={`${addon.background} object-contain rounded-lg w-full h-32 `} />
                        <div className="m-2">
                            <h2 className="text-xl font-bold my-2">{t(addon.name)}</h2>
                            <p className="">{t(addon.description)}</p>
                        </div>
                        <div className="absolute bottom-4">
                            <Button
                                variant="outlined"
                                sx={{ borderRadius: 99 }}
                                component={Link}
                                to={`/addons/${addon.key}`}

                            >
                                {t('Settings')}
                            </Button>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default Addons;