import { useQuery, useQueryClient } from "react-query";
import AddCard from "./AddCard";
import DonorStatus from "./DonorCard/DonorStatus";
import useApi from "hooks/useApi";
import { Box, Card, Skeleton } from "@mui/material";
import SetCampaignCard from "./SetCampaignCard/SetCampaignCard";

function ContactCardSkeleton() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Skeleton variant="rectangular" width="100%" height={100} />
        </Box>
    )
}

function ContactCard({ children }) {
    return (
        <Card
            elevation={0}
            sx={{
                p: 2,
                my: 1,
                borderRadius: '10px',
                border: (theme) => `1px solid ${theme.palette.divider}`,
            }}
        >
            {children}
        </Card>
    )
}

function ContactViewCards({ contact }) {
    const queryClient = useQueryClient();
    const api = useApi();

    const cards = queryClient.getQueryData('contactViewCards');

    const donorCardsCampaigns = cards?.filter(card => card.card_type === 'Donor')?.map(card => card.data.id);

    const donorsQuery = useQuery(['donors', contact.contact_id, donorCardsCampaigns],
        () => api.get('/contacts/donors', { params: { donor__contact_id: contact.contact_id, campaign__in: donorCardsCampaigns } }).then(res => res.data),
        {
            enabled: !!donorCardsCampaigns,
        }
    );

    const { data: donorsData, isLoading: donorsLoading } = donorsQuery;

    if (!Array.isArray(cards)) {
        return null;
    }

    if (donorsLoading) {
        return <ContactCardSkeleton />
    }

    return (
        <>
            {cards.map((card, index) => {
                if (card.card_type === 'Donor') {
                    const donorData = donorsData?.find(donor => donor.campaign.id === card.data.id);
                    if (donorData) return <ContactCard><DonorStatus key={index} cardData={card.data} donorData={donorData} cardId={card.id} /></ContactCard>
                }
                if (card.card_type === 'Campaign') {
                    return <ContactCard><SetCampaignCard key={index} cardData={card.data} cardId={card.id} contactId={contact.contact_id}/></ContactCard>
                }
                return null;
            })}
            <AddCard />
        </>
    );
}

export default ContactViewCards;