import { Box, CircularProgress, Grid, Skeleton, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { PieCenterLabel } from "components/common/PieChart";
import Section from "components/common/Section";
import { useTranslation } from "react-i18next";
import { localDateTime } from "utils/dates";

function Overview({ data, loading }) {
    const { t, i18n } = useTranslation();

    if (!data || loading) return (
        <Section>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Skeleton width="50%" height={40} />
                    <Skeleton width="100%" height={30} />
                    <Skeleton width="70%" height={30} />
                </Grid>
                <Grid item xs={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Skeleton variant="circular" width={160} height={160} />
                        <Skeleton width="70%" height={40} />
                        <Skeleton width="50%" height={20} />
                    </Box>
                </Grid>
            </Grid>
        </Section>
    )

    const donationsSumRatio = data.donations_sum / data.goal
    const donationsPercent = donationsSumRatio * 100

    const tooltipFormatter = (value) => {
        return value.value.toLocaleString(i18n.language, { style: 'currency', currency: data.goal_currency, minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }

    return (
        <Section sx={{ m: 0 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography fontWeight='600' variant="h1" gutterBottom>
                        {data.name}
                    </Typography>
                    <Typography fontWeight='regular' variant="h3" paragraph>
                        {data.description}
                    </Typography>
                    {data?.start_date && (
                        <Typography>
                            <span className="font-semibold">{t('Starts at')}: </span>{localDateTime(data.start_date)}
                        </Typography>
                    )}
                    {data?.start_date && (
                        <Typography>
                            <span className="font-semibold">{t('Ends at')}: </span>{localDateTime(data.end_date)}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <Box position='relative' width='100%'>
                        <div className="absolute left-1/2 top-5 transform -translate-x-1/2">
                            <CircularProgress
                                variant="determinate"
                                value={100}
                                size={160}
                                thickness={5}
                                sx={{ color: (theme) => theme.palette.grey[200] }}
                            />
                        </div>
                        <PieChart
                            series={[
                                {
                                    startAngle: 0,
                                    endAngle: donationsSumRatio * 360,
                                    paddingAngle: 0,
                                    innerRadius: 60,
                                    outerRadius: 80,
                                    cornerRadius: 5,
                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                    faded: { innerRadius: 60, additionalRadius: 0 },
                                    highlighted: { additionalRadius: 3 },
                                    valueFormatter: tooltipFormatter,
                                    data: [{ label: data.name, value: data.donations_sum }],
                                },
                            ]}
                            margin={{ right: 5 }}
                            height={200}
                            slotProps={{
                                legend: {
                                    hidden: true,
                                    position: { vertical: 'middle', horizontal: 'right' },
                                    padding: 0,
                                },
                            }}
                        >
                            <PieCenterLabel>
                                {data.goal ?
                                    <>{donationsPercent.toFixed(2)}%</>
                                    :
                                    <>{data.donations_sum.toLocaleString(i18n.language, { style: 'currency', currency: data.goal_currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</>}
                            </PieCenterLabel>
                        </PieChart>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        {data.goal &&
                            <Typography textAlign='center' variant="h4" component="p" gutterBottom>
                                {data.donations_sum.toLocaleString(i18n.language, { style: 'currency', currency: data.goal_currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })} {t('out of')} {data.goal?.toLocaleString(i18n.language, { style: 'currency', currency: data.goal_currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            </Typography>}
                        <Typography textAlign='center' variant="subtitle1" color="textSecondary">
                            {t('from')} {data.donations_count} {t('donations')}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Section>
    );
}

export default Overview;
