import { Autocomplete, Box, Button, Chip, FormControl, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Modal, Popover, Select, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingComponent from "components/Loading";
import SearchContact from "components/general/SearchContact";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { LoadingButton } from "@mui/lab";
import currencies from "data/currencies";
import { pledgeStatuses } from "data/general";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import DeleteDialog from "components/common/DeleteDialog";
import { mainStore } from "stores/MainStore";
import InfoModal from "components/common/InfoModal";
import { CurrencyMask } from "components/utils/masks";
import useCampaignDropdown from "hooks/usCampaignDropdown";
import { errorsStore } from "stores/ErrorsStore";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import useApi from "hooks/useApi";

const paymentsOptions = ['0', '1', '6', '12', '24', '36'];


function PledgeDetails({ onClose, onUpdate, hideContact, hideCampaign, hideFundraiser, initialData, pledgeData }) {
    const { t } = useTranslation();
    const api = useApi();
    const dir = document.dir;
    const { enqueueSnackbar } = useSnackbar();
    const { error400, setError400 } = errorsStore;
    const { subscription } = mainStore;

    const getField = (field) => {
        const fields = field.split('.');
        let value = initialData;
        for (let f of fields) {
            value = value?.[f];
            if (value === undefined) break;
        }
        // console.log(field, value, pledgeData?.[field]);
        return value || pledgeData?.[field] || '';
    };

    const [contact, setContact] = useState(getField('contact'));
    const [contactId, setContactId] = useState(getField('contact.id'));
    const [date, setDate] = useState(new Date(getField('donation_date') || Date.now()).toLocaleInput());
    const [donationType, setDonationType] = useState(1);
    const [payments, setPayments] = useState('1');
    const [amount, setAmount] = useState(getField('amount'));
    const [currency, setCurrency] = useState(getField('currency') || localStorage.getItem('currency') || subscription.currency);
    const [fundraiser, setFundraiser] = useState(getField('fundraiser'));
    const [campaignId, setCampaignId] = useState(getField('campaign') || '');
    const [subCampaignId, setSubCampaignId] = useState(getField('sub_campaign') || '');
    const [notes, setNotes] = useState(getField('donation_notes'));
    const id = getField('donation_id');

    const [pledgeStatus, setPledgeStatus] = useState(pledgeData?.pledge || 0);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [pledgeStatusAnchorEl, setPledgeStatusAnchorEl] = useState(null);
    const [saving, setSaving] = useState(false);

    const [updateData, setUpdateData] = useState({
        donation_date: date || null,
        currency: currency,
        payments: payments,
        amount: amount,
        donation_type: donationType,
        donation_notes: notes || null,
        contact: contact?.id || null,
        campaign: campaignId || null,
        sub_campaign: subCampaignId || null,
        fundraiser: fundraiser?.id || null,
    });

    const { campaignDropdown } = useCampaignDropdown();
    const campaigns = campaignDropdown || [];

    useEffect(() => {
        return () => setError400({});
    }, []);

    const handleInputChange = (key, val) => {
        setUpdateData(prevState => {
            let newState = { ...prevState };

            if (!val && val !== '') {
                delete newState[key];
            } else {
                newState[key] = val;
            }

            return newState;
        })
    }

    const handleNameChange = (e) => {
        setContactId('');
        if (typeof e === 'string' || !e) {
            handleInputChange('contact_name', e);
            handleInputChange('contact', '');
        } else {
            handleInputChange('contact_name');
            handleInputChange('contact', e.id);
        }
    }

    const handleSave = () => {
        setSaving(true);
        if (!pledgeData) {
            api.post('/donations/create/', { ...updateData, pledge: 1 }).then((response) => {
                setSaving(false);
                onUpdate();
                enqueueSnackbar(t('Pledge Created'), { variant: 'success' });
                onClose(true);
            }).catch((error) => {
                enqueueSnackbar(t('Error saving pledge'), { variant: 'error' });
                setSaving(false);
            });
        } else {
            api.patch(`/donations/${id}/update/`, updateData).then((response) => {
                setSaving(false);
                enqueueSnackbar(t('Pledge Updated'), { variant: 'success' });
                onUpdate();
                onClose(true);
            }).catch((error) => {
                enqueueSnackbar(t('Error saving pledge'), { variant: 'error' });
                setSaving(false);
            });
        }
    }

    const handlePledgeStatusChange = (status) => {
        api.patch(`/donations/${id}/update/`, {
            pledge: status,
            currency: updateData.currency,
            amount: updateData.amount,
            payments: updateData.payments,
        }).then((response) => {
            setPledgeStatus(status);
            onUpdate();
        }).catch((error) => {
            console.error(`Error updating pledge: ${error.message}`);
        });
    }

    const removePledge = () => {
        api.post('/pledges/remove/', { donation_ids: [pledgeData.donation_id] }).then(() => {
            onUpdate();
            enqueueSnackbar(t('Pledge Deleted'), { variant: 'success' });
            onClose(true);
        }).catch((error) => {
            enqueueSnackbar(t('Error deleting pledge'), { variant: 'error' });
        });
    }

    return (
        <InfoModal
            open
            onClose={onClose}
            title={`${pledgeData ? t('Add') : t('Edit')} ${t('Pledge')}`}
            sx={{ width: '40rem' }}
        >
            <Stack spacing={2}>
                {pledgeData?.donation_id &&
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Chip
                            label={t(pledgeStatuses[pledgeStatus]?.label)}
                            color={pledgeStatuses[pledgeStatus]?.muiColor}
                            onDelete={(e) => setPledgeStatusAnchorEl(e.currentTarget)}
                            onClick={(e) => setPledgeStatusAnchorEl(e.currentTarget)}
                            deleteIcon={<ArrowDropDownRoundedIcon />}
                        />
                    </Box>}
                <Stack direction="row" spacing={4}>
                    {!hideContact &&
                        <div className="w-1/2">
                            <SearchContact
                                onChange={handleNameChange}
                                freeSolo={true}
                                value={contact}
                                placeholder={t('Contact')}
                                label={t('Contact')}
                                error={error400?.contact_name}
                                helperText={error400?.contact_name}
                            />
                        </div>}
                    <TextField
                        label={t('Date')}
                        sx={{ width: '50%' }}
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                        type="datetime-local"
                        value={date}
                        onChange={(e) => {
                            setDate(e.target.value)
                        }}
                        onBlur={(e) => {
                            handleInputChange('donation_date', e.target.value)
                        }}
                        error={error400?.donation_date}
                        helperText={error400?.donation_date}
                    />
                </Stack>
                <Stack direction="row" spacing={4}>
                    <TextField
                        variant="standard"
                        fullWidth
                        label={t("Amount")}
                        InputProps={{
                            inputComponent: CurrencyMask,
                        }}
                        value={amount}
                        onChange={(e) => {
                            setAmount(e.target.value)
                            handleInputChange('amount', e.target.value)
                        }}
                        error={error400?.amount}
                        helperText={error400?.amount}
                    />
                    <Autocomplete
                        clearOnEscape
                        disableClearable
                        fullWidth
                        options={Object.keys(currencies)}
                        getOptionLabel={(option) => t(currencies[option].name)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="standard"
                                label={t("Currency")}
                                error={error400?.currency}
                                helperText={error400?.currency}
                            />}
                        filterSelectedOptions={false}
                        value={currency}
                        onChange={(event, newValue) => {
                            setCurrency(newValue)
                            localStorage.setItem('currency', newValue);
                            handleInputChange('currency', newValue)
                        }}
                    />
                    <Autocomplete
                        clearOnEscape
                        disableClearable
                        freeSolo
                        fullWidth
                        options={paymentsOptions}
                        getOptionLabel={(option) => (option === '0' ? t('No Time Limit') : option) || (payments === '0' ? t('No Time Limit') : payments)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label={t('Payments')}
                                variant="standard"
                                error={error400?.payments}
                                helperText={error400?.payments}
                            />}
                        filterOptions={(options) => options}
                        filterSelectedOptions={false}
                        value={payments?.toString()}
                        onChange={(event, newValue) => {
                            setPayments(newValue)
                            handleInputChange('payments', newValue)
                        }}
                        onInputChange={(event, newInputValue) => {
                            if (newInputValue === 'No Time Limit') newInputValue = '0';
                            if (newInputValue && !/^\d+$/.test(newInputValue)) newInputValue = updateData.payments;
                            setPayments(newInputValue)
                            handleInputChange('payments', newInputValue)
                        }}
                    />
                    <FormControl fullWidth variant="standard">
                        <InputLabel id='payment-type-label' variant="standard">{t('Payment Type')}</InputLabel>
                        <Select
                            variant="standard"
                            labelId='payment-type-label'
                            value={donationType}
                            onChange={(e) => {
                                setDonationType(e.target.value)
                                handleInputChange('donation_type', e.target.value)
                            }}
                            label={t('Donation Type')}
                        >
                            <MenuItem value={1}>{t('Regular')}</MenuItem>
                            <MenuItem value={2}>{t('Recurring')}</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={4}>
                    {!hideCampaign &&
                        <>
                            <Box sx={{ width: '50%' }}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel id='campaign-label' variant="standard">{t('Campaign')}</InputLabel>
                                    <Select
                                        variant="standard"
                                        labelId='campaign-label'
                                        value={campaignId}
                                        onChange={(e) => {
                                            setCampaignId(e.target.value)
                                            setSubCampaignId('')
                                            handleInputChange('campaign', e.target.value)
                                        }}
                                        label={t('Campaign')}
                                    >
                                        <MenuItem sx={{ color: 'GrayText' }} value=''><i>{t('None')}</i></MenuItem>
                                        {campaigns.map((campaign) => (
                                            <MenuItem key={campaign.id} value={campaign.id}>{campaign.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ width: '50%' }}>
                                <FormControl fullWidth variant="standard">
                                    <InputLabel id='sub-campaign-label' variant="standard">{t('Sub Campaign')}</InputLabel>
                                    <Select
                                        variant="standard"
                                        labelId='sub-campaign-label'
                                        value={subCampaignId}
                                        onChange={(e) => {
                                            setSubCampaignId(e.target.value)
                                            handleInputChange('sub_campaign', e.target.value)
                                        }}
                                        label={t('Sub Campaign')}
                                    >
                                        <MenuItem sx={{ color: 'GrayText' }} value=''><i>{t('None')}</i></MenuItem>
                                        {campaignId && campaigns.find(campaign => campaign.id === campaignId)?.sub_campaigns?.map((subCampaign) => (
                                            <MenuItem key={subCampaign.id} value={subCampaign.id}>{subCampaign.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </>}
                    {!hideFundraiser &&
                        <Box sx={{ width: '50%' }}>
                            <SearchContact
                                fullWidth
                                onChange={(e) => {
                                    setFundraiser(e || '')
                                    handleInputChange('fundraiser', e.id || '')
                                }}
                                value={fundraiser}
                                placeholder={t('Fundraiser')}
                                label={t('Fundraiser')}
                                error={error400?.fundraiser}
                                helperText={error400?.fundraiser}
                            />
                        </Box>}
                </Stack>
                <TextField
                    variant="standard"
                    label={t('Notes')}
                    value={notes}
                    onChange={(e) => {
                        setNotes(e.target.value)
                        handleInputChange('donation_notes', e.target.value)
                    }}
                    error={error400?.donation_notes}
                    helperText={error400?.donation_notes}
                />
            </Stack>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: '2rem' }}>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    size="large"
                    loading={saving}
                    sx={{ borderRadius: 99 }}
                    onClick={handleSave}
                >
                    {pledgeData ? t('Add') : t('Save')}
                </LoadingButton>
            </Box>
            <Popover
                open={Boolean(pledgeStatusAnchorEl)}
                anchorEl={pledgeStatusAnchorEl}
                onClose={() => setPledgeStatusAnchorEl(null)}
                sx={{ '& .MuiPopover-paper': { borderRadius: '0.5rem' } }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: dir === 'rtl' ? 'left' : 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: dir === 'rtl' ? 'left' : 'right',
                }}
            >
                <List dense sx={{ maxWidth: 270, bgcolor: 'background.paper' }}>
                    {Object.keys(pledgeStatuses).map((key) => (
                        <ListItem disablePadding >
                            <ListItemButton onClick={() => { handlePledgeStatusChange(key); setPledgeStatusAnchorEl(null) }} >
                                <ListItemIcon>
                                    {pledgeStatus == key && <CheckRoundedIcon />}
                                </ListItemIcon>
                                <ListItemText primary={<Chip label={t(pledgeStatuses[key].label)} variant="filled" size="small" color={pledgeStatuses[key].muiColor} />} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    <ListItem disablePadding >
                        <ListItemButton onClick={() => setDeleteDialogOpen(true)} >
                            <ListItemIcon>
                                <DeleteOutlineRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('Remove')} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Popover>
            <DeleteDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onDelete={removePledge}
                title={t('Delete Pledge')}
                text={`${t('Are you sure you want to delete this pledge')}?`}
            />
        </InfoModal>
    )
}

export default observer(PledgeDetails);