import { Skeleton } from "@mui/material";

function TableSkeleton() {
    return (
        <div>
            <Skeleton variant="text" sx={{ mt: 6 }} />
            <Skeleton variant="text" height={90} />
            <Skeleton variant="text" height={90} />
        </div>
    );
}

export default TableSkeleton;