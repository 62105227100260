import { styled } from '@mui/material';
import { useDrawingArea } from '@mui/x-charts';

const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    direction: 'ltr',
    fontSize: 20,
}));

function PieCenterLabel({ children, position="center" }) {
    const { width, height, left, top } = useDrawingArea();
    const positions = {
        top: { x: left + width / 2, y: top + 20 },
        bottom: { x: left + width / 2, y: top + height - 20 },
        left: { x: left + 20, y: top + height / 2 },
        right: { x: left + width - 20, y: top + height / 2 },
        center: { x: left + width / 2, y: top + height / 2 },
    };
    
    const x = positions[position]?.x || positions.center.x;
    const y = positions[position]?.y || positions.center.y;


    return (
        <StyledText x={x} y={y}>
            {children}
        </StyledText>
    );
}

export { PieCenterLabel }