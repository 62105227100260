import React, { useMemo } from "react";

import { EmailEditorProvider, TextFormat } from "easy-email-pro-editor";
import { BlockManager, ElementType, PluginManager, EditorCore } from "easy-email-pro-core";
import { Retro, useEditorContext } from "easy-email-pro-theme";
import 'easy-email-pro-theme/lib/style.css';
import "@arco-themes/react-easy-email-pro/css/arco.css";
import he from './locals/he.json'
import { useAuthInfo } from "@propelauth/react";
import { mainStore } from "stores/MainStore";

// function POC() {
//     return (
//         <div>
//             <button onClick={() => {
//                 console.log('Test')
//             }
//             }>log</button>
//         </div>
//     )
// }

const hoveringToolbar = {
    list({ isCollapsed, selection }) {
        return [
            TextFormat.FONT_FAMILY,
            TextFormat.FONT_SIZE,
            TextFormat.BOLD,
            TextFormat.ITALIC,
            TextFormat.UNDERLINE,
            TextFormat.STRIKETHROUGH,
            TextFormat.TEXT_COLOR,
            TextFormat.BACKGROUND_COLOR,
            TextFormat.ALIGN,
            TextFormat.LINK,
            TextFormat.REMOVE_FORMAT,
            // () => <POC />,
        ];
    },
};

const fontList = [
    {
        value: "Arial",
        label: "Arial",
    },
    {
        value: "Helvetica",
        label: "Helvetica",
    },
    {
        value: "Times New Roman",
        label: "Times New Roman",
    },
    {
        value: "Courier New",
        label: "Courier New",
    },
    {
        value: "Courier",
        label: "Courier",
    },
    {
        value: "Verdana",
        label: "Verdana",
    },
    {
        value: "Georgia",
        label: "Georgia",
    },
    {
        value: "Palatino",
        label: "Palatino",
    },
    {
        value: "Garamond",
        label: "Garamond",
    },
    {
        value: "Bookman",
        label: "Bookman",
    },
    {
        value: "Comic Sans MS",
        label: "Comic Sans MS",
    },
    {
        value: "Trebuchet MS",
        label: "Trebuchet MS",
    },
    {
        value: "Arial Black",
        label: "Arial Black",
    },
    {
        value: "Impact",
        label: "Impact",
    },
    // Add the following fonts Aharoni, Calibri, David, FrankRuehl, Gisha, Levenim MT, Lucida Sans Unicode, Miriam, Miriam Fixed, Narkisim, Rod, Segoe UI, Tahoma
    // and add also Guttman Aharoni, Guttman Droogolin, Guttman Frank, Guttman Fnew, Guttman Haim, Guttman Haim-Condensed, Guttman Hatzvi, Guttman Kav, Guttman Kav-Light, Guttman Logo1, Guttman Mantova, Guttman Mantova-Decor, Guttman Miryam, Guttman Myamfix, Guttman Rashi, Guttman Stam, Guttman Stam1, Guttman Vilna, Guttman Yad, Guttman Yad-Brush, Guttman Yad-Light, Guttman-Aharoni, Guttman-Aram, Guttman-CourMir
];


function EmailEditor({ emailId, locale, template, EmailHeader, handleSave }) {
    const token = useAuthInfo().accessToken
    const { orgId } = mainStore;

    const initialValues = useMemo(() => {
        return {
            subject: template.subject,
            content: template.content,
        };
    }, []);

    const onUpload = async (file) => {
        const data = new FormData();
        data.append("image", file);
        data.append("row_id", emailId);
        data.append("model", "email");

        try {
            const response = await fetch(
                `/api/upload-image/?org_id=${orgId}`,
                {
                    method: "POST",
                    body: data,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            const res = await response.json();
            return 'https://media.donbar.org/' + res;
        } catch (error) {
            return null;
        }
    };

    const config = Retro.useCreateConfig({
        clientId: 'FREE',
        height: "calc(100vh - 58px)",
        onUpload,
        initialValues: initialValues,
        hoveringToolbar: hoveringToolbar,
        fontList: fontList,
        onSubmit: handleSave,
        ...(locale === 'he' ? { localeData: he } : {})
    });

    return (
        <EmailEditorProvider
            {...config}
        >
            {/* <button onClick={() => {
                console.log('CustomEditorTypes')
            }}>log</button> */}
            <EmailHeader />
            <Retro.Layout></Retro.Layout>
        </EmailEditorProvider>
    );
}

export default EmailEditor;