import { useTranslation } from "react-i18next";
import H1 from "components/common/Typography/H1";
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Box from "components/common/Box";
import { Alert, Button, Collapse, FormControl, IconButton, Input, InputAdornment, InputLabel, OutlinedInput, TextField, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import ActionModal from "../../../components/ActionModal";
import { useEffect } from "react";
import Table from "../../../components/common/TableComponents/Table";
import { Tbody, Td, Tr } from "../../../components/common/TableComponents/Tbody";
import Thead from "../../../components/common/TableComponents/Thead";
import Th from "../../../components/common/TableComponents/Th";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Modal from "components/common/Modal";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import Options from "components/Options";
import WarningDialog from "components/common/DeleteDialog";
import useApi from "hooks/useApi";

const cacheLtr = createCache({
    key: 'muiltr',
    stylisPlugins: [prefixer],
});

const domainStatus = {
    Success: {
        color: 'green',
        text: 'Verified',
        icon: 'check'
    },
    Pending: {
        color: 'yellow',
        text: 'Pending',
        icon: 'pending'
    },
    Failed: {
        color: 'red',
        text: 'Failed',
        icon: 'error'
    }
}

function EmailSettings() {
    const { t, i18n } = useTranslation();
    const api = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const [addDomainAnchorEl, setAddDomainAnchorEl] = useState(null);
    // const [domains, setDomains] = useState(domaindata);
    const [domains, setDomains] = useState([]);
    const [newDomain, setNewDomain] = useState('');
    const [domainRowOpen, setDomainRowOpen] = useState(null);
    const [editEmail, setEditEmail] = useState(null);
    const [domainEmail, setDomainEmail] = useState({ domain: '', email: '', name: '', reply_to: '' });
    const [dnsRecords, setDnsRecords] = useState(null);
    const [submitingNewDomain, setSubmitingNewDomain] = useState(false);
    const [savingNewEmail, setSavingNewEmail] = useState(false);
    const [verifyingDomain, setVerifyingDomain] = useState(false);
    const [openedDomainId, setOpenedDomainId] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(null);
    const [singleSenders, setSingleSenders] = useState([]);

    useEffect(() => {
        api.get('/settings/domains/').then(res => {
            setDomains(res.data);
        }).catch(err => {
            enqueueSnackbar(t("Something went wrong"), { variant: 'error' });
        })
        api.get('/settings/from-emails/').then(res => {
            setSingleSenders(res.data);
        }).catch(err => {
            enqueueSnackbar(t("Something went wrong"), { variant: 'error' });
        })
    }, [])

    const handleAddDomain = () => {
        setSubmitingNewDomain(true);
        api.post('/settings/add-domain/', {
            domain: newDomain
        }).then(res => {
            setSubmitingNewDomain(false);
            setAddDomainAnchorEl(null);
            setDomains(prev => [...prev, res.data]);
            setNewDomain('');
            setDnsRecords(res.data.dns_records);
            setOpenedDomainId(res.data.id);
        }).catch(err => {
            setSubmitingNewDomain(false);
            enqueueSnackbar(t("Something went wrong"), { variant: 'error' });
        })
    }

    const handleDeleteEmial = (emailId) => {
        api.delete(`/settings/domains/emails/delete/${emailId}/`).then(res => {
            setDomains(prev => prev.map(domain => {
                const newDomain = { ...domain };
                newDomain.domain_emails = newDomain.domain_emails.filter(email => email.id !== emailId);
                return newDomain;
            }))
            enqueueSnackbar(t("Email deleted successfully"), { variant: 'success' });
        }).catch(err => {
            enqueueSnackbar(t("Something went wrong"), { variant: 'error' });
        })
    }

    const handleAddEmail = () => {
        setSavingNewEmail(true);
        api.post('/settings/domains/emails/create/', domainEmail).then(res => {
            setDomains(prev => prev.map(domain => {
                const newDomain = { ...domain };
                if (newDomain.id === res.data.domain) {
                    newDomain.domain_emails.push(res.data);
                }
                return newDomain;
            }))
            setSavingNewEmail(false);
            setEditEmail(null);
            enqueueSnackbar(t("Email added successfully"), { variant: 'success' });
        }).catch(err => {
            setSavingNewEmail(false);
            enqueueSnackbar(t("Something went wrong"), { variant: 'error' });
        })
    }

    const hundleUpdateEmail = (emailId) => {
        setSavingNewEmail(true);
        api.put(`/settings/domains/emails/update/${emailId}/`, domainEmail).then(res => {
            setDomains(prev => prev.map(domain => {
                const newDomain = { ...domain };
                if (newDomain.id === res.data.domain) {
                    newDomain.domain_emails = newDomain.domain_emails.map(email => {
                        if (email.id === res.data.id) {
                            return res.data;
                        }
                        return email;
                    })
                }
                return newDomain;
            }))
            setSavingNewEmail(false);
            setEditEmail(null);
            enqueueSnackbar(t("Email updated successfully"), { variant: 'success' });
        }).catch(err => {
            setSavingNewEmail(false);
            enqueueSnackbar(t("Something went wrong"), { variant: 'error' });
        })
    }

    const handleVerifyDomain = (domainId) => {
        setVerifyingDomain(true);
        api.post(`/settings/domains/verify/${domainId}/`, {}).then(res => {
            setDomains(prev => prev.map(domain => {
                const newDomain = { ...domain };
                if (newDomain.id === domainId) {
                    newDomain.dns_records = res.data.dns_records;
                }
                return newDomain;
            }))
            setDnsRecords(res.data.dns_records);
            enqueueSnackbar(t("Domain verification started"), { variant: 'success' });
            setVerifyingDomain(false);
        }).catch(err => {
            enqueueSnackbar(t("Something went wrong"), { variant: 'error' });
            setVerifyingDomain(false);
        })
    }

    const handleDeleteDomain = (domainId) => {
        api.delete(`/settings/domains/delete/${domainId}/`).then(res => {
            setDomains(prev => prev.filter(domain => domain.id !== domainId));
            enqueueSnackbar(t("Domain deleted successfully"), { variant: 'success' });
            setDeleteDialogOpen(null);
            setDnsRecords(null);
            setOpenedDomainId(null);
        }).catch(err => {
            enqueueSnackbar(t("Something went wrong"), { variant: 'error' });
            setDeleteDialogOpen(null);
        })
    }

    return (
        <div>
            <H1>{t("Email Settings")}</H1>
            <p className="text-start text-2xl text-custom-blue font-bold mt-0 ">{t("Domains")}</p>
            <Box>
                <div>
                    <Table className={!domains.length && "hidden"} >
                        <Thead>
                            <Th>{t("Status")}</Th>
                            <Th>{t("Domain")}</Th>
                        </Thead>
                        <Tbody>
                            {domains.map((domain, index) => (
                                <>
                                    <Tr
                                        onClick={() => {
                                            setDnsRecords(domain.dns_records);
                                            setOpenedDomainId(domain.id);
                                        }}
                                        className="cursor-pointer"
                                    >
                                        <Td className="w-14">
                                            <div className="flex items-center gap-2">
                                                <div className={`w-3 h-3 rounded-full bg-${domainStatus[domain.summarized_status].color}-500`}></div>
                                                <div className={`text-${domainStatus[domain.summarized_status].color}-500`}>
                                                    {t(domainStatus[domain.summarized_status].text)}
                                                </div>
                                            </div>
                                        </Td>
                                        <Td className="ltr">{domain.domain}</Td>
                                        <Td className="text-end">
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setDomainRowOpen(prev => prev === index ? null : index)
                                                }}
                                            >
                                                {domainRowOpen === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </Td>
                                    </Tr>
                                    <Tr className={`${domainRowOpen === index ? '' : 'hidden'}`}>
                                        <Td colSpan={3}>
                                            <Collapse in={domainRowOpen === index} unmountOnExit>
                                                {(domain.domain_emails.length > 0 || (editEmail?.domain === index && editEmail?.email === 'new')) && (
                                                    <Table>
                                                        <Thead>
                                                            <Th>{t("Email Address")}</Th>
                                                            <Th>{t("Name")}</Th>
                                                            <Th>{t("Reply To")}</Th>
                                                        </Thead>
                                                        <Tbody>
                                                            {domain.domain_emails.map((email, emailIndex) => (
                                                                editEmail?.domain === index && editEmail?.email === emailIndex ? (
                                                                    <EmailForm
                                                                        email={domainEmail}
                                                                        setEmail={setDomainEmail}
                                                                        domain={domain}
                                                                        onClose={() => {
                                                                            setEditEmail(null);
                                                                            setDomainEmail({ domain: '', email: '', name: '', reply_to: '' });
                                                                        }}
                                                                        saving={savingNewEmail}
                                                                        onSave={() => hundleUpdateEmail(email.id)}
                                                                    />
                                                                ) : (
                                                                    <Tr>
                                                                        <Td>{email.email}@{domain.domain}</Td>
                                                                        <Td>{email.name}</Td>
                                                                        <Td>{email.reply_to}</Td>
                                                                        <Td>
                                                                            <div className="text-end">
                                                                                <Options
                                                                                    options={[
                                                                                        {
                                                                                            text: t("Edit"),
                                                                                            icon: <EditRoundedIcon />,
                                                                                            onClick: () => {
                                                                                                setEditEmail({ domain: index, email: emailIndex });
                                                                                                setDomainEmail({ domain: domain.id, email: email.email, name: email.name, reply_to: email.reply_to });
                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            text: t("Delete"),
                                                                                            icon: <DeleteOutlinedIcon />,
                                                                                            onClick: () => handleDeleteEmial(email.id)
                                                                                        }

                                                                                    ]}
                                                                                    dense
                                                                                />
                                                                            </div>
                                                                        </Td>
                                                                    </Tr>
                                                                )
                                                            ))}
                                                            {editEmail?.domain === index && editEmail?.email === 'new' && (
                                                                <EmailForm
                                                                    email={domainEmail}
                                                                    setEmail={setDomainEmail}
                                                                    domain={domain}
                                                                    saving={savingNewEmail}
                                                                    onSave={handleAddEmail}
                                                                    onClose={() => {
                                                                        setEditEmail(null);
                                                                        setDomainEmail({ domain: '', email: '', name: '', reply_to: '' });
                                                                    }}
                                                                />
                                                            )}
                                                        </Tbody>
                                                    </Table>
                                                )}
                                                {editEmail?.domain !== index && editEmail?.email !== 'new' && (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                            setEditEmail({ domain: index, email: 'new' });
                                                            setDomainEmail({ domain: domain.id, email: '', name: '', reply_to: '' });
                                                        }}>
                                                        {t("Add Email")}
                                                    </Button>
                                                )}
                                            </Collapse>
                                        </Td>
                                    </Tr>
                                </>
                            ))}
                        </Tbody>
                    </Table>
                    <Button variant="contained" color="primary" onClick={(e) => setAddDomainAnchorEl(e.currentTarget)}>{t("Add Domain")}</Button>
                    <ActionModal
                        title={t("Add Domain")}
                        // text={t("Add Domain Text")}
                        open={addDomainAnchorEl}
                        anchorEl={addDomainAnchorEl}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        okButtonText={t("Add Domain")}
                        submiting={submitingNewDomain}
                        onClose={() => setAddDomainAnchorEl(null)}
                        handleOk={handleAddDomain}
                    >
                        <TextField
                            fullWidth
                            label={t("Your Domain")}
                            placeholder="example.com"
                            value={newDomain}
                            onChange={(e) => setNewDomain(e.target.value)}
                            inputProps={{
                                dir: 'ltr'
                            }}
                        />
                    </ActionModal>
                </div>
            </Box >
            <SingleSender
                emails={singleSenders}
                setEmails={setSingleSenders}
            />
            <DnsRecords
                records={dnsRecords || []}
                open={dnsRecords}
                onClose={() => setDnsRecords(null)}
                handleVerify={() => handleVerifyDomain(openedDomainId)}
                verifying={verifyingDomain}
                onDelete={() => setDeleteDialogOpen(openedDomainId)}
            />
            <WarningDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(null)}
                onDelete={() => handleDeleteDomain(deleteDialogOpen)}
                title={t("Delete Domain")}
                text={t("Are you sure you want to delete this domain?")}
            />
        </div >
    );
}

function SingleSender({ emails, setEmails }) {
    const { t } = useTranslation();
    const api = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const [editEmail, setEditEmail] = useState({ email: '', name: '', reply_to: '' });
    const [editEmailIndex, setEditEmailIndex] = useState(null);
    const [saving, setSaving] = useState(false);

    const handleAddEmail = () => {
        setSaving(true);
        api.post('/settings/from-emails/create/', editEmail).then(res => {
            setEmails(prev => [...prev, res.data]);
            setSaving(false);
            setEditEmail({ email: '', name: '', reply_to: '' });
            setEditEmailIndex(null);
            enqueueSnackbar(t("Email added successfully"), { variant: 'success' });
        }).catch(err => {
            setSaving(false);
            enqueueSnackbar(t("Something went wrong"), { variant: 'error' });
        })
    }

    const handleDeleteEmail = (emailId) => {
        api.delete(`/settings/from-emails/delete/${emailId}/`).then(res => {
            setEmails(prev => prev.filter(email => email.id !== emailId));
            enqueueSnackbar(t("Email deleted successfully"), { variant: 'success' });
        }).catch(err => {
            enqueueSnackbar(t("Something went wrong"), { variant: 'error' });
        })
    }

    const hundleUpdateEmail = (emailId) => {
        setSaving(true);
        api.put(`/settings/from-emails/update/${emailId}/`, editEmail).then(res => {
            setEmails(prev => prev.map(email => {
                if (email.id === res.data.id) {
                    return res.data;
                }
                return email;
            }))
            setSaving(false);
            setEditEmail({ email: '', name: '', reply_to: '' });
            setEditEmailIndex(null);
            enqueueSnackbar(t("Email updated successfully"), { variant: 'success' });
        }).catch(err => {
            setSaving(false);
            enqueueSnackbar(t("Something went wrong"), { variant: 'error' });
        })
    }

    return (
        <div>
            <p className="text-start text-2xl text-custom-blue font-bold mt-0 ">{t("Single Sender")}</p>
            <Box>
                <div>
                    <Table>
                        {(emails.length > 0 || editEmailIndex !== null) && (
                            <Thead>
                                <Th>{t("Status")}</Th>
                                <Th>{t("Email Address")}</Th>
                                <Th>{t("Sennder Name")}</Th>
                                <Th>{t("Reply To")}</Th>
                                <Th></Th>
                            </Thead>
                        )}
                        <Tbody>
                            {emails.map((email, index) => (
                                editEmailIndex === index ? (
                                    <EmailForm
                                        emailDisabled
                                        email={editEmail}
                                        setEmail={setEditEmail}
                                        saving={saving}
                                        onSave={() => hundleUpdateEmail(email.id)}
                                        onClose={() => {
                                            setEditEmailIndex(null);
                                            setEditEmail({ email: '', name: '', reply_to: '' });
                                        }}
                                    />
                                ) : (
                                    <Tr>
                                        <Td>
                                            <div className="flex items-center gap-2">
                                                <div className={`w-3 h-3 rounded-full bg-${domainStatus[email.status].color}-500`}></div>
                                                <div className={`text-${domainStatus[email.status].color}-500`}>
                                                    {t(domainStatus[email.status].text)}
                                                </div>
                                            </div>
                                        </Td>
                                        <Td>{email.email}</Td>
                                        <Td>{email.name}</Td>
                                        <Td>{email.reply_to}</Td>
                                        <Td className="text-end">
                                            <Options
                                                options={[
                                                    {
                                                        text: t("Edit"),
                                                        icon: <EditRoundedIcon />,
                                                        onClick: () => {
                                                            setEditEmailIndex(emails.findIndex(e => e.id === email.id));
                                                            setEditEmail(email);
                                                        }
                                                    },
                                                    {
                                                        text: t("Delete"),
                                                        icon: <DeleteOutlinedIcon />,
                                                        onClick: () => handleDeleteEmail(email.id)
                                                    }
                                                ]}
                                                dense
                                            />
                                        </Td>
                                    </Tr>
                                )))}
                            {emails.length === editEmailIndex && (
                                <EmailForm
                                    email={editEmail}
                                    setEmail={setEditEmail}
                                    saving={saving}
                                    onSave={handleAddEmail}
                                    onClose={() => {
                                        setEditEmailIndex(null);
                                        setEditEmail({ email: '', name: '', reply_to: '' });
                                    }}
                                />
                            )}
                        </Tbody>
                    </Table>
                    <Button variant="contained" color="primary" onClick={() => setEditEmailIndex(emails.length)}>{t("Add Email")}</Button>
                </div>
            </Box>
        </div>
    );
}

function DnsRecords({ records, onDelete, handleVerify, verifying, ...otherProps }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const handleCopy = (value) => {
        navigator.clipboard.writeText(value);
        enqueueSnackbar(t("Copied to clipboard"), { variant: 'success' });
    }

    return (
        <Modal
            {...otherProps}
            className="max-w-4xl"
            title={t("DNS Records")}
        >
            {/* Helper text instructing the user to add these dns records to their dns and then verify */}
            <div className="flex justify-center m-4">
                <Alert severity="info" variant="standard" icon >
                    {t("Add these DNS records to your DNS provider, then click Verify to verify your domain.")}
                </Alert>
            </div>
            <Table className="overflow-scroll">
                <Thead>
                    <Th>{t("Status")}</Th>
                    <Th>{t("Name")}</Th>
                    <Th>{t("dns-record-type")}</Th>
                    <Th>{t("Value")}</Th>
                </Thead>
                <Tbody className="w-[100px]">
                    {records.map(record => (
                        <Tr>
                            <Td>
                                <div className="flex items-center gap-2">
                                    <div className={`w-3 h-3 rounded-full bg-${domainStatus[record.status].color}-500`}></div>
                                    <div className={`text-${domainStatus[record.status].color}-500`}>
                                        {t(domainStatus[record.status].text)}
                                    </div>
                                </div>
                            </Td>
                            <Td className="flex items-center justify-between">
                                <code className="font-mono truncate text-ellipsis max-w-[12rem]">{record.record.name}</code>
                                <Tooltip title={t("Copy to clipboard")}>
                                    <IconButton
                                        onClick={() => handleCopy(record.record.name)}
                                        size="small"
                                    >
                                        <ContentCopyIcon fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>
                            </Td>
                            <Td>{record.record.type}</Td>
                            <Td className="flex items-center justify-between">
                                <code className="font-mono truncate text-ellipsis max-w-[12rem]">{record.record.value}</code>
                                <Tooltip title={t("Copy to clipboard")}>
                                    <IconButton
                                        onClick={() => handleCopy(record.record.value)}
                                        size="small"
                                    >
                                        <ContentCopyIcon fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <div className="text-center justify-center flex gap-x-4">
                <Button
                    variant="outlined"
                    color="error"
                    sx={{ borderRadius: 99 }}
                    onClick={onDelete}
                >
                    {t("Delete")}
                </Button>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: 99 }}
                    loading={verifying}
                    onClick={handleVerify}
                >
                    {t("Verify")}
                </LoadingButton>
            </div>
        </Modal>
    )
}

function EmailForm({ email, setEmail, emailDisabled, domain = false, saving = false, onSave, onClose }) {
    const { t, i18n } = useTranslation();

    return (
        <Tr>
            <Td>
                {!domain ? (
                    <TextField
                        fullWidth
                        disabled={emailDisabled}
                        label={t("Email Address")}
                        placeholder="email@example.com"
                        size="small"
                        value={email.email}
                        onChange={(e) => setEmail(prev => ({ ...prev, email: e.target.value }))}
                    />
                ) : (
                    i18n.dir() === 'rtl' ? (
                        <CacheProvider value={cacheLtr}>
                            <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                label={t("Email Address")}
                                placeholder="email"
                                id="email-input"
                                value={email.email}
                                onChange={(e) => setEmail(prev => ({ ...prev, email: e.target.value }))}
                                InputLabelProps={{
                                    sx: {
                                        '&.MuiInputLabel-shrink': {
                                            width: '5rem',
                                            right: '14px',
                                            left: 'auto !important',
                                        }
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">@{domain.domain}</InputAdornment>
                                    ),
                                    style: {
                                        direction: 'ltr',
                                    }
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        paddingLeft: 'calc(100% - 5rem)',
                                    },
                                    '& .MuiInputAdornment-root': {
                                        marginLeft: 'auto',
                                    },
                                }}
                            />
                        </CacheProvider>
                    ) : (
                        <TextField
                            fullWidth
                            label={t("Email Address")}
                            placeholder="support"
                            size="small"
                            value={email.email}
                            onChange={(e) => setEmail(prev => ({ ...prev, email: e.target.value }))}
                            InputProps={{
                                endAdornment: <InputAdornment position="end" sx={{ m: 1 }}>@{domain.domain}</InputAdornment>,
                            }}
                        />
                    )
                )}
            </Td>
            <Td>
                <TextField
                    fullWidth
                    label={t("Sennder Name")}
                    placeholder={t("Sennder Name")}
                    size="small"
                    value={email.name}
                    onChange={(e) => setEmail(prev => ({ ...prev, name: e.target.value }))}
                />
            </Td>
            <Td>
                <TextField
                    fullWidth
                    label={t("Reply To")}
                    placeholder={`${email.email}@${domain.domain}`}
                    size="small"
                    value={email.reply_to}
                    onChange={(e) => setEmail(prev => ({ ...prev, reply_to: e.target.value }))}
                />
            </Td>
            <Td>
                <div className="flex justify-end gap-2">
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        onClick={onSave}
                        size="small"
                        loading={saving}
                    >
                        {t("Save")}
                    </LoadingButton>
                    <IconButton
                        onClick={onClose}
                        size="small"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </Td>
        </Tr>
    )
}

export default EmailSettings;