import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";
import currencies from 'data/currencies';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { itemStatus } from "data/general";
import { Chip, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover } from "@mui/material";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import { useState } from "react";
import { localDate } from "utils/dates";
import Sort from "components/sort";
import { ListItemChangeStatus } from "components/ListItemStatus";
import Pagination from "components/common/Pagination";

function DonationList({ donations, checkedItems, setCheckedItems, checkAll, setCheckAll, currentPage, setCurrentPage, getSort, setSort, totalPages, addDonation, statusField, removeListItem, handleChangeStatus, checkbox = true, donorName = true, email = true }) {
    const { t } = useTranslation()
    const dir = document.dir;

    const [listStatusAnchorEl, setListStatusAnchorEl] = useState(null);
    const [listStatusIndex, setListStatusIndex] = useState(0);
    const [listStatus, setListStatus] = useState(0);

    const pagePadding = 2;
    const visiblePages = 3;

    // check functions
    const handleCheckAll = () => {
        setCheckAll(prev => !prev);
        if (!checkAll) {
            const allDonations = donations.map((donation) => donation.donation_id);
            setCheckedItems(allDonations);
        } else {
            setCheckedItems([]);
        }
    };

    const handleCheck = (donation) => {
        setCheckedItems((prev) => {
            if (prev.includes(donation)) {
                setCheckAll(false);
                return prev.filter((item) => item !== donation);
            } else {
                const newItems = [...prev, donation];
                if (newItems.length === donations.length) {
                    setCheckAll(true);
                }
                return newItems;
            }
        });
    };

    // pagination functions

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const pagination = () => {
        const pages = [];

        for (let i = 1; i <= Math.min(2, totalPages); i++) {
            pages.push(i);
        }

        if (currentPage - visiblePages - pagePadding > 1) {
            pages.push('...');
        }

        for (let i = currentPage - visiblePages; i <= currentPage + visiblePages; i++) {
            if (i > pagePadding && i <= totalPages - pagePadding) {
                pages.push(i);
            }
        }

        if (currentPage + visiblePages + pagePadding < totalPages) {
            pages.push('...');
        }

        for (let i = totalPages - 1; i <= totalPages; i++) {
            if (i > 2) {
                pages.push(i);
            }
        }

        return pages;
    };


    return (
        <div className="text-start">
            {/* <div className="relative h-screen overflow-hidden">
                <div className="overflow-y-auto max-h-[calc(100vh-20px)]"> */}
            <div className="overflow-x-auto mb-10">
                <table className="table-auto border-collapse mb-2 w-full">
                    <thead>
                        <tr className="rounded-lg text-sm font-medium text-gray-700">
                            <th className="px-4 py-2">
                                {checkbox &&
                                    <div className="flex justify-center items-center h-full">
                                        <input
                                            type="checkbox"
                                            onChange={handleCheckAll}
                                            checked={checkAll}
                                            className="h-4 w-4"
                                        />
                                    </div>}
                            </th>
                            <th className="px-4 py-2">
                                {/* <div className='flex gap-2'>{t('Status')}
                                <Sort field='status' sort={getSort('status')} onSort={onSort} />
                            </div> */}
                            </th>
                            <th className="px-4 py-2">{t('number')}</th>
                            {donorName &&
                                <th className="px-4 py-2">
                                    <div className='flex gap-2 items-center'>{t('donor')}
                                        <Sort field='name' sort={getSort('name')} onSort={setSort} />
                                    </div>
                                </th>}
                            <th className="px-4 py-2">
                                <div className='flex gap-2 items-center'>{t('date')}
                                    <Sort field='donation_date' sort={getSort('donation_date')} onSort={setSort} />
                                </div>
                            </th>
                            <th className="px-4 py-2">
                                <div className='flex gap-2 items-center'>{t('Donation Type')}
                                    <Sort field='donation_type' sort={getSort('donation_type')} onSort={setSort} />
                                </div>
                            </th>
                            <th className="px-4 py-2">
                                <div className='flex gap-2 items-center'>{t('amount')}
                                    <Sort field='usd_amount' sort={getSort('usd_amount')} onSort={setSort} />
                                </div>
                            </th>
                            <th className="px-4 py-2">
                                <div className='flex gap-2 items-center'>{t('payments')}
                                    <Sort field='payments' sort={getSort('payments')} onSort={setSort} />
                                </div>
                            </th>
                            {email && <th className="px-4 py-2">{t('email')}</th>}
                            <th className="px-4 py-2">
                                <div className='flex gap-2 items-center'>{t('fundraiser')}
                                    <Sort field='fundraiser' sort={getSort('fundraiser')} onSort={setSort} />
                                </div>
                            </th>
                            <th className="px-4 py-2">
                                <div className='flex gap-2 items-center'>{t('Notes')}
                                    <Sort field='donor_note' sort={getSort('donor_note')} onSort={setSort} />
                                </div>
                            </th>
                            {statusField && <th className="px-4 py-2">
                                <div className='flex gap-2 items-center'>{t('Done')}
                                    <Sort field='list_status' sort={getSort('list_status')} onSort={setSort} />
                                </div>
                            </th>}
                        </tr>
                    </thead>
                    <tbody className="text-sm font-normal text-gray-700 bg-white rounded-2xl shadow-sm">
                        {donations.map((donation, index) => (
                            <tr key={donation.id} className={`hover:bg-gray-100 ${index === donations.length - 1 ? '' : 'border-b '} border-gray-200 py-10`}>
                                <td className={`px-4 py-4 ${index === 0 && 'rounded-ts-2xl'}
                            ${index === donations.length - 1 && 'rounded-bs-2xl'}`}>
                                    {checkbox &&
                                        <div className="flex justify-center items-center h-full">
                                            <input type="checkbox" onChange={() => handleCheck(donation.donation_id)} checked={checkedItems.includes(donation.donation_id)} className="h-4 w-4" />
                                        </div>}
                                </td>
                                <td className="">
                                    {donation.donation_type === 2 &&
                                        (donation.payments === donation.payments_months && donation.payments !== 0 ?
                                            <div className="bg-gray-200 text-xs font-semibold px-2 py-1 w-fit text-gray-700 rounded-md">
                                                {t('Inactive')}
                                            </div>
                                            :
                                            (donation.donation_status === 3 ?
                                                <div className="bg-red-200 text-xs font-semibold px-2 py-1 w-fit text-red-700 rounded-md">
                                                    {t('Rejected')}
                                                </div>
                                                :
                                                <div className="bg-green-100 text-xs font-semibold px-2 py-1 w-fit text-green-700 rounded-md">
                                                    {t('Active')}
                                                </div>
                                            ))}
                                </td>
                                <td className="px-4 py-4">
                                    <Link to={`/donations/${donation.donation_id}`} className="underline underline-offset-4 hover:text-blue-900">
                                        {donation.donation_id}
                                    </Link>
                                </td>
                                {donorName &&
                                    <td className="px-4 py-4">
                                        {donation.contact?.status === 1 ?
                                            <Link to={`/contacts/${donation.contact?.contact_id}`} className="underline underline-offset-4 hover:text-blue-900">
                                                {donation.contact?.first_name} {donation.contact?.last_name}
                                            </Link>
                                            :
                                            // <div className="text-gray-400">
                                            <div>
                                                {donation.contact?.first_name} {donation.contact?.last_name}
                                            </div>
                                        }
                                    </td>}
                                <td className="px-4 py-4">
                                    {localDate(donation.donation_date)}
                                </td>
                                <td className="px-4 py-4">
                                    {donation.donation_type === 1 && t('Regular')}
                                    {donation.donation_type === 2 && t('Recuring')}
                                </td>
                                <td className="px-4 py-4">
                                    {parseFloat(donation.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currencies[donation.currency].symbol_native}
                                </td>
                                <td className="px-4 py-4">
                                    {donation.payments > 1 && (`${donation.payments_months} / `)}
                                    {donation.payments === 0 ? `${donation.payments_months} / ${t('No Time Limit')}` : (donation.payments)}
                                </td>
                                {email &&
                                    <td className="px-4 py-4">
                                        {donation.contact?.email_addresses?.length > 0 && (
                                            <a
                                                href={`mailto:${donation.contact.email_addresses[donation.contact.email_addresses.length - 1].email}`}
                                                className="font-medium hover:text-blue-900"
                                            >
                                                {donation.contact.email_addresses[donation.contact.email_addresses.length - 1].email}
                                            </a>
                                        )}
                                    </td>}
                                <td className="px-4 py-4">
                                    <Link to={`/contacts/${donation.fundraiser?.contact_id}`} className="underline underline-offset-4 hover:text-blue-900">
                                        {donation.fundraiser?.first_name} {donation.fundraiser?.last_name}
                                    </Link>
                                </td>
                                <td className={`px-4 py-4 ${index === 0 && !statusField && 'rounded-te-2xl'}
                            ${index === donations.length - 1 && !statusField && 'rounded-be-2xl'}`}>
                                    <div className='flex-shrink min-w-0 max-w-xs truncate-2-lines'>
                                        {donation.donor_note}
                                    </div>
                                </td>
                                {statusField && <td className={`px-4 py-4 ${index === 0 && 'rounded-te-2xl'}
                            ${index === donations.length - 1 && 'rounded-be-2xl'}`}>
                                    <Chip
                                        label={t(itemStatus[donation?.list_status].label)}
                                        variant="filled"
                                        size="small"
                                        color={itemStatus[donation?.list_status].color}
                                        deleteIcon={<ArrowDropDownRoundedIcon />}
                                        onDelete={(e) => {
                                            setListStatusAnchorEl(e.currentTarget)
                                            setListStatusIndex(donation?.donation_id)
                                            setListStatus(donation?.list_status)
                                        }}
                                        onClick={(e) => {
                                            setListStatusAnchorEl(e.currentTarget)
                                            setListStatusIndex(donation?.donation_id)
                                            setListStatus(donation?.list_status)
                                        }}
                                    />
                                </td>}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <ListItemChangeStatus
                anchorEl={listStatusAnchorEl}
                onClose={() => setListStatusAnchorEl(null)}
                listStatusIndex={listStatusIndex}
                handleChangeStatus={handleChangeStatus}
                listStatus={listStatus}
                removeListItem={removeListItem}
            />
            {addDonation}
            <Pagination totalPages={totalPages} currentPage={currentPage} onChange={setCurrentPage} />
        </div>
    )
}

export default DonationList