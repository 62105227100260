import { Box, Stack, Typography } from "@mui/material";
import Options from "components/Options";
import { observer } from "mobx-react-lite";
import { uiStore } from "stores/UiStore";

function ViewHeader() {
    const { title, options, viewNavigation } = uiStore;

    if (!title) return null;

    return (
        <Box>
            {viewNavigation}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Typography variant="h1">
                    {title}
                </Typography>
                <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
                    {/* <Button
                        variant="outlined"
                        size="small"
                        sx={{
                            textTransform: 'none',
                            borderRadius: 99,
                        }}
                    >
                        {t('Export Contacts')}
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        sx={{
                            textTransform: 'none',
                            borderRadius: 99,
                        }}
                    >
                        {t('Export Contacts')}
                    </Button> */}
                    {options?.length ? <Options options={options} /> : null}
                </Stack>
            </Box>
        </Box>
    );
}

export default observer(ViewHeader);