import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mainStore } from 'stores/MainStore';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import { Badge, Box, Stack, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';

const SecondaryNav = () => {
    const { orgId, organizations, subscription } = mainStore;
    const { t } = useTranslation();
    const location = useLocation();
    const pathname = location.pathname;
    const locations = pathname.split('/').filter(Boolean);
    const tab = locations[0];

    const groupsPath = useMemo(() => {
        const groupsIndex = locations.indexOf('groups')
        const hasGroupId = groupsIndex !== -1 && locations.length > (groupsIndex + 1)
        const fundraiserIndex = locations.indexOf('fundraisers')
        const hasFundraiserId = fundraiserIndex !== -1 && locations.length > (fundraiserIndex + 1)
        const subCampaignsIndex = locations.indexOf('sub-campaigns')
        const hasSubCampaignId = subCampaignsIndex !== -1 && locations.length > (subCampaignsIndex + 1)

        if (groupsIndex !== -1) return locations.slice(1, groupsIndex).join('/')
        if (fundraiserIndex !== -1) return locations.slice(1, fundraiserIndex).join('/')
        if (hasSubCampaignId) return locations.slice(1, locations.indexOf('sub-campaigns') + 2).join('/')
        if (subCampaignsIndex !== -1) return locations.slice(1, subCampaignsIndex).join('/')
        return locations.slice(1, locations.length).join('/')
    }, [locations])

    const fundraisersPath = useMemo(() => {
        const groupsIndex = locations.indexOf('groups')
        const hasGroupId = groupsIndex !== -1 && locations.length > (groupsIndex + 1)
        const fundraiserIndex = locations.indexOf('fundraisers')
        const hasFundraiserId = fundraiserIndex !== -1 && locations.length > (fundraiserIndex + 1)
        const subCampaignsIndex = locations.indexOf('sub-campaigns')
        const hasSubCampaignId = subCampaignsIndex !== -1 && locations.length > (subCampaignsIndex + 1)

        if (fundraiserIndex !== -1) return locations.slice(1, fundraiserIndex).join('/')
        if (hasGroupId) return locations.slice(1, groupsIndex + 2).join('/')
        if (groupsIndex !== -1) return locations.slice(1, groupsIndex).join('/')
        if (hasSubCampaignId) return locations.slice(1, subCampaignsIndex + 2).join('/')
        if (subCampaignsIndex !== -1) return locations.slice(1, subCampaignsIndex).join('/')
        return locations.slice(1).join('/')
    }, [locations])

    const TABS = {
        contacts: {
            label: 'Contacts',
            links: [
                { label: 'Contacts', href: '' },
                { label: 'Tasks', href: '/tasks' },
                { label: 'Task Types', href: '/task-types' },
                { label: 'Groups', href: '/groups' },
                ...(subscription.emails ? [{ label: 'Emails', href: '/emails' }] : []),
            ],
        },
        donations: {
            label: 'Donations',
            links: [
                { label: 'Donations', href: '' },
                { label: 'Pledges', href: '/pledges' },
                { label: 'Groups', href: '/groups' },
                {
                    label: 'Campaigns',
                    href: '/campaigns',
                    disabled: true,
                    enabled: false,
                },
                { label: 'Fix and Merge', href: '/fix-and-merge' },
            ]
        },
        expenses: {
            label: 'Expenses',
            links: [
                { label: 'Expenses', href: '' },
                { label: 'Expense Types', href: '/expense-types' },
            ],
        },
        campaigns: {
            label: 'Campaigns',
            links: [
                { label: 'Campaigns', href: '' },
                ...locations?.[1] ? [
                    { label: 'Sub Campaigns', href: `/${locations[1]}/sub-campaigns` },
                    { label: 'Dashboards', href: `/${locations[1]}/dashboards`,
                    disabled: true,
                    enabled: false,},
                    // { label: 'Groups', href: `/${groupsPath}/groups` },
                    // { label: 'Fundraisers', href: `/${fundraisersPath}/fundraisers` },
                ] : [],
            ],
        },
        settings: {
            label: 'Settings',
            links: [
                { label: 'Account', href: '/account' },
                { label: 'Emails', href: '/emails' },
                { label: 'Billing', href: '/billing' },
            ],
        },
        addons: {
            label: 'Addons',
            links: [
                { label: 'Addons', href: '' },
            ],
        },
    }

    return (
        <nav className="bg-white h-16 px-4 flex justify-between items-center shadow-sm">
            <Stack direction="row" spacing={4} sx={{ mx: '1rem' }}>
                {TABS[tab]?.links.map((link, index) => (
                    <Badge key={index} variant='dot' invisible={!link?.enabled} color="secondary">
                        <Typography key={index}>
                            <Link to={!link?.disabled && `${tab}${link.href}`} className={`${!link?.disabled ? 'text-gray-700 hover:text-gray-900' : 'text-gray-400 cursor-not-allowed'}`}>
                                {t(link.label)}
                            </Link>
                        </Typography>
                    </Badge>
                ))}
            </Stack>
            <div className="flex items-center space-x-2 mx-12">
                <select
                    id="org-select-dropdown"
                    className="rounded-md py-1"
                    value={orgId}
                    onChange={(e) => mainStore.setOrgId(e.target.value)}
                >
                    {organizations.map((org, index) => (
                        <option key={index} value={org.org_id}>
                            {org.org_name}
                        </option>
                    ))}
                </select>
            </div>
        </nav>
    );
};

export default SecondaryNav;