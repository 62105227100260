import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Autocomplete, Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Tab, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import H1 from "components/common/Typography/H1";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "components/Loading";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import PlaylistRemoveRoundedIcon from '@mui/icons-material/PlaylistRemoveRounded';
import DeleteDialog from "components/common/DeleteDialog";
import currencies from "data/currencies";
import DonationListLeg from "../../../../../components/DonationListLeg";
import Options from "components/Options";
import ContactList from "views/Contacts/Contacts/ContactListLeg";
import useApi from "hooks/useApi";

function SubCampaignView() {
    const { name, scName } = useParams();
    const { t } = useTranslation();
    const api = useApi();
    const dir = document.dir;
    const navigate = useNavigate();
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [tabValue, setTabValue] = useState('2');
    const [id, setId] = useState();
    const [subCampaignName, setSubCampaignName] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [goal, setGoal] = useState('');
    const [goalCurrency, setGoalCurrency] = useState('');

    const [savingCampaign, setSavingCampaign] = useState(false);
    const [loading, setLoading] = useState(false);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState('');
    const [deleteText, setDeleteText] = useState('');
    const [deleteFunction, setDeleteFunction] = useState(() => { });

    const [donations, setDonations] = useState([]);
    const pageSizes = [50, 100, 250, 500];
    // const [pageSize, setPageSize] = useState(localStorage.getItem('donationsPageSize') || pageSizes[0]);
    const [pageSize, setPageSize] = useState(pageSizes[0]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [donationsCurrentPage, setDonationsCurrentPage] = useState(1);
    const [donationsTotalPages, setDonationsTotalPages] = useState(1);
    const [donationSort, setDonationSort] = useState([]);

    const [donors, setDonors] = useState([]);
    const [donorSort, setDonorSort] = useState([]);
    const [donorsCurrentPage, setDonorsCurrentPage] = useState(1);
    const [donorsTotalPages, setDonorsTotalPages] = useState(1);

    const [updated, setUpdated] = useState(false);

    const currencyValues = Object.keys(currencies)

    const options = [
        {
            text: t('Delete Sub Campaign'),
            icon: <DeleteOutlineRoundedIcon />,
            onClick: () => {
                setDeleteModalOpen(true);
                setDeleteTitle(t('Delete Sub Campaign'));
                setDeleteText(t('Are you sure you want to delete this sub campaign?'));
                setDeleteFunction(() => handleDeleteSubCampaign);
            }
        },
        ...(tabValue === '4' ? [{
            text: `${t('Remove')} ${checkedItems.length} ${t('Donors')}`,
            icon: <PlaylistRemoveRoundedIcon />,
            onClick: () => {
                setDeleteModalOpen(true);
                setDeleteTitle(`${t('Remove')} ${checkedItems.length} ${t('Donors')}`);
                setDeleteText(`${t('Are you sure you want to remove')} ${checkedItems.length} ${t('donors from this sub campaign?')}`);
                setDeleteFunction(() => handleRemoveDonors);
            },
            disabled: checkedItems.length === 0,
        }] : []),
    ];

    useEffect(() => {
        if (scName === 'new') return;
        setLoading(true);
        api.get('/campaigns/sub-campaigns/', {
            params: {
                campaign_name: name,
                name: scName,
            },
        }).then(res => {
            const campaign = res.data[0];
            setId(campaign.id);
            setSubCampaignName(campaign.name);
            setDescription(campaign.description);
            setStartDate(new Date(campaign.start_date).toISOString().slice(0, 16) || '');
            setEndDate(new Date(campaign.end_date).toISOString().slice(0, 16) || '');
            setGoal(campaign.goal);
            setGoalCurrency(campaign.goal_currency);
            setLoading(false);
        }).catch(err => {
            console.error(err);
        });
    }, []);

    const fetchDonations = () => {
        api.get('/donation-list/donations/', {
            params: {
                page_size: pageSize,
                page: donationsCurrentPage,
                campaign__name: JSON.stringify([name]),
                sub_campaign__name: JSON.stringify([scName]),
                sort: JSON.stringify(donationSort),
            },
        }).then((res) => {
            setDonationsTotalPages(Math.ceil(res.data.count / pageSize));
            setDonations(res.data.results);
        }).catch((error) => {
            console.log(error);
        });
    }

    const fetchDonors = () => {
        api.get('/contacts/', {
            params: {
                page: donorsCurrentPage,
                page_size: pageSize,
                sort: JSON.stringify(donorSort),
                sub_campaign_donors: JSON.stringify([id]),
            },
        }).then((res) => {
            setDonorsTotalPages(Math.ceil(res.data.count / pageSize));
            setDonors(res.data.results);
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        if (tabValue === '3') {
            fetchDonations();
        } else if (tabValue === '4') {
            fetchDonors();
        }
    }, [pageSize, donationsCurrentPage, donationSort, donorsCurrentPage, donorSort, name, tabValue, updated]);

    useEffect(() => {
        setCheckedItems([]);
        setCheckAll(false);
    }, [tabValue]);

    const handleSaveCampaign = () => {
        setSavingCampaign(true);
        if (scName === 'new') {
            api.post('/campaigns/sub-campaigns/update/', {
                name: subCampaignName,
                description: description,
                start_date: startDate,
                end_date: endDate,
                goal: goal,
                goal_currency: goalCurrency,
            }, {
                params: {
                    campaign_name: name,
                },
            }).then(res => {
                setSavingCampaign(false);
                navigate(`/donations/campaigns/${name}/sub-campaigns/${subCampaignName}`);
            }).catch(err => {
                console.error(err);
                setSavingCampaign(false)
            });
        } else {
            api.put('/campaigns/sub-campaigns/update/', {
                name: subCampaignName,
                description: description,
                start_date: startDate,
                end_date: endDate,
                goal: goal,
                goal_currency: goalCurrency,
            }, {
                params: {
                    sub_campaign_name: scName,
                    campaign_name: name,
                },
            }).then(res => {
                setSavingCampaign(false);
                navigate(`/donations/campaigns/${name}/sub-campaigns/${subCampaignName}`);
            }).catch(err => {
                console.error(err);
                setSavingCampaign(false)
            });
        }
    };

    const handleDeleteSubCampaign = () => {
        api.delete('/campaigns/sub-campaigns/update/', {
            params: {
                sub_campaign_name: scName,
                campaign_name: name,
            },
        }).then(res => {
            setDeleteModalOpen(false);
            setDeleteFunction(() => { });
            navigate(`/donations/campaigns/${name}`);
        }).catch(err => {
            console.error(err);
        });
    };

    const handleRemoveDonors = () => {
        api.post('/sub-campaign-donors-update/remove/', {
            sub_campaign_id: id,
            donor_ids: checkedItems,
        }).then((res) => {
            setCheckedItems([]);
            setCheckAll(false);
            if (donorsCurrentPage >= donorsTotalPages && donorsCurrentPage > 1) {
                setDonorsCurrentPage(donorsCurrentPage - 1);
            } else {
                setUpdated(!updated);
            }
            setDeleteFunction(() => { });
            setDeleteModalOpen(false);
        }).catch((error) => {
            console.log(error);
        });
    }


    const renderCampaingDetails = () => (
        <div className="text-start">
            <h2 className=" text-custom-blue font-bold text-xl">{t('Campaign Details')}</h2>
            <div className="flex flex-col gap-8 mb-10">
                <fieldset className="flex gap-x-8">
                    <TextField
                        sx={{ width: '16rem' }}
                        variant="standard"
                        label={t('Name')}
                        value={subCampaignName}
                        onChange={(e) => setSubCampaignName(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        variant="standard"
                        label={t('Description')}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </fieldset>
                <fieldset className="flex gap-x-8">
                    <TextField
                        variant="standard"
                        label={t('Start Date')}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        type='datetime-local'
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        variant="standard"
                        label={t('End Date')}
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        type='datetime-local'
                        InputLabelProps={{ shrink: true }}
                    />
                </fieldset>
                <fieldset className="flex gap-x-8">
                    <TextField
                        variant="standard"
                        label={t('Goal')}
                        value={goal}
                        onChange={(e) => setGoal(e.target.value)}
                        type="number"
                    />
                    <div className="w-44">
                        <Autocomplete
                            clearOnEscape
                            options={currencyValues}
                            getOptionLabel={(option) => t(currencies[option]?.name || '')}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label={t("Goal Currency")}
                                    variant="standard"
                                />}
                            filterSelectedOptions={false}
                            value={goalCurrency}
                            onChange={(event, newValue) => {
                                setGoalCurrency(newValue)
                            }}
                        />
                    </div>
                </fieldset>
                <div className='flex justify-center mt-12'>
                    <LoadingButton
                        sx={{ borderRadius: 99 }}
                        color='primary'
                        size='large'
                        className='rounded-full'
                        variant='contained'
                        type='submit'
                        loading={savingCampaign}
                        onClick={handleSaveCampaign}
                    >
                        {t('Save')}
                    </LoadingButton>
                </div>
            </div>
        </div >
    );

    const renderDonations = () => (
        <DonationListLeg
            donations={donations}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            checkAll={checkAll}
            setCheckAll={setCheckAll}
            currentPage={donationsCurrentPage}
            setCurrentPage={setDonationsCurrentPage}
            sort={donationSort}
            setSort={setDonationSort}
            totalPages={donationsTotalPages}
        />
    )

    const renderDonors = () => (
        <ContactList
            contacts={donors}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            checkAll={checkAll}
            setCheckAll={setCheckAll}
            currentPage={donorsCurrentPage}
            setCurrentPage={setDonorsCurrentPage}
            getSort={(field) => {
                const sortItem = donorSort.find((item) => item[0] === field);
                return sortItem ? sortItem[1] : '';
            }}
            setSort={(field, sortDir) => {
                setDonorSort((prevSort) => {
                    const newSort = prevSort.filter((item) => item[0] !== field);
                    if (sortDir !== '') {
                        return [...newSort, [field, sortDir]];
                    } else {
                        return newSort;
                    }
                });
            }}
            totalPages={donorsTotalPages}
        />
    )

    return (
        <>
            <div className="flex float-end items-center">
                {scName !== 'new' && <Options options={options} />}
            </div>
            <H1>{scName === 'new' ? t('New Sub Campaign') : scName}</H1>
            <div className="mt-12 bg-white rounded-2xl shadow-sm">
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', pt: '0.8rem' }}>
                        <TabList onChange={(e, value) => setTabValue(value)}>
                            {/* <Tab label={t('Overview')} value="1" disabled={scName === 'new'} /> */}
                            <Tab label={t('Campaign Details')} value="2" />
                            <Tab label={t('Donations')} value="3" disabled={scName === 'new'} />
                            <Tab label={t('Donors')} value="4" disabled={scName === 'new'} />
                            {/*  <Tab label={t('Fundraisers')} value="5" disabled={scName === 'new'} /> */}
                        </TabList>
                    </Box>
                    <TabPanel value="2">{renderCampaingDetails()}</TabPanel>
                    <TabPanel value="3">{renderDonations()}</TabPanel>
                    <TabPanel value="4">{renderDonors()}</TabPanel>
                </TabContext>
            </div>
            <DeleteDialog
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onDelete={deleteFunction}
                title={deleteTitle}
                text={deleteText}
            />
        </>
    );
}

export default SubCampaignView;