import { useMutation, useQuery, useQueryClient } from 'react-query';
import useApi from './useApi';

const useSubscription = () => {
    const queryClient = useQueryClient();
    const api = useApi();

    const updateEmailPlan = useMutation(
        (payload) => api.post('/subscription/email', payload),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('subscription');
            },
        }
    );

    const updateSubscription = useMutation(
        (payload) => api.patch('/subscription/', payload),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('subscription');
            },
        }
    );

    return {
        updateEmailPlan,
        updateSubscription,
    };
};

export default useSubscription;
