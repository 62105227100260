// Find a differnt location for this
function removeExtraSpaces(str) {
    return str.replace(/\s+/g, ' ').trim();
}

const Validate = {
    phone_number: (value) => {
        const phoneRegex = /^[0-9+]{4,15}$/;
        return phoneRegex.test(value);
    },
    email: (value) => {
        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return emailRegex.test(value);
    },
    required: (value) => {
        return value !== '';
    }
};


export {
    removeExtraSpaces,
    Validate
}