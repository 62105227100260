import api from "api/axiosInstance";
import { useQuery } from "react-query";

const useCampaign = (campaignName, params) => {

    const campaignQuery = useQuery(['campaignOverview', params],
        () => api.get(`/campaigns/${campaignName}/overview`, { params }).then(res => res.data),
        {
            refetchOnWindowFocus: true,
            enabled: !!campaignName,
            keepPreviousData: true,
        }
    );

    const { data: campaign, isLoading: campaignLoading, isRefetching: campaignRefetching } = campaignQuery;

    return {
        campaignQuery,
        campaign,
        campaignLoading,
        campaignRefetching,
    }
}

export default useCampaign;