import { format, isToday, isTomorrow, isYesterday } from 'date-fns';
import { he } from 'date-fns/locale';

function localDateTime(date) {
    if (!date) return '';
    return new Date(date).toLocaleString(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    })
}

function localDate(date) {
    if (!date) return '';
    return new Date(date).toLocaleString(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    })
}

function UTCToLocalInput(utcDate) {
    if (!utcDate) return '';

    // Convert UTC date string to local date object
    const localDate = new Date(utcDate);

    // Adjusting for timezone offset
    const offset = localDate.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(localDate.getTime() - offset);

    // Formatting to "YYYY-MM-DDTHH:mm"
    return adjustedDate.toISOString().slice(0, 16);
}

function localDateTimeToUTC(utcDate) {
    if (!utcDate) return '';
    console.log(utcDate);
    console.log(new Date(utcDate).toISOString());
    return new Date(utcDate).toISOString();
}

const t = {
    he: {
        'Today at': 'היום ב',
        'Tomorrow at': 'מחר ב',
        'Yesterday at': 'אתמול ב',
        'at': 'ב'
    },
    en: {
        'Today at': 'Today at',
        'Tomorrow at': 'Tomorrow at',
        'Yesterday at': 'Yesterday at',
        'at': 'at'
    }
}
function activityDateTime(dateStr, lang) {
    const date = new Date(dateStr);
    const formattedDate = format(date, `MMM d, yyyy ${t[lang].at} h:mm a`, lang === 'he' ? { locale: he } : {});
    return formattedDate;
}

function natrualDateTime(date, lang) {
    const time = format(date, 'H:mm', lang === 'he' ? { locale: he } : {});

    if (isToday(date)) {
        return `${t[lang]['Today at']} ${time}`;
    } else if (isTomorrow(date)) {
        return `${t[lang]['Tomorrow at']} ${time}`;
    } else if (isYesterday(date)) {
        return `${t[lang]['Yesterday at']} ${time}`;
    } else {
        return localDateTime(date);
    }
}


export { localDateTime, UTCToLocalInput, localDateTimeToUTC, localDate, natrualDateTime, activityDateTime };