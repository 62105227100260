import Lists from "../../../../components/Lists";

function DonationsLists() {
  return (
    <div>
      <Lists
        listSet="donations"
      />
    </div>
  )
}

export default DonationsLists;