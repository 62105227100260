import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CustomInput from '../../../../components/CustomInput';
import { IMaskInput } from 'react-imask';
import TagInput from '../../../../components/TagInput';
import ConnectionInput from '../../../../components/ConnectionInput';
import { HiOutlineX } from 'react-icons/hi'
import { MdMoreVert } from 'react-icons/md'
import Connections from '../../../../components/ConnectionInput';
import H1 from 'components/common/Typography/H1';
import { useAuthInfo } from '@propelauth/react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Autocomplete, Avatar, Box, CircularProgress, Fab, FormControl, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, Select, Snackbar, Tab, TextField, Button, Chip, Popover, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import CloseIcon from '@mui/icons-material/Close';
import currencies from 'data/currencies';
import { InView } from 'react-intersection-observer';
import LoadingComponent from 'components/Loading';
import PropTypes from 'prop-types';
import { formatActivityDate } from '../../../../utils';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import SearchContact from '../../../../components/general/SearchContact';
import { contactActivityTypeData, contactActivityTypes, paymentMethods } from 'data/general';
import Total from '../../../../components/totals';
import { render } from '@testing-library/react';
import { a } from 'react-spring';
import { LoadingButton, LocalizationProvider } from '@mui/lab';
import AddTag from '../../../../components/AddTag';
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded';
import TurnedInRoundedIcon from '@mui/icons-material/TurnedInRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import { useTranslation } from 'react-i18next';
import DeleteDialog from 'components/common/DeleteDialog';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded';
import PledgeDetails from 'views/Donations/Pledges/PledgeDetails';
import DonationListLeg from '../../../../components/DonationListLeg';
import Options from 'components/Options';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { log } from '../../../../utils/devUtils';
import { useSnackbar } from 'notistack';
import { UTCToLocalInput, localDateTime, localDateTimeToUTC } from 'utils/dates';
import { ArrowBackIosNewRounded, ArrowForwardIosRounded, NavigateBeforeRounded, NavigateNextRounded } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import { contactsStore } from 'stores/ContactsStore';
import { useQueryClient } from 'react-query';
import { useContacts } from 'hooks/useContacts';
import useApi from 'hooks/useApi';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const currencyValues = Object.keys(currencies)

const paymentsOptions = ['0', '1', '6', '12', '24', '36'];

const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
        // prefix="$"
        />
    );
});

NumericFormatCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
};

function ContactView() {
    const { id, tab } = useParams();
    const { contactsList, location: prevLocation, currentPage, setCurrentPage, setReferrer, listType, nextPageContactId, prevPageContactId, getParams, count } = contactsStore;
    const api = useApi();
    const { t, i18n } = useTranslation();
    const authInfo = useAuthInfo()
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const [contact, setContact] = useState()
    const [loading, setLoading] = useState(false)

    const [donations, setDonations] = useState([]);
    const [donationSort, setDonationSort] = useState([]);
    const [updated, setUpdated] = useState(false);

    const [newDonation, setNewDonation] = useState(false);
    const [newDonSubmiting, setNewDonSubmiting] = useState(false);
    const [submitNewDon, setSubmitNewDon] = useState({
        currency: localStorage.getItem('currency') || 'USD',
        payments: 1,
        donation_date: new Date().toISOString().slice(0, 16)
    });
    const [totals, setTotals] = useState({});
    const [paymentMethodSubmitData, setPaymentMethodSubmitData] = useState({});
    const [nesDonDonType, setNewDonationType] = useState('');
    const [newDonPaymentMethod, setNewDonPaymentMethod] = useState('');
    const [newDonDate, setNewDonDate] = useState('');
    const [newDonAmount, setNewDonAmount] = useState('');
    const [newDonCurrency, setNewDonCurrency] = useState(localStorage.getItem('currency') || 'USD')
    const [newDonPayments, setNewDonPayments] = useState('1');
    const [newDonFundraiser, setNewDonFundraiser] = useState('');
    const [newDonNotes, setNewDonNotes] = useState('');

    const [newTask, setNewTask] = useState(false);
    const [newTaskTitle, setNewTaskTitle] = useState('');
    const [newTaskDescription, setNewTaskDescription] = useState('');
    const [newTaskDueDate, setNewTaskDueDate] = useState();
    const [selectDateOpen, setSelectDateOpen] = useState(false);
    const [selectTaskTypeOpen, setSelectTaskTypeOpen] = useState(false);
    const [newTaskType, setNewTaskType] = useState('');
    const [taskTypes, setTaskTypes] = useState([]);
    const [taskTypeOpen, setTaskTypeOpen] = useState(false);

    const [taskDetails, setTaskDetails] = useState();

    const [prefix, setPrefix] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [suffix, setSuffix] = useState('');

    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [emailAddresses, setEmailAddresses] = useState([]);
    const [tags, setTags] = useState([]);

    const [addressId, setAddressId] = useState('');
    const [street, setStreet] = useState('');
    const [house, setHouse] = useState('');
    const [apt, setApt] = useState('');
    const [city, setCity] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [addressNotes, setAddressNotes] = useState('');

    const [description, setDescription] = useState('');
    const [connections, setConnections] = useState([]);
    const [updatedConnections, setUpdatedConnections] = useState('');

    const [param, setParam] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [matchingSearch, setMatchingSearch] = useState([]);

    const [newActivity, setNewActivity] = useState('');
    const [newActivitySubmiting, setNewActivitySubmiting] = useState(false);
    const [editActivitySaving, setEditActivitySaving] = useState(false);
    const [activities, setActivities] = useState([]);
    const [activityPage, setActivityPage] = useState(1);
    const [activityInViewActive, setActivityInViewActive] = useState(true);
    const [editActivityLog, setEditActivityLog] = useState(false);
    const [editActivity, setEditActivity] = useState();
    const [dueDateOpen, setDueDateOpen] = useState(false);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState('');
    const [deleteText, setDeleteText] = useState('');
    const [onDelete, setOnDelete] = useState();

    const [anchorEl, setAnchorEl] = useState(null);
    const openAddTag = Boolean(anchorEl);
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [saving, setSaving] = useState(false);
    const closeOptions = useRef();
    const newDonationRef = useRef();
    const activityLoader = useRef();
    const navigate = useNavigate();

    const [pledgeOpen, setPledgeOpen] = useState(false);
    const [pledge, setPledge] = useState(null);
    const [newPledge, setNewPledge] = useState(false);

    const nextContactId = contactsList[contactsList.indexOf(parseInt(id)) + 1] || nextPageContactId || null;
    const prevContactId = contactsList[contactsList.indexOf(parseInt(id)) - 1] || prevPageContactId || null;

    const { contactsQuery } = useContacts({ enabled: contactsList.includes(parseInt(id)) ? false : true });

    const options = [
        {
            text: t('Delete Contact'),
            icon: <DeleteOutlineRoundedIcon />,
            onClick: () => {
                setDeleteTitle(t('Delete Contact'))
                setDeleteText(t('Are you sure you want to delete this contact?'))
                setOnDelete(() => handleDelete)
                setDeleteDialogOpen(true)
            }
        }
    ];

    useEffect(() => {
        if (!contactsList.includes(parseInt(id))) {
            if (nextPageContactId === parseInt(id)) setCurrentPage(currentPage + 1)
            else if (prevPageContactId === parseInt(id)) setCurrentPage(currentPage - 1);
        }
    }, [id])

    const handleTaskDuedateChange = (event) => {
        let taskDate = new Date();
        if (event === "hour") {
            setNewTaskDueDate(new Date(new Date().getTime() + 60 * 60 * 1000).toISOString())
        } else if (event === "tomorrow") {
            taskDate.setHours(9, 0, 0, 0);
            taskDate.setDate(taskDate.getDate() + 1)
            setNewTaskDueDate(taskDate.toISOString())
        } else if (event === "nextWeek") {
            taskDate.setHours(9, 0, 0, 0);
            taskDate.setDate(taskDate.getDate() + (9 - taskDate.getDay()))
            setNewTaskDueDate(taskDate.toISOString())
        } else if (event === "none") {
            setNewTaskDueDate(null)
        }
        setSelectDateOpen(false);
        setAnchorEl(null);
    };

    const handleNewTaskOpen = (e) => {
        setTaskTypeOpen(true);
        setAnchorEl(e.currentTarget);
        api.get('/tasks/task-types/').then((response) => {
            setTaskTypes(response.data);
        }).catch((error) => {
            console.error('Error fetching task types:', error);
        });
    };

    const handleSaveNewTask = (task) => {
        setNewActivitySubmiting(true);
        api.post('/tasks/create/', task).then((response) => {
            setSnackbarMessage('Task created successfully');
            setNewActivitySubmiting(false);
            setSnackbar(true);
            setActivityInViewActive(false);
            setActivities([])
            setActivityPage(1)
            loadActivities();
            setNewTask(false)
        }).catch((error) => {
            console.error('Error creating task:', error);
            setNewActivitySubmiting(false);
        });
    };

    const handleSaveTask = (task) => {
        api.put('/tasks/create/', task).then(res => {
            setEditActivity(null);
            setSnackbarMessage('Task updated successfully');
            setNewActivitySubmiting(false);
            setSnackbar(true);
            setActivityInViewActive(false);
            setActivities([])
            setActivityPage(1)
            loadActivities();
        }).catch(err => {
            console.log(err);
        })
    };

    const handleNewActivity = () => {
        setNewActivitySubmiting(true);
        api.post('/new-contact-activity/', {
            log: newActivity,
        }, {
            params: {
                contact_pk: contact.id,
            },
        }).then((response) => {
            setActivities(prevActivities => [response.data, ...prevActivities]);
            setNewActivity('');
            setNewActivitySubmiting(false);
        }).catch((error) => {
            console.error('Error creating activity:', error);
            setNewActivitySubmiting(false);
        });
    };

    const handleDeleteActivity = (activityIndex) => {
        api.delete(`/contact_activities/delete/${activities[activityIndex].id}/`).then((response) => {
            setActivities(prevActivities => prevActivities.filter((activity, index) => index !== activityIndex));
        }).catch((error) => {
            console.error('Error deleting activity:', error);
        });
    };

    const handleEditActivity = (activityIndex) => {
        setEditActivity(activityIndex);
        setEditActivityLog(activities[activityIndex].log);
    }

    const handleUpdateActivity = () => {
        setEditActivitySaving(true);
        api.patch(`/contact_activities/update/${activities[editActivity].id}/`, {
            log: editActivityLog,
        }).then((response) => {
            setActivities(prevActivities => prevActivities.map((activity, index) => {
                if (index === editActivity) {
                    activity.log = editActivityLog;
                    // return editActivityLog;
                }
                return activity;
            }));
            setEditActivity(null);
            setEditActivitySaving(false);
        }).catch((error) => {
            console.error('Error updating activity:', error);
        });
    }

    const loadActivities = () => {
        api.get('/contact-activities/', {
            params: {
                contact_id: id,
                page: activityPage,
                // page_size: 3,
            },
        }).then((response) => {
            setActivities(prevActivities => [...prevActivities, ...response.data.results])
            setActivityPage(prevPage => {
                if (response.data.next === null) return null;
                return prevPage + 1
            });
            setActivityInViewActive(true);
        }).catch((error) => {
            console.error('Error fetching activities:', error);
        });
    };

    // const handleObserver = useCallback(entries => {
    //     const target = entries[0];
    //     if (target.isIntersecting && activityPage !== null) {
    //         loadActivities();
    //     }
    // }, [id, activityPage]);

    // useEffect(() => {
    //     const option = {
    //         root: null,
    //         rootMargin: "20px",
    //         threshold: 0
    //     };
    //     const observer = new IntersectionObserver(handleObserver, option);
    //     if (activityLoader.current) observer.observe(activityLoader.current);
    //     return () => observer.disconnect();
    // }, [handleObserver]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (closeOptions.current && !closeOptions.current.contains(event.target)) {
                setOptionsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [closeOptions]);

    useEffect(() => {
        if (newDonation) {
            newDonationRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [newDonation])

    const handleNewDonChange = (name, value) => {
        setSubmitNewDon(prevState => {
            let newState = { ...prevState };

            if (!value && value !== '') {
                delete newState[name];
            } else {
                newState[name] = value;
            }

            return newState;
        });
    };

    const handlePaymentMethodDataChange = (name, value) => {
        setPaymentMethodSubmitData(prevState => {
            let newState = { ...prevState };
            if (name === 'payment_method_type') {
                newState['payment_method_type'] = value;
            } else if (name === 'card_number') {
                newState['payment_method_data'] = { ...prevState.payment_method_data, card_number: value };
            } else if (name === 'card_type') {
                newState['payment_method_data'] = { ...prevState.payment_method_data, card_type: value };
            } else if (name === 'transaction_type') {
                newState['payment_method_data'] = { ...prevState.payment_method_data, transaction_type: value };
            }
            handleNewDonChange('payment_method', newState);
            return newState;
        });
    };

    const handlePaymentTypeChange = (e) => {
        setNewDonationType(e.target.value);
        handleNewDonChange('donation_type', e.target.value);
    }
    const handlePaymentMethodChange = (e) => {
        setNewDonPaymentMethod(e.target.value);
        handlePaymentMethodDataChange('payment_method_type', e.target.value);
        handlePaymentMethodDataChange('card_number', null);
    }
    const handleNewDonDateChange = (e) => {
        setNewDonDate(e.target.value);
        handleNewDonChange('donation_date', e.target.value);
    }
    const handleAmountChange = (e) => {
        setNewDonAmount(e.target.value);
        handleNewDonChange('amount', e.target.value);
    }
    const handleCurrencyChange = (e) => {
        setNewDonCurrency(e);
        localStorage.setItem('currency', e);
        handleNewDonChange('currency', e);
    }
    const handlePaymentsChange = (e) => {
        setNewDonPayments(e);
        handleNewDonChange('payments', e);
    }

    const handleNewDonSubmit = (e) => {
        document.addEventListener('keydown', function (event) {
            if (event.key === 'Enter') {
                return;
            }
        });
        e.preventDefault();
        setNewDonSubmiting(true);
        api.post('/donations/create/', submitNewDon).then((response) => {
            setSubmitNewDon({
                currency: localStorage.getItem('currency') || 'USD',
                payments: 1,
                donation_date: new Date().toISOString().slice(0, 16)
            });
            setNewDonPaymentMethod('');
            setNewDonAmount('');
            setNewDonCurrency(localStorage.getItem('currency') || 'USD');
            setNewDonPayments('1');
            setNewDonFundraiser('');
            setNewDonation(false);
            setNewDonSubmiting(false);
            setSnackbar(true);
            setSnackbarMessage(t('Donation Added Successfully'));
            setUpdated(!updated);
        }).catch((error) => {
            console.error('Error creating donation:', error);
            setNewDonSubmiting(false);
        });
    }

    const handleTabChange = (event, newValue) => {
        if (newValue === 'contact') navigate(`/contacts/${id}`);
        else navigate(`/contacts/${id}/${newValue}`);
    };

    const handleDelete = async () => {
        try {
            await api.post(`/contacts/delete/`, {
                contact_ids: [id]
            });
            enqueueSnackbar(t('Contact deleted successfully'), { variant: 'success' })
            navigate('/contacts')
        } catch (error) {
            enqueueSnackbar(t('Error deleting contact'), { variant: 'error' })
            console.error(`Error deleting contact: ${error.message}`);
        }
    }

    const handleStreetChange = (event) => {
        setStreet(event.target.value);
        setParam('street');
        setSearchQuery(event.target.value);
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
        setParam('city');
        setSearchQuery(event.target.value);
    };

    const handleStateChange = (event) => {
        setState(event.target.value);
        setParam('state');
        setSearchQuery(event.target.value);
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
        setParam('country');
        setSearchQuery(event.target.value);
    };

    const handleSearchItemClick = (item, name) => {
        if (name === "street") {
            setStreet(item);
        }
        else if (name === "city") {
            setCity(item)
        }
        else if (name === "state") {
            setState(item)
        }
        else if (name === "country") {
            setCountry(item)
        }
        setMatchingSearch([]);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setMatchingSearch([]);
        }, 200);
    };

    const handleSubmit = async (event, close) => {
        event.preventDefault();

        const data = {
            prefix,
            first_name: firstName,
            last_name: lastName,
            suffix,
            description: description,
            connections: updatedConnections,
            addresses: [
                {
                    ...(addressId ? { id: addressId } : {}),
                    ...(country !== '' ? { country } : {}),
                    ...(state !== '' ? { state } : {}),
                    ...(city !== '' ? { city } : {}),
                    ...(street !== '' ? { street } : {}),
                    ...(zipcode !== '' ? { zipcode } : {}),
                    ...(house !== '' ? { house } : {}),
                    ...(apt !== '' ? { apartment: apt } : {}),
                    ...(addressNotes !== '' ? { notes: addressNotes } : {}),
                }
            ],
        };
        // A temporary patch to fix the issue with the phone numbers and email addresses
        data.phone_numbers = phoneNumbers.map((phone) => {
            return typeof phone === 'string' ? { phone_number: phone } : phone
        })
        contact?.phone_numbers.map((phone) => {
            if (phoneNumbers.find((item) => item?.id === phone?.id) === undefined) {
                data.phone_numbers.push({ id: phone.id, phone_number: "" })
            }
        })
        data.email_addresses = emailAddresses.map((email) => {
            return typeof email === 'string' ? { email: email } : email
        })
        contact?.email_addresses.map((email) => {
            if (emailAddresses.find((item) => item?.id === email?.id) === undefined) {
                data.email_addresses.push({ id: email.id, email: "" })
            }
        })
        // End of temporary patch
        if (id === 'new') {
            try {
                setSaving(true)
                const response = await api.post('/contacts/create/', data, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setSaving(false)
                enqueueSnackbar(t('Contact Added Successfully'), { variant: 'success' })
                if (close === true) navigate(prevLocation)
                else navigate(`/contacts/${response.data.contact_id}`)
            } catch (error) {
                console.error(`Error creating contact: ${error.message}`);
            }
        } else {
            try {
                setSaving(true)
                const response = await api.patch(`/contacts/${id}/update/`, data);
                setAddressId(response.data?.addresses[0]?.id)
                setSaving(false)
                enqueueSnackbar(t('Contact Updated Successfully'), { variant: 'success' })
                if (close === true) navigate(prevLocation)
                else setUpdated(!updated)
            } catch (error) {
                setSaving(false)
                console.error(`Error updating contact: ${error.message}`);
            }
        }
    }

    useEffect(() => {
        if (id === 'new') return;
        setLoading(true)
        const fecthContact = async () => {
            try {
                // let contactDetails = contacts.find((contact) => contact.contact_id == id)
                // console.log({ contacts })
                // if (contactDetails === undefined) {
                const contactResponse = await api.get('/contacts/', {
                    params: {
                        // search: searchValue,
                        contact_id: id,
                    },
                })
                // console.log({ contactDetails })
                const contactDetails = contactResponse.data.results[0]
                // }
                setContact(contactDetails)
                setPrefix(contactDetails.prefix);
                setFirstName(contactDetails.first_name);
                setLastName(contactDetails.last_name);
                setSuffix(contactDetails.suffix);

                setPhoneNumbers(contactDetails?.phone_numbers);
                setEmailAddresses(contactDetails?.email_addresses);
                setTags(contactDetails?.tags);

                setAddressId(contactDetails?.addresses[0]?.id);
                setStreet(contactDetails?.addresses[0]?.street || '');
                setHouse(contactDetails?.addresses[0]?.house || '');
                setApt(contactDetails?.addresses[0]?.apartment || '');
                setCity(contactDetails?.addresses[0]?.city || '');
                setZipcode(contactDetails?.addresses[0]?.zipcode || '');
                setState(contactDetails?.addresses[0]?.state || '');
                setCountry(contactDetails?.addresses[0]?.country || '');
                setAddressNotes(contactDetails?.addresses[0]?.notes || '');

                setDescription(contactDetails?.description || '');
                setConnections(contactDetails?.connections || '');

                handleNewDonChange('contact', contactDetails.id);
            } catch (error) {
                console.error(`Error fetching contact: ${error.message}`);
            }
        };

        api.get(`/donation-list/donations/`, {
            params: {
                contact_id: id,
                sort: JSON.stringify(donationSort),
            },
        }).then((res) => {
            setDonations(res.data.results)
            const resTotals = {
                total_amount: res.data.total_amount,
                count: res.data.count,
                total_count_donors: res.data.total_count_donors,
                org_currency: res.data.org_currency,
            };
            setTotals(resTotals);
        })

        fecthContact().then(() => setLoading(false))

    }, [id, updated, donationSort])

    useEffect(() => {
        const fetchMatchingAddress = async () => {
            if (searchQuery.trim()) {
                try {
                    const response = await api.get('/search_address_fields/', {
                        params: {
                            param,
                            search: searchQuery,
                            city_name: city,
                            state_name: state,
                            country_name: country,
                        }
                    },);
                    const filteredResults = [...new Set(response.data.map((item) => item.name))];
                    setMatchingSearch(filteredResults);
                } catch (error) {
                    console.error(`Error fetching matching streets: ${error.message}`);
                }
            } else {
                setMatchingSearch([]);
            }
        };

        fetchMatchingAddress();
    }, [searchQuery]);

    useEffect(() => {
        const updateConnections = []
        connections.map((connection) => updateConnections.push({
            ...(connection?.id ? { id: connection.id } : {}),
            related_to_id: connection.related_to && connection.related_to.id ? connection.related_to.id : null,
            relationship: connection.relationship
        }))
        setUpdatedConnections(updateConnections)
    }, [connections])

    const removeTag = (tag) => {
        api.delete('/contacts/tags/', {
            params: {
                contact_id: contact.contact_id,
                tag_id: tag.id,
            },
        }).then((response) => {
            setTags(prevTags => prevTags.filter((prevTag) => prevTag.id !== tag.id))
        }).catch((error) => {
            console.error('Error removing tag:', error);
        });
    }

    const addTag = (tag) => {
        api.patch('/contacts/tags/', {}, {
            params: {
                contact_id: id,
                tag_id: tag.id,
            },
        }).then((response) => {
            setTags(prevTags => [...prevTags, tag])
        }).catch((error) => {
            console.error('Error adding tag:', error);
        });
    }

    const handleDeleteTask = (index) => {
        api.delete('/tasks/delete/', {
            params: {
                task_ids: JSON.stringify([activities[index].task.id]),
            },
        }).then(() => {
            const task_id = activities[index].task.id
            setActivities(prevActivities => prevActivities.filter((activity, i) => activity?.task?.id !== task_id))
            setDeleteDialogOpen(false)
        }).catch((error) => {
            console.error('Error deleting task:', error);
        });
    }

    const renderContact = () => (
        <div className='grid grid-cols-6 2xl:grid-cols-8'>
            <div className='2xl:col-span-2'>
                <div className='flex flex-wrap gap-2'>
                    {tags.map((tag, index) => (
                        <Chip
                            key={index}
                            label={tag.name}
                            sx={{ borderRadius: '8px', backgroundColor: tag.color }}
                            color='primary'
                            size='small'
                            // variant='outlined'
                            onDelete={() => removeTag(tag)}
                        />
                    ))}
                    <AddTag elementTags={tags} setElementTags={setTags} element_id={id} addTag={addTag} removeTag={removeTag} />
                    {/* <Chip
                        label={t('Add Tag')}
                        sx={{ borderRadius: '8px' }}
                        color='primary'
                        size='small'
                        variant='outlined'
                        icon={<AddIcon />}
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                    /> */}
                </div>
            </div>
            <div className='col-span-4'>
                <div className='flex flex-col gap-2 p-10 mx-auto text-start'>
                    <h2 className='text-xl mb-2'>
                        {contact?.prefix} <span className='font-semibold'>{contact?.first_name} {contact?.last_name}</span> {contact?.suffix} ({contact?.contact_id})
                    </h2>
                    <table className="table-auto border-collapse w-full text-start">
                        <colgroup>
                            <col className="w-40" />
                        </colgroup>
                        {contact?.email_addresses?.length > 0 && (
                            <tr>
                                <td className='py-2'>{t('Email Addresses')}:</td>
                                <td>
                                    {contact?.email_addresses?.map((email, index) => (
                                        <a
                                            key={index}
                                            href={`mailto:${email.email}`}
                                            className="font-medium hover:text-blue-900"
                                        >
                                            {email.email}{index !== contact?.email_addresses?.length - 1 && ', '}
                                        </a>
                                    ))}
                                </td>
                            </tr>
                        )}
                        {contact?.phone_numbers?.length > 0 && (
                            <tr>
                                <td className='py-2'>{t('Phone Numbers')}:</td>
                                <td>
                                    {contact?.phone_numbers?.map((phone, index) => (
                                        <a
                                            href={`tel:${phone.phone_number}`} key={index}
                                            className="font-medium hover:text-blue-900"
                                        >
                                            {phone.phone_number}{index !== contact?.phone_numbers?.length - 1 && ', '}
                                        </a>
                                    ))}
                                </td>
                            </tr>
                        )}
                        {contact?.addresses?.length > 0 && (
                            <tr>
                                <td className='py-2'>{t('Address')}:</td>
                                <td>
                                    {contact?.addresses[0]?.street && <span>{contact?.addresses[0]?.street}, </span>}
                                    {contact?.addresses[0]?.house && <span>{contact?.addresses[0]?.house}, </span>}
                                    {contact?.addresses[0]?.apartment && <span>{contact?.addresses[0]?.apartment}, </span>}
                                    {contact?.addresses[0]?.city && <span>{contact?.addresses[0]?.city}, </span>}
                                    {contact?.addresses[0]?.state && <span>{contact?.addresses[0]?.state}, </span>}
                                    {contact?.addresses[0]?.zipcode && <span>{contact?.addresses[0]?.zipcode}, </span>}
                                    {contact?.addresses[0]?.country && <span>{contact?.addresses[0]?.country}</span>}
                                    {contact?.addresses[0]?.notes && <span><br />{contact?.addresses[0]?.notes}</span>}
                                </td>
                            </tr>
                        )}
                        {contact?.donations?.length > 0 && (
                            <tr>
                                <td className='py-2'>{t('Connections')}:</td>
                                <td>
                                    {contact?.connections?.map((connection, index) => (
                                        <div key={index}>
                                            <span>{connection.relationship}&nbsp;</span>
                                            <Link

                                                to={`/contacts/${connection?.related_to?.contact_id}`}
                                                className="underline underline-offset-4 hover:text-blue-900"
                                            >
                                                {connection?.related_to?.name}
                                            </Link>
                                            {index !== contact?.connections?.length - 1 && <span>,&nbsp;</span>}
                                        </div>
                                    ))}
                                </td>
                            </tr>
                        )}
                        {contact?.description && (
                            <tr>
                                <td className='py-2'>{t('Description')}:</td>
                                <td>{contact?.description}</td>
                            </tr>
                        )}
                    </table>
                </div>
                {renderActivity()}
            </div>
            <div className='2xl:col-span-2'>
            </div>
        </div>
    )

    const renderActivity = () => (
        <div>
            {newTask ? (
                <div className='relative mb-6 flex gap-x-3'>
                    <Avatar sx={{ width: '1.5rem', height: '1.5rem' }} src={authInfo.user.pictureUrl} />
                    <EditTask onClose={() => setNewTask(false)} handleSaveNewTask={handleSaveNewTask} contact={contact} />
                </div>
            ) : (
                <div className='mb-6 flex gap-x-3'>
                    <Avatar sx={{ width: '1.5rem', height: '1.5rem' }} src={authInfo.user.pictureUrl} />
                    <div className='w-full text-gray-900 text-sm'>
                        <TextField
                            fullWidth
                            multiline
                            sx={{ '& .MuiInputBase-root': { p: '0.5rem', borderRadius: '0.5rem', fontSize: '0.875rem' } }}
                            minRows={2}
                            maxRows={8}
                            placeholder={t('Write a comment')}
                            disabled={newActivitySubmiting}
                            value={newActivity}
                            onChange={(e) => setNewActivity(e.target.value)}
                        />
                        {newActivity &&
                            <div className='flex gap-2 mt-2'>
                                <LoadingButton
                                    color='primary'
                                    variant='contained'
                                    size='small'
                                    onClick={() => handleNewActivity()}
                                    loading={newActivitySubmiting}
                                >
                                    {t('Save')}
                                </LoadingButton>
                                <Button size='small' sx={{ color: '#aaaaaa' }} onClick={() => setNewActivity('')}>
                                    {t('Cancel')}
                                </Button>
                            </div>}
                    </div>
                    <div className='relative h-8 w-8 end-3.5'>
                        {/* <IconButton
                            size='small'
                            title={t('Add Task')}
                            onClick={() => setNewTask(true)}
                        >
                            <AddTaskRoundedIcon titleAccess={t('Add Task')} />
                        </IconButton> */}
                        <SpeedDial
                            ariaLabel="SpeedDial contact activity"
                            // alighn the button to the top and make it smaller
                            sx={{
                                position: 'absolute', bottom: '0',
                                '& .MuiSpeedDial-fab': {
                                    width: 28,
                                    height: 28,
                                    boxShadow: 'none',
                                    minHeight: 'unset',
                                }
                            }}
                            direction='up'
                            icon={<SpeedDialIcon />}
                        >
                            <SpeedDialAction
                                icon={<AddTaskRoundedIcon />}
                                tooltipTitle={t('Add Task')}
                                tooltipOpen
                                sx={{ '& .MuiSpeedDialAction-staticTooltipLabel': { minWidth: 'max-content' } }}
                                onClick={() => setNewTask(true)}
                            />
                            <SpeedDialAction
                                icon={<MoreTimeRoundedIcon />}
                                tooltipTitle={t('Add Pledge')}
                                tooltipOpen
                                noContact
                                sx={{ '& .MuiSpeedDialAction-staticTooltipLabel': { minWidth: 'max-content' } }}
                                onClick={() => {
                                    setNewPledge(true)
                                    setPledgeOpen(true)
                                }}
                            />
                        </SpeedDial>
                    </div>
                </div>)}
            <ul role="list" className="space-y-6 text-start">
                {activities.map((activity, index) => (
                    <li key={index} className='relative flex gap-4'>
                        <div
                            className={classNames(
                                index === activities.length - 1 ? 'h-6' : '-bottom-6',
                                'absolute start-0 top-0 flex w-6 justify-center'
                            )}
                        >
                            <div className="w-px bg-gray-200" />
                        </div>
                        {activity.activity_type === contactActivityTypes.NOTE &&
                            <>
                                <Avatar sx={{ width: '1.5rem', height: '1.5rem' }} src={activity.user_picture_url} />
                                {editActivity === index ?
                                    (
                                        <div className='w-full'>
                                            <TextField
                                                fullWidth
                                                multiline
                                                sx={{ '& .MuiInputBase-root': { p: '0.5rem', borderRadius: '0.5rem', fontSize: '0.875rem' } }}
                                                minRows={2}
                                                maxRows={8}
                                                // disabled={newActivitySubmiting}
                                                value={editActivityLog}
                                                onChange={(e) => setEditActivityLog(e.target.value)}
                                            />
                                            <div>
                                                <LoadingButton
                                                    color='primary'
                                                    onClick={() => handleUpdateActivity()}
                                                    loading={editActivitySaving}
                                                >
                                                    {t('Save')}
                                                </LoadingButton>
                                                <Button sx={{ color: '#aaaaaa' }} onClick={() => setEditActivity(null)}>
                                                    {t('Cancel')}
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='w-full'>
                                            <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                                                <div className="flex justify-between gap-x-4">
                                                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                                                        <span className="font-medium text-gray-900">{activity.user_name.split("@")[0]} {activity.user_last_name}</span> {t("commented")}
                                                    </div>
                                                    <time dateTime={activity.activity_date} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                                        {formatActivityDate(activity.activity_date)}
                                                    </time>
                                                </div>
                                                <p className="text-sm leading-6 text-gray-500">{activity.log}</p>
                                            </div>
                                            <div>
                                                {activity.user === authInfo.user.userId &&
                                                    <>
                                                        <button
                                                            className='text-xs underline text-gray-500 hover:text-gray-900'
                                                            onClick={() => handleEditActivity(index)}
                                                        >
                                                            {t('Edit')}
                                                        </button>
                                                        <span className='text-gray-500'> • </span>
                                                    </>}
                                                <button className='text-xs underline text-gray-500 hover:text-gray-900'
                                                    onClick={() => handleDeleteActivity(index)}
                                                >{t('Delete')}</button>
                                            </div>
                                        </div>
                                    )}
                            </>}
                        {[contactActivityTypes.TASK_CREATED, contactActivityTypes.TASK_COMPLETED].includes(activity.activity_type) &&
                            <>
                                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                    <div className={`h-1.5 w-1.5 rounded-full ring-1 ${new Date(activity.activity_date) < new Date() && activity.activity_type === contactActivityTypes.TASK_DUE ? 'bg-red-100 ring-red-300' : 'bg-gray-100 ring-gray-300'}`} />
                                </div>
                                <p className={`flex-auto py-0.5 text-xs leading-5 ${new Date(activity.activity_date) < new Date() && activity.activity_type === contactActivityTypes.TASK_DUE ? 'text-red-500' : 'text-gray-500'}`}>
                                    <span className={'font-medium'}>{activity.user_name.split("@")[0]} {activity.user_last_name}</span> {t(contactActivityTypeData[activity.activity_type].text)}
                                    {/* {activity.activity_type === contactActivityTypes.TASK_DUE  &&
                                        <button className='text-xs underline text-gray-500 hover:text-gray-900'>
                                            {t('Mark as done')}
                                        </button>} */}
                                    <button
                                        className='font-bold text-blue-900 hover:text-blue-500'
                                        onClick={(e) => {
                                            setAnchorEl(e.currentTarget)
                                            setTaskDetails(activity.task)
                                        }}
                                    >
                                        &nbsp;{activity.task.title}
                                    </button>
                                </p>
                                <time dateTime={activity.activity_date} className={`flex-none py-0.5 text-xs leading-5 ${new Date(activity.activity_date) < new Date() && activity.activity_type === contactActivityTypes.TASK_DUE ? 'text-red-500' : 'text-gray-500'}`}>
                                    {formatActivityDate(activity.activity_date)}
                                </time>
                            </>}
                        {activity.activity_type === contactActivityTypes.TASK_DUE &&
                            <>
                                <Avatar sx={{ width: '1.5rem', height: '1.5rem', border: new Date(activity.activity_date) < new Date() && activity.activity_type === contactActivityTypes.TASK_DUE ? '1px solid #EF4444' : 0 }} src={activity.user_picture_url} />
                                {editActivity === index ?
                                    <EditTask
                                        task={activity.task}
                                        onClose={() => setEditActivity(false)}
                                        handleSaveTask={handleSaveTask}
                                        contact={contact}
                                    /> :
                                    <div className='w-full'>
                                        <div className={`flex-auto rounded-md p-3 ring-1 ring-inset ${new Date(activity.activity_date) < new Date() && activity.activity_type === contactActivityTypes.TASK_DUE ? 'ring-red-500' : 'ring-gray-200'}`}>
                                            <div className="flex justify-between gap-x-4">
                                                <div className="py-0.5 text-xs leading-5 text-gray-500">
                                                    <span className="font-medium text-gray-900">{activity.user_name.split("@")[0]} {activity.user_last_name}</span> {t(contactActivityTypeData[activity.activity_type].text)}
                                                </div>
                                                <time dateTime={activity.activity_date} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                                    {formatActivityDate(activity.activity_date)}
                                                </time>
                                            </div>
                                            <div className='w-full border-0 pt-2.5 pb-2 flex justify-between'>
                                                <div className="font-medium">
                                                    {activity.task.title}
                                                </div>
                                                {activity.task.task_type?.name &&
                                                    <Chip
                                                        variant='outlined'
                                                        size='small'
                                                        label={activity.task.task_type?.name}
                                                        icon={<TurnedInRoundedIcon fontSize='small' />}
                                                    />}
                                            </div>
                                            <div
                                                className="text-sm leading-6 text-gray-500"
                                            >
                                                {activity.task.description}
                                            </div>
                                        </div>
                                        <div className='flex justify-between'>
                                            <div>
                                                {activity.user === authInfo.user.userId &&
                                                    <>
                                                        <button
                                                            className='text-xs underline text-gray-500 hover:text-gray-900'
                                                            onClick={() => handleEditActivity(index)}
                                                        >
                                                            {t('Edit')}
                                                        </button>
                                                        <span className='text-gray-500'> • </span>
                                                    </>}
                                                <button className='text-xs underline text-gray-500 hover:text-gray-900'
                                                    onClick={() => {
                                                        setDeleteTitle(t('Delete task'))
                                                        setDeleteText(t('Are you sure you want to delete this task?'))
                                                        setOnDelete(() => () => handleDeleteTask(index))
                                                        setDeleteDialogOpen(true)
                                                    }}
                                                >{t('Delete')}</button>
                                            </div>
                                            <Button
                                                // variant='outlined'
                                                size='small'
                                                sx={{ textTransform: 'none', fontSize: '0.75rem' }}
                                                onClick={() => handleSaveTask({ task_id: activity.task.id, completed_date: new Date().toISOString().slice(0, 16) })}
                                            >
                                                {t('Mark as done')}
                                            </Button>
                                        </div>
                                    </div>}
                            </>}
                        {[contactActivityTypes.PLEDGE_CREATED, contactActivityTypes.PLEDGE_CANCELLED, contactActivityTypes.PLEDGE_PAID, contactActivityTypes.PLEDGE_DUE].includes(activity.activity_type) &&
                            <>
                                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                    <div className={`h-1.5 w-1.5 rounded-full ring-1 ${new Date(activity.activity_date) < new Date() && activity.activity_type === contactActivityTypes.PLEDGE_DUE ? 'bg-red-100 ring-red-300' : 'bg-gray-100 ring-gray-300'}`} />
                                </div>
                                <p className={`flex-auto py-0.5 text-xs leading-5 ${new Date(activity.activity_date) < new Date() && activity.activity_type === contactActivityTypes.PLEDGE_DUE ? 'text-red-500' : 'text-gray-500'}`}>
                                    <span className={'font-medium'}>{activity.user_name.split("@")[0]} {activity.user_last_name}</span> {t(contactActivityTypeData[activity.activity_type].text)}
                                    <button
                                        className='font-bold text-blue-900 hover:text-blue-500'
                                        onClick={(e) => {
                                            setPledgeOpen(true)
                                            setNewPledge(false)
                                            setPledge(activity.donation)
                                        }}
                                    >
                                        &nbsp;
                                        {activity.donation.donation_type === 1 && `${activity.donation.amount}${currencies[activity.donation.currency].symbol_native} ${activity.donation.payments === 0 ? t('per month') : activity.donation.payments > 1 ? ` ${t('in')} ${activity.donation.payments} ${t('payments')}` : ''}`}
                                        {activity.donation.donation_type === 2 && `${activity.donation.amount}${currencies[activity.donation.currency].symbol_native} ${activity.donation.payments === 0 ? t('per month') : `× ${activity.donation.payments} ${t('months')}`}`}
                                    </button>
                                </p>
                                <time dateTime={activity.activity_date} className={`flex-none py-0.5 text-xs leading-5 ${new Date(activity.activity_date) < new Date() && activity.activity_type === contactActivityTypes.PLEDGE_DUE ? 'text-red-500' : 'text-gray-500'}`}>
                                    {formatActivityDate(activity.activity_date)}
                                </time>
                            </>}
                    </li>
                ))}
                {/* {activityPage &&
                    <div className='m-4' ref={activityLoader}>
                    <LinearProgress sx={{ height: '2px', borderRadius: 99, backgroundColor: '#eeeeee' }} />
                </div>} */}
                {activityPage && activityInViewActive &&
                    <InView as="div" onChange={inView => inView && loadActivities()}>
                        <LinearProgress sx={{ height: '2px', borderRadius: 99, backgroundColor: '#eeeeee' }} />
                    </InView>}
            </ul>
            {pledgeOpen &&
                <PledgeDetails
                    open={pledgeOpen}
                    onClose={() => {
                        setPledgeOpen(false)
                        setPledge(null)
                    }}
                    initialData={{ contact: contact }}
                    pledgeData={pledge}
                    onUpdate={() => {
                        setSnackbarMessage(t('Pledge Saved Successfully'))
                        setSnackbar(true)
                        setActivityInViewActive(false);
                        setActivities([])
                        setActivityPage(1)
                        loadActivities();
                    }}
                    hideContact
                />}
        </div>
    )

    const renderContactDetails = () => (
        <div>
            <form className=' p-10 mx-auto text-start'>

                <div className="w-full mb-16">
                    <h2 className="text-custom-blue font-bold mb-8 text-xl">{t('Name')}</h2>
                    <fieldset className="flex gap-x-8 justify-between">
                        <TextField
                            fullWidth
                            variant='standard'
                            label={t('Prefix')}
                            placeholder={t('Prefix')}
                            value={prefix}
                            onChange={(e) => setPrefix(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            variant='standard'
                            label={t('First Name')}
                            placeholder={t('First Name')}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            variant='standard'
                            label={t('Last Name')}
                            placeholder={t('Last Name')}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            variant='standard'
                            label={t('Suffix')}
                            placeholder={t('Suffix')}
                            value={suffix}
                            onChange={(e) => setSuffix(e.target.value)}
                        />
                    </fieldset>
                </div>
                <div className="w-full mb-16">
                    <h2 className="text-custom-blue font-bold mb-8 text-xl">{t('Comunication')}</h2>
                    <fieldset className="flex gap-x-8 justify-between items-end">
                        <TagInput
                            tags={phoneNumbers}
                            setTags={setPhoneNumbers}
                            name="phone_number"
                            label={t('phone_number')}
                            validationText={t('Up to 3 phone numbers')}
                        />
                        <TagInput
                            tags={emailAddresses}
                            setTags={setEmailAddresses}
                            name="email"
                            label={t('email')}
                            validationText={t('Up to 3 emails')}
                        />
                    </fieldset>
                </div>
                <div className="w-full mb-16">
                    <h2 className="text-custom-blue font-bold mb-8 text-xl">{t('Address')}</h2>
                    <fieldset className="flex gap-x-8 justify-between mb-8">
                        <CustomInput
                            className="min-w-[12rem]"
                            name="street"
                            param={param}
                            label={t('Street')}
                            placeholder={t('Street')}
                            value={street}
                            onChange={handleStreetChange}
                            search={matchingSearch}
                            onSearchItemClick={handleSearchItemClick}
                            onBlur={handleBlur}
                        />
                        <TextField
                            fullWidth
                            variant='standard'
                            label={t('House')}
                            placeholder={t('House')}
                            value={house}
                            onChange={(e) => setHouse(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            variant='standard'
                            label={t('Apt.')}
                            placeholder={t('Apt.')}
                            value={apt}
                            onChange={(e) => setApt(e.target.value)}
                        />
                        <CustomInput
                            className="min-w-[12rem]"
                            name="city"
                            param={param}
                            label={t('City')}
                            placeholder={t('City')}
                            value={city}
                            onChange={handleCityChange}
                            search={matchingSearch}
                            onSearchItemClick={handleSearchItemClick}
                            onBlur={handleBlur}
                        />
                        <TextField
                            fullWidth
                            variant='standard'
                            label={t('Zip Code')}
                            placeholder={t('Zip Code')}
                            value={zipcode}
                            onChange={(e) => setZipcode(e.target.value)}
                        />
                    </fieldset>
                    <fieldset className="flex gap-x-8 justify-between">
                        <TextField
                            fullWidth
                            variant='standard'
                            label={t('Additional Details')}
                            placeholder={t('Additional Details')}
                            value={addressNotes}
                            onChange={(e) => setAddressNotes(e.target.value)}
                        />
                        <CustomInput
                            className="w-1/4"
                            name="state"
                            param={param}
                            label={t('State')}
                            placeholder={t('State')}
                            value={state}
                            onChange={handleStateChange}
                            search={matchingSearch}
                            onSearchItemClick={handleSearchItemClick}
                            onBlur={handleBlur}
                        />
                        <CustomInput
                            className="w-1/4"
                            name="country"
                            param={param}
                            label={t('Country')}
                            placeholder={t('Country')}
                            value={country}
                            onChange={handleCountryChange}
                            search={matchingSearch}
                            onSearchItemClick={handleSearchItemClick}
                            onBlur={handleBlur}
                        />
                    </fieldset>
                </div>
                <div className="w-full mb-16">
                    <h2 className="text-custom-blue font-bold mb-8 text-xl">{t('More Details')}</h2>
                    <fieldset className='mb-8'>
                        <Connections connections={connections} setConnections={setConnections} />
                    </fieldset>
                    <fieldset className="gap-x-8 justify-between">
                        <TextField
                            fullWidth
                            variant='standard'
                            label={t('Description')}
                            multiline
                            sx={{ '& .MuiInputBase-root': { mt: '1.5rem' } }}
                            maxRows={8}
                            placeholder={t('Description')}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="w-full py-1.5 border-b border-gray-300 mt-1 focus:border-blue-900 focus:border-b-2 outline-none resize-y custom-textarea"
                        />
                    </fieldset>
                </div>
                <div className='flex gap-4 justify-center mt-12'>
                    <LoadingButton
                        sx={{ borderRadius: 99 }}
                        size='lg'
                        className='rounded-full'
                        variant='outlined'
                        loading={saving}
                        onClick={handleSubmit}
                    >
                        {t('Save')}
                    </LoadingButton>
                    <LoadingButton
                        sx={{ borderRadius: 99 }}
                        size='lg'
                        className='rounded-full'
                        variant='contained'
                        loading={saving}
                        onClick={(e) => handleSubmit(e, true)}
                    >
                        {t('Save and Close')}
                    </LoadingButton>
                </div>
            </form>
            <Snackbar
                open={snackbar}
                onClose={() => setSnackbar(false)}
                autoHideDuration={5000}
                message={t(snackbarMessage)}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.5 }}
                        onClick={() => setSnackbar(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            ></Snackbar>
        </div>
    )

    const renderTotals = () => {
        const [whole, fraction] = totals.total_amount.toString().split('.');
        return (
            <div className="flex p-3 mb-4 gap-7 items-center">
                <Total
                    prefix={currencies[totals.org_currency].symbol_native}
                    amount={parseInt(whole).toLocaleString()}
                    suffix={'.' + fraction}
                    description={t('Sum of Donations')}
                />
                <Total
                    amount={totals.count}
                    description={t('Donations')}
                />
            </div>
        );
    }

    const renderDonations = () => (
        <div>
            {donations.length > 0 ? renderTotals() : <p>{t('No donations yet')}</p>}
            <div className={`${donations.length === 0 && 'hidden'}`}>
                <hr className='mb-7' />
                {/* <table className="table-auto border-collapse w-full text-start">
                    <thead>
                        <tr className="rounded-lg border-b border-gray-200 text-sm font-medium text-gray-700">
                            <th className="px-4 py-"></th>
                            <th className="px-4 py-2">{t('number')}</th>
                            <th className="px-4 py-2">{t('date')}</th>
                            <th className="px-4 py-2">{t('Donation Type')}</th>
                            <th className="px-4 py-2">{t('amount')}</th>
                            <th className="px-4 py-2">{t('payments')}</th>
                            <th className="px-4 py-2">{t('fundraiser')}</th>
                            <th className="px-4 py-2">{t('Notes')}</th>
                        </tr>
                    </thead>
                    <tbody className="text-sm font-normal text-gray-700 ">
                        {donations.map((donation, index) => (
                            <tr key={donation.id} className={`hover:bg-gray-100 ${index === donations.length - 1 ? '' : 'border-b '} border-gray-200 py-10`}>
                                <td className="ps-4">
                                    {donation.donation_type === 2 &&
                                        (donation.payments === donation.payments_months ?
                                            <div className="bg-gray-200 text-xs font-semibold px-2 py-1 w-fit text-gray-700 rounded-md">
                                                {t('Inactive')}
                                            </div>
                                            :
                                            (donation.donation_status === 3 ?
                                                <div className="bg-red-200 text-xs font-semibold px-2 py-1 w-fit text-red-700 rounded-md">
                                                    {t('Rejected')}
                                                </div>
                                                :
                                                <div className="bg-green-100 text-xs font-semibold px-2 py-1 w-fit text-green-700 rounded-md">
                                                    {t('Active')}
                                                </div>
                                            ))}
                                </td>
                                <td className="px-4 py-4">
                                    <Link to={`/donations/${donation.donation_id}`} className="underline underline-offset-4 hover:text-blue-900">
                                        {donation.donation_id}
                                    </Link>
                                </td>
                                <td className="px-4 py-4">
                                    {formatDate(donation.donation_date)}
                                </td>
                                <td className="px-4 py-4">
                                    {donation.donation_type === 1 && t('Regular')}
                                    {donation.donation_type === 2 && t('Recuring')}
                                </td>
                                <td className="px-4 py-4">
                                    {parseFloat(donation.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currencies[donation.currency].symbol_native}
                                </td>
                                <td className="px-4 py-4">
                                    {donation.payments > 1 && (`${donation.payments_months} / `)}
                                    {donation.payments === 0 ? `${donation.payments_months} / ${t('No Time Limit')}` : (donation.payments)}
                                </td>
                                <td className="px-4 py-4">
                                    <Link to={`/contacts/${donation.fundraiser?.contact_id}`} className="underline underline-offset-4 hover:text-blue-900">
                                        {donation.fundraiser?.first_name} {donation.fundraiser?.last_name}
                                    </Link>
                                </td>
                                <td className={`px-4 py-4`}>{donation.donor_note}</td>
                            </tr>
                        ))}
                    </tbody>
                </table> */}
                <DonationListLeg
                    donations={donations}
                    sort={donationSort}
                    setSort={setDonationSort}
                    checkbox={false}
                    donorName={false}
                    email={false}
                />
            </div>
            {newDonation &&
                <div ref={newDonationRef}>
                    <hr />
                    <div className='m-4'>
                        <form onSubmit={handleNewDonSubmit} className='flex gap-4 text-start'>
                            <div className='flex flex-col w-full gap-4'>
                                <div className='flex gap-4'>
                                    <FormControl size='small' fullWidth className='max-w-[7.5rem]'>
                                        <InputLabel id="donation-type-label">{t('Donation Type')}</InputLabel>
                                        <Select
                                            labelId="donation-type-label"
                                            label={t('Donation Type')}
                                            placeholder={t('Donation Type')}
                                            defaultValue={1}
                                            onChange={handlePaymentTypeChange}
                                        >
                                            <MenuItem value={1}>{t('Regular')}</MenuItem>
                                            <MenuItem value={2}>{t('Recurring')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl size='small' fullWidth className='max-w-[10rem]'>
                                        <InputLabel id="payment-method-label">{t('Payment Method')}</InputLabel>
                                        <Select
                                            labelId="payment-method-label"
                                            label={t('Payment Method')}
                                            placeholder={t('Payment Method')}
                                            onChange={handlePaymentMethodChange}
                                        >
                                            {paymentMethods.map((method) => (
                                                <MenuItem key={method.value} value={method.value}>
                                                    {t(method.label)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <div className='max-w-[12rem]'>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            type='datetime-local'
                                            label={t('Date')}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={handleNewDonDateChange}
                                            defaultValue={new Date().toISOString().slice(0, 16)}
                                        />
                                    </div>
                                    <div className='min-w-[8rem]'>
                                        <TextField
                                            fullWidth
                                            value={newDonAmount}
                                            onChange={handleAmountChange}
                                            size='small'
                                            label={t('Amount')}
                                            placeholder={t('Amount')}
                                            InputProps={{
                                                inputComponent: NumericFormatCustom,
                                            }}
                                        />
                                    </div>
                                    <Autocomplete
                                        clearOnEscape
                                        disableClearable
                                        size='small'
                                        fullWidth
                                        className='max-w-[12rem]'
                                        options={currencyValues}
                                        getOptionLabel={(option) => t(currencies[option].name)}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label={t("Currency")}
                                            />}
                                        filterSelectedOptions={false}
                                        value={newDonCurrency}
                                        onChange={(event, newValue) => {
                                            handleCurrencyChange(newValue)
                                        }}
                                    />
                                    <Autocomplete
                                        clearOnEscape
                                        disableClearable
                                        freeSolo
                                        fullWidth
                                        className='max-w-[8rem]'
                                        size='small'
                                        options={paymentsOptions}
                                        getOptionLabel={(option) => (option === '0' ? t('No Time Limit') : option) || (newDonPayments === '0' ? t('No Time Limit') : newDonPayments)}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label={t('Payments')}
                                            />}
                                        filterOptions={(options) => options}
                                        filterSelectedOptions={false}
                                        value={newDonPayments.toString()}
                                        onChange={(event, newValue) => {
                                            handlePaymentsChange(newValue)
                                        }}
                                    />
                                </div>
                                <div className='flex gap-4'>
                                    <div className='w-[24.5em]'>
                                        <SearchContact
                                            onChange={(e) => handleNewDonChange('fundraiser', e?.id || '')}
                                            value={newDonFundraiser}
                                            size='small'
                                            placeholder={t('Fundraiser')}
                                            label={t('Fundraiser')}
                                            variant='outlined'
                                        />
                                    </div>
                                    <div className='w-full max-w-[43.2rem]'>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={t('Notes')}
                                            placeholder={t('Notes')}
                                            onChange={(e) => handleNewDonChange('donation_notes', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex gap-4'>
                                <LoadingButton
                                    sx={{ borderRadius: 99, alignSelf: 'center', height: 'fit-content' }}
                                    size='small'
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                    loading={newDonSubmiting}
                                >
                                    {t('Save')}
                                </LoadingButton>
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    sx={{ p: 1, my: 'auto' }}
                                    onClick={() => { setNewDonation(false) }}
                                >
                                    <HiOutlineX />
                                </IconButton>
                            </div>
                        </form>
                    </div>
                </div>}
        </div>
    )

    return (
        <>
            <DeleteDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onDelete={onDelete}
                title={deleteTitle}
                text={deleteText}
            />
            <Popover
                open={taskDetails}
                anchorEl={anchorEl}
                onClose={() => setTaskDetails(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <div className='p-4 max-w-xs'>
                    {taskDetails &&
                        <div>
                            <div className="mb-4 flex justify-between gap-4 items-center">
                                <h2 className="text-lg font-semibold mb-1">{taskDetails?.title}</h2>
                                {taskDetails?.task_type?.name &&
                                    <Chip
                                        variant='outlined'
                                        size='small'
                                        label={taskDetails?.task_type?.name}
                                        icon={<TurnedInRoundedIcon fontSize='small' />}
                                    />}
                            </div>
                            <div className="mb-4">
                                <p className="text-gray-600">{taskDetails?.description}</p>
                            </div>
                        </div>
                    }
                </div>
            </Popover>
            {loading ?
                <LoadingComponent className='h-[32rem]' /> :
                <>
                    {id !== 'new' &&
                        <div className="flex justify-between items-center mb-2 -mt-10">
                            <Button
                                onClick={() => {
                                    setReferrer(listType)
                                }}
                                LinkComponent={Link}
                                to={prevLocation}
                                startIcon={i18n.language === 'he' ? <ArrowForwardIosRounded /> : <ArrowBackIosNewRounded />}
                                disabled={!prevLocation}
                            >
                                {t('Back')}
                            </Button>
                            <div>
                                <Button
                                    LinkComponent={Link}
                                    to={`/contacts/${prevContactId}${tab ? `/${tab}` : ''}`}
                                    startIcon={i18n.language === 'he' ? <NavigateNextRounded /> : <NavigateBeforeRounded />}
                                    disabled={!prevContactId}
                                >
                                    {t('Previous')}
                                </Button>
                                <Button
                                    LinkComponent={Link}
                                    to={`/contacts/${nextContactId}${tab ? `/${tab}` : ''}`}
                                    endIcon={i18n.language === 'he' ? <NavigateBeforeRounded /> : <NavigateNextRounded />}
                                    disabled={!nextContactId}
                                >
                                    {t('Next')}
                                </Button>
                            </div>
                        </div>}
                    <div className="flex float-end items-center my-1">
                        {id !== 'new' && <Options options={options} />}
                    </div>
                    <H1>
                        {contact ? (!contact.first_name && !contact.last_name ? contact.contact_id : `${contact.first_name || ''} ${contact.last_name || ''}`) : t('New Contact')}
                    </H1>
                    <div className="mt-12 bg-white rounded-2xl shadow-sm">
                        <TabContext value={id === 'new' ? 'details' : tab || 'contact'}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', pt: '0.8rem' }}>
                                <TabList onChange={handleTabChange} >
                                    <Tab label={t('Contact')} disabled={id === 'new'} value='contact' />
                                    <Tab label={t('Donations')} disabled={id === 'new'} value='donations' />
                                    <Tab label={t('Contact Details')} value='details' />
                                </TabList>
                            </Box>
                            <TabPanel value="contact">{renderContact()}</TabPanel>
                            <TabPanel sx={{ px: 0 }} value="donations">
                                {renderDonations()}
                                {/* {donations.length === 0 ? <p>{t('No donations yet')}</p> : renderDonations()} */}
                                <div className="flex justify-end">
                                    <div className='fixed bottom-10 text-end mx-10'>
                                        <Fab
                                            color='primary'
                                            onClick={() => setNewDonation(true)}
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value="details">{renderContactDetails()}</TabPanel>
                        </TabContext>
                    </div>

                </>
                // : <p>{t('Loading contact details...')}</p>
            }
        </>
    );
};

function EditTask({ task, contact, onClose, handleSaveNewTask, handleSaveTask }) {
    const { t } = useTranslation();
    const api = useApi();

    const [data, setData] = useState(task?.id ? { 'task_id': task?.id } : {});
    const [saving, setSaving] = useState(false);
    const [taskTypeOpen, setTaskTypeOpen] = useState(false);
    const [dueDateOpen, setDueDateOpen] = useState(false);
    const [selectDateOpen, setSelectDateOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [taskTypes, setTaskTypes] = useState([]);
    const [edit, setEdit] = useState(false);
    const [taskId, setTaskId] = useState(task?.id || null);
    const [title, setTitle] = useState(task?.title || '');
    const [description, setDescription] = useState(task?.description || '');
    const [taskType, setTaskType] = useState(task?.task_type || null);
    const [dueDate, setDueDate] = useState(task?.due_date || null);
    const [pickDueDate, setPickDueDate] = useState('');
    const [submiting, setSubmiting] = useState(false);
    const [deleteSubmiting, setDeleteSubmiting] = useState(false);

    useEffect(() => {
        setPickDueDate(UTCToLocalInput(dueDate || new Date()))
    }, [dueDateOpen])

    const handleInputChange = (key, val) => {
        setData({ ...data, [key]: val });
    }

    useEffect(() => {
        log({ dueDate })
    }, [dueDate])

    const handleTaskDuedateChange = (event) => {
        let date = new Date();
        if (event === "hour") {
            date.setHours(date.getHours() + 1)
            const hour = date.toISOString()
            setDueDate(hour)
            handleInputChange('due_date', hour);
        } else if (event === "tomorrow") {
            date.setHours(9, 0, 0, 0);
            date.setDate(date.getDate() + 1)
            const tomorrow = date.toISOString()
            setDueDate(tomorrow)
            handleInputChange('due_date', tomorrow);
        } else if (event === "nextWeek") {
            date.setHours(9, 0, 0, 0);
            date.setDate(date.getDate() + (9 - date.getDay()))
            const nextWeek = date.toISOString()
            setDueDate(nextWeek)
            handleInputChange('due_date', nextWeek);
        } else if (event === "none") {
            setDueDate(null)
            handleInputChange('due_date', null);
        }
        setSelectDateOpen(false);
        setAnchorEl(null);
    };

    const handleNewTaskOpen = (e) => {
        setTaskTypeOpen(true);
        setAnchorEl(e.currentTarget);
        api.get('/tasks/task-types/').then((response) => {
            setTaskTypes(response.data);
        }).catch((error) => {
            console.error('Error fetching task types:', error);
        });
    };

    return (
        <>
            <div className='w-full'>
                <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-blue-900 focus-within:ring-1 focus-within:ring-blue-900">
                    <input
                        type="text"
                        name="title"
                        id="title"
                        className="block w-full border-0 pt-2.5 px-3 pb-2 text-lg font-medium placeholder:text-gray-400 outline-none focus:ring-0"
                        placeholder={t("Title")}
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value)
                            handleInputChange('title', e.target.value)
                        }}
                    />
                    <textarea
                        rows={2}
                        name="description"
                        id="description"
                        className="block w-full border-spacing-0 px-3 resize-none border-0 py-0 text-gray-900 outline-none placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder={t("Write a description...")}
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value)
                            handleInputChange('description', e.target.value)
                        }}
                    />

                    <div className="flex flex-nowrap justify-end gap-2 px-2 py-2 sm:px-3">
                        <Chip
                            label={taskType?.name || t('Task Type')}
                            icon={<TurnedInRoundedIcon fontSize='small' />}
                            onClick={(e) => handleNewTaskOpen(e)}
                        />
                        <Chip
                            label={dueDate ? localDateTime(dueDate) : t('Due Date')}
                            icon={<EventRoundedIcon fontSize='small' />}
                            onClick={(e) => {
                                setAnchorEl(e.currentTarget)
                                setSelectDateOpen(true)
                            }}
                        />
                        <Popover
                            open={selectDateOpen}
                            anchorEl={anchorEl}
                            onClose={() => {
                                setAnchorEl(null)
                                setSelectDateOpen(false)
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <ul className='py-2'>
                                <li
                                    className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
                                    onClick={() => handleTaskDuedateChange("hour")}>
                                    {t('Another hour')}
                                </li>
                                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer' onClick={() => handleTaskDuedateChange("tomorrow")}>
                                    {t('Tomorrow morning at 9am')}
                                </li>
                                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer' onClick={() => handleTaskDuedateChange("nextWeek")}>
                                    {t('Next week Monday at 9am')}
                                </li>
                                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer' onClick={() => !dueDateOpen && setDueDateOpen(true)}>
                                    {t('Pick a date and time')}
                                    <Dialog
                                        open={dueDateOpen}
                                        onClose={() => setDueDateOpen(false)}
                                        sx={{ textAlign: 'center' }}
                                    >
                                        <DialogTitle>{t('Pick a date and time')}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                <TextField
                                                    type='datetime-local'
                                                    value={UTCToLocalInput(pickDueDate)}
                                                    onChange={(e) => {
                                                        log('date', e.target.value, localDateTimeToUTC(e.target.value))
                                                        setPickDueDate(localDateTimeToUTC(e.target.value))
                                                    }}
                                                />
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions >
                                            <Button onClick={() => {
                                                setPickDueDate('')
                                                setDueDateOpen(false)
                                            }} color="primary">
                                                {t('Cancel')}
                                            </Button>
                                            <Button onClick={() => {
                                                setDueDate(localDateTimeToUTC(pickDueDate))
                                                handleInputChange('due_date', localDateTimeToUTC(pickDueDate))
                                                setPickDueDate('')
                                                setDueDateOpen(false)
                                                setSelectDateOpen(false);
                                                setAnchorEl(null);
                                            }} variant='contained' color="primary">
                                                {t('Select')}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </li>
                                <li
                                    className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
                                    onClick={() => handleTaskDuedateChange("none")}>
                                    {t('No due date')}
                                </li>
                            </ul>
                        </Popover>
                        <Popover
                            open={taskTypeOpen}
                            anchorEl={anchorEl}
                            onClose={() => {
                                setTaskTypeOpen(false)
                                setAnchorEl(null)
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <ul className='py-2 w-40 max-h-56 overflow-y-auto'>
                                <li
                                    className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
                                    onClick={() => {
                                        setTaskType(null)
                                        handleInputChange('task_type', null)
                                        setTaskTypeOpen(false)
                                        setAnchorEl(null)
                                    }}
                                >
                                    {t('No task type')}
                                </li>
                                {taskTypes.map((taskType, index) => (
                                    <li
                                        key={index}
                                        className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
                                        onClick={() => {
                                            setTaskType(taskType)
                                            handleInputChange('task_type', taskType.id)
                                            setTaskTypeOpen(false)
                                            setAnchorEl(null)
                                        }}
                                    >
                                        {taskType.name}
                                    </li>
                                ))}
                            </ul>
                        </Popover>
                    </div>
                </div>
                <div className="absolute inset-x-px bottom-0">
                </div>
                <div className='flex gap-2 mt-2'>
                    <LoadingButton
                        color='primary'
                        variant='contained'
                        size='small'
                        onClick={() => {
                            setSaving(true)
                            if (task?.id) {
                                handleSaveTask(data)
                            } else {
                                handleSaveNewTask({
                                    title: title,
                                    description: description,
                                    due_date: dueDate || null,
                                    task_type: taskType?.id || null,
                                    contact: contact.id
                                })
                            }
                        }}
                        loading={saving}
                    >
                        {t('Save')}
                    </LoadingButton>
                    <Button size='small' sx={{ color: '#aaaaaa' }} onClick={onClose}>
                        {t('Cancel')}
                    </Button>
                </div>
            </div>
        </>
    )
}

export default observer(ContactView);