import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import H1 from "components/common/Typography/H1"
import { formatDate } from "../../../../utils"
import { Link } from "react-router-dom"
import LoadingComponent from "components/Loading"
import currencies from 'data/currencies';
import AddIcon from '@mui/icons-material/Add';
import { Fab, InputAdornment, TextField } from "@mui/material"
import DeleteDialog from "components/common/DeleteDialog"
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { AiOutlineSearch } from "react-icons/ai"
import { debounce } from "lodash";
import Options from "components/Options"
import useApi from "hooks/useApi"
import ExpenseList from "./ExpensesList"

function Expenses() {
    const { t } = useTranslation()
    const api = useApi();
    const [expenses, setExpenses] = useState([])
    const [loading, setLoading] = useState(true)
    const [updated, setUpdated] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [searchSuggestions, setSearchSuggestions] = useState([])

    const pageSizes = [50, 100, 250, 500];
    const [pageSize, setPageSize] = useState(localStorage.getItem('expensesPageSize') || pageSizes[0]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [checkedItems, setCheckedItems] = useState([]);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState('');
    const [deleteText, setDeleteText] = useState('');

    const options = [
        {
            text: `${t('Delete')} ${checkedItems.length > 0 ? checkedItems.length : ''} ${t('Expenses')}`,
            icon: <DeleteOutlineRoundedIcon />,
            onClick: () => {
                setDeleteDialogOpen(true);
                setDeleteTitle(t('Delete Expenses'));
                setDeleteText(`${t('Are you sure you want to delete')} ${checkedItems.length} ${t('expenses')}?`);
            },
            disabled: checkedItems.length === 0
        }
    ]

    const fetchExpenses = async () => {
        return api.get('/expenses/', {
            params: {
                page: currentPage,
                page_size: pageSize,
                search: searchValue
            },
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        fetchExpenses().then((response) => {
            setExpenses(response.data.results)
            setTotalPages(Math.ceil(response.data.count / pageSize));
            setLoading(false)
        })
    }, [updated, pageSize, currentPage])

    useEffect(() => {
        const searchExpenses = debounce(() => {
            fetchExpenses().then((response) => {
                setSearchSuggestions(response.data.results)
            })
        }, 100)
        if (searchValue) searchExpenses()
        return () => searchExpenses.cancel()
    }, [searchValue])

    const handleSearchBlur = () => {
        setTimeout(() => {
            setSearchSuggestions([]);
        }, 200);
    };

    const handleSearchSelect = (result) => {
        const selectSearchValue = result?.contact?.first_name ? result?.contact?.first_name + ' ' + result.contact?.last_name : result?.contact?.last_name
        setSearchValue(selectSearchValue);
        setSearchSuggestions([]);
    };

    const handlePageSizeChange = (e) => {
        setPageSize(e.target.value);
        localStorage.setItem('expensesPageSize', e.target.value);
        setCurrentPage(1);
    };

    const handleDelete = () => {
        api.delete('/expenses/update/', {
            params: {
                expense_ids: JSON.stringify(checkedItems)
            },
        }).then((response) => {
            setUpdated(!updated)
            setDeleteDialogOpen(false)
        }).catch((error) => {
            console.log(error)
        })
    }

    const renderSearchBar = () => (
        <div className="flex gap-7 bg-white p-7 mb-9 rounded-2xl">
            <div className="w-1/2 flex items-center relative">
                <TextField
                    fullWidth
                    variant='standard'
                    type='search'
                    placeholder={t('Search expense by contact name')}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onBlur={handleSearchBlur}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setUpdated(!updated)
                            setSearchSuggestions([])
                        }
                    }}
                    value={searchValue}
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start">
                                <button onClick={() => setUpdated(!updated)}>
                                    <AiOutlineSearch />
                                </button>
                            </InputAdornment>,
                    }}
                />
                {searchSuggestions.length > 0 && (
                    <ul className="absolute text-start top-12 -ms-2 self-start bg-white shadow-lg border border-gray-200 rounded py-1 z-10 w-full max-h-60 overflow-y-auto">
                        {searchSuggestions.map((result, index) => (
                            <li
                                key={index}
                                className="py-1 hover:bg-gray-200 cursor-pointer"
                                onClick={() => handleSearchSelect(result)}
                            >
                                <div className="px-3 font-medium">
                                    {result?.contact?.first_name ? result?.contact?.first_name + ' ' + result?.contact?.last_name : result?.contact?.last_name}
                                    <span className="font-normal">
                                        {result?.contact?.connections[0]?.relationship && ` • ${result?.contact?.connections[0]?.relationship}`}
                                        {result?.contact?.connections[0]?.related_to?.name && ` ${result?.contact?.connections[0]?.related_to?.name}`}
                                        {
                                            (result?.contact.street || result?.contact.house_number || result?.contact.city)
                                            && " • "
                                        }
                                        {
                                            `${result?.contact.street ? result?.contact.street + ' ' : ''}` +
                                            `${result?.contact.house_number ? result?.contact.house_number + (result?.contact.street ? ',' : '') : ''}` +
                                            `${result?.contact.city ? result?.contact.city : ''}`
                                        }
                                    </span>
                                </div>
                                {searchSuggestions.length !== index + 1 && <hr />}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div className="flex items-center">
                <select
                    className=""
                    value={pageSize}
                    onChange={handlePageSizeChange}
                >
                    {pageSizes.map((size) => (
                        <option key={size} value={size}>
                            {`${size} ${t('Expenses Per Page')}`}
                        </option>
                    ))}
                </select>
            </div>
            {/* <div className="flex items-center">
                <button className="ms-2 text-xl text-custom-blue">
                    <HiAdjustmentsHorizontal />
                </button>
            </div> */}
        </div>
    );

    const renderTable = () => (
        <ExpenseList
            expenses={expenses}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            checkedExpenses={checkedItems}
            setCheckedExpenses={setCheckedItems}
        />
    );

    return (
        <>
            <div className="flex float-end items-center">
                <DeleteDialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                    onDelete={handleDelete}
                    title={deleteTitle}
                    text={deleteText}
                />
                <Options options={options} />
            </div>
            <H1>{t('Expenses')}</H1>
            {renderSearchBar()}
            {loading ? <LoadingComponent /> :
                <div>
                    {renderTable()}
                </div>}
            <div className="flex justify-end">
                <div className='fixed bottom-10 text-end mx-10'>
                    <Fab color='primary' component={Link} to={`/expenses/new`}>
                        <AddIcon />
                    </Fab>
                </div>
            </div>
        </>
    )
}

export default Expenses