import { SearchRounded } from "@mui/icons-material";
import { IconButton, InputBase, alpha, styled } from "@mui/material";
import { useEffect, useState } from "react";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 99,
    // borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.04),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.08),
    },
    marginRight: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(1),
        width: 'auto',
    },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 0, 1, 1),
        // vertical padding + font size from searchIcon
        paddingRight: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '14ch',
            // width: '12ch',
            // '&:focus': {
            //     width: '20ch',
            // },
        },
    },
}));

function InnerSearch({ searchValue, onSubmit, placholder }) {
    const [search, setSearch] = useState('');

    useEffect(() => {
        setSearch(searchValue);
    }, [searchValue]);
    // const onSubmit = (e) => {
    //     console.log('searching for', search);
    // }

    return (
        <Search>
            <IconButton
                onClick={() => onSubmit(search)}
                size="small"
                sx={{
                    position: 'absolute',
                    right: 0,
                    m: 0.355,
                    zIndex: 1,
                }}
            >
                <SearchRounded />
            </IconButton>
            <StyledInputBase
                placeholder={placholder}
                inputProps={{ 'aria-label': 'search' }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        onSubmit(e.target.value);
                    }
                }}
            />
        </Search>
    );
}

export default InnerSearch;