import { useTranslation } from "react-i18next";
import H1 from "components/common/Typography/H1";
import { useEffect, useState } from "react";
import currencies from "data/currencies";
import Modal from '@mui/material/Modal';
import { MdClose } from "react-icons/md";
import { Chip, IconButton, Radio, RadioGroup, Snackbar, TextField, Avatar, Tooltip, Button, Collapse } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { Done } from "@mui/icons-material";
import { debounce } from "lodash";
import CloseIcon from '@mui/icons-material/Close';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import Pagination from "components/common/Pagination";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { TransitionGroup } from "react-transition-group";
import { InView } from "react-intersection-observer";
import SmallPopover from "components/SmallPopover";
import SearchContact from "components/general/SearchContact";
import useApi from "hooks/useApi";
const pageSize = 20;

const npKeys = {
    "Mail": "Email",
    "Phone": "Phone",
    "Amount": "Amount",
    "Adresse": "Address",
    "LastNum": "Last 4 digits",
    "KabalaId": "Receipt Number",
    "MasofName": "Masof Name",
    "ClientName": "Name",
    "Tashloumim": "Payments",
    "NextTashloum": "Next Payment",
    "FirstTashloum": "First Payment",
    "TransactionId": "Transaction ID",
    "TransactionTime": "Transaction Time",
    "TransactionType": "Transaction Type",
    "KevaAdresse": "Address",
    "KevaAmount": "Amount",
    "KevaCity": "City",
    "KevaFrequency": "Payments",
    "KevaGroupe": "Group",
    "KevaId": "Recuring ID",
    "KevaLastNum": "Last 4 digits",
    "KevaMail": "Email",
    "KevaPhone": "Phone",
    "KevaName": "Name",
    "KevaNextDate": "Next Payment",
    "KevaSuccess": "Success Payments",
    "KevaTashlumim": "Payments Left",
    "TotalHistoryAmount": "Total History Amount",
}

const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
    }
};

const updateDonationHeaders = {
    "X-Update-Donation": "np-fix"
}

function FixContacts() {
    const api = useApi();
    const [npContacts, setNpContacts] = useState([])
    const [MoreDetails, setMoreDetails] = useState()
    const [selectedContactPopup, setSelectedContactPopup] = useState()
    const [newContactModal, setNewContactModal] = useState(false)
    const [searchContactsModal, setSearchContactsModal] = useState(false)
    const [searchContactsResults, setSearchContactsResults] = useState([])
    const [selectedSearchContact, setSelectedSearchContact] = useState('')
    const [searchContactValue, setSearchContactValue] = useState('')
    const [searchContactPage, setSearchContactPage] = useState(1)
    const [snackbar, setSnackbar] = useState(false)
    const [snackbarViewButton, setSnackbarViewButton] = useState(false)
    const [ncFirsstName, setNcFirsstName] = useState('')
    const [ncLastName, setNcLastName] = useState('')
    const [ncEmail, setNcEmail] = useState('')
    const [ncPhone, setNcPhone] = useState('')
    const { t } = useTranslation();
    const direction = document.dir;
    const [searchContactAnchorEl, setSearchContactAnchorEl] = useState(null);
    const [searchContactDonation, setSearchContactDonation] = useState({});

    const [npPage, setNpPage] = useState(1);
    const [npTotalPages, setNpTotalPages] = useState(1);

    useEffect(() => {
        console.log({ selectedSearchContact })
    }, [selectedSearchContact])

    const handleSelectContact = async (donation_id, selected_contact_pk, donation_contact_id, amount, currency) => {
        const data = {
            contact: selected_contact_pk,
            amount,
            currency,
        }
        try {
            await api.patch(`/donations/${donation_id}/update/`, data, { headers: updateDonationHeaders });            nedarimPlusContacts()
            await api.post(`/contacts/delete/`, {
                contact_ids: [donation_contact_id]
            })
            setSelectedContactPopup(null)
            setSearchContactsModal(false)
            setSearchContactAnchorEl(null)
            setSelectedSearchContact('')
            setSearchContactValue('')
            setSearchContactDonation({})
            setSnackbarViewButton(`/donations/${donation_id}`)
            setSnackbar(true)
        } catch (error) {
            console.error('Error updating donation:', error);
            nedarimPlusContacts()
        }
    }

    const handleAprooveContact = async (contact_id, status, donation_id) => {
        try {
            await api.patch(`/contacts/${contact_id}`, {
                status
            });
            nedarimPlusContacts()
            setSnackbarViewButton(`/donations/${donation_id}`)
            setSnackbar(true)
        } catch (error) {
            console.error('Error updating donation:', error);
            nedarimPlusContacts()
        }
    }

    const handleCLoseNcForm = () => {
        setNcFirsstName('')
        setNcLastName('')
        setNcEmail('')
        setNcPhone('')
        setNewContactModal(false)
    }

    const handleCreateContact = async (donation, donation_contact_id, e) => {
        e.preventDefault()
        const data = {
            first_name: ncFirsstName,
            last_name: ncLastName,
            email_addresses: [ncEmail],
            phone_numbers: [ncPhone],
            status: 1,
        }
        try {
            const response = await api.post(`/contacts/create`, data);
            const contact_pk = response.data.id
            const data2 = {
                contact: contact_pk,
                amount: donation.amount,
                currency: donation.currency,
            }
            await api.put(`/donations/${donation.donation_id}/update/`, data2, { headers: updateDonationHeaders });
            await api.post(`/contacts/delete/`, {
                contact_ids: [donation_contact_id]
            })
            setNewContactModal(false)
            setSnackbarViewButton(`/donations/${donation.donation_id}`)
            nedarimPlusContacts()
            setSnackbar(true)
        } catch (error) {
            console.error('Error updating donation:', error);
            nedarimPlusContacts()
        }
    }

    const nedarimPlusContacts = async () => {
        try {
            const response = await api.get('/fix-and-merge/np/', {
                params: {
                    page_size: pageSize,
                    page: npPage,
                }
            });
            setNpContacts(response.data.results)
            setNpTotalPages(Math.ceil(response.data.count / pageSize))
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        nedarimPlusContacts()
    }, [npPage])

    const fetchContactsDebounce = debounce((search, page) => {
        api.get('/contacts/', {
            params: {
                search: search,
                status: JSON.stringify([1]),
                page_size: 25,
                page: page,

            }
        }).then(res => {
            if (page === 1) {
                setSearchContactsResults(res.data.results);
            } else {
                setSearchContactsResults([...searchContactsResults, ...res.data.results]);
            }
            setSearchContactPage(page)
        }).catch(err => {
            console.error('Error fetching search results:', err);
        });

    }, 100);

    const fetchContacts = (search, page) => {
        fetchContactsDebounce(search, page);
        return () => fetchContactsDebounce.cancel();
    }


    const renderNedarimPlus = () => {
        return (
            <div className="text-start">
                <h2 className="text-2xl font-medium mb-2">{t('Nedarim Plus') + ' '}
                    <span className="text-base font-normal">
                        {t('Associate new donations from Nedarim plus to contacts')}
                    </span></h2>
                <div className="flex mb-4 justify-center items-center text-center">
                    <div className="flex justify-center items-center text-center bg-blue-100 rounded-lg p-4 mt-4">
                        <div className="text-sm">
                            {t('np-top-message-box')}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-7 bg-white py-7 mb-9 rounded-2xl">
                    <TransitionGroup>
                        {npContacts.map((contact, npContactIndex) => (
                            <Collapse key={contact.donation_id}>
                                <div className="mx-7">
                                    <div className="flex flex-col">
                                        <div className="items-center">
                                            <div className="flex gap-2 mb-4">
                                                <div className="font-bold">
                                                    {contact?.donation_type === 1 && t('Donation')}
                                                    {contact?.donation_type === 2 && t('Recurring Donation')}
                                                    {` ${contact?.amount}${currencies[contact?.currency].symbol_native} `}
                                                    {contact?.payments === 0 && ` ${t('per month, no time limit')} `}
                                                    {contact?.payments > 1 && ` ✕ ${contact?.payments} ${t('months')} `}
                                                    {` ${t('from-in')}`}
                                                    '{contact?.contact?.first_name} {contact?.contact?.last_name}'
                                                </div>
                                                {MoreDetails === npContactIndex ? (
                                                    <button
                                                        className="text-blue-500 hover:text-blue-700"
                                                        onClick={() => setMoreDetails(null)}
                                                    >
                                                        ({t('Less Details')})
                                                    </button>
                                                ) :
                                                    <button
                                                        className="text-blue-500 hover:text-blue-700"
                                                        onClick={() => setMoreDetails(npContactIndex)}
                                                    >
                                                        ({t('More Details')})
                                                    </button>
                                                }
                                            </div>
                                            <div className="flex gap-2 mb-4">
                                                <Tooltip placement="top" title={`${t('new-contact-tooltip-1')} '${contact?.contact?.first_name} ${contact?.contact?.last_name}' ${t('new-contact-tooltip-2')}`} arrow color="">
                                                    <Chip
                                                        color="primary"
                                                        label={`${t('Create contact')} '${contact?.contact?.first_name} ${contact?.contact?.last_name}'`}
                                                        icon={<CheckIcon />}
                                                        variant="solid"
                                                        sx={{ color: '#fff', '&:hover': { backgroundColor: '#1e3b8acc' } }}
                                                        onClick={() => handleAprooveContact(contact.contact.contact_id, 1, contact.donation_id)}
                                                    />
                                                </Tooltip>
                                                <Tooltip placement="top" title={`${t('ignore-tooltip-1')}. (${t('ignore-tooltip-2')} '${contact?.contact?.first_name} ${contact?.contact?.last_name}' ${t('ignore-tooltip-3')})`} arrow>
                                                    <Chip
                                                        color="secondary"
                                                        label={t('Ignore')}
                                                        icon={<CloseIcon />}
                                                        variant="soft"
                                                        sx={{ fontWeight: 600, color: '#1e3b8a', '&:hover': { backgroundColor: '#1e3b8a88' } }}
                                                        onClick={() => handleAprooveContact(contact.contact.contact_id, 5, contact.donation_id)}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="flex flex-wrap gap-2 mb-4">
                                                {contact?.similar_contacts.map((similarContact, index) => (
                                                    // <div className="mb-4" key={index}
                                                    //     onMouseEnter={() => setSelectedContactPopup(npContactIndex + ':' + index)}
                                                    //     onMouseLeave={() => setSelectedContactPopup(null)}
                                                    // >
                                                    <Tooltip
                                                        key={index}
                                                        placement="top"
                                                        title={
                                                            <div className="justify-between">
                                                                <a
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href={`/contacts/${similarContact.contact_id}`}
                                                                    className="font-medium text-sm underline underline-offset-4 hover:text-blue-900">
                                                                    {similarContact?.first_name} {similarContact?.last_name}
                                                                </a>
                                                                <div className="font-medium text-sm">
                                                                    {similarContact.street} {similarContact.house_number}{similarContact.street ? ',' : ''} {similarContact.city}
                                                                </div>
                                                                <div className="font-medium text-sm">
                                                                    {similarContact?.connections?.map((connection, connectionIndex) => (
                                                                        <div key={connectionIndex}>
                                                                            {connection?.relationship}{' '}
                                                                            {connection?.related_to && (
                                                                                <a
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    href={`/contacts/${connection.related_to.contact_id}`}
                                                                                    className="underline underline-offset-4 hover:text-blue-900"
                                                                                >
                                                                                    {connection.related_to.name}
                                                                                </a>
                                                                            )}
                                                                            {connectionIndex < similarContact.connections.length - 1 && ', '}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="flex font-medium text-sm">
                                                                    {similarContact.phone_numbers.map((phoneNumber, phoneIndex) => (
                                                                        <div key={phoneIndex}>
                                                                            {phoneIndex > 0 && ', '}
                                                                            <a href={`tel:${phoneNumber.phone_number}`} className="font-medium hover:text-blue-900">
                                                                                {phoneNumber.phone_number}
                                                                            </a>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="flex font-medium text-sm">
                                                                    {similarContact.email_addresses.map((email, emailIndex) => (
                                                                        <div key={emailIndex}>
                                                                            {emailIndex > 0 && ', '}
                                                                            <a href={`mailto:${email.email}`} className="font-medium hover:text-blue-900">
                                                                                {email.email}
                                                                            </a>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="flex font-medium text-sm">
                                                                    {similarContact.description}
                                                                </div>
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    sx={{ mt: 2 }}
                                                                    onClick={() => handleSelectContact(contact.donation_id, similarContact.id, contact.contact_id, contact.amount, contact.currency)}
                                                                >
                                                                    {t('Select')}
                                                                </Button>
                                                            </div>
                                                        }
                                                    >
                                                        <Chip
                                                            color="primary"
                                                            label={`${similarContact?.first_name} ${similarContact?.last_name}`}
                                                            variant="outlined"
                                                            sx={{ borderWidth: '2px', fontWeight: 600, color: '#1e3b8a', '&:hover': { backgroundColor: '#1e3b8a88' } }}
                                                            onClick={() => handleSelectContact(contact.donation_id, similarContact.id, contact.contact_id, contact.amount, contact.currency)}
                                                            avatar={<Avatar>
                                                                {similarContact?.first_name[0]?.toUpperCase()}
                                                                {similarContact?.last_name[0]?.toUpperCase()}
                                                            </Avatar>}
                                                        />
                                                    </Tooltip>
                                                ))}
                                            </div>
                                            <div className="flex gap-2">
                                                <Tooltip placement="top" title={t('create-new-contact-tooltip')} arrow>
                                                    <Chip
                                                        color="primary"
                                                        label={t('New Contact')}
                                                        icon={<AddSharpIcon />}
                                                        variant="outlined"
                                                        sx={{ '&:hover': { backgroundColor: '#1e3b8a88' } }}
                                                        onClick={() => setNewContactModal(npContactIndex)}
                                                    />
                                                </Tooltip>
                                                <Tooltip placement="top" title={t('search-existing-contact-tooltip')} arrow>
                                                    <Chip
                                                        color="primary"
                                                        label={t('Search Contact')}
                                                        icon={<SearchSharpIcon color="#1e3b8a" />}
                                                        variant="outlined"
                                                        sx={{ borderWidth: '2px', fontWeight: 600, '&:hover': { backgroundColor: '#1e3b8a88' } }}
                                                        onClick={(e) => {
                                                            setSearchContactDonation(contact)
                                                            setSearchContactAnchorEl(e.currentTarget)
                                                        }}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <Modal
                                            open={npContactIndex === newContactModal}
                                            onClose={() => {
                                                setNewContactModal(false)
                                                setNcFirsstName('')
                                                setNcLastName('')
                                                setNcEmail('')
                                                setNcPhone('')
                                            }}
                                            className="flex justify-center items-center"
                                        >
                                            <div className="bg-white rounded-2xl p-4">
                                                <button className="text-gray-500" onClick={handleCLoseNcForm}>
                                                    <MdClose size={28} />
                                                </button>
                                                <div className="px-10 flex flex-col gap-4 text-center">
                                                    <div className="font-semibold text-2xl">
                                                        {t('Add New Contact')}
                                                    </div>
                                                    <div className="block mx-auto bg-blue-100 rounded-lg p-4 mt-4">
                                                        <div className="text-sm">
                                                            {t('Initial Details, You could add more details later')}
                                                        </div>
                                                    </div>
                                                    <form onSubmit={(e) => handleCreateContact(contact, contact.contact_id, e)}
                                                        className="text-start my-4">
                                                        <fieldset className="relative flex gap-4 mb-2">
                                                            <TextField
                                                                id="standard-basic"
                                                                label={t('First Name')}
                                                                className="w-full"
                                                                name={'first_name'}
                                                                variant="standard"
                                                                value={ncFirsstName}
                                                                onChange={(e) => setNcFirsstName(e.target.value)}
                                                            />
                                                            <TextField
                                                                id="standard-basic"
                                                                label={t('Last Name')}
                                                                className="w-full"
                                                                name={'last_name'}
                                                                variant="standard"
                                                                value={ncLastName}
                                                                onChange={(e) => setNcLastName(e.target.value)}
                                                            />
                                                        </fieldset>
                                                        <fieldset className="relative flex gap-4 mb-2">
                                                            <TextField
                                                                id="standard-basic"
                                                                label={t('Email')}
                                                                className="w-full"
                                                                name={'email'}
                                                                variant="standard"
                                                                value={ncEmail}
                                                                onChange={(e) => setNcEmail(e.target.value)}
                                                            />
                                                            <TextField
                                                                id="standard-basic"
                                                                label={t('Phone')}
                                                                className="w-full"
                                                                name={'phone'}
                                                                variant="standard"
                                                                value={ncPhone}
                                                                onChange={(e) => setNcPhone(e.target.value)}
                                                            />
                                                        </fieldset>
                                                        <input
                                                            type="submit"
                                                            value={t('Add Contact')}
                                                            className="mt-10 bg-blue-900 block mx-auto text-white rounded-lg px-4 leading-10"
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                        </Modal>
                                        <Modal
                                            open={npContactIndex === searchContactsModal}
                                            onClose={() => {
                                                setSearchContactsModal(false)
                                                setSelectedSearchContact('')
                                            }}
                                            className="flex justify-center items-center"
                                        >
                                            <div className="bg-white rounded-2xl p-4">
                                                <button className="text-gray-500" onClick={() => {
                                                    setSearchContactsModal(false)
                                                    setSelectedSearchContact('')
                                                }}>
                                                    <MdClose size={28} />
                                                </button>
                                                <div className="px-10 flex flex-col gap-4 text-center">
                                                    <div className="font-semibold text-2xl">
                                                        {t('Search Contact')}
                                                    </div>
                                                    <form
                                                        onSubmit={(e) => {
                                                            e.preventDefault()
                                                            handleSelectContact(contact.donation_id, selectedSearchContact, contact.contact_id, contact.amount, contact.currency)
                                                        }}
                                                        onKeyDown={handleKeyDown}
                                                        className="text-start my-4">
                                                        <div className="flex justify-center mb-6">
                                                            <TextField
                                                                id="standard-basic"
                                                                label={t('Search Contact')}
                                                                className="w-[28rem]"
                                                                name={'search'}
                                                                variant="standard"
                                                                type="search"
                                                                onChange={(e) => {
                                                                    setSearchContactValue(e.target.value)
                                                                    fetchContacts(e.target.value, 1)
                                                                }}
                                                            />
                                                        </div>
                                                        <RadioGroup
                                                            aria-labelledby="product-color-attribute"
                                                            value={selectedSearchContact}
                                                            onChange={(e) => setSelectedSearchContact(e.target.value)}
                                                        >
                                                            <ul className="w-[32rem] self-start bg-white border border-gray-300 rounded-2xl py-1 h-72 overflow-y-auto">
                                                                {searchContactsResults.map((result, srindex) => (
                                                                    <li
                                                                        key={srindex}
                                                                        className="hover:bg-gray-200"
                                                                    // onClick={() => handleSearchSelect(result)}
                                                                    >
                                                                        <div className="p-3 font-medium">
                                                                            <Radio
                                                                                value={result.id}
                                                                                name="contact"
                                                                                checkedIcon={<Done fontSize="xl2" />}
                                                                                onChange={(e) => setSelectedSearchContact(e.target.value)}
                                                                            />
                                                                            <a
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                href={`/contacts/${result.contact_id}`}
                                                                                className="font-medium text-sm underline underline-offset-4 decoration-gray-400 decoration-1 hover:text-blue-900">
                                                                                {result.first_name ? result.first_name + ' ' + result.last_name : result.last_name}
                                                                            </a>
                                                                            <span className="font-normal">
                                                                                <span>
                                                                                    {result?.connections[0]?.relationship && ` • ${result?.connections[0]?.relationship}`}
                                                                                    {result?.connections[0]?.related_to?.name &&
                                                                                        <a
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                            href={`/contacts/${result?.connections[0]?.related_to?.name.contact_id}`}
                                                                                            className="underline underline-offset-4 decoration-gray-400 decoration-1 hover:text-blue-900"
                                                                                        >
                                                                                            {result?.connections[0]?.related_to?.name}
                                                                                        </a>}
                                                                                </span>
                                                                                {
                                                                                    `${result?.street ? result?.street + ' ' : ''}` +
                                                                                    `${result?.house_number ? result?.house_number + (result?.street ? ',' : '') : ''}` +
                                                                                    `${result?.city ? result?.city : ''}`
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        {searchContactsResults.length !== srindex + 1 && <hr />}
                                                                    </li>
                                                                ))}
                                                                {searchContactsResults.length > 24 &&
                                                                    <InView onChange={(inView) => {
                                                                        if (inView) {
                                                                            fetchContacts(searchContactValue, searchContactPage + 1)
                                                                        }
                                                                    }
                                                                    } />}
                                                            </ul>
                                                        </RadioGroup>
                                                        <input
                                                            type="submit"
                                                            value={t('Select Contact')}
                                                            className="mt-10 bg-blue-900 block mx-auto text-white rounded-lg px-4 leading-10"
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                    <div className="mt-4">
                                        {MoreDetails === npContactIndex &&
                                            <div className="mb-4">
                                                {contact?.payment_method?.payment_method_data?.callback_data ? (
                                                    <>
                                                        <div className="mb-4 font-medium text-sm">
                                                            {t('Contact Data from Nedarim Plus')}:
                                                        </div>
                                                        {Object.entries(contact?.payment_method?.payment_method_data?.callback_data).map(([key, value]) => (Object.keys(npKeys).includes(key) && value &&
                                                            <div key={key} className="flex justify-between">
                                                                <div className="font-medium text-sm">
                                                                    {t(npKeys[key])}: <span className="font-normal">{value}</span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <div className="mb-4 font-medium text-sm">
                                                        {t('No Contact Data from Nedarim Plus')}
                                                    </div>
                                                )
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                {npContactIndex !== npContacts.length - 1 && <hr />}
                            </Collapse>
                        ))}
                    </TransitionGroup>
                </div>
                <Pagination onChange={(page) => setNpPage(page)} totalPages={npTotalPages} currentPage={npPage} setCurrentPage={setNpPage} />
                <SmallPopover
                    title={t('Search Contact')}
                    text={t('Select a contact to associate with the donation')}
                    optionAnchorEl={searchContactAnchorEl}
                    onClose={() => {
                        setSearchContactAnchorEl(null)
                        setSearchContactValue('')
                        setSearchContactDonation({})
                    }}
                    onConfirm={() => {
                        handleSelectContact(searchContactDonation?.donation_id, searchContactValue, searchContactDonation?.contact_id, searchContactDonation?.amount, searchContactDonation?.currency)
                        // handleSelectContact(searchContactDonation?.donation_id, selectedSearchContact, searchContactDonation?.contact_id, searchContactDonation?.amount, searchContactDonation?.currency)
                    }}
                >
                    <SearchContact
                        onChange={(e) => setSearchContactValue(e?.id || '')}
                        value={searchContactValue}
                        size="small"
                        placeholder={t('Search Contact')}
                        label={t('Search Contact')}
                        variant='outlined'
                    />
                </SmallPopover>

            </div>
        );
    }

    return (
        <>
            <H1>{t('Fix and Merge')}</H1>
            {npContacts.length > 0 ? renderNedarimPlus() :
                <p>{t('No Contacts to fix')}</p>}
            <Snackbar
                open={snackbar}
                onClose={() => setSnackbar(false)}
                autoHideDuration={5000}
                message={t('Donation Updated')}
                action={
                    <>
                        <Button
                            size="small"
                            LinkComponent={Link}
                            to={snackbarViewButton}
                            target="_blank"
                        >
                            {t('View Donation')}
                        </Button>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.5 }}
                            onClick={() => setSnackbar(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                }
            ></Snackbar>
        </>
    );
}

export default FixContacts;