import { ArrowDropDownRounded, ArrowDropUpRounded, PlaylistAddRounded, TuneRounded } from "@mui/icons-material";
import { Box, Checkbox, Chip, Collapse, Divider, FormControlLabel, IconButton, Stack, TextField, Typography } from "@mui/material";
import Section from "components/common/Section";
import ToggleIcon from "material-ui-toggle-icon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Filters, { ChecklistFilter } from "./Filters";
import { itemStatus } from "data/general";
import HelperTooltip from "components/common/HelperTooltip";
import { LoadingButton } from "@mui/lab";

function GroupDetails({ group, type, statusFilters, setStatusFilters, saveQuery }) {
    const { t } = useTranslation();
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [statusFilterAnchorEl, setStatusFilterAnchorEl] = useState(null);
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [name, setName] = useState(group.name);
    const [description, setDescription] = useState(group.description);
    const [toDoList, setToDoList] = useState(group.type);
    const [filterCriteria, setFilterCriteria] = useState(group.filter_criteria);

    const isDirty = name !== group.name || description !== group.description || toDoList !== group.type || JSON.stringify(filterCriteria) !== JSON.stringify(group.filter_criteria);

    const { mutate: saveGroup, isLoading: saving } = saveQuery;

    const handleSave = () => {
        saveGroup({ name, description, type: toDoList, filter_criteria: filterCriteria });
    }
        

    return (
        <Section>
            <Box display="flex" justifyContent="space-between" alignItems='center'>
                <Stack direction="row" spacing={2} alignItems='center'>
                    <Typography>
                        <strong>{group.count}</strong> {t(type)}
                    </Typography>
                    {group.type &&
                        <>
                            <Typography>
                                <strong>{group.todo_count}</strong> {t("To Do")}
                            </Typography>
                            <IconButton onClick={(e) => setStatusFilterAnchorEl(e.currentTarget)}>
                                <TuneRounded />
                            </IconButton>
                            <ChecklistFilter
                                anchorEl={statusFilterAnchorEl}
                                open={Boolean(statusFilterAnchorEl)}
                                setAnchorEl={setStatusFilterAnchorEl}
                                filterField={{ name: 'list_status', type: 'checklist', label: 'Status', filterOptions: ['in'] }}
                                options={itemStatus.map((item) => ({ value: item.value, label: item.label }))}
                                checked={statusFilters}
                                addFilter={(k, v) => setStatusFilters([...statusFilters, v])}
                                removeFilter={(k, v) => setStatusFilters(statusFilters.filter((item) => item !== v))}
                            />
                            {statusFilters.map((status) => (
                                <Chip
                                    key={status}
                                    label={itemStatus[status].label}
                                    onDelete={() => setStatusFilters(statusFilters.filter((item) => item !== status))}
                                />
                            ))}
                        </>
                    }
                </Stack>
                <Chip
                    label={t('Settings')}
                    deleteIcon={<ToggleIcon
                        on={settingsOpen}
                        offIcon={<ArrowDropDownRounded />}
                        onIcon={<ArrowDropUpRounded />}
                    />}
                    onDelete={() => setSettingsOpen(!settingsOpen)}
                    onClick={() => setSettingsOpen(!settingsOpen)}
                />
            </Box>
            <Collapse in={settingsOpen}>
                {settingsOpen &&
                    <Box display='flex' flexDirection='column' gap={3.5}>
                        <Divider sx={{ mt: 3.5, mx: -3.5 }} />
                        <Stack direction="row" spacing={3.5}>
                            <TextField
                                variant="standard"
                                label={t('Name')}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                variant="standard"
                                label={t('Description')}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Stack>
                        <FormControlLabel
                            label={t('To Do List')}
                            control={<Checkbox
                                checked={toDoList}
                                onChange={(e) => setToDoList(e.target.checked)}
                            />}
                        />
                        <Stack direction="row" spacing={2} alignItems='center'>
                            <Typography>
                                {t("Auto-Grouping Filters")}&nbsp;
                                <HelperTooltip variant="help" content={`${t("Automatically adds")} ${t(type)} ${t("to this group based on set criteria")}`} />
                            </Typography>
                            <IconButton onClick={(e) => setFilterAnchorEl(e.currentTarget)}>
                                <PlaylistAddRounded />
                            </IconButton>
                            <Filters
                                filterSet={type}
                                filtersAnchorEl={filterAnchorEl}
                                setFiltersAnchorEl={setFilterAnchorEl}
                                jsonFilters={filterCriteria}
                                setJsonFilters={(e) => setFilterCriteria(e)}
                                saveFilterButton={false}
                            />
                        </Stack>
                        <div>
                            <LoadingButton
                                variant="outlined"
                                color="primary"
                                loading={saving}
                                sx={{ borderRadius: 99 }}
                                disabled={!isDirty}
                                onClick={handleSave}
                            >
                                {t('Save')}
                            </LoadingButton>
                        </div>
                    </Box>}
            </Collapse>
        </Section>
    );
}

export default GroupDetails;