import { useTranslation } from "react-i18next";
import Container from "./container";
import SectionTitle from "./sectionTitle";
import { FaRegChartBar, FaUserFriends, FaDonate } from "react-icons/fa"; // For Donor Management

const featuresData = [
    {
        icon: (<FaUserFriends size={52} />),
        title: "Donor Management",
        paragraph:
            "slide-description-1",
    },
    {
        icon: (<FaRegChartBar size={52} />),
        title: "Donation Tracking",
        paragraph:
            "slide-description-2",
    },
    {
        icon: (<FaDonate size={52} />),
        title: "Donor Retention",
        paragraph:
            "slide-description-3",
    },
];

const SingleFeature = ({ feature }) => {
    const { icon, title, paragraph } = feature;
    const { t } = useTranslation();
    return (
        <div className="relative flex flex-col justify-between w-full h-full bg-white dark:bg-gray-900 p-16 rounded-3xl shadow-2xl">
            <div className="absolute -start-8 -top-8 p-8 bg-gray-300 dark:bg-gray-700 mb-10 flex items-center justify-center rounded-full">
                {icon}
            </div>
            <h3 className="mb-5 text-xl font-bold sm:text-2xl lg:text-xl xl:text-2xl">
                {t(title)}
            </h3>
            <p className="pe-[10px] text-base leading-relaxed">
                {t(paragraph)}
            </p>
        </div>
    );
};


const Features = () => {
    return (
        <div className="text-center bg-gray-100 dark:bg-gray-800">
            <Container>
                <section
                    id="features"
                    className="pb-16"
                >
                    <div className="">
                        <SectionTitle
                            title="Main Features"
                            paragraph="There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form."
                            center
                        />

                        <div className="grid gap-10 lg:grid-cols-2 xl:grid-cols-3 gap-x-16 gap-y-14">
                            {featuresData.map((feature, index) => (
                                // index === 0 && (
                                <div key={index} className={`${index === 0 && "lg:col-span-2 xl:col-auto"}`}>
                                    <SingleFeature feature={feature} />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </Container>
        </div>
    );
};

export default Features;