import { LoadingButton } from "@mui/lab";
import { Autocomplete, Stack, TextField } from "@mui/material";
import InfoModal from "components/common/InfoModal";
import { CurrencyMask } from "components/utils/masks";
import Validations from "components/utils/validations";
import currencies from "data/currencies";
import useValidation from "hooks/useValidation";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { errorsStore } from "stores/ErrorsStore";
import { mainStore } from "stores/MainStore";

const currencyValues = Object.keys(currencies);

function CampaignForm({ onSave, onClose, saving, title, formDescription, type, data = {} }) {
	const { t } = useTranslation();
	const { settings } = mainStore;
	const { error400, setError400 } = errorsStore;
	const nameField = useValidation([{ validation: Validations.isNotBlank, message: 'Required' }]);
	const [description, setDescription] = useState(data.description || '');
	const [currency, setCurrency] = useState(data.currency || settings.currency);
	const [goal, setGoal] = useState(data.goal || '0');
	const [startDate, setStartDate] = useState(data?.start_date ? new Date(data.start_date).toLocaleInput(): '');
	const [endDate, setEndDate] = useState(data?.end_date ? new Date(data.end_date).toLocaleInput(): '');

	const validate = () => {
		if (nameField.validate()) {
			onSave({
				name: nameField.value,
				description,
				goal_currency: currency,
				goal,
				...(type !== 'groups' ? {
					start_date: startDate || null,
					end_date: endDate || null,
				} : {})
			});
		}
	}

	useEffect(() => {
		nameField.setValue(data.name || '')
		return () => setError400({})
	}, [])

	return (
		<InfoModal
			open
			title={title}
			onClose={onClose}
			description={formDescription}
			sx={{ width: '36rem' }}
		>
			<form autoComplete="off">
				{type !== 'fundraisers' &&
					<TextField
						sx={{ width: '50%' }}
						required
						variant="standard"
						label={t("Name")}
						error={nameField.error || error400?.name}
						value={nameField.value}
						helperText={nameField.errorText || error400?.name?.[0]}
						onChange={nameField.handleChange}
					/>}
				<Stack spacing={2}>
					<TextField
						variant="standard"
						fullWidth
						label={t("Description")}
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						error={error400?.description}
						helperText={error400?.description?.[0]}
					/>
					<Stack direction="row" spacing={4}>
						<TextField
							variant="standard"
							fullWidth
							label={t("Goal")}
							InputProps={{
								inputComponent: CurrencyMask,
							}}
							value={goal}
							onChange={(e) => setGoal(e.target.value)}
							error={error400?.goal}
							helperText={error400?.goal?.[0]}
						/>
						<Autocomplete
							clearOnEscape
							disableClearable
							fullWidth
							options={currencyValues}
							getOptionLabel={(option) => t(currencies[option].name)}
							renderInput={(params) =>
								<TextField
									{...params}
									variant="standard"
									label={t("Goal Currency")}
									error={error400?.goal_currency}
									helperText={error400?.goal_currency?.[0]}
								/>}
							filterSelectedOptions={false}
							value={currency}
							onChange={(event, newValue) => setCurrency(newValue)}
						/>
					</Stack>
					{!['groups', 'fundraisers'].includes(type) &&
						<Stack direction="row" spacing={4}>
							<TextField
								InputLabelProps={{ shrink: true }}
								variant="standard"
								fullWidth
								label={t("Start Date")}
								type="datetime-local"
								value={startDate}
								onChange={(e) => setStartDate(e.target.value)}
								error={error400?.start_date}
								helperText={error400?.start_date?.[0]}
							/>
							<TextField
								InputLabelProps={{ shrink: true }}
								variant="standard"
								fullWidth
								label={t("End Date")}
								type="datetime-local"
								value={endDate}
								onChange={(e) => setEndDate(e.target.value)}
								error={error400?.end_date}
								helperText={error400?.end_date?.[0]}
							/>
						</Stack>}
					<div className="text-center">
						<LoadingButton
							variant="contained"
							sx={{ mt: 2 }}
							loading={saving}
							onClick={() => validate()}
						>
							{t("Save")}
						</LoadingButton>
					</div>
				</Stack>
			</form>
		</InfoModal>
	);
}

export default observer(CampaignForm);
