import { LoadingButton } from "@mui/lab";
import { Alert, Box, TextField } from "@mui/material";
import InfoModal from "components/common/InfoModal";
import CountryInput from "components/general/CountryInput";
import useSubscription from "hooks/useSubscription";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mainStore } from "stores/MainStore";

function OrganizationDetails({ open, onClose, onSave, organizationAlert, saveText = 'Save'}) {
    const { t } = useTranslation();
    const { subscription } = mainStore;
    const { updateSubscription } = useSubscription();
    const { isLoading, isError, error, isSuccess } = updateSubscription;
    const { enqueueSnackbar } = useSnackbar();
    const [organizationName, setOrganizationName] = useState(subscription.organization_name);
    const [organizationId, setOrganizationId] = useState(subscription.organization_id);
    const [country, setCountry] = useState(subscription.country);
    const [address, setAddress] = useState(subscription.address);

    useEffect(() => {
        if (isError) {
            enqueueSnackbar(t('Failed to update organization details.'), { variant: 'error' });
        }
    }, [error]);

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar(t('Organization details updated.'), { variant: 'success' });
            // onSave();
            // onClose();
        }
    }, [isSuccess]);

    const handleSave = () => {
        updateSubscription.mutate({
            organization_name: organizationName,
            organization_id: organizationId,
            country,
            address,
        });
        if (onSave) onSave(country);
    };

    return (
        <InfoModal
            open={open}
            title={t('Organization Billing Details')}
            sx={{ width: '30rem' }}
            onClose={onClose}
        >
            <div className="flex justify-center">
                <Alert severity="info" icon sx={{ m: 1 }}>
                    {organizationAlert || t('This information would appear on your receipt.')}
                </Alert>
            </div>
            <Box sx={{ '& .MuiTextField-root': { m: 1 } }}>
                <div className="flex gap-x-4">
                    <div className="w-[13.75rem]">
                        <CountryInput
                            country={country}
                            setCountry={setCountry}
                        />
                    </div>
                    <TextField
                        label={t('Address')}
                        variant="standard"
                        size="small"
                        placeholder={t('Address')}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>
                <TextField
                    label={t('Organization Name')}
                    variant="standard"
                    size="small"
                    placeholder={t('Organization Name')}
                    value={organizationName}
                    onChange={(e) => setOrganizationName(e.target.value)}
                />
                <TextField
                    label={t('Organization ID')}
                    variant="standard"
                    size="small"
                    placeholder={t('Organization ID')}
                    value={organizationId}
                    onChange={(e) => setOrganizationId(e.target.value)}
                />
            </Box>
            <div className="text-center">
                <LoadingButton
                    variant="contained"
                    sx={{ mt: 4 }}
                    loading={isLoading}
                    onClick={handleSave}
                    disabled={!country}
                >
                    {t(saveText)}
                </LoadingButton>
            </div>
        </InfoModal>
    );
}

export default OrganizationDetails;