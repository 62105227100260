import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Autocomplete, Box, Breadcrumbs, Button, ButtonGroup, Chip, IconButton, Link, Stack, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, alpha, styled, toggleButtonGroupClasses } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Options from "components/Options";
import useCampaignDropdown from "hooks/usCampaignDropdown";
import useApi from "hooks/useApi";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { optionsClickOutside } from "utils/controls";
import { HighlightOffRounded, MoreVert, NavigateBeforeRounded, NavigateNextRounded } from "@mui/icons-material";
import NavigationNextIcon from "components/common/NavigationNextIcon";

const PriorityButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 'none',
        borderRadius: '0.25rem',
        fontWeight: 450,
        padding: '0.25rem 1rem',
    },
}));

const PriorityButton = styled(ToggleButton)(({ color, theme }) => ({
    backgroundColor: alpha(theme.palette[color].main, 0.2),
    color: theme.palette[color].main,
    '&.Mui-selected': {
        backgroundColor: theme.palette[color].main,
        color: theme.palette[color].contrastText,
        ':hover': {
            backgroundColor: theme.palette[color].dark,
        },
    },
    ':hover': {
        backgroundColor: alpha(theme.palette[color].main, 0.3),
    },
}));

function CampaignButton({ item, onClick, ...props }) {
    return (
        <LoadingButton
            key={item.id}
            loading={false}
            variant="outlined"
            sx={{ flexGrow: 1 }}
            onClick={onClick}
        >
            {item.name}
        </LoadingButton>
    );
}

function AddToCampaign({ cardData, contactId }) {
    const { t } = useTranslation();
    const api = useApi();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: addDonorProspect, isLoading, isSuccess } = useMutation(
        (data) => api.post(`/campaigns/add-donor-prospects`, data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('donors');
            },
            onError: () => {
                enqueueSnackbar(t('Error adding donor prospects'), { variant: 'error' });
            }
        }
    );

    return (
        <LoadingButton
            loading={isLoading}
            variant="contained"
            color={isSuccess ? 'success' : 'primary'}
            sx={{ width: '70%', alignSelf: 'center' }}
            onClick={() => addDonorProspect({ campaign_id: cardData.id, contact_ids: [contactId] })}
        >
            {isSuccess ? t('Added to Campaign') : t('Add to Campaign')}
        </LoadingButton>
    );
}

function SetCampaignCard({ cardId, cardData, contactId }) {
    const { t } = useTranslation();
    const dir = document.dir;
    const api = useApi();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const [tab, setTab] = useState('sub_campaigns');
    const { campaignDropdown, campaignDropdownLoading } = useCampaignDropdown();

    const donorUrl = `/donor/${cardData.id}/${contactId}`;

    const donorQuery = useQuery(['donors', donorUrl],
        () => api.get(donorUrl)
            .then(res => {
                return res.data;
            })
            .catch((error) => {
                if (error.response?.status === 404) return {};
                throw error;
            }),
        {
            enabled: !!cardData.id,
        }
    );

    const { data: donorData, isLoading: donorLoading, refetch: refetchDonor } = donorQuery;
    const { campaign, sub_campaign: subCampaign, group, fundraiser } = donorData || {};

    const campaignLink = `/campaigns/${cardData.name}`;
    const subCampaignLink = subCampaign ? `${campaignLink}/sub-campaigns/${subCampaign.name}` : campaignLink;
    const groupLink = group ? `${subCampaignLink}/groups/${group.name}` : subCampaignLink;

    const { sub_campaigns: subCampaigns, groups } = campaignDropdown?.find(c => c.id === campaign?.id) || {};
    const subCampaignGroups = subCampaigns?.find(sc => sc.id === subCampaign?.id)?.groups || [];

    const fundraiserParams = useMemo(() => {
        if (group && !subCampaign) setTab('groups');
        else setTab('sub_campaigns');
        if (group) return { group: group.id };
        if (subCampaign) return { sub_campaign: subCampaign.id, group: group?.id || null };
        if (campaign) return { campaign: campaign.id, sub_campaign: subCampaign?.id || null, group: group?.id || null };
        return null;
    }, [group, subCampaign, campaign]);

    const { data: fundraisersData, isLoading: fundraisersLoading } = useQuery(['fundraisersDropdown', fundraiserParams],
        () => api.get('/campaigns/fundraisers/dropdown', { params: fundraiserParams }).then(res => res.data),
        {
            enabled: !!fundraiserParams,
        }
    );

    const fundraisers = fundraisersData || [];

    const { mutate: deleteCard } = useMutation(
        () => api.delete(`/settings/contact-cards/${cardId}/remove`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('contactViewCards');
                optionsClickOutside();
            },
            onError: () => enqueueSnackbar(t('Error removing card'), { variant: 'error' }),
        }
    )

    const { mutate: removeDonor } = useMutation(
        () => api.delete(donorUrl),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('donors');
            },
            onError: () => enqueueSnackbar(t('Error removing donors.'), { variant: 'error' }),
        }
    );

    const { mutate: updateDonor, isLoading: updateLoading } = useMutation(
        (data) => api.patch(donorUrl, data),
        {
            onSuccess: refetchDonor,
            onError: () => enqueueSnackbar(t('Error updating donor'), { variant: 'error' }),
        }
    );

    return (
        <Stack spacing={2}>
            <Box display='flex' textAlign='start' justifyContent='space-between'>
                <Box>
                    <Typography fontWeight='bold' variant="subtitle2" color="text.secondary">
                        {t('Add donor to campaign')}
                    </Typography>
                    <Link
                        component={RouterLink}
                        to={campaignLink}
                        underline="hover"
                        color="primary"
                        variant="body1"
                        fontWeight="bold"
                    >
                        {cardData.name}
                    </Link>
                </Box>
                <Options
                    dense
                    optionsButton={
                        <IconButton sx={{ p: 0, ml: 1 }}>
                            <MoreVert fontSize="small" />
                        </IconButton>
                    }
                    options={
                        [
                            {
                                text: t('Remove Card'),
                                onClick: deleteCard,
                            },
                        ]
                    }
                />
            </Box>
            {!campaign ? (
                <AddToCampaign cardData={cardData} contactId={contactId} />
            ) : (
                <>
                    <Breadcrumbs
                        separator={<NavigationNextIcon sx={{ mx: '-6px', mt: 0.5 }} />}
                    >
                        <Chip
                            label={<Link
                                component={RouterLink}
                                underline="hover"
                                to={campaignLink}
                                variant="subtitle2"
                                fontWeight="bold"
                            >
                                {campaign.name}
                            </Link>
                            }
                            size="small"
                            onDelete={removeDonor}
                        />
                        {donorData?.sub_campaign ? (
                            <Chip
                                label={<Link
                                    component={RouterLink}
                                    underline="hover"
                                    to={subCampaignLink}
                                    variant="subtitle2"
                                    fontWeight="bold"
                                >
                                    {subCampaign.name}
                                </Link>
                                }
                                size="small"
                                onDelete={() => updateDonor({ sub_campaign_id: null, group_id: null, fundraiser_id: null })}
                            />) : null}

                        {donorData?.group ?
                            <Chip
                                label={<Link
                                    component={RouterLink}
                                    underline="hover"
                                    to={groupLink}
                                    variant="subtitle2"
                                    fontWeight="bold"
                                >
                                    {group.name}
                                </Link>
                                }
                                size="small"
                                onDelete={() => updateDonor({ group_id: null, fundraiser_id: null })}
                            /> : null}
                    </Breadcrumbs>
                    <Box>
                        <Typography fontWeight='bold' variant="subtitle2" color="text.secondary">
                            {t('Priority')}
                        </Typography>
                        <PriorityButtonGroup
                            fullWidth
                            exclusive
                            size="small"
                            value={donorData.priority}
                            onChange={(e, value) => value !== null && updateDonor({ priority: value })}
                        >
                            <PriorityButton
                                color="error"
                                value={2}
                            >
                                {t('High')}
                            </PriorityButton>
                            <PriorityButton
                                color="warning"
                                value={1}
                            >
                                {t('Medium')}
                            </PriorityButton>
                            <PriorityButton
                                color="success"
                                value={0}
                            >
                                {t('Low')}
                            </PriorityButton>
                        </PriorityButtonGroup>
                    </Box>
                    <TabContext value={tab}>
                        <TabList
                            onChange={(e, value) => setTab(value)}
                            variant="fullWidth"
                            sx={{
                                borderRadius: '0.25rem 0.25rem 0 0',
                                minHeight: '2rem',
                                '& .MuiTab-root': {
                                    py: 0,
                                    minHeight: '2rem',
                                    // borderRadius: '0.25rem',
                                    '&.Mui-selected': {
                                        backgroundColor: 'grey.100',
                                    },
                                },
                            }}
                        >
                            <Tab label={t('Sub Campaigns')} value="sub_campaigns" />
                            <Tab label={t('Groups')} value="groups" />
                        </TabList>
                        <TabPanel value="sub_campaigns" sx={{ p: 0 }} >
                            {subCampaigns?.length ? (
                                <PriorityButtonGroup
                                    exclusive
                                    size="small"
                                    value={subCampaign?.id}
                                    onChange={(e, value) => updateDonor({ sub_campaign_id: value, group_id: null, fundraiser_id: null })}
                                    sx={{ flexWrap: 'wrap', width: '100%' }}
                                >
                                    {subCampaigns?.map(subCampaign => (
                                        <PriorityButton
                                            key={subCampaign.id}
                                            value={subCampaign.id}
                                            color='primary'
                                            sx={{ flexGrow: 1 }}
                                        >
                                            {subCampaign.name}
                                        </PriorityButton>
                                    ))}
                                </PriorityButtonGroup>
                            ) : (
                                <Typography textAlign='center' variant="body2" color="text.secondary">
                                    {t('No sub campaigns.')}
                                </Typography>
                            )}
                            {subCampaignGroups?.length ?
                                <Typography marginTop={2} fontWeight='bold' variant="subtitle2" color="text.secondary">
                                    {t('Groups')}
                                </Typography> : null}
                            <PriorityButtonGroup
                                exclusive
                                size="small"
                                value={group?.id}
                                onChange={(e, value) => updateDonor({ group_id: value, fundraiser_id: null })}
                                sx={{ flexWrap: 'wrap', width: '100%' }}
                            >
                                {subCampaignGroups.map(group => (
                                    <PriorityButton
                                        key={group.id}
                                        value={group.id}
                                        color='primary'
                                        sx={{ flexGrow: 1 }}
                                    >
                                        {group.name}
                                    </PriorityButton>
                                ))}
                            </PriorityButtonGroup>
                        </TabPanel>
                        <TabPanel value="groups" sx={{ p: 0 }} >
                            {groups?.length ? (
                                <PriorityButtonGroup
                                    exclusive
                                    size="small"
                                    value={group?.id}
                                    onChange={(e, value) => updateDonor({ group_id: value, sub_campaign_id: null, fundraiser_id: null })}
                                    sx={{ flexWrap: 'wrap', width: '100%' }}
                                >
                                    {groups?.map(group => (
                                        <PriorityButton
                                            key={group.id}
                                            value={group.id}
                                            color='primary'
                                            sx={{ flexGrow: 1 }}
                                        >
                                            {group.name}
                                        </PriorityButton>
                                    ))}
                                </PriorityButtonGroup>
                            ) : (
                                <Typography textAlign='center' variant="body2" color="text.secondary">
                                    {t('No groups.')}
                                </Typography>
                            )}
                        </TabPanel>
                    </TabContext>
                    <Typography fontWeight='bold' variant="subtitle2" color="text.secondary">
                        {t('Fundraiser')}
                    </Typography>
                    <Autocomplete
                        fullWidth
                        size="small"
                        allowEscapeClear
                        options={fundraisers}
                        getOptionLabel={(option) => `${option.name} (${option?.contact_id || option.fundraiser_id})`}
                        loading={fundraisersLoading}
                        renderInput={(params) => <TextField sx={{ mt: -1 }} {...params} label={t('Fundraiser')} />}
                        value={fundraiser}
                        onChange={(e, value) => updateDonor({ fundraiser_id: value?.id || null })}
                    />
                </>
            )}
        </Stack>
    );
}

export default SetCampaignCard;