import { use } from 'i18next';
import React, { useState, createContext, useContext, useEffect } from 'react';

// Define a context
const ThemeContext = createContext();

// Define a provider for this context
export const DarkThemeProvider = ({ children }) => {
  // Read the current theme from local storage or maybe from an api
  if (!localStorage.getItem('theme')) {
    localStorage.setItem('theme', 'dark');
  }
  const currentTheme = localStorage.getItem('theme') || 'dark';
  const [theme, setTheme] = useState(currentTheme); // Replace this with your default theme

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  // The value that we're going to pass to our components
  const contextValue = {
    theme,
    setTheme,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

// Hook to use the context
export const useTheme = () => useContext(ThemeContext);
