import { AddRounded } from '@mui/icons-material';
import { Button, Fab } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { uiStore } from 'stores/UiStore';

const AddFab = observer(({ block }) => {
    const { fabAction } = uiStore;
    if (!fabAction) return null;

    if (block) return (
        <Button
            variant='contained'
            color='primary'
            sx={{ borderRadius: 99, height: 48, width: 48, minWidth: 48 }}
            onClick={fabAction}
        >
            <AddRounded />
        </Button>
    )

    return (
        <div className="flex justify-end">
            <div className='fixed bottom-10 text-end mx-10'>
                <Fab
                    color="primary"
                    onClick={fabAction}
                >
                    <AddRounded />
                </Fab>
            </div>
        </div>
    )
})

export default AddFab;