function Table({ children, ...otherProps }) {
    return (
        <div {...otherProps}>
            <div className="container mx-auto text-start">
                <table className="table-auto border-collapse w-full mb-4">
                    {children}
                </table>
            </div>
        </div>
    )
}

export default Table;