import { DonutLargeRounded } from "@mui/icons-material";
import TotalCard from "./TotalCard";
import { useTranslation } from "react-i18next";

function ProgressCard({ value }) {
    const { t, i18n } = useTranslation();

    return (
        <TotalCard
            title={t('Overall Progress')}
            value={value?.toLocaleString(i18n.language, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 })}
            icon={<DonutLargeRounded />}
        />
    );
}

export default ProgressCard;