import { LoadingButton } from "@mui/lab";
import { Box, Button, Popover, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function InputPopover({ title, description, onSubmit, disabled, submiting, anchorEl, setAnchorEl, onClose, children, ...props }) {
    const dir = document.dir;
    const { t } = useTranslation();

    return (
        <Popover
            open={anchorEl}
            anchorEl={anchorEl}
            onClose={onClose}
            sx={{ '& .MuiPopover-paper': { borderRadius: '0.5rem' } }}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: dir === 'rtl' ? 'left' : 'right',
            }}
            {...props}
        >
            <Stack padding={2} spacing={2}>
                <Box>
                    <Typography variant='h3' gutterBottom>{title}</Typography>
                    <Typography variant='subtitle2'>{description}</Typography>
                </Box>
                {children}
                <div className='flex justify-end gap-2 mt-4'>
                    <Button
                        onClick={onClose}
                        size="small"
                    >
                        {t('Cancel')}
                    </Button>
                    <LoadingButton
                        variant='contained'
                        color='primary'
                        onClick={onSubmit}
                        disabled={disabled}
                        loading={submiting}
                        size="small"
                    >
                        {t('Confirm')}
                    </LoadingButton>
                </div>
            </Stack>
        </Popover>
    )
}

export default InputPopover;