import { VolunteerActivismRounded } from "@mui/icons-material";
import DonationListLeg from "components/DonationListLeg";
import NoResultsFound from "components/general/NoResultsFound";
import { useTranslation } from "react-i18next";

function Donations({ donations, donationSort, setDonationSort }) {
    const { t } = useTranslation();

    if (!donations?.results) return null;

    if (!donations?.results?.length) return (
        <NoResultsFound
            title={t("No donations")}
            subtitle={t("This contact has no donations")}
            Icon={VolunteerActivismRounded}
        />
    )
    return (
        <>
            <DonationListLeg
                donations={donations?.results || []}
                sort={donationSort}
                setSort={setDonationSort}
                checkbox={false}
                donorName={false}
                email={false}
            />
        </>
    )
}

export default Donations;