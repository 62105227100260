import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { paperClasses } from '@mui/material';

// A custom theme for this app
const theme = createTheme({
    direction: 'rtl',
    palette: {
        primary: {
            main: '#1e3b8a',
        },
        secondary: {
            main: '#1e3b8a44',
        },
        error: {
            main: red.A400,
        },
        success: {
            main: '#22c55e',
            light: '#22c55e62',
        }
    },
    typography: {
        fontFamily: "inherit",
        h1: {
            fontSize: '1.875rem',
            lineHeight: '2.25rem',
            fontWeight: 'bold',
            color: '#030741',
            marginBottom: '1.5rem',
        },
        h2: {
            fontSize: '1.5rem',
            lineHeight: '1.75rem',
            fontWeight: 'bold',
            color: '#030741',
            marginBottom: '0.5rem',
        },
        h3: {
            fontSize: '1.125rem',
            fontWeight: 'bold',
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 20,
                    [`&.${paperClasses.elevation1}`]: {
                        boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)'
                    }
                }
            }
        },
        // MuiPaper: {
        //     styleOverrides: {
        //         root: {
        //             borderRadius: 20,
        //             [`&.${paperClasses.elevation1}`]: {
        //                 boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)'
        //             }
        //         }
        //     }
        // },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {
                    variant: 'h6'
                },
                subheaderTypographyProps: {
                    variant: 'body2'
                }
            },
            styleOverrides: {
                root: {
                    padding: '32px 24px 16px'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    textAlign: 'start',
                },
            },
        },
        MuiPagination: {
            defaultProps: {
                dir: 'ltr',
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: 99
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 'bold',
                    // backgroundColor: '#eeeeee'
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:last-child td, &:last-child th': {
                        borderBottom: 0,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.MuiInputLabel-standard': {
                        marginTop: '0.5rem',
                    },
                    '&.MuiInputLabel-shrink': {
                        // '&.MuiInputLabel-standard': {
                        //     color: '#030741',
                        // },
                        fontWeight: 700,
                    }
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#030741',
                    fontWeight: 500,
                },
            },
        },
        // MuiOutlinedInput: {
        //     styleOverrides: {
        //         root: {
        //             padding: '0',
        //             '&.MuiOutlinedInput-root .MuiAutocomplete-input': {
        //                 padding: '10px 4px',
        //             },
        //         },
        //     },
        // },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '0.5rem',
                },
                contained: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: '0.5rem',
                },
            },
        },
        // MuiFilledInput: {
        //     styleOverrides: {
        //         root: {
        //             '&:before, &:after': {
        //                 borderBottom: '2px solid var(--TextField-brandBorderColor)',
        //             },
        //             '&:hover:not(.Mui-disabled, .Mui-error):before': {
        //                 borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
        //             },
        //             '&.Mui-focused:after': {
        //                 borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
        //             },
        //         },
        //     },
        // },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&:before': {
                        borderBottom: '2px solid #E0E3E7',
                    },
                    '&:hover:not(.Mui-disabled, .Mui-error):before': {
                        borderBottom: '2px solid #d1d5db',
                    },
                    '&.Mui-focused:after': {
                        borderBottom: '2px solid #1e3b8a',
                    },
                    padding: '6px 0',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: {
                    padding: '6.5px 0',
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                placement: 'top',
            },
            styleOverrides: {
                tooltip: {
                    backgroundColor: 'white',
                    color: 'rgba(0, 0, 0, 0.87)',
                    maxWidth: 220,
                    border: '0.5px solid #eeeeee',
                    boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)'
                    // fontSize: typography.pxToRem(12),
                    // shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                },
            },
        },
        // MuiTooltip: {
        //     styleOverrides: {
        //         tooltip: {
        //             backgroundColor: '#ffffff',
        //             color: '#030741',
        //             boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        //             borderRadius: '1rem',
        //             border: '1px solid #E0E3E7',
        //             textAlign: 'center',
        //             fontSize: '1rem',
        //             fontWeight: 500,
        //             padding: '0.5rem 1rem',
        //         },
        //         arrow: {
        //             color: '#ffffff',
        //             // borderBlockStart: '10px solid #E0E3E7',
        //             // borderInlineStart: '10px solid transparent',
        //             // borderInlineEnd: '10px solid transparent',
        //             // borderColor: '#E0E3E7',
        //             // borderRightWidth: '1px',
        //             // boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)',
        //             // borderLeft: '1px solid #E0E3E7',
        //             // // borderTop: '1px solid #E0E3E7',
        //             // borderRight: '1px solid #E0E3E700',
        //             // borderBottom: '1px solid #E0E3E7',
        //         },
        //     },
        // },
        MuiChip: {
            styleOverrides: {
                outlined: {
                    borderWidth: '2px',
                    fontWeight: 600,
                    // borderColor: '#E0E3E7',
                },
                // filled: {
                //     fontWeight: 600,
                // },
            },
        },
    },
});

export default theme;