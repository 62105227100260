import { useAuthInfo } from "@propelauth/react";
import { useEffect } from "react";
import api from "../api/axiosInstance";
import { mainStore } from "stores/MainStore";

const useApi = () => {
    const { accessToken } = useAuthInfo();
    const { orgId } = mainStore;

    useEffect(() => {
        const reqInterceptor = api.interceptors.request.use(config => {
            config.headers.Authorization = `Bearer ${accessToken}`;
            config.params = { ...(config.params || {}), org_id: orgId };
            return config;
        });

        return () => {
            api.interceptors.request.eject(reqInterceptor);
        };
    }, [accessToken, orgId]);

    return api;
}

export default useApi;