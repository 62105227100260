import { mainStore } from "stores/MainStore";
import OrganizationDetails from "./OrganizationDetails";
import SelectEmailPlan from "./SelectEmailPlan";
import CreditCardForm from "./creditCardForm";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import useApi from "hooks/useApi";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

function UpdateEmailPlan({ onClose }) {
    const { t } = useTranslation();
    const QueryClient = useQueryClient();
    const api = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { subscription, nextEmailPlan, emailPlan } = mainStore;
    const [creditCardKey, setCreditCardKey] = useState(Date.now());
    const [openedComponent, setOpenedComponent] = useState('plan');
    const [creditCardAlert, setCreditCardAlert] = useState(null);
    const [newPlan, setNewPlan] = useState(null);
    const [pendingCount, setPendingCount] = useState(0);

    const reloadCreditCardForm = () => {
        setCreditCardAlert(null);
        setCreditCardKey(Date.now());
    };

    const onSuccessfulPlanUpdate = () => {
        QueryClient.invalidateQueries('subscription');
        enqueueSnackbar(t('Plan updated successfully'), { variant: 'success' });
        onClose();
    };

    function checkBillingStatus(billingId, intervalId) {
        api.get(`/subscription/billing/${billingId}`).then((res) => {
            if (res.data.status === 'Success') {
                clearInterval(intervalId);
                onSuccessfulPlanUpdate();
            } else if (res.data.status === 'Failed') {
                clearInterval(intervalId);
                setOpenedComponent('creditCard');
                if (res.data.code) {
                    reloadCreditCardForm();
                    setCreditCardAlert({
                        message: <span>{t('Card ending with')} <b>{res.data?.last_four}</b> {t('failed with code')} <b>{res.data.code}</b></span>,
                        severity: 'error'
                    });
                }
            } else if (res.data.status === 'Unkown') {
                clearInterval(intervalId);
                enqueueSnackbar(t('Something went wrong, Please contact support'), { variant: 'error' });
            } else if (res.data.status === 'Pending') {
                setPendingCount(pendingCount + 1);
                if (pendingCount > 60) {
                    clearInterval(intervalId);
                    enqueueSnackbar(t('Something went wrong, Please contact support'), { variant: 'error' });
                    onClose();
                }
            } else {
                clearInterval(intervalId);
                enqueueSnackbar(t('Something went wrong'), { variant: 'error' });
            }
        }).catch((error) => {
            clearInterval(intervalId);
            enqueueSnackbar(t('Something went wrong'), { variant: 'error' });
        });
    }

    const updateEmailPlan = useMutation(
        (payload) => api.post('/subscription/email', payload),
        {
            onSuccess: (response) => {
                if (response.status === 200) {
                    onSuccessfulPlanUpdate()
                } else if (response.status === 202) {
                    const billingId = response.data.billing_id;
                    const intervalId = setInterval(() => checkBillingStatus(billingId, intervalId), 1000);
                }
            },
            onError: (error) => {
                if (error.response.status === 402) {
                    setOpenedComponent('creditCard');
                } else {
                    enqueueSnackbar(t('Something went wrong'), { variant: 'error' });
                }
            }
        }
    );

    const handleEmailPlanUpdate = (data, country) => {
        const upgrading = data?.price > emailPlan?.price;
        const hasCreditCard = subscription?.subscription_credit_card;
        setNewPlan(data);
        if (!subscription.country && !country) {
            setOpenedComponent('organization');
        } else if (upgrading && hasCreditCard) {
            updateEmailPlan.mutate(data);
        } else if (upgrading && !hasCreditCard) {
            setOpenedComponent('creditCard');
        } else {
            updateEmailPlan.mutate(data);
        }
    };


    if (openedComponent === 'plan') {
        return (
            < SelectEmailPlan
                open
                onClose={onClose}
                currentPlan={nextEmailPlan || emailPlan}
                saveText={(!subscription.country || !subscription.subscription_credit_card) && t('Continue')}
                onSave={handleEmailPlanUpdate}
                saving={updateEmailPlan.isLoading || updateEmailPlan.isSuccess}
            />
        );
    } else if (openedComponent === 'organization') {
        return (
            <OrganizationDetails
                open
                saveText={t("Proceed to Payment")}
                onClose={onClose}
                onSave={(country) => handleEmailPlanUpdate(newPlan, country)}
            />
        )
    } else if (openedComponent === 'creditCard') {
        return (
            <CreditCardForm
                subscription={{
                    type: 'email',
                    name: newPlan.name,
                }}
                onCardUpdated={onClose}
                alert={creditCardAlert}
                checkBillingStatus={checkBillingStatus}
                key={creditCardKey}
                onClose={onClose}
            // currency={subscription.billing_currency}
            // amount={subscription.next_total_billing}
            />
        )
    }

}

export default UpdateEmailPlan;