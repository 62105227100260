function Box({ children, className }) {
    return (
        <div className={`my-12 bg-white rounded-2xl shadow-sm text-start p-10 ${className}`}>
            <div className="flex flex-col gap-y-8">
                {children}
            </div>
        </div>
    );
}

export default Box;