import React from "react";
import { Button, ListSubheader, MenuItem, Popover, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function SmallPopover({ title, text, optionAnchorEl, setOptionAnchorEl, selectedOption, setSelectedOption, onClose, onConfirm, children }) {
    const dir = document.dir;
    const { t } = useTranslation();

    return (
        <Popover
            open={optionAnchorEl}
            anchorEl={optionAnchorEl}
            onClose={onClose}
            sx={{ '& .MuiPopover-paper': { borderRadius: '0.5rem' } }}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: dir === 'rtl' ? 'left' : 'right',
            }}
        >
            <div className='p-4'>
                <Typography variant='h3'>{title}</Typography>
                <p className='text-sm text-gray-500 mb-2'>{text}</p>
                {children}
                <div className='flex justify-end gap-2 mt-4'>
                    <Button
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onConfirm}
                    >
                        {t('Add')}
                    </Button>
                </div>
            </div>
        </Popover>
    )
}

export default SmallPopover;