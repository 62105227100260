import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Icon, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

function Sort({ field, sort, onSort }) {
    // const [sort, setSort] = useState('');

    const onSortChange = (sort) => {
        // setSort(sort);
        onSort(field, sort);
    }

    return (
        <IconButton onClick={() => onSortChange(sort === '' ? 'asc' : sort === 'asc' ? 'desc' : '')}>
            {sort === 'asc' && <ArrowDownwardIcon fontSize='small' />}
            {sort === 'desc' && <ArrowUpwardIcon fontSize='small' />}
            {sort === '' &&
                <SyncAltIcon
                    color='secondary'
                    fontSize='small'
                    className='rotate-90'
                />}
        </IconButton>
    )

    return (
        <div className='flex'>
            <button onClick={() => onSortChange(sort === 'asc' ? '' : 'asc')}>
                <ArrowDownwardIcon color={sort !== 'asc' ? 'secondary' : ''} fontSize='small' />
            </button>
            <button onClick={() => onSortChange(sort === 'desc' ? '' : 'desc')}>
                <ArrowUpwardIcon color={sort !== 'desc' ? 'secondary' : ''} fontSize='small' />
            </button>
        </div>
    );
}

export default Sort;