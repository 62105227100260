import { makeAutoObservable } from 'mobx';

class DonationsStore {
    donationsList = [];
    donationsCount = 0;
    filters = [];
    params = new URLSearchParams('');
    search = '';
    donationId = '';
    pageSize = localStorage.getItem('donationsPageSize') || 50;
    sort = [];
    currentPage = 1;
    totalPages = 1;

    constructor() {
        makeAutoObservable(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.setSort = this.setSort.bind(this)
        this.getSort = this.getSort.bind(this)
        this.setFilters = this.setFilters.bind(this)
        this.setDonationsList = this.setDonationsList.bind(this)
        this.setSearchActive = this.setSearchActive.bind(this)
        this.setDonationId = this.setDonationId.bind(this)
        this.setSearch = this.setSearch.bind(this)
        this.setPageSize = this.setPageSize.bind(this)
        this.setParams = this.setParams.bind(this)
        this.reset = this.reset.bind(this)
        this.setDonationsCount = this.setDonationsCount.bind(this)
    }

    setDonationsCount(count) {
        this.donationsCount = count;
    }

    setParams(params) {
        this.params = params;
    }

    setSearch(search) {
        this.currentPage = 1;
        this.donationId = '';
        this.search = search;
    }

    setDonationId(donationId) {
        this.currentPage = 1;
        this.search = '';
        this.donationId = donationId;
    }

    setPageSize(pageSize) {
        this.currentPage = 1;
        this.pageSize = pageSize;
        localStorage.setItem('donationsPageSize', pageSize);
    }

    setSearchActive(searchActive) {
        this.searchActive = searchActive;
    }

    setDonationsList(donationsList) {
        this.donationsList = donationsList.map(donation => donation.donation_id);
    }

    setFilters(filters) {
        this.filters = filters;
    }

    setCurrentPage(page) {
        this.currentPage = page;
    }

    getSort(field) {
        const sortItem = this.sort.find((item) => item[0] === field);
        return sortItem ? sortItem[1] : '';
    }

    setSort(field, direction) {
        const existingSortIndex = this.sort.findIndex((item) => item[0] === field);
        if (existingSortIndex > -1) {
            if (direction !== '') {
                // Update the existing sort direction
                this.sort[existingSortIndex][1] = direction;
            } else {
                // Remove the sort if direction is empty
                this.sort.splice(existingSortIndex, 1);
            }
        } else if (direction !== '') {
            // Add a new sort if the field is not currently being sorted
            this.sort.push([field, direction]);
        }
    }

    setFilters(filters) {
        this.filters = filters
    }

    reset() {
        this.donationsList = [];
        this.donationsCount = 0;
        this.filters = [];
        this.params = new URLSearchParams('');
        this.search = '';
        this.donationId = '';
        this.pageSize = localStorage.getItem('donationsPageSize') || 50;
        this.sort = [];
        this.currentPage = 1;
        this.totalPages = 1;
    }

    get pageCount() {
        return Math.ceil(this.donationsCount / this.pageSize);
    }

    get getParams() {
        const params = {
            page: this.currentPage,
            page_size: this.pageSize,
            sort: JSON.stringify(this.sort)
        }
        if (this.donationId) {
            params.donation_id = this.donationId;
            return params
        }
        if (this.search) params.search = this.search
        // this.filters.forEach(filter => {
        //     const paramName = `${filter.name}__${filter.filterOption}`;
        //     const paramValue = JSON.stringify(filter.value.split(','));
        //     params[paramName] = paramValue;
        // });
        Array.from(this.params.keys()).forEach((key) => {
            params[key] = JSON.stringify(this.params.get(key).split(','));
        });
        return params
    }
}

export const donationsStore = new DonationsStore();