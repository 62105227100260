import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function WarningDialog({ open, onClose, onDelete: onApprove, title, text, approveText, requireInput, deleteInputText }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [inputText, setInputText] = useState('');
  const inputConfirmationText = deleteInputText || t('delete')

  useEffect(() => {
    setInputText('');
    setLoading(false);
  }, [open]);

  const handleDelete = () => {
    setLoading(true);
    onApprove();
  };

  return (
    <Dialog maxWidth='xs' open={open} onClose={onClose}>
      <DialogTitle>{title || t('Delete')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text || t('Are you sure you want to delete this item?')}
        </DialogContentText>
        {requireInput &&
          <TextField
            sx={{ mt: 2 }}
            label={<span>{t('type-text')} "<strong>{inputConfirmationText}</strong>" {t('to confirm')}</span>}
            variant='outlined'
            fullWidth
            size="small"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Cancel')}</Button>
        <LoadingButton
          onClick={handleDelete}
          color="error"
          loading={loading}
          disabled={requireInput && inputText !== inputConfirmationText}
        >
          {approveText || t('Delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default WarningDialog;