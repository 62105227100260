import { IconButton, Modal as MUIModal } from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

function Modal({ open, onClose, title, children, className }) {
    return (
        <MUIModal open={open} onClose={onClose}>
            <div className={"bg-white text-start absolute text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-3xl p-4 " + (className || "w-[48rem]")} >
                <div className="w-full flex justify-between items-center align-middle mb-4">
                    <div className="w-10 h-10"></div>
                    <h2 className="text-2xl font-bold text-center">
                        {title}
                    </h2>
                    <div className="">
                        <IconButton
                            onClick={onClose}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    </div>
                </div>
                <div className="px-4 pb-4">
                    {children}
                </div>
            </div>
        </MUIModal>
    )
}

export default Modal;