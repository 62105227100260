import api from "api/axiosInstance";
import { useQuery } from "react-query";


const useCampaignDropdown = () => {
    const query = useQuery('campaignDropdown', () => api.get('/campaigns/dropdown').then(res => res.data), {
        refetchOnWindowFocus: true,
        keepPreviousData: true,
    });

    const { data: campaignDropdown, isLoading: campaignDropdownLoading, isRefetching: campaignDropdownRefetching } = query;

    return {
        campaignDropdown,
        campaignDropdownLoading,
        campaignDropdownRefetching,
    }
}

export default useCampaignDropdown;