import { ArrowDropDownRounded, CheckRounded, DeleteSweepRounded } from "@mui/icons-material";
import { Chip } from "@mui/material";
import Options from "components/Options";
import { getItemsStatus, itemStatus } from "data/general";
import { useTranslation } from "react-i18next";

function ListStatus({ currentStatus, updateStatus, removeItem }) {
    const { t } = useTranslation();
    const dir = document.dir;

    const statusOptions = itemStatus.map((status) => ({
        key: status.label,
        text: <Chip
            label={t(status.label)}
            color={status.color}
            variant="filled"
            size="small"
        />,
        icon: status.value === currentStatus ? <CheckRounded /> : <div />,
        onClick: () => updateStatus(status.value),
    }));

    const options = [
        ...statusOptions,
        {
            key: 'remove-item',
            text: t('Remove'),
            icon: <DeleteSweepRounded />,
            onClick: removeItem,
        }
    ];

    return (
        <Options
            optionsButton={
                <Chip
                    label={<div className="flex items-center"><span>{t(getItemsStatus(currentStatus).label)}</span><ArrowDropDownRounded /></div>}
                    color={getItemsStatus(currentStatus).color}
                    variant="filled"
                    size="small"
                />
            }
            options={options}
            variant='secondary'
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: dir === 'rtl' ? 'left' : 'right',
            }}
        />

    );
}

export default ListStatus;
