import GroupDetails from "components/specific/GroupDetails";
import TableSkeleton from "components/specific/TableSkeleton";
import ListView from "components/specific/ListView";
import useApi from "hooks/useApi";
import { useContacts } from "hooks/useContacts";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { contactsStore } from "stores/ContactsStore";
import { uiStore } from "stores/UiStore";
import ContactList from "views/Contacts/Contacts/ContactList";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { optionsClickOutside } from "utils/controls";
import ListStatus from "components/specific/ListStatus";
import { DeleteOutlineRounded, PlaylistRemoveRounded, RecentActorsRounded } from "@mui/icons-material";
import WarningDialog from "components/common/DeleteDialog";
import NoResultsFound from "components/general/NoResultsFound";
import { Link, Skeleton } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";



function ContactListView() {
    const { name } = useParams();
    const api = useApi();
    const { setTitle, setOptions } = uiStore;
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const [contactParams, setContactParams] = useSearchParams('');
    const [statusFilters, setStatusFilters] = useState([0, 1]);

    const [isInitialised, setIsInitialised] = useState(false);
    const [checkedContacts, setCheckedContacts] = useState([]);
    const [deleteWarningOpen, setDeleteWarningOpen] = useState(false);

    const { contactsQuery } = useContacts({ enabled: isInitialised });
    const { currentPage, setCurrentPage, getSort, setSort, setContactId, setSearch, getParams, setParams, search, contactId, referrer, setReferrer, reset, listType, setListType, setLocation } = contactsStore;

    const { data: contactsData, isLoading: contactsLoading } = contactsQuery;
    const { results: contacts, count: contactsCount } = contactsData || {};


    useEffect(() => {
        reset();
        setTitle(name);
        contactParams.set('contactlistitems__contact_list__name', name);
        if (statusFilters.length === 0) contactParams.delete('list_status__in');
        else contactParams.set('list_status__in', statusFilters.join(','));
        setParams(contactParams);
        setIsInitialised(true);
        setLocation(location.pathname);

        return () => {
            setTitle(null);
        };
    }, [name, statusFilters]);

    useEffect(() => {
        setOptions([
            {
                text: `${t('Remove')} ${checkedContacts.length > 0 ? checkedContacts.length : ''} ${t('contacts')}`,
                icon: <PlaylistRemoveRounded />,
                onClick: () => removeItems(checkedContacts),
                disabled: checkedContacts.length === 0,
            },
            {
                text: t('Delete Group'),
                icon: <DeleteOutlineRounded />,
                onClick: () => setDeleteWarningOpen(true),
            },
        ]);

        return () => setOptions([]);
    }, [checkedContacts]);


    const groupQuery = useQuery(["contactGroup", name],
        () => api.get('/contacts/lists/', { params: { name } }).then(res => res.data[0]),
        {}
    );

    const { data: group, isLoading: groupLoading } = groupQuery;

    const loading = groupLoading;

    const { mutate: updateStatus } = useMutation(({ contact_id, status }) => api.put('/contacts/lists/item/', { status }, {
        params: { contact_id, contact_list_id: group.id },
    }), {
        onSuccess: () => {
            contactsQuery.refetch();
            groupQuery.refetch();
            optionsClickOutside();
        },
        onError: () => enqueueSnackbar(t('Failed to update status'), { variant: 'error' }),
    }
    );

    const { mutate: removeItems } = useMutation((contact_ids) => api.post('/contacts/lists/delete/',
        { contact_ids, contact_list_id: group.id }
    ), {
        onSuccess: () => {
            setCheckedContacts([]);
            contactsQuery.refetch();
            groupQuery.refetch();
            optionsClickOutside();
        },
        onError: () => enqueueSnackbar(t('Failed to remove item'), { variant: 'error' }),
    }
    );

    const { mutate: deleteGroup } = useMutation(() => api.delete(`/contacts/lists/${group.id}/update`, { params: { id: group.id } }), {
        onSuccess: () => {
            enqueueSnackbar(t('Group deleted successfully'), { variant: 'success' });
            setDeleteWarningOpen(false);
            navigate('/contacts/groups');
        },
        onError: () => enqueueSnackbar(t('Failed to delete group'), { variant: 'error' }),
    }
    );

    const updateGroupMutation = useMutation(
        (data) => api.put(`/contacts/lists/${group.id}/update`, data).then(res => res.data),
        {
            onSuccess: (res) => {
                if (res.name !== name) navigate(`/contacts/groups/${res.name}`);
                else groupQuery.refetch();
            },
            onError: () => enqueueSnackbar(t('Failed to update group'), { variant: 'error' }),
        }
    );


    if (loading || !isInitialised) return <Skeleton variant="rounded" height={96} sx={{ borderRadius: 4 }} />;

    const pageCount = Math.ceil(contactsCount / 50);

    return (
        <div>
            <GroupDetails
                group={group}
                type='contacts'
                statusFilters={statusFilters}
                setStatusFilters={setStatusFilters}
                saveQuery={updateGroupMutation}
            />
            {contactsLoading ? <TableSkeleton /> : (
                contactsCount === 0 ? (
                    <NoResultsFound
                        title={t('No Contacts')}
                        subtitle={<>{t('Go to')} <Link fontWeight="bold" underline="hover" component={RouterLink} to="/contacts">{t('Contacts')}</Link> {t('to add contacts to this group')}</>}
                        Icon={RecentActorsRounded}
                    />
                ) : (
                    <ContactList
                        contacts={contacts}
                        totalPages={pageCount}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        getSort={getSort}
                        setSort={setSort}
                        checkedContacts={checkedContacts}
                        setCheckedContacts={setCheckedContacts}
                        {...(group.type ? {
                            additionalColumns: [{
                                value: "Status",
                                sort: "list_status",
                                field: "list_status",
                                renderField: (contact) => <ListStatus currentStatus={contact.list_status || 0} updateStatus={(status) => updateStatus({ contact_id: contact.contact_id, status: status })} removeItem={() => removeItems([contact.contact_id])} />
                            }]
                        } : {})}
                    />
                )
            )}
            <WarningDialog
                open={deleteWarningOpen}
                onClose={() => setDeleteWarningOpen(false)}
                onDelete={deleteGroup}
                title={t('Delete Group')}
                text={t('Are you sure you want to delete this group') + '?'}
            />
        </div>
    )
}

export default observer(ContactListView);