import { useTranslation } from "react-i18next";
import Sort from "../../../components/sort";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { Card, Checkbox, Chip, Tooltip } from "@mui/material";
import { itemStatus } from "data/general";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { useState } from "react";
import { ListItemChangeStatus } from "../../../components/ListItemStatus";
import Pagination from "components/common/Pagination";
import Section from "components/common/Section";

function ContactList({ contacts, checkedItems, setCheckedItems, checkAll, setCheckAll, totalPages, addContact, getSort, setSort, currentPage, setCurrentPage, removeListItem, handleChangeStatus, statusField, statusFieldLabel }) {
    const { t } = useTranslation();

    const [listStatusAnchorEl, setListStatusAnchorEl] = useState(null);
    const [listStatusIndex, setListStatusIndex] = useState(0);
    const [listStatus, setListStatus] = useState(0);


    const handleCheck = (contact) => {
        setCheckedItems(prevItems => {
            if (prevItems.includes(contact)) {
                setCheckAll(false)
                return prevItems.filter(item => item !== contact)
            } else {
                const newItems = [...prevItems, contact];
                if (newItems.length === contacts.length) {
                    setCheckAll(true)
                }
                return newItems;
            }
        });
    }

    const handleCheckAll = () => {
        setCheckAll(prevCheck => !prevCheck);
        if (!checkAll) {
            const allContacts = contacts.map(contact => contact.contact_id)
            setCheckedItems(allContacts)
        } else {
            setCheckedItems([])
        }
    }

    return (
        <div className="container mx-auto text-start">
            <div className="overflow-x-auto mb-10">
                <table className="table-auto border-collapse w-full mb-3">
                    <thead>
                        <tr className="rounded-lg text-sm font-medium text-gray-700">
                            <th className="px-4 py-2">
                                <div className="flex justify-center items-center h-full">
                                    <Checkbox size="small" checked={checkAll} onChange={handleCheckAll} />
                                </div>
                            </th>
                            <th className="px-4 py-2">
                                <div className='flex gap-2 items-center'>{t('Name')}
                                    <Sort field="name" sort={getSort("name")} onSort={setSort} />
                                </div>
                            </th>
                            <th className="px-4 py-2">
                                <div className='flex gap-2 items-center'>{t('Address')}
                                    <Sort field="address" sort={getSort("address")} onSort={setSort} />
                                </div>
                            </th>
                            <th className="px-4 py-2">
                                <div className='flex gap-2 items-center'>{t('relationship')}
                                    <Sort field="relationship" sort={getSort("relationship")} onSort={setSort} />
                                </div>
                            </th>
                            <th className="px-4 py-2">{t('Emails')}</th>
                            <th className="px-4 py-2">{t('phone_number')}</th>
                            <th className="px-4 py-2">
                                <div className='flex gap-2 items-center'>{t('Description')}
                                    <Sort field="description" sort={getSort("description")} onSort={setSort} />
                                </div>
                            </th>
                            {statusField && <th className="px-4 py-2">
                                <div className='flex gap-2 items-center'>{statusFieldLabel || t('Done')}
                                    <Sort field='list_status' sort={getSort('list_status')} onSort={setSort} />
                                </div>
                            </th>}
                        </tr>
                    </thead>
                    <Section component='tbody'>
                        {contacts.map((contact, index) => (
                            <tr key={index} className={`hover:bg-gray-100 ${index === contacts.length - 1 ? '' : 'border-b '} border-gray-200 py-10`}>
                                <td className={`items-center px-4 py-4 ${index === 0 && 'rounded-ts-2xl'}
                            ${index === contacts.length - 1 && 'rounded-bs-2xl'}`}>
                                    <div className="flex justify-center items-center h-full">
                                        <Checkbox
                                            onChange={() => handleCheck(contact.contact_id)}
                                            checked={checkedItems.includes(contact.contact_id)}
                                            size="small"
                                        />
                                    </div>
                                </td>
                                <td className="px-4 py-4">
                                    <Link to={`/contacts/${contact.contact_id}`}
                                        className="underline underline-offset-4 hover:text-blue-900">
                                        {contact.first_name} {contact.last_name}
                                        {!contact.first_name && !contact.last_name && contact.contact_id}
                                    </Link>
                                </td>
                                <td className="px-4 py-4">
                                    {contact.addresses[0]?.street} {contact.addresses[0]?.house}{contact.addresses[0]?.street ? ',' : ''} {contact.addresses[0]?.city}
                                </td>
                                <td className="px-4 py-4">
                                    <div className='flex-shrink min-w-0 max-w-xs truncate-2-lines'>
                                        {contact.connections.map((connection, index) => (
                                            <React.Fragment key={index}>
                                                {connection.relationship}{' '}
                                                {connection.related_to && (
                                                    <Link
                                                        to={`/contacts/${connection.related_to.contact_id}`}
                                                        className="underline underline-offset-4 hover:text-blue-900"
                                                    >
                                                        {connection.related_to.name}
                                                    </Link>
                                                )}
                                                {index < contact.connections.length - 1 && ', '}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </td>
                                <td className="px-4 py-4">
                                    {contact.email_addresses.map((email, index) => (
                                        <div key={index}>
                                            {index === 0 ? (
                                                <a href={`mailto:${email.email}`} className="font-medium hover:text-blue-900">
                                                    {email.email}
                                                </a>
                                            ) : index === 1 ? (
                                                <Tooltip
                                                    title={contact.email_addresses.map((email, index) => (
                                                        <div key={index} className="ltr">
                                                            <a href={`mailto:${email.email}`} className="font-medium hover:text-blue-900">
                                                                {email.email}
                                                            </a>
                                                            {index < contact.email_addresses.length - 1 && ', '}
                                                        </div>
                                                    ))}
                                                    arrow
                                                >
                                                    <span className="font-medium cursor-pointer text-blue-900"> +{contact.email_addresses.length - 1}</span>
                                                </Tooltip>
                                            ) : null}
                                        </div>
                                    ))}
                                </td>
                                <td className="px-4 py-4">
                                    {contact.phone_numbers.map((phoneNumber, index) => (
                                        <div key={index}>
                                            {index === 0 ? (
                                                <a href={`tel:${phoneNumber.phone_number}`} className="font-medium hover:text-blue-900">
                                                    {phoneNumber.phone_number}
                                                </a>
                                            ) : index === 1 ? (
                                                <Tooltip
                                                    title={contact.phone_numbers.map((phoneNumber, index) => (
                                                        <div key={index} className="ltr">
                                                            <a href={`tel:${phoneNumber.phone_number}`} className="font-medium hover:text-blue-900">
                                                                {phoneNumber.phone_number}
                                                            </a>
                                                            {index < contact.phone_numbers.length - 1 && ', '}
                                                        </div>
                                                    ))}
                                                    arrow
                                                >
                                                    <span className="font-medium cursor-pointer text-blue-900"> +{contact.phone_numbers.length - 1}</span>
                                                </Tooltip>
                                            ) : null}
                                        </div>
                                    ))}
                                </td>
                                <td className={`px-4 py-4 ${index === 0 && !statusField && 'rounded-te-2xl'}
                            ${index === contacts.length - 1 && !statusField && 'rounded-be-2xl'}`}>
                                    <div className='flex-shrink min-w-0 max-w-xs truncate-2-lines'>
                                        {contact.description}
                                    </div>
                                </td>
                                {statusField && <td className={`px-4 py-4 ${index === 0 && 'rounded-te-2xl'}
                            ${index === contacts.length - 1 && 'rounded-be-2xl'}`}>
                                    <Chip
                                        label={t(itemStatus[contact?.list_status].label)}
                                        variant="filled"
                                        size="small"
                                        color={itemStatus[contact?.list_status].color}
                                        deleteIcon={<ArrowDropDownRoundedIcon />}
                                        onDelete={(e) => {
                                            setListStatusAnchorEl(e.currentTarget)
                                            setListStatusIndex(contact?.contact_id)
                                            setListStatus(contact?.list_status)
                                        }}
                                        onClick={(e) => {
                                            setListStatusAnchorEl(e.currentTarget)
                                            setListStatusIndex(contact?.contact_id)
                                            setListStatus(contact?.list_status)
                                        }}
                                    />
                                </td>}
                            </tr>
                        ))}
                    </Section>
                </table>
            </div>
            <ListItemChangeStatus
                anchorEl={listStatusAnchorEl}
                onClose={() => setListStatusAnchorEl(null)}
                listStatusIndex={listStatusIndex}
                handleChangeStatus={handleChangeStatus}
                listStatus={listStatus}
                removeListItem={removeListItem}
            />
            {addContact}
            <Pagination totalPages={totalPages} currentPage={currentPage} onChange={(p) => setCurrentPage(p)} />
        </div>
    )
}

export default ContactList