import { useTranslation } from "react-i18next";
import Details from "./Details/Details";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useApi from "hooks/useApi";
import { uiStore } from "stores/UiStore";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { optionsClickOutside } from "utils/controls";
import { DeleteRounded, EditRounded } from "@mui/icons-material";
import CampaignForm from "./CampaignForm";
import { useSnackbar } from "notistack";
import WarningDialog from "components/common/DeleteDialog";
import { Typography } from "@mui/material";
import useCampaign from "hooks/useCampaign";

function FundraiserDetails() {
	const { t } = useTranslation();
	const { campaignName, subCampaignName, groupName, fundraiserId } = useParams();
	const queryClient = useQueryClient();
	const api = useApi();
	const { setOptions } = uiStore;
	const location = useLocation();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [editFundraiser, setEditFundraiser] = useState(false);
	const [deleteFundraiserDialog, setDeleteFundraiserDialog] = useState(false);

	const { campaign: fundraiser, campaignLoading: fundraiserLoading } = useCampaign(campaignName,
		{ sub_campaign_name: subCampaignName, group_name: groupName, fundraiser_id: fundraiserId }
	);

	const { mutate: updateFundraiser, isLoading: fundraiserUpdating } = useMutation(
		(data) => api.put(`/campaigns/fundraisers/${fundraiser?.id}`, data),
		{
			onSuccess: (res) => {
				enqueueSnackbar(t('Fundraiser updated successfully.'), { variant: 'success' });
				queryClient.invalidateQueries('campaignOverview')
				setEditFundraiser(false);
				optionsClickOutside();
			},
			onError: () => enqueueSnackbar(t('Error updating fundraiser.'), { variant: 'error' }),
		}
	);

	const { mutate: deleteFundraiser, isLoading: fundraiserDeleting } = useMutation(
		() => api.delete(`/campaigns/fundraisers/${fundraiser?.id}`),
		{
			onSuccess: () => {
				enqueueSnackbar(t('Fundraiser deleted successfully.'), { variant: 'success' });
				navigate(location.pathname.split('/').slice(0, -1).join('/'));
				setDeleteFundraiserDialog(false);
				optionsClickOutside();
			},
			onError: () => enqueueSnackbar(t('Error deleting fundraiser.'), { variant: 'error' }),
		}
	);

	useEffect(() => {
		setOptions([
			{
				key: 'edit-fundraiser',
				text: t('Edit Fundraiser'),
				icon: <EditRounded />,
				onClick: () => setEditFundraiser(true),
			},
			{
				key: 'delete-fundraiser',
				text: t('Delete Fundraiser'),
				icon: <DeleteRounded />,
				onClick: () => setDeleteFundraiserDialog(true),
			}
		]);

		return () => setOptions([]);
	}, []);

	return (
		<>
			<Details key='fundraiser' type='fundraiser' data={fundraiser} loading={fundraiserLoading} />
			{editFundraiser &&
				<CampaignForm
					title={t('Edit Fundraiser')}
					data={fundraiser}
					onClose={() => setEditFundraiser(false)}
					onSave={(data) => updateFundraiser(data)}
					saving={fundraiserUpdating}
					type='fundraisers'
				/>
			}
			<WarningDialog
				open={deleteFundraiserDialog}
				requireInput
				title={t('Delete Fundraiser')}
				text={
					<div>
						<Typography variant='subtitle2' fontWeight={600} gutterBottom>
							{t('All your associations with donations, pledges and donors will be lost. (They will not be deleted)')}
						</Typography>
					</div>
				}
				onClose={() => setDeleteFundraiserDialog(false)}
				onDelete={() => deleteFundraiser()}
				approveText={t('Delete')}
			/>
		</>
	);
}

export default observer(FundraiserDetails);