import { useTranslation } from 'react-i18next';
import H1 from 'components/common/Typography/H1';
import { useAuthInfo } from '@propelauth/react';
import currencies from "data/currencies";
import { mainStore } from 'stores/MainStore';
import useApi from '../../../hooks/useApi';
import { useMutation } from 'react-query';
import H2 from 'components/common/Typography/H2';
import { Button, Divider, Stack, Typography } from '@mui/material';
import Section from 'components/common/Section';

function Settings() {
    const { t } = useTranslation()
    const authInfo = useAuthInfo();
    const { orgId, settings } = mainStore;
    const api = useApi();

    const updateLanguage = async (language) => {
        const { data } = await api.patch('/user_settings', { language })
        return data;
    }

    const { mutate: changeLanguage } = useMutation(updateLanguage, {
        onSuccess: (data) => {
            mainStore.setSettings({ ...settings, language: data.language })
        }
    })

    const updateCurrency = async (currency) => {
        const { data } = await api.patch('/org_settings', { currency })
        return data;
    }

    const { mutate: changeCurrency } = useMutation(updateCurrency, {
        onSuccess: (data) => {
            mainStore.setSettings({ ...settings, currency: data.currency })
        }
    })

    const renderSettings = () => (
        <Section>
            <Stack divider={<Divider />} spacing={2}>
                <div className="flex gap-x-4 text-start text-lg">
                    <div className='font-medium'>
                        {t('Language')}:
                    </div>
                    <div className="ms-4 outline-none">
                        <select
                            onChange={(e) => changeLanguage(e.target.value)}
                            value={settings.language}
                        >
                            <option value="en">English</option>
                            <option value="he">עברית</option>
                        </select>
                    </div>
                </div>
                <div className="flex gap-x-4 text-start text-lg">
                    <div className='font-medium'>
                        {t('Currency')}:
                    </div>
                    <div className="ms-4 outline-none ">
                        <select className='w-48'
                            onChange={(e) => changeCurrency(e.target.value)}
                            value={settings.currency}
                        >
                            {Object.keys(currencies).map((currencyCode) => (
                                <option key={currencyCode} value={currencyCode}
                                >
                                    {t(currencies[currencyCode].name)}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </Stack>
        </Section>
    )

    const renderAccounts = () => (
        <Section>
            <Stack divider={<Divider />} spacing={2}>
                <div className="flex gap-x-4 text-start items-center text-lg">
                    <div className='font-medium'>
                        {t('Account')}:
                    </div>
                    <div className="ms-4 outline-none">
                        <div>{authInfo.user.email}
                            <span>{` (${t('Member of')} ${authInfo.orgHelper.getOrgs().length} ${t('Organizations')})`}</span>
                        </div>
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        LinkComponent={'a'}
                        href={`${process.env.REACT_APP_AUTH_URL}/account`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('Manage Account')}
                    </Button>
                </div>
                <div className="flex gap-x-4 text-start items-center text-lg">
                    <div className='font-medium'>
                        {t('Organizations')}:
                    </div>
                    <div className="ms-4 outline-none">
                        <div>{`${authInfo.orgHelper.getOrg(orgId)?.orgName}`}</div>
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        LinkComponent={'a'}
                        href={`${process.env.REACT_APP_AUTH_URL}/org`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('Manage Organization')}
                    </Button>
                </div>
            </Stack>
        </Section>
    )

    return (
        <>
            <Typography variant='h1'>{t('Settings')}</Typography>
            <Typography variant='h2'>{t('General')}</Typography>
            {renderSettings()}
            <Typography variant='h2'>{t('Account')}</Typography>
            {renderAccounts()}
        </>
    );
}

export default Settings;