import { Autocomplete, TextField } from "@mui/material";
import currencies from "data/currencies";
import { useTranslation } from "react-i18next";

const currencyValues = Object.keys(currencies);

function CurrencyInput({ inputProps, onChange, ...props }) {
    const { t } = useTranslation();

    return (
        <Autocomplete
            clearOnEscape
            disableClearable
            options={currencyValues}
            getOptionLabel={(option) => t(currencies[option].name)}
            renderInput={(params) =>
                <TextField
                    {...params}
                    {...inputProps}
                />}
            filterSelectedOptions={false}
            onChange={(e, value) => {
                localStorage.setItem('currency', value)
                if (onChange) onChange(e, value)
            }}
            {...props}
        />
    );
}

export default CurrencyInput;