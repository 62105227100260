import { Skeleton, Stack } from "@mui/material";

function LoadingSkeleton() {
    return (
        <Stack marginTop={2} spacing={4}>
            <Skeleton variant="rounded" width={284} height={70} />
            <Stack spacing={2}>
                <Skeleton variant="text" />
                <Skeleton variant="rounded" height={74} />
            </Stack>
        </Stack>
    );
}

export default LoadingSkeleton;
