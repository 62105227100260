import { ArrowDropDownRounded, BorderColorRounded, InfoRounded, MoreVert, NavigateBeforeRounded, NavigateNextRounded } from "@mui/icons-material";
import { Autocomplete, Box, Breadcrumbs, Button, Card, Chip, IconButton, Link, Stack, TextField, Tooltip, Typography, alpha } from "@mui/material";
import { mainStore } from "stores/MainStore";
import { StyledChip } from "views/Campaigns/Details/Donors";
import { Link as RouterLink } from 'react-router-dom';
import Options from "components/Options";
import { useTranslation } from "react-i18next";
import InputPopover from "components/common/InputPopover";
import { useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import useApi from "hooks/useApi";
import { useSnackbar } from "notistack";
import { optionsClickOutside } from "utils/controls";
import CurrencyInput from "components/common/CurrencyInput";
import { CurrencyMask } from "components/utils/masks";
import NavigationNextIcon from "components/common/NavigationNextIcon";

function DonorAmount({ anchorEl, setAnchorEl, defaultData, onSubmit, onClose, title, description, tooltip }) {
    const { t } = useTranslation();

    const [amount, setAmount] = useState(defaultData?.amount);
    const [currency, setCurrency] = useState(defaultData.currency);
    const [submitting, setSubmitting] = useState(false);

    return (
        <InputPopover
            title={title}
            description={<>
                {description}
                <Tooltip title={tooltip}>
                    <InfoRounded fontSize="inherit" color="secondary" />
                </Tooltip>
            </>}
            onSubmit={() => onSubmit({ amount: parseFloat(amount) || null, currency, status: defaultData.status })}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            submiting={submitting}
            onClose={() => {
                setAnchorEl(null);
                onClose();
            }}
            disabled={!amount}
        >
            <Stack direction="row" spacing={2}>
                <TextField
                    size="small"
                    label={t('Amount')}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    InputProps={{
                        inputComponent: CurrencyMask,
                    }}
                    sx={{
                        width: 120,
                    }}
                />
                <CurrencyInput
                    size="small"
                    value={currency}
                    onChange={(e, value) => setCurrency(value)}
                />
            </Stack>
        </InputPopover >
    )
}

export const donorStatusMapping = {
    'Pending': {
        color: 'info',
        value: 0,
    },
    'In Progress': {
        color: 'warning',
        value: 1,
    },
    'Committed': {
        color: 'primary',
        value: 2,
        amountTitle: 'Mark as Committed',
        amountDescription: 'Mark donor as committed.',
        amountTooltip: "this-wont-add-a-new-pledge",
    },
    'Donated': {
        color: 'success',
        value: 3,
        amountTitle: 'Mark as Donated',
        amountDescription: 'Mark donor as donated.',
        amountTooltip: "this-wont-add-a-new-donation",
    },
    'Did Not Donate': {
        color: 'error',
        value: 4,
    },
}


function DonorStatus({ cardId, cardData, donorData }) {
    const { t, i18n } = useTranslation();
    const api = useApi();
    const { settings } = mainStore;
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const dir = document.dir;
    const [anchorEl, setAnchorEl] = useState(null);
    const [donorAmountData, setDonorAmountData] = useState({});
    const [donorAmounText, setDonorAmountText] = useState({});

    const { mutate: updateDonorStatus, isLoading: isSubmiting } = useMutation(
        (data) => api.patch(`/donor/${donorData.id}/update`, data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('donors');
                if (donorStatusMapping[donorData.status].value !== donorAmountData.status) {
                    optionsClickOutside();
                }
                setDonorAmountData({});
                setDonorAmountText({});
            },
            onError: () => enqueueSnackbar(t('Error updating donor status'), { variant: 'error' }),
        }
    )

    const { mutate: deleteDonorCard } = useMutation(
        () => api.delete(`/settings/contact-cards/${cardId}/remove`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('contactViewCards');
                optionsClickOutside();
            },
            onError: () => enqueueSnackbar(t('Error removing card'), { variant: 'error' }),
        }
    )


    if (!donorData) return null;

    const currency = localStorage.getItem('currency') || settings.currency;

    const campaignLink = `/campaigns/${cardData.name}`;
    const subCampaignLink = donorData.sub_campaign ? `${campaignLink}/sub-campaigns/${donorData.sub_campaign.name}` : campaignLink;
    const groupLink = donorData.group ? `${subCampaignLink}/groups/${donorData.group.name}` : subCampaignLink;

    const donorStatusOptions = Object.entries(donorStatusMapping).filter(([k, v]) => k !== 'Committed').map(([key, value]) => {
        if (key === donorData.status) return null;
        return {
            key: key,
            text: <>
                <StyledChip
                    label={t(key)}
                    color={value.color}
                    active
                    size="small"
                />
            </>,
            onClick: (e) => {
                if (['Pending', 'In Progress', 'Did Not Donate'].includes(key)) {
                    updateDonorStatus({ status: value.value })
                } else {
                    setDonorAmountData({ currency: currency, status: value.value });
                    setDonorAmountText({
                        title: t(value.amountTitle),
                        description: t(value.amountDescription),
                        tooltip: t(value.amountTooltip),
                    });
                    setAnchorEl(e.currentTarget);
                }
            }
        }
    }).filter(Boolean);


    return (
        <>
            {anchorEl && <DonorAmount
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                defaultData={donorAmountData}
                submitting={isSubmiting}
                title={donorAmounText?.title}
                description={donorAmounText?.description}
                tooltip={donorAmounText?.tooltip}
                onClose={() => setAnchorEl(null)}
                onSubmit={(data) => {
                    updateDonorStatus({ ...data });
                    setAnchorEl(null);
                }}
            />}
            <Stack spacing={2}>
                <Box display='flex' alignItems='start' justifyContent='space-between'>
                    <Box>
                        <Typography fontWeight='bold' variant="subtitle2" color="text.secondary">
                            {t('Donor in')}
                        </Typography>
                        <Link
                            component={RouterLink}
                            to={campaignLink}
                            underline="hover"
                            color="primary"
                            variant="body1"
                            fontWeight="bold"
                        >
                            {cardData.name}
                        </Link>
                    </Box>
                    <Options
                        dense
                        optionsButton={
                            <IconButton sx={{ p: 0, ml: 1 }}>
                                <MoreVert fontSize="small" />
                            </IconButton>
                        }
                        options={
                            [
                                {
                                    text: t('Remove Card'),
                                    onClick: deleteDonorCard,
                                },
                            ]
                        }
                    />
                </Box>
                <Breadcrumbs
                    separator={<NavigationNextIcon sx={{ mx: '-6px', mt: 0.5 }} />}
                >
                    {donorData?.sub_campaign &&
                        <Chip
                            label={<Link
                                component={RouterLink}
                                underline="hover"
                                to={subCampaignLink}
                                variant="subtitle2"
                                fontWeight="bold"
                            >
                                {donorData.sub_campaign.name}
                            </Link>}
                            size="small"
                        />}
                    {donorData?.group &&
                        <Chip
                            label={<Link
                                component={RouterLink}
                                underline="hover"
                                to={groupLink}
                                variant="subtitle2"
                                fontWeight="bold"
                            >
                                {donorData.group.name}
                            </Link>}
                            size="small"
                        />}
                </Breadcrumbs>
                <Stack spacing={0.5}>
                    <Box display='flex' alignItems='center'>
                        <Typography variant="body1" color="text.secondary">
                            {t('Fundraiser')}
                        </Typography>
                        {donorData?.fundraiser &&
                            <Link
                                component={RouterLink}
                                to={`/contacts/${donorData.fundraiser.fundraiser_id}`}
                                underline="hover"
                                color="primary"
                                variant="body1"
                                fontWeight="semibold"
                                sx={{
                                    ml: 1,
                                }}
                            >
                                {donorData.fundraiser.name}
                            </Link>}
                    </Box>
                    <Box display='flex' alignItems='center'>
                        <Typography variant="body1">
                            {t('Status')}:
                        </Typography>
                        <Options
                            dense
                            variant='secondary'
                            optionsButton={<StyledChip
                                color={donorStatusMapping[donorData.status].color}
                                label={
                                    <div className="flex items-center">
                                        {t(donorData.status)}
                                        <ArrowDropDownRounded />
                                    </div>
                                }
                                size="small"
                                sx={{
                                    '& .MuiChip-label': {
                                        pr: 0,
                                    },
                                }}
                            />}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: dir === 'rtl' ? 'right' : 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: dir === 'rtl' ? 'right' : 'left',
                            }}
                            options={donorStatusOptions}
                        />
                    </Box>
                    {donorData.amount &&
                        <Box display='flex' alignItems='center'>
                            <Typography variant="body1">
                                {t('Amount')}: {donorData.amount.toLocaleString(
                                    i18n.language,
                                    {
                                        style: 'currency',
                                        currency: donorData.currency,
                                    }
                                )}
                            </Typography>
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    setDonorAmountText({
                                        title: t("Update Amount"),
                                        description: t("Update the amount of the donor."),
                                        tooltip: t(`This has no effect on the on the actual ${donorData.status === 'Committed' ? t('pledge') : t('donation')}`),
                                    });
                                    setDonorAmountData({ currency: donorData.currency, amount: donorData.amount, status: donorStatusMapping[donorData.status].value });
                                    setAnchorEl(e.currentTarget);
                                }}
                            >
                                <BorderColorRounded fontSize="small" color="disabled" sx={{ ml: 1 }} />
                            </IconButton>
                        </Box>}
                </Stack>
            </Stack>
        </>
    )
}

export default DonorStatus;