import { makeAutoObservable } from "mobx";

class ErrorsStore {
    error400 = {};

    constructor() {
        makeAutoObservable(this);
        this.setError400 = this.setError400.bind(this);
    }

    setError400(error400) {
        this.error400 = error400;
    }
}

export const errorsStore = new ErrorsStore();