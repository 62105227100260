import { TextField } from '@mui/material';
import React from 'react';

function CustomInput({ label, name, type, param, placeholder, value, onChange, disabled, onFocus, validationText, className, search, onSearchItemClick, onBlur }) {
  return (
    <div className={className}>
      <div className="relative">
        <TextField
          fullWidth
          disabled={disabled}
          variant='standard'
          label={label}
          type={type ? type : "text"}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          name={name}
          autoComplete="i-dont-want-autocomplete"
        />
        {name === param && search && search.length > 0 && (
          <ul className="absolute w-full mt-1 bg-white shadow-lg border border-gray-200 rounded py-1 z-10 max-h-60 overflow-y-scroll">
            {search.map((item, index) => (
              <li
                key={index}
                className="px-3 py-1 hover:bg-gray-200 cursor-pointer"
                onClick={() => onSearchItemClick(item, name)}
              >
                {item}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default CustomInput;