import { ExpandMoreRounded, NavigateNextRounded } from "@mui/icons-material";
import { Box, Breadcrumbs, Chip, Link, Menu, MenuItem, Stack, Typography } from "@mui/material";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { Outlet, Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { uiStore } from "stores/UiStore";
import Options from "components/Options";
import { observer } from "mobx-react-lite";

const listLocations = ['campaigns', 'sub-campaigns', 'groups', 'fundraisers'];


function NavigationHeader() {
    const { t } = useTranslation();
    const { campaignName, subCampaignName, groupName, fundraiserId } = useParams();
    const { options } = uiStore;
    const location = useLocation();
    const locations = location.pathname.split('/').filter(Boolean).map(loc => decodeURIComponent(loc));
    const lastLocation = locations.pop();
    const api = useApi();
    const queryClient = useQueryClient();
    const [navigationAnchorEL, setNavigationAnchorEL] = useState(null);

    useEffect(() => {
        queryClient.resetQueries('campaignOverview');
    }, [lastLocation]);

    const breadcrumbLabels = {
        'campaigns': t('Campaigns'),
        'sub-campaigns': t('Sub Campaigns'),
        'groups': t('Teams'),
        'fundraisers': t('Fundraisers'),
    }

    const prevLocLabelMap = {
        'campaigns': t('Campaign'),
        'sub-campaigns': t('Sub Campaign'),
        'groups': t('Team'),
        'fundraisers': t('Fundraiser'),
    }

    const locationsData = locations.reduce((acc, location, index) => {
        if (listLocations.includes(location)) {
            acc.push({ location, id: locations[index + 1] });
        }
        return acc;
    }, []);

    const previosListLocation = locationsData.slice(-1)[0]?.location;

    const params = {
        sub_campaign_name: subCampaignName,
        group_name: groupName,
        fundraiser_id: fundraiserId,
    }

    const campaignQuery = useQuery(['campaignDetails', lastLocation],
        () => api.get(`/campaigns/${campaignName}/overview`, { params }).then(res => res.data),
        {
            // enabled: !!campaignName && (['campaigns', 'sub-campaigns', 'groups'].includes(lastLocation) || Boolean(fundraiserId)),
            enabled: !!fundraiserId,
        }
    );

    const { data: campaign, isLoading: campaignLoading } = campaignQuery;

    const getBreadcrumbLabel = (breadcrumb) => breadcrumbLabels[breadcrumb] || breadcrumb

    return (
        <Box>
            <Breadcrumbs
                sx={{ mb: '1rem', mt: '-2rem' }}
                separator={<NavigateNextRounded fontSize="small" sx={{ mx: '-6px' }} />}
            >
                {locations.map((location, index) => (
                    <Link component={RouterLink} key={index} underline="hover" to={'/' + locations.slice(0, index + 1).join('/')}>
                        {getBreadcrumbLabel(location)}
                    </Link>)
                )}
                {listLocations.slice(1).includes(lastLocation) &&
                    <Chip
                        label={fundraiserId ? campaign?.name || ' ' : getBreadcrumbLabel(lastLocation)}
                        size="small"
                        deleteIcon={<ExpandMoreRounded />}
                        onDelete={(e) => setNavigationAnchorEL(e.currentTarget)}
                        onClick={(e) => setNavigationAnchorEL(e.currentTarget)}
                    />}
            </Breadcrumbs>
            <Menu
                anchorEl={navigationAnchorEL}
                open={Boolean(navigationAnchorEL)}
                onClose={() => setNavigationAnchorEL(null)}
                MenuListProps={{
                    dense: true,
                    sx: { minWidth: '120px' }
                }}
            >
                {listLocations.slice(listLocations.indexOf(previosListLocation) + 1).map((location, index) => (
                    <MenuItem
                        key={index}
                        component={RouterLink}
                        to={'/' + locations.join('/') + '/' + location}
                        onClick={() => setNavigationAnchorEL(null)}
                    >
                        {breadcrumbLabels[location]}
                    </MenuItem>
                ))}
            </Menu>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <div>
                    <Typography variant="h1">
                        {fundraiserId ? campaign?.name || ' ' : getBreadcrumbLabel(lastLocation)}
                    </Typography>
                    {previosListLocation &&
                        <Typography fontWeight='bold' variant="subtitle2" sx={{ mb: '-0.5rem', mx: '0.5rem', textTransform: 'capitalize' }}>
                            {listLocations.includes(lastLocation) ? getBreadcrumbLabel(locations.slice(-1)[0]) : prevLocLabelMap[previosListLocation]}
                        </Typography>}
                </div>
                <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
                    {/* <Button
                        variant="outlined"
                        size="small"
                        sx={{
                            textTransform: 'none',
                            borderRadius: 99,
                        }}
                    >
                        {t('Export Contacts')}
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        sx={{
                            textTransform: 'none',
                            borderRadius: 99,
                        }}
                    >
                        {t('Export Contacts')}
                    </Button> */}
                    {options?.length ? <Options options={options} /> : null}
                </Stack>
            </Box>
            <Outlet />
        </Box>
    );
}

export default observer(NavigationHeader);
