class Validations {
    static isNotBlank(value) {
        return value.trim() !== '';
    }

    static isEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
}

export default Validations;