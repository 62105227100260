// needs refactoring
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/logo.svg';
import { useLogoutFunction, useRedirectFunctions, withAuthInfo } from '@propelauth/react';
import { HiOutlineLogout } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import CampaignRounded from '@mui/icons-material/CampaignRounded';
import { Badge, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { mainStore } from 'stores/MainStore';
import { InfoRounded } from '@mui/icons-material';

const NAV_ITEMS = [
	{
		name: 'Contacts',
		icon: GroupsRoundedIcon,
		path: '/contacts',
	},
	{
		name: 'Donations',
		icon: VolunteerActivismRoundedIcon,
		path: '/donations',
	},
	{
		name: 'Expenses',
		icon: AccountBalanceWalletRoundedIcon,
		path: '/expenses',
	},
	{
		name: 'Addons',
		icon: ExtensionRoundedIcon,
		path: '/addons',
	},
	{
		name: 'Campaigns',
		icon: CampaignRounded,
		path: '/campaigns',
	}
]

function SideNav() {
	const [showPopup, setShowPopup] = useState(false);
	const [hidePopup, setHidePopup] = useState(true);
	const logoutFunction = useLogoutFunction();
	const { redirectToAccountPage } = useRedirectFunctions()
	const { orgId, subscription } = mainStore;
	const { t, i18n } = useTranslation();
	const direction = i18n.dir();

	const [itemPy, setItemPy] = useState('0.25rem');
	const [itemIcon, setItemIcon] = useState('large');

	useEffect(() => {
		setTimeout(() => {
			if (hidePopup === false && showPopup === false) {
				setHidePopup(true)
			}
		}, 300)
	}, [showPopup])

	useEffect(() => {
		const handleResize = () => {
			if (window.innerHeight > 712) {
				setItemPy('0.75rem');
				setItemIcon('large');
			} else if (window.innerHeight > 576) {
				setItemPy('0.5rem');
				setItemIcon('medium');
			} else {
				setItemPy('0.25rem');
				setItemIcon('small');
			}
		};
	
		// Call the handleResize function initially to set the state
		handleResize();
	
		// Attach the event listener
		window.addEventListener('resize', handleResize);
	
		// Clean up function
		return () => {
			// Remove the event listener
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const Auth = withAuthInfo((props) => {
		return (
			<div className='p-3'>
				{/* <hr /> */}
				<div
					className="flex space-y-2"
				>
					<img src={props.user.pictureUrl} height={42} width={42}
						className='rounded-full border-2 my-3 me-3 border-blue-900'
						alt="profile" />
					<div className='items-start text-start'>
						<strong>{props.orgHelper.getOrg(orgId)?.orgName}</strong>
						<p>{props.user.email}</p>
					</div>
				</div>
				<hr className='my-3' />
				<div className='flex justify-between'>
					<button onClick={redirectToAccountPage} className='text-left'>{t('Account')}</button>
					<button onClick={logoutFunction} className='text-left'>
						{t('Logout')}
						<HiOutlineLogout className='inline-block ms-2 rtl:rotate-180' size={24}></HiOutlineLogout>
					</button>
				</div>
			</div>
		)
	});

	return (
		<Drawer
			variant="permanent"
			anchor="left"
			PaperProps={{
				component: 'nav',
			}}
			sx={{
				zIndex: 0,
				width: '5rem',
				'& .MuiDrawer-paper': {
					width: '5rem',
					boxSizing: 'border-box',
					backgroundColor: '#1e3a8a',
					color: 'white',
					borderRadius: '0 1rem 1rem 0',
				},
			}}
		>
			<Link to="/contacts" className="text-white p-2 hover:text-blue-300 flex flex-col items-center mt-4">
				<Badge
					badgeContent={t('Beta')}
					color="success"
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					sx={{
						'& .MuiBadge-badge': {
							left: 24,
							top: 28,
							borderRadius: 1,
							color: 'white',
						},
					}}
				>
					<div className="text-white fill-white border-white">
						<img
							src={logo}
							alt="logo"
							width="40"
							height="40"
							className="w-12 p-1.5 bg-white rounded-md"
						/>
					</div>
				</Badge>
			</Link>
			<List sx={{ height: '100%' }}>
				{NAV_ITEMS.map((item) => (
					<ListItem
						key={item.name}
						dense
					>
						{/* {item.name === 'Campaigns' && !subscription.campaigns &&
							<Tooltip placement="top-start" title={
								<>
									<Typography color="inherit">{t('Coming Soon!')}</Typography>
									{t('Launching on Wednesday, 28th of February AT 8PM.')}
									<br />
									{t('For registration and more details, contact us at:')}
									<a href="mailto:info@donbar.org"> info@donbar.org</a>
								</>
							}>
								<Badge
									badgeContent={<InfoRounded fontSize="small" />}
									sx={{
										'& .MuiBadge-badge': {
											left: 20,
											top: -32,
										}
									}}
								/>
							</Tooltip>} */}
						<ListItemButton
							component={Link}
							to={item.path}
							// disabled={item.name === 'Campaigns' && !subscription.campaigns}
							sx={{
								flexDirection: 'column',
								// py: '0.75rem',
								// add padding y only if screen height is more than 600px
								py: itemPy,
								px: '0.5rem',
								'&:hover': {
									color: '#93c5fd',
								},
							}}
						>
							<ListItemIcon sx={{ justifyContent: 'center', color: 'inherit' }}>
								<item.icon fontSize={itemIcon} />
							</ListItemIcon>
							<ListItemText
								primary={t(item.name)}
								primaryTypographyProps={{
									sx: {
										fontSize: '0.75rem',
										fontWeight: 'bold',
										whiteSpace: 'nowrap',
									},
								}}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<div className="mb-20"
				onMouseEnter={() => {
					setHidePopup(false)
					setShowPopup(true)
				}}
				onMouseLeave={() => {
					setShowPopup(false)
				}}
			>
				<Link to="/settings/account" className="text-white p-2 hover:text-blue-300 mb-4">
					<ManageAccountsRoundedIcon fontSize='large' />
				</Link>
				<div className={`popup ${direction === 'rtl' ? 'rtl' : 'ltr'} fixed text-black start-20 bottom-16 bg-white rounded-2xl shadow-2xl ${showPopup ? 'active' : (hidePopup && 'hidden')}`}>
					<Auth />
				</div>
			</div>
		</Drawer>
	)
}

export default SideNav;