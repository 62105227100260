import Pagination from "components/common/Pagination";
import DynamicTable from "components/general/DynamicTable";
import Sort from "components/sort";
import { useTranslation } from "react-i18next";

function DonationList({ donations, totalPages, currentPage, setCurrentPage, getSort, setSort, checkedDonations, setCheckedDonations, additionalColumns = [] }) {
    const { t } = useTranslation();

    const handleCheck = (donationId) => {
        const donationChecked = checkedDonations.includes(donationId);
        if (donationChecked) setCheckedDonations(prev => prev.filter(d => d !== donationId));
        else setCheckedDonations(prev => [...prev, donationId]);
    };

    const handleCheckAll = () => {
        if (checkedDonations.length === donations.length) setCheckedDonations([]);
        else setCheckedDonations(donations.map(donation => donation.donation_id));
    };

    const columns = [
        {
            value: t('number'),
            field: 'donation_id',
        },
        {
            value: t("donor"),
            sort: "name",
            field: 'donor',
            componentType: 'item_contact'
        },
        {
            value: t("date"),
            sort: "donation_date",
            field: 'donation_date',
            componentType: 'date'
        },
        {
            value: t("Donation Type"),
            sort: "donation_type",
            field: 'donation_type',
        },
        {
            value: t("amount"),
            sort: "amount",
            field: 'donation_amount',
            componentType: 'currency_amount'
        },
        {
            value: t("payments"),
            sort: "payments",
            field: 'donation_payments',
        },
        {
            value: t("fundraiser"),
            sort: "fundraiser",
            field: 'fundraiser',
        },
        {
            value: t("Notes"),
            sort: "donor_note",
            field: 'donor_note',
        },
        ...additionalColumns
    ];

    return (
        <>
            <DynamicTable
                columns={columns}
                rows={donations || []}
                checkField={'donation_id'}
                checkedRows={checkedDonations}
                handleCheck={(donation) => handleCheck(donation.donation_id)}
                handleCheckAll={handleCheckAll}
                getSort={getSort}
                onSort={setSort}
            />
            <Pagination sx={{ mt: '2rem' }} totalPages={totalPages} currentPage={currentPage} onChange={setCurrentPage} />
        </>
    );
}

export default DonationList;